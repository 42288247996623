import {ProfileType} from "../ClientManagement/models/ProfileResponse";
import {selectProfile} from "../ClientManagement/ClientProfile/activeProfileSlice";
import {useAppSelector} from "../store/hooks";
import {selectProposals} from "../ClientManagement/Proposals/proposalsSlice";
import {selectReleaseToggles} from "../ReleaseToggles/releaseTogglesSlice";


const useProfileEditableState = () => {
    const profileState = useAppSelector(selectProfile);
    const proposalsState = useAppSelector(selectProposals);
    const releaseToggles = useAppSelector(selectReleaseToggles);

    const isArchiveReadOnly = !! profileState?.archived;
    const isProfileWithProposalsReadOnly = releaseToggles?.enableBlockProfileEditsWithActiveProposals
        && profileState.type === ProfileType.PROFILE
        && proposalsState.length > 0;
    const isProfileWithProposalsOrArchived = isArchiveReadOnly || isProfileWithProposalsReadOnly
    const isArchivedActiveClient = isArchiveReadOnly && profileState?.activeClient

    return {
        isArchiveReadOnly,
        isProfileWithProposalsReadOnly,
        isProfileWithProposalsOrArchived,
        isArchivedActiveClient
    };
}

export default useProfileEditableState;
