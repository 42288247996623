import React from "react";

interface AssetReliancePointerProps {
    id: string;
    label: string;
    width: string;
    height?: string;
    zIndex?: number;
    verticalOffset: string;
    marginLeft?: number,
    lineColor: string;
    textColor: string;
}

const ChartStatusLine: React.FC<AssetReliancePointerProps> = ({
                                                                  label, width, height = '4px',
                                                                  zIndex = 1, verticalOffset, marginLeft = 310,
                                                                  lineColor, textColor, id
                                                              }) => {

    return (
        <div className="asset-reliance-chart-status-line-container"
             data-testid={id}
             style={{
                 top: `calc(${verticalOffset}%)`,
                 zIndex: zIndex,
                 marginLeft: marginLeft
             }}
        >
            <span style={{color: textColor, marginLeft: "auto"}}>{label}</span>
            <div className="horizontal-rule" style={{
                width: width,
                height: height,
                backgroundColor: lineColor,
            }}/>
        </div>
    )
}

export default ChartStatusLine;