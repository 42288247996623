import React, {MouseEvent} from "react";
import {AccordionItem, DropdownItem, InfoPopover, PageActionMenu} from "../../components";
import {DropdownGroup} from "xps-react";
import {formatCurrency} from "../../utils/format";
import LifestyleSpendingTable from "../Summary/LifestyleSpendingTable";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectGoalModel, setDiscountRateSelection} from "../controller/GoalsModelSlice";
import {DiscountRateType} from "../models/DiscountRate";
import {PlanningPeriodType} from "../../ClientManagement/models/InvestorGroupType";
import {GoalModelType} from "../models/GoalModelType";
import {GoalType} from "../models/GoalType";
import SummaryAddGoalButton from "./SummaryAddGoalButton";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";
import goalUtils from "./GoalUtils";


const LifestyleGoalListHeader = (props: {
    lifestyleGoal: LifestyleSpendingGoal,
    isProfileWithProposalsOrArchived?: boolean,
}) => {
    const {presentValue} = props.lifestyleGoal.calculatedFields;
    const dispatch = useAppDispatch();
    const {discountRateSelection} = useAppSelector(selectGoalModel);

    const handleRateDropdownItemClick = (e: MouseEvent<HTMLDivElement>) => {
        const selectedDiscount = (e.currentTarget.querySelector('input'))!.value;
        e.stopPropagation();
        dispatch(setDiscountRateSelection(selectedDiscount === "tndr" ? DiscountRateType.TRIPLE_NET : DiscountRateType.GROSS));
    };
    return (
        <div className="section-header" data-testid={`${GoalType.LIFESTYLE_SPENDING}-goal-table`}>
            <h3>
                You plan to fund <b>{formatCurrency(presentValue)}</b> in lifestyle spending:
            </h3>
            <div>
                <PageActionMenu
                    className="marginright-lg"
                    panelHeight="auto"
                    panelWidth={240}
                    aria-label="more-options-dropdown"
                    key="page-action-menu"
                >
                    <DropdownGroup groupName="Discount Rate" key={"dr-dropdown-group"}>
                        <DropdownItem key="tndr">
                            <div style={{width: "100%"}} className="page-action-menu-options" onClick={(e) => {
                                handleRateDropdownItemClick(e)
                            }}>
                                Triple Net Discount Rate
                                <input name="discount-group"
                                       type="radio" value='tndr'
                                       aria-label="triple-net-discount-rate-option"
                                       readOnly={true}
                                       checked={discountRateSelection === DiscountRateType.TRIPLE_NET}
                                />
                            </div>
                        </DropdownItem>
                        <DropdownItem className="page-action-menu-options" key={"gdr"}>
                            <div style={{width: "100%"}} className="page-action-menu-options" onClick={(e) => {
                                handleRateDropdownItemClick(e)
                            }}>
                                Gross Discount Rate <input name="discount-group" type="radio"
                                                           aria-label="gross-discount-rate" value='gdr'
                                                           readOnly={true}
                                                           checked={discountRateSelection === DiscountRateType.GROSS}/>
                            </div>
                        </DropdownItem>
                    </DropdownGroup>
                </PageActionMenu>
                <SummaryAddGoalButton isProfileWithProposalsOrArchived={props.isProfileWithProposalsOrArchived}/>
            </div>
        </div>
    );
}

export const FooterText = (props: {
    planningPeriod: PlanningPeriodType
}) => {
    return <div style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "16px",
        paddingBottom: "10px",
        background: "white"
    }}>
        <span
            style={{
                minWidth: "128px",
                maxWidth: "128px", textAlign: "right",
                fontWeight: "500",
                padding: "0 8px"
            }}>Planning Period</span>
        <span style={{
            minWidth: "128px",
            maxWidth: "128px",
            textAlign: "right",
            padding: "0 8px"
        }}>{props.planningPeriod.numberOfYears} years</span>
        <span style={{minWidth: "30px", maxWidth: "30px"}}/>
    </div>;
}

export const ReserveFooterText = (props: {
    reserveTarget: number | undefined,
    reserveTargetText: string
}) => {
    return <div style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "2px",
        paddingBottom: "16px",
        background: "white"
    }}
                data-testid={"reserveFooter"}>
        <span
            style={{
                minWidth: "128px",
                maxWidth: "250px", textAlign: "right",
                fontWeight: "500",
                padding: "0 8px"
            }}>{props.reserveTargetText}</span>
        <span style={{
            minWidth: "128px",
            maxWidth: "128px",
            textAlign: "right",
            padding: "0 8px"
        }}>{props.reserveTarget} years</span>
        <span style={{minWidth: "30px", maxWidth: "30px"}}/>
    </div>;
}

type SummaryLifestyleGoalListProps = {
    isProfileWithProposalsOrArchived?: boolean
};

const SummaryLifestyleGoalList = ({isProfileWithProposalsOrArchived = false}: SummaryLifestyleGoalListProps) => {

    const {proposal, lifestyleSpendingGoal, investorGroup} = useAppSelector<GoalModelType>(selectGoalModel);
    const reserveTargetIsSet = (proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null)
    const remainingPortfolioReserveYears = proposal.isActivePortfolioReserve ? goalUtils.getRemainingPortfolioReserveLength(proposal.portfolioReserveTargetLength, proposal.portfolioReserveActivationDate) : proposal.portfolioReserveTargetLength
    const reserveTargetText = proposal.isActivePortfolioReserve ? 'Expected Reserve Length' : 'Reserve Target'
    return (
        <>
            <section>
                <div className="goal-list">
                    <div className="accordion-tables" data-testid={`${GoalType.LIFESTYLE_SPENDING}-goal-list`}>
                        <LifestyleGoalListHeader lifestyleGoal={lifestyleSpendingGoal} isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}/>
                        <AccordionWithHeader
                            headerProps={
                                {
                                    className: "goal-list-header-grid",
                                    gridSpanClass: "grid-span-1"
                                }
                            }
                            accordionId={"LifestyleSpendingAccordion"}
                            allItems={["LifestyleSpending"]}
                            columnTitles={["Goal Type", "Present Value"]}
                            renderHeader={true}
                        >
                            <AccordionItem
                                uuid="LifestyleSpending"
                                accentColor="#52897F"
                                primaryText="Lifestyle Spending"
                                rightAlignedContent={
                                    <div className="display-flex align-items-center">
                                        {!reserveTargetIsSet && <InfoPopover
                                            content={<div
                                                /* TODO change class name since this is not a tax-info-card */
                                                className={"tax-info-card__content hover-text"}
                                                data-testid={"default-portfolio-reserve"}
                                            >This assumes a default Portfolio Reserve target equal to the number of
                                                years of lifestyle spending that your current risk control dollars
                                                can
                                                support. When assets and goals have been captured,
                                                the Set Portfolio Reserve page will help you select a custom reserve
                                                target</div>}
                                            direction="bottom"
                                            width="196px"
                                        />}
                                        <span className="font-lg paddingright-xl">
                                                {formatCurrency(lifestyleSpendingGoal.calculatedFields.presentValue)}
                                            </span>
                                    </div>
                                }
                            >
                                <LifestyleSpendingTable
                                    lifestyleSpendingPeriods={lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods}
                                    planningPeriod={investorGroup.planningPeriod}/>
                                <FooterText planningPeriod={investorGroup.planningPeriod}/>
                                {reserveTargetIsSet &&
                                    <ReserveFooterText reserveTarget={remainingPortfolioReserveYears}
                                                       reserveTargetText={reserveTargetText}/>}
                            </AccordionItem>
                        </AccordionWithHeader>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SummaryLifestyleGoalList;
