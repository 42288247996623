import {StackedBarChart} from "../../components";
import React, {useEffect, useMemo, useState} from "react";
import {formatCurrency, truncateCurrency} from "../../utils/format";
import {COLOR_ASSETS_ACCOUNTS, COLOR_EMPTY_ASSETS, COLOR_NT_GREY_050} from "../../constants/colors";
import {useAppSelector} from "../../store/hooks";
import {selectPlanSummary} from "./planSummarySlice";
import classNames from "classnames";
import {StackedBarChartItemType} from "../../components/StackedBarChart/StackedBarChartItem";
import AssetRelianceBarChartLegend, {
    assetRelianceBarChartLegend
} from "src/components/Legend/AssetRelianceBarChartLegend";

export type ExcessAssetsProps = {
    hasAssetsAndGoals: boolean;
};

type ChartLabelStyles = {
    netAssetsStyle: Position,
    goalsStyle: Position,
    excessLineStyle: Position,
    excessLabelStyle: Position,
    excessValueStyle: Position,
}

type Position = {
    top: string,
    bottom?: string
}

type ShiftClasses = {
    goalsLabel: string;
    netAssetsLabel: string;
    excessAssetsChartContainer: string;
}

const ExcessAssets: React.FC<ExcessAssetsProps> = ({hasAssetsAndGoals}) => {
    const planSummary = useAppSelector(selectPlanSummary)!;
    const [netAssetsChartLabelElement, setNetAssetsChartLabelElement] = useState<HTMLDivElement | null>(null);
    const [goalsChartLabelElement, setGoalsChartLabelElement] = useState<HTMLDivElement | null>(null);
    const [shiftClasses, setShiftClasses] = useState<ShiftClasses>({
        goalsLabel: '',
        netAssetsLabel: '',
        excessAssetsChartContainer: '',
    });

    const netAssets = planSummary.totalNetValue + planSummary.totalAssetPurchaseValue;
    const totalGoals = planSummary.goalsTotalPresentValue;
    const excessAssets = planSummary.excessAssets;
    const hasExcessAssets = excessAssets >= 0;

    const excessAssetsPercentage = hasExcessAssets ? (excessAssets / netAssets) * 100 : (Math.abs(excessAssets) / (netAssets + Math.abs(excessAssets))) * 100;
    const excessLinePosition = excessAssetsPercentage.toFixed(2);
    const excessAssetsPosition = (excessAssetsPercentage / 2).toFixed(2);
    const excessAssetsStyles: ChartLabelStyles = {
        excessLineStyle: {top: '0%', bottom: `calc(100% - ${excessLinePosition}%)`},
        excessLabelStyle: {top: `calc(${excessAssetsPosition}% - 17px)`},
        netAssetsStyle: {top: '0%'},
        goalsStyle: {top: `${excessLinePosition}%`},
        excessValueStyle: {top: `${excessAssetsPosition}%`},
    }
    const excessClassPrefix = `${hasExcessAssets ? 'excess-assets' : 'asset-shortfall'}`;
    let chartData: StackedBarChartItemType[];

    if (hasAssetsAndGoals) {
        if (hasExcessAssets) {
            chartData = [
                {
                    label: 'Excess Assets',
                    total: Math.abs(excessAssets),
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: 0,
                },
                {
                    label: 'Goals',
                    total: totalGoals,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: totalGoals,
                },
            ];
        } else {
            chartData = [
                {
                    label: 'Asset Shortfall',
                    total: Math.abs(excessAssets),
                    color: COLOR_NT_GREY_050,
                    highlight: 0,
                },
                {
                    label: 'Net Assets',
                    total: netAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: netAssets,
                },
            ];
            excessAssetsStyles.goalsStyle.top = '0%';
            excessAssetsStyles.netAssetsStyle.top = `${excessLinePosition}%`;
            excessAssetsStyles.excessLineStyle.top = '3px';
        }
    } else {
        chartData = [
            {
                label: "No Assets",
                total: 1,
                color: COLOR_EMPTY_ASSETS
            }
        ]
    }

    const assetsMessage = useMemo(() => {
        if (!hasAssetsAndGoals) {
            return (
                <>No assets or goals have been captured yet.</>
            );
        } else if (hasExcessAssets) {
            return (
                <>With this plan, your assets exceed your goals by{" "}
                    <b>{formatCurrency(excessAssets)}</b>.</>
            );
        }
        return (
            <>With this plan, there is an asset shortfall of{" "}
                <b>{formatCurrency(Math.abs(excessAssets))}.</b></>
        );
    }, [hasAssetsAndGoals, hasExcessAssets, netAssets, totalGoals]);

    useEffect(() => {
        if (hasAssetsAndGoals) {
            // Calculate if labels should be shifted upward and container expanded to accommodate
            const goalsLabelTop = goalsChartLabelElement?.getBoundingClientRect().top || 0;
            const assetsLabelTop = netAssetsChartLabelElement?.getBoundingClientRect().top || 0;
            const doAssetsAndGoalsLabelsCollide = Math.abs(goalsLabelTop - assetsLabelTop) <= 48;
            const doGoalsExceedAssets = totalGoals > netAssets;
            setShiftClasses({
                goalsLabel: classNames({
                    "excess-assets-shift-upward": doGoalsExceedAssets && doAssetsAndGoalsLabelsCollide
                }),
                netAssetsLabel: classNames({
                    "excess-assets-shift-upward": !doGoalsExceedAssets && doAssetsAndGoalsLabelsCollide
                }),
                excessAssetsChartContainer: classNames(
                    'excess-assets-chart-container',
                    doAssetsAndGoalsLabelsCollide && "excess-assets-extra-padding-top"
                ),
            });
        } else {
            setShiftClasses({
                goalsLabel: '',
                netAssetsLabel: '',
                excessAssetsChartContainer: 'excess-assets-chart-container',
            });
        }
    }, [hasAssetsAndGoals, goalsChartLabelElement, netAssetsChartLabelElement, totalGoals, netAssets]);

    const leftPanelContent = <>
        <div
            data-testid="net-assets-chart-label"
            style={excessAssetsStyles.netAssetsStyle}
            className="excess-assets-chart-label net-assets__label-color"
            ref={(element) => {
                setNetAssetsChartLabelElement(element);
            }}
        >
            <div
                className="excess-assets-chart-line net-assets-chart-line__color"
                data-testid="net-assets-chart-line"
            />
            <div className={shiftClasses.netAssetsLabel}>NET ASSETS</div>
            <div className={shiftClasses.netAssetsLabel}>{formatCurrency(netAssets)}</div>
        </div>
        <div
            data-testid="goals-chart-label"
            style={excessAssetsStyles.goalsStyle}
            className="excess-assets-chart-label goals__label-color"
            ref={(element) => {
                setGoalsChartLabelElement(element);
            }}
        >
            <div
                className="excess-assets-chart-line excess-assets-chart-line__full-width goals-chart-line__color"
                data-testid="goals-chart-line"
            />
            <div className={shiftClasses.goalsLabel}>GOALS</div>
            <div className={shiftClasses.goalsLabel}>{formatCurrency(-1 * totalGoals)}</div>
        </div>
    </>;

    const centerPanelOverlayContent = <>
        <div data-testid="assets-chart-excess-bar"
             className={`assets-chart-excess-bar ${excessClassPrefix}__bar-color`}
             style={excessAssetsStyles.excessLineStyle}/>
    </>;

    const rightPanelContent = <div
        data-testid="excess-assets-chart-label"
        style={excessAssetsStyles.excessValueStyle}
        className={`excess-assets-chart-label ${excessClassPrefix}__label-color`}
    >
        <div
            className="excess-assets-chart-line"
            data-testid="excess-value-chart-line"
        />
        <div>{hasExcessAssets ? 'EXCESS ASSETS' : 'ASSET SHORTFALL'}</div>
        <div>{formatCurrency(excessAssets)}</div>
    </div>;

    const chartLegend = <div data-testid="excess-assets-chart-legend"
                             className="excess-assets-barchart-legend">
        <div className="asset-reliance-barchart-legend-label">
            <AssetRelianceBarChartLegend legend={assetRelianceBarChartLegend.GOALS}
                                         label={"GOALS"}/>
        </div>
        <div className="asset-reliance-barchart-legend-label">
            {hasExcessAssets ?
                <AssetRelianceBarChartLegend legend={assetRelianceBarChartLegend.EXCESS_ASSETS}
                                             label={"EXCESS ASSETS"}/>
                : <AssetRelianceBarChartLegend legend={assetRelianceBarChartLegend.ASSET_SHORTFALL}
                                               label={"ASSET SHORTFALL"}/>}

        </div>
        <div className="asset-reliance-barchart-legend-label">
            <AssetRelianceBarChartLegend legend={assetRelianceBarChartLegend.NET_ASSETS}
                                         label={"NET ASSETS"}/>
        </div>
    </div>;

    return (
        <div className={"excess-assets-card"}>
            <div className="sub-header">Excess Assets</div>
            <div>
                <span
                    className="excess-assets-value"
                    data-testid="excess-assets-value"
                >{assetsMessage}</span>
            </div>
            {hasAssetsAndGoals ? chartLegend : null}
            <div data-testid="excess-assets-chart-container"
                 className={shiftClasses.excessAssetsChartContainer}>
                <div className={"excess-assets-chart__left-panel"}>{hasAssetsAndGoals ? leftPanelContent : null}</div>
                <div className={"excess-assets-chart__center-panel"}>
                    {hasAssetsAndGoals ? centerPanelOverlayContent : null}
                    <div data-testid="assets-chart-container" className={"assets-chart-container"}>
                        <StackedBarChart
                            className={"assets-chart"}
                            showLabels={false}
                            chartWidth={220}
                            data={chartData}
                        />
                    </div>
                </div>
                <div className={"excess-assets-chart__right-panel"}>{hasAssetsAndGoals ? rightPanelContent : null}</div>
            </div>
        </div>
    );
};

export default ExcessAssets;
