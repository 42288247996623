import React, {useContext, useEffect, useState} from "react";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory, useParams} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {LegalAgreement} from "../models/InvestmentProgram";
import {RouteWithAssetId} from "../../routes/types";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {HoldingAssetSubclassDetails} from "../models/Holding";
import {LoadingIndicator} from "../../pages/LoadingIndicator";
import {Icon} from "../../components";
import {HoldingsScrollableContainer} from '../StandaloneAccount/Holdings/HoldingsScrollableContainer';
import {HoldingSummaryAccordion} from "../StandaloneAccount/Holdings/HoldingSummaryAccordion";
import {LegalAgreementHoldingSummarySubclassDetailsRow} from "./LegalAgreementHoldingSummarySubclassDetailsRow";
import {useAppInsights} from "../../AppInsights";
import HoldingsInfo from "../StandaloneAccount/Holdings/HoldingsInfo";
import {HoldingSummaryAssetSubclass} from "../StandaloneAccount/Holdings/HoldingSummaryAssetSubclass";
import {AssetClassifications} from "../models/AssetClassifications";
import AssetsViewContext from "../common/AssetsViewContext";
import {DomesticTrustHoldingWriteModel} from "./DomesticTrustAccountWriteModel";
import useProfileEditableState from "../../hooks/useProfileEditableState";

export default function LegalAgreementHoldings() {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const appInsights = useAppInsights();
    const [legalAgreement, setLegalAgreement] = useState<LegalAgreement>();
    const [domesticTrustHoldings, setDomesticTrustHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [updatedDomesticTrustHoldings, setUpdatedDomesticTrustHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const {assetId: legalAgreementId} = useParams<RouteWithAssetId>();
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [isDoneButtonDisabled, updateDoneButtonDisabled] = useState(false);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    useEffect(() => {
        appInsights.startTrackPage("ViewLegalAgreementHoldings")
        Promise.all([
            assetsApiClient.getDomesticTrustHoldings(profile.id, legalAgreementId),
            assetsApiClient.getLegalAgreement(profile.id, legalAgreementId),
            assetsApiClient.getAssetClassifications()
        ])
            .then(([holdingsResponse, legalAgreementResponse, assetClassificationResponse]) => {
                setDomesticTrustHoldings(holdingsResponse.allHoldings);
                setUpdatedDomesticTrustHoldings(holdingsResponse.allHoldings.map(holding => (
                    {
                        ...holding
                    }))
                );
                setLegalAgreement(legalAgreementResponse);
                setClassifications(assetClassificationResponse);
            })
            .catch(reason => console.log(reason))
            .finally(() => {
                appInsights.stopTrackPage("ViewLegalAgreementHoldings", window.location.href, {
                    profileId: profile.id,
                    legalAgreementId: legalAgreementId
                });
            });
    }, [profile.id, legalAgreementId]);

    const handleLockHolding = async (index: number, checked: boolean) => {
        handleAssetSubclassDetailUpdate(index, (assetSubclassDetail: HoldingAssetSubclassDetails) => {
            assetSubclassDetail.locked = checked
        })
    }

    const handleConcentrateHolding = async (index: number, checked: boolean) => {
        handleAssetSubclassDetailUpdate(index, (assetSubclassDetail: HoldingAssetSubclassDetails) => {
            assetSubclassDetail.concentrated = checked
        })
    }

    const handleAssetSubclassDetailUpdate = async (index: number, changeFunction: (assetSubclassDetail: HoldingAssetSubclassDetails) => void) => {
        if (index >= 0) {
            const holdingToUpdate = updatedDomesticTrustHoldings[index];
            changeFunction(holdingToUpdate);

            setUpdatedDomesticTrustHoldings([
                ...updatedDomesticTrustHoldings.slice(0, index),
                holdingToUpdate,
                ...updatedDomesticTrustHoldings.slice(index + 1, updatedDomesticTrustHoldings.length),
            ])
        }
    }

    function navigateToEditLegalAgreement() {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/EditLegalAgreement/${legalAgreementId}`);
    }

    const handleDone = async () => {
        const updatedHoldings: DomesticTrustHoldingWriteModel[] = updatedDomesticTrustHoldings
            .filter((trustHoldings, index) => {
                return trustHoldings.locked !== domesticTrustHoldings[index].locked
                    || trustHoldings.concentrated !== domesticTrustHoldings[index].concentrated
            })
            .map((trustHolding) => {
                const domesticTrustHoldingWriteModel: DomesticTrustHoldingWriteModel = {
                    holdingPersistentId: trustHolding.id!,
                    locked: trustHolding.locked,
                    concentrated: trustHolding.concentrated
                }
                return domesticTrustHoldingWriteModel;
            });
        if (updatedHoldings.length > 0) {
            updateDoneButtonDisabled(true);
            const response =
                await assetsApiClient.putLegalAgreementHoldings(
                    profile.id,
                    {domesticTrustHoldings: updatedHoldings}
                );
            if (response.status === 200) {
                navigateToEditLegalAgreement()
            }
        } else {
            navigateToEditLegalAgreement();
        }
    }

    if (!domesticTrustHoldings || !legalAgreement || !classifications) {
        return <LoadingIndicator/>
    }

    const assetSubclassDetailsRowRenderer = (assetSubclassDetail: HoldingAssetSubclassDetails, index: number) =>
        <LegalAgreementHoldingSummarySubclassDetailsRow
            key={`asset-subclass-details-${assetSubclassDetail.id}-${assetSubclassDetail.productName}`}
            index={index}
            assetSubclassDetail={assetSubclassDetail}
            handleConcentrateHolding={handleConcentrateHolding}
            handleLockHolding={handleLockHolding}
            disableCheckboxes={isProfileWithProposalsOrArchived!}
        />;

    return (
        <div className="legal-agreement-holdings asset-form holdings-container">
            <div className="layout-data-entry-form">
                <DataEntryHeader
                    className='dataEntryHeader'
                    title={legalAgreement.name}
                    onPrimaryButtonClick={handleDone}
                    disablePrimaryButton={isDoneButtonDisabled}
                    primaryButtonText="Done"
                    hideSecondaryButton={true}
                />
            </div>
            <HoldingsInfo
                account={{
                    institution: legalAgreement.institution,
                    accountNumber: legalAgreement.legalAgreementNumber,
                    taxStatus: legalAgreement.taxStatus,
                    memberOwnerships: [],
                    legalEntityOwnerships: [],
                    ownershipCategory: "Sole"
                }}
                allHoldings={updatedDomesticTrustHoldings}
                classifications={classifications}
            />
            <div className="holding-grid holding-grid-container">
                <div className="grid-display-contents" role="row">
                        <span className="display-flex align-items-center paddingbottom-md"
                              role="cell">
                            <Icon name="chevron_double_right"/>
                            <span className="condensed-subtitle paddingleft-md">Asset Class / Subclass</span>
                        </span>
                    <span className="condensed-subtitle" role="cell">Product</span>
                    <span className="textalign-right condensed-subtitle" role="cell">Account</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Tax Cost</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Market Value</span>
                    <span className="condensed-subtitle textalign-right" role="cell">Investable Value</span>
                    <span className="condensed-subtitle textalign-center" role="cell">Locked</span>
                    <span className="condensed-subtitle textalign-left" role="cell">Concen</span>
                </div>
                <div className="empty-grid-row"/>
                <HoldingsScrollableContainer>
                    <HoldingSummaryAccordion
                        riskAssetClasses={classifications?.riskAssetClasses}
                        riskControlAssetClasses={classifications?.riskControlAssetClasses}
                        assetSubclassRowRenderer={(assetSubclass, holdingsWithIndex, subclassIndex) => {
                            return <HoldingSummaryAssetSubclass
                                key={subclassIndex}
                                assetSubclass={assetSubclass}
                                holdingsWithIndex={holdingsWithIndex}
                                subclassIndex={subclassIndex}
                                assetSubclassDetailsRowRenderer={assetSubclassDetailsRowRenderer}/>
                        }}
                        selectedHoldings={domesticTrustHoldings}
                    />
                </HoldingsScrollableContainer>
            </div>
        </div>
    )
}
