import {Label} from "xps-react";
import React, {useState} from "react";
import InfoPopoverPRStatus from "./InfoPopoverPRStatus";

type PortfolioReserveStatusProps = {
    isActivePortfolioReserve: boolean,
    activatePRDate: string | null,
    onClickMonitorPortfolioReserve: () => void
}
const PortfolioReserveStatus = ({
                                    isActivePortfolioReserve,
                                    activatePRDate,
                                    onClickMonitorPortfolioReserve
                                }: PortfolioReserveStatusProps) => {
    const [showPopover, setShowPopover] = useState(false);
    const activatePRDateText = `Activated ${activatePRDate}`

    return (
        <>
            <div className="display-flex paddingtop-lg">
                <Label
                    className="font--small"
                    id="switch_01_Label"
                    name="Label_01"
                    text="Portfolio Reserve"
                />
                {isActivePortfolioReserve &&
                    <InfoPopoverPRStatus
                        onClick={() => setShowPopover(!showPopover)}
                        showPopover={showPopover}
                        onClickMonitorPortfolioReserve={() => {
                            onClickMonitorPortfolioReserve()
                            setShowPopover(false);
                        }}
                        onClose={() => setShowPopover(false)}
                    />
                }
            </div>
            <span
                data-testid="portfolio-reserve"
                className="display-flex paddingleft-sm paddingtop-sm">
                    {isActivePortfolioReserve ?
                        <div
                            className="border-all paddingtop-xs paddingbottom-xs paddingleft-md paddingright-md borderradius-5 display-flex align-items-center bordercolor-transparent"
                            style={{
                                backgroundColor: "#C6E396"
                            }}>
                            <span style={{backgroundColor: "#05676e", marginRight: "6px"}}
                                  className="portfolio-reserve-status-bullet"/>
                            <span className="paddingleft-sm paddingright-sm font-sm fontweight-500">
                                ON
                            </span>
                        </div>
                        :
                        <div
                            className="border-all paddingtop-xs paddingbottom-xs paddingleft-md paddingright-md borderradius-5 display-flex align-items-center bordercolor-transparent"
                            style={{
                                backgroundColor: "#CECECE"
                            }}>
                            <span style={{backgroundColor: "#939191", marginRight: "6px"}}
                                  className="portfolio-reserve-status-bullet"/>
                            <span className="paddingleft-md paddingright-sm font-sm fontweight-500">
                                OFF
                            </span>
                        </div>
                    }
            </span>
            {isActivePortfolioReserve &&
                <Label
                    className="font--small margintop-sm color-text--lightgrey"
                    id="switch_01_Label"
                    text={activatePRDateText}
                    data-testid="activate-pr-date"
                />
            }
        </>
    )
}

export default PortfolioReserveStatus;