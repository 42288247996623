import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {RouteWithId} from "../../../routes/types";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import {resetAccordionTableState} from "../../../components/AccordionTable/AccordionTableSlice";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import AssetRelianceContentReport from "./AssetRelianceContentReport";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {selectAssetRelianceReportResponse, setAssetRelianceReportResponse} from "./AssetRelianceReportSlice";

interface AssetRelianceWrapperProps {
    displayName: string,
    id: string,
    isFutureValueOfExcessRequired: boolean
}


const AssetRelianceWrapper = ({displayName, id, isFutureValueOfExcessRequired}: AssetRelianceWrapperProps) => {

    return (<>
            <div className="asset-reliance">
                <AssetRelianceContentReport
                    id={id}
                    displayName={displayName}
                    isFutureValueOfExcessRequired={isFutureValueOfExcessRequired}
                />
            </div>
        </>
    )
}

type assetRelianceContainerProps = {
    displayName: string,
    isFutureValueOfExcessRequired: boolean,
    profileId: string
}

const AssetRelianceContainer = ({displayName,isFutureValueOfExcessRequired,profileId}: assetRelianceContainerProps) => {

    return <AssetRelianceWrapper displayName={displayName}
                                 id={profileId}  isFutureValueOfExcessRequired={isFutureValueOfExcessRequired}/>
}

export default AssetRelianceContainer;