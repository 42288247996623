import {MemberBeneficiary, PhilanthropicBeneficiary} from "../../../WealthTransfer/models/api";
import {GroupedTableData, GroupedTableItem} from "../../../components/Table/GroupedTable";
import {LegalEntityTypeSummary, NonClientMemberSummary} from "../../../Assets/mappers";

export const MINIMUM_SPACE_REQUIRED_FOR_FED_AND_STATE_TAX = 760;
export const MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX = 200;
export const MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX = 520;
export const MINIMUM_SPACE_REQUIRED_FOR_NON_EMPTY_STATE_TAX = 660;
export const MINIMUM_SPACE_REQUIRED_FOR_NON_EMPTY_FED_TAX = 660;

export type WealthTransferOutOfEstateAssets = {
    legalEntitySummary: LegalEntityTypeSummary[] | undefined,
    nonClientMemberSummary: NonClientMemberSummary[] | undefined
}

const MAX_BENFICIARIES_ROWCOUNT_IN_PAGE = 15;

export const PAGE_CONSTANTS = {
    TOTAL_PAGE_HEIGHT: 1055,
    PAGE_PADDING_HEIGHT: 96,
    SECTION_HEADER_HEIGHT: 105,
    OUTESTATE_SECTION_HEADER_HEIGHT: 40,
    ACCORDION_HEADER_HEIGHT: 68,
    TABLE_HEADER_HEIGHT: 36,
    ACCORDION_HEADER_LABEL_HEIGHT: 36,
    TABLE_GROUP_LABEL_ROW_HEIGHT: 48,
    TABLE_ROW_HEIGHT: 48
}

export type WealthTransferData = {
    beneficiaryPageData :GroupedTableData[] | undefined,
    outOfEstatePages: WealthTransferOutOfEstateAssets| undefined,
}

export type BeneficiaryPagesAndSpace = {
    beneficiaryArrayPages:Array<Beneficiary[]>,
    remainingSpace:number
}

type DataAndSpace={legalEntityTypeSummariesArray:Array<LegalEntityTypeSummary[]>, remainingSpaceAfterLegalEntities:number}

export type Beneficiary = MemberBeneficiary | PhilanthropicBeneficiary

export function splitBeneficiaryData(beneficiaries: Beneficiary[], beneficiaryArrayPages:Array<Beneficiary[]>, remainingSpace:number):BeneficiaryPagesAndSpace {
    if(beneficiaries.length <= 0 )  return {beneficiaryArrayPages:  beneficiaryArrayPages, remainingSpace: remainingSpace || (PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT-PAGE_CONSTANTS.PAGE_PADDING_HEIGHT - PAGE_CONSTANTS.SECTION_HEADER_HEIGHT-PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT)};

    const minimumSpaceForaGroupRow= PAGE_CONSTANTS.TABLE_ROW_HEIGHT+PAGE_CONSTANTS.TABLE_GROUP_LABEL_ROW_HEIGHT;
    let availablePageHeight = remainingSpace>minimumSpaceForaGroupRow?remainingSpace:PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT;
    let usedSpace = 0;
    if(remainingSpace === 0) {
        usedSpace += PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT +PAGE_CONSTANTS.ACCORDION_HEADER_LABEL_HEIGHT;
        usedSpace += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
    }
    let remainingSpaceAfterSplitting =0;
    let previousUsedSpace = remainingSpace > minimumSpaceForaGroupRow?PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT-remainingSpace : 0;
    let beneficiariesForAPage:Beneficiary[] = [];
    for(let index=0;index<beneficiaries.length;index++){
        const beneficiary:Beneficiary=beneficiaries[index];
        usedSpace += PAGE_CONSTANTS.TABLE_ROW_HEIGHT;
        if(usedSpace > availablePageHeight){
            beneficiariesForAPage.push(beneficiary);
            beneficiaryArrayPages.push(beneficiariesForAPage);
            availablePageHeight=PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT;
            const remainingBeneficiaries = beneficiaries.slice(index+1);
            if(remainingBeneficiaries.length >= 1) {
                let tempData = splitBeneficiaryData(remainingBeneficiaries, beneficiaryArrayPages,0);
                remainingSpaceAfterSplitting=tempData.remainingSpace;
                beneficiariesForAPage =[];
                break;
            }
        }
        else {
            beneficiariesForAPage.push(beneficiary);
            remainingSpaceAfterSplitting = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT -previousUsedSpace-usedSpace;
        }

    }

    if(beneficiariesForAPage.length !=0 && usedSpace<availablePageHeight) beneficiaryArrayPages.push(beneficiariesForAPage);
    let totalAvailableSpace = remainingSpaceAfterSplitting;
    if(totalAvailableSpace<0) totalAvailableSpace=0;
    return {beneficiaryArrayPages:  beneficiaryArrayPages, remainingSpace:totalAvailableSpace};
}
export const hasSufficientSpaceForOutEstate = (space:number):boolean=>space>(PAGE_CONSTANTS.OUTESTATE_SECTION_HEADER_HEIGHT+PAGE_CONSTANTS.ACCORDION_HEADER_LABEL_HEIGHT+PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT+PAGE_CONSTANTS.TABLE_HEADER_HEIGHT+PAGE_CONSTANTS.TABLE_GROUP_LABEL_ROW_HEIGHT);
export const hasSufficientSpace = (space:number):boolean=> space >( PAGE_CONSTANTS.OUTESTATE_SECTION_HEADER_HEIGHT+PAGE_CONSTANTS.ACCORDION_HEADER_LABEL_HEIGHT+PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT+PAGE_CONSTANTS.TABLE_HEADER_HEIGHT) ;

export const splitOutOfEstateLegalEntities = (legalEntityTypeSummaries: LegalEntityTypeSummary[], remainingSpace: number) :DataAndSpace=> {

    if (legalEntityTypeSummaries.length === 0) return {legalEntityTypeSummariesArray:[], remainingSpaceAfterLegalEntities:remainingSpace};
    let totalAvailablePageHeight:number = remainingSpace-PAGE_CONSTANTS.OUTESTATE_SECTION_HEADER_HEIGHT;
    let usedPageHeight: number = 0;
    let pages: Array<LegalEntityTypeSummary[]> = [];
    let dataForAPage: LegalEntityTypeSummary[] = [];
    usedPageHeight += PAGE_CONSTANTS.ACCORDION_HEADER_LABEL_HEIGHT + PAGE_CONSTANTS.PAGE_PADDING_HEIGHT
    legalEntityTypeSummaries.forEach((accordionLevelData) => {
        usedPageHeight +=  PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT   ;
        let accordionDataForAPage: LegalEntityTypeSummary = JSON.parse(JSON.stringify(accordionLevelData));
        accordionDataForAPage.entities = [];
        let copyOfGroupedData: GroupedTableData;
        accordionLevelData.entities.forEach((groupLevelData: GroupedTableData) => {
            usedPageHeight += PAGE_CONSTANTS.TABLE_GROUP_LABEL_ROW_HEIGHT  ;

            copyOfGroupedData = JSON.parse(JSON.stringify(groupLevelData));

            copyOfGroupedData.items = [];
            if(groupLevelData.items.length===0) {
                accordionDataForAPage.entities.push(JSON.parse(JSON.stringify(copyOfGroupedData)));

            } else {
                groupLevelData.items.forEach((item: GroupedTableItem, itemIndex: number) => {
                    usedPageHeight += PAGE_CONSTANTS.TABLE_ROW_HEIGHT;

                    copyOfGroupedData.items.push(item);
                    if (usedPageHeight > totalAvailablePageHeight) {
                        accordionDataForAPage.entities.push(JSON.parse(JSON.stringify(copyOfGroupedData)));

                        dataForAPage.push(JSON.parse(JSON.stringify(accordionDataForAPage)));

                        pages.push(dataForAPage);
                        usedPageHeight = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + 100;
                        dataForAPage = [];
                        accordionDataForAPage.entities = [];
                        totalAvailablePageHeight = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT;
                        copyOfGroupedData.items = [];

                    } else if (itemIndex === groupLevelData.items.length - 1) {
                        accordionDataForAPage.entities.push(JSON.parse(JSON.stringify(copyOfGroupedData)));
                    }
                })
            }

        })
        if (accordionDataForAPage.entities.length > 0) dataForAPage.push(JSON.parse(JSON.stringify(accordionDataForAPage)));

    })
    if (dataForAPage.length > 0) pages.push(dataForAPage);
    return {legalEntityTypeSummariesArray:pages, remainingSpaceAfterLegalEntities:PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT-usedPageHeight};
}

export const splitNonClientMemberSummaries = (nonClientMemberSummaries: NonClientMemberSummary[], remainingSpace:number) => {
        if (nonClientMemberSummaries.length === 0) return [];
        let totalPAgeHeight = remainingSpace;
        let usedPageHeight: number = 0;
        let pages: Array<NonClientMemberSummary[]> = [];
        let dataForAPage: NonClientMemberSummary[] = [];
        nonClientMemberSummaries.forEach((accordionLevelData) => {
            usedPageHeight += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            let accordionDataForAPage: NonClientMemberSummary = JSON.parse(JSON.stringify(accordionLevelData));
            accordionDataForAPage.assets = [];
            let copyOfGroupedData: GroupedTableData;
            accordionLevelData.assets.forEach((groupLevelData: GroupedTableData) => {
                usedPageHeight += PAGE_CONSTANTS.SECTION_HEADER_HEIGHT;
                copyOfGroupedData = JSON.parse(JSON.stringify(groupLevelData));
                copyOfGroupedData.items = [];

                groupLevelData.items.forEach((item: GroupedTableItem, itemIndex: number) => {
                    usedPageHeight += PAGE_CONSTANTS.TABLE_ROW_HEIGHT;
                    copyOfGroupedData.items.push(item);
                    if (usedPageHeight > totalPAgeHeight) {
                        accordionDataForAPage.assets.push(JSON.parse(JSON.stringify(copyOfGroupedData)));
                        dataForAPage.push(JSON.parse(JSON.stringify(accordionDataForAPage)));
                        pages.push(dataForAPage);
                        usedPageHeight = 0;
                        dataForAPage = [];
                        accordionDataForAPage.assets = [];
                        totalPAgeHeight = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT;
                        copyOfGroupedData.items = [];
                    } else if (itemIndex === groupLevelData.items.length - 1) {
                        accordionDataForAPage.assets.push(JSON.parse(JSON.stringify(copyOfGroupedData)));
                    }
                })
            })
            if (accordionDataForAPage.assets.length > 0) dataForAPage.push(JSON.parse(JSON.stringify(accordionDataForAPage)));
        })

        if (dataForAPage.length > 0) pages.push(dataForAPage);
        return pages;
    }

export function createIndividualData(memberPageData: any[]) {
    const individualBeneficiaries = memberPageData.map(memberBeneficiary => ({
        itemName: `${memberBeneficiary.name} (Age ${memberBeneficiary.age})`,
        itemValue: memberBeneficiary.presentValue
    }));

    return {
        groupName: "Individual",
        groupId: '',
        items: individualBeneficiaries,
        groupValue: individualBeneficiaries.reduce((previousValue: number, currentValue) => {
            return previousValue + currentValue.itemValue;
        }, 0)
    };
}

export function createPhilanthropicData(philanthropicPageData: any[]) {
    const philanthropicBeneficiaries = philanthropicPageData.map(beneficiary => ({
        itemName: beneficiary.philanthropyName,
        itemValue: beneficiary.value
    }));

    return {
        groupName: "Philanthropy",
        groupId: '',
        items: philanthropicBeneficiaries,
        groupValue: philanthropicBeneficiaries.reduce((previousValue: number, currentValue) => {
            return previousValue + currentValue.itemValue;
        }, 0)
    };
}

export function isMergeRequired(individualPageData: Array<any[]>, philanthropicPageData: Array<any[]>, MAX_ROWCOUNT_IN_PAGE: number): boolean {
    return (philanthropicPageData[philanthropicPageData.length - 1].length + individualPageData[0].length) <= MAX_ROWCOUNT_IN_PAGE;
}

export function mergePhilanthropicAndIndividualPagesToEstatesummary(individualPageData: Array<any[]>, philanthropicPageData: Array<any[]>) {
    let tempgroupedTablePages: GroupedTableData[][] = []

    for (const element of philanthropicPageData) {
        const philanthropicGroupTableData = createPhilanthropicData(element);
        tempgroupedTablePages.push([philanthropicGroupTableData]);
    }

    let startIndexForIndividualData = 0;
    if (isMergeRequired(individualPageData, philanthropicPageData, MAX_BENFICIARIES_ROWCOUNT_IN_PAGE)) {
        startIndexForIndividualData = 1;
        const individualGroupTableData = createIndividualData(individualPageData[0]);
        tempgroupedTablePages[philanthropicPageData.length - 1] = [...tempgroupedTablePages[philanthropicPageData.length - 1], individualGroupTableData];
    }

    for (startIndexForIndividualData; startIndexForIndividualData < individualPageData.length; startIndexForIndividualData++) {
        const individualGroupTableData = createIndividualData(individualPageData[startIndexForIndividualData]);
        tempgroupedTablePages.push([individualGroupTableData]);
    }
    return tempgroupedTablePages;
}

export function mergeBeneficiaryAndOutEstatePages(remainingSpaceAfterTaxes: number, allOutOfEstateAssetsPages: WealthTransferOutOfEstateAssets[], tempgroupedTablePages: GroupedTableData[][], allPages: WealthTransferData[]) {

    const lastPageWithBeneficiary = allPages[tempgroupedTablePages.length - 1];
    const firstOutEstatePage = allPages[tempgroupedTablePages.length];

    lastPageWithBeneficiary.outOfEstatePages = firstOutEstatePage.outOfEstatePages;
    allPages.splice(tempgroupedTablePages.length, 1);

}

export function mergeOutEstateLegalEntitiesAndNonClientPages(legalEntitySummariesPages: WealthTransferOutOfEstateAssets[], nonClientMemberSummariesPages: WealthTransferOutOfEstateAssets[], allAssetsPages: WealthTransferOutOfEstateAssets[]) {
    let lastPageOfLegalEntities = legalEntitySummariesPages[legalEntitySummariesPages.length - 1];
    let firstPageOfNonClientSummaries = nonClientMemberSummariesPages[0];
    lastPageOfLegalEntities.nonClientMemberSummary = firstPageOfNonClientSummaries.nonClientMemberSummary;
    firstPageOfNonClientSummaries.nonClientMemberSummary = undefined;
    allAssetsPages.splice(legalEntitySummariesPages.length, 1);
}

export function calculateSpaceAfterTaxes(availableSpaceAfterSplittingBeneficiaries: number, isStateTaxZero: boolean) {
    let remainingSpaceAfterTaxes ;
    if (!isStateTaxZero) {
        if (availableSpaceAfterSplittingBeneficiaries > MINIMUM_SPACE_REQUIRED_FOR_FED_AND_STATE_TAX) {
            remainingSpaceAfterTaxes = availableSpaceAfterSplittingBeneficiaries - MINIMUM_SPACE_REQUIRED_FOR_FED_AND_STATE_TAX ;//760
        } else if (availableSpaceAfterSplittingBeneficiaries > MINIMUM_SPACE_REQUIRED_FOR_NON_EMPTY_STATE_TAX) {
            remainingSpaceAfterTaxes = availableSpaceAfterSplittingBeneficiaries - MINIMUM_SPACE_REQUIRED_FOR_NON_EMPTY_FED_TAX ;//760
        } else remainingSpaceAfterTaxes = 0;
    } else {

        if (availableSpaceAfterSplittingBeneficiaries > MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX) {
            remainingSpaceAfterTaxes = availableSpaceAfterSplittingBeneficiaries - MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX;//520
        } else if (availableSpaceAfterSplittingBeneficiaries > MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX) {
            remainingSpaceAfterTaxes = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT-MINIMUM_SPACE_REQUIRED_FOR_NON_EMPTY_FED_TAX;
        } else remainingSpaceAfterTaxes = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT-MINIMUM_SPACE_REQUIRED_FOR_EMPTY_STATE_TAX_AND_FULL_FED_TAX
    }
    if (remainingSpaceAfterTaxes < 0) return 0;
    return remainingSpaceAfterTaxes;
}
