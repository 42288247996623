import React, {useMemo} from "react";
import {formatCurrency} from "../../utils/format";
import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {AccordionItem} from "../../components";
import {Table} from "xps-react";
import {TaxLiabilities} from "../models/GoalModelType";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";

export const TaxLiabilitiesListHeader = (props: {
    taxLiabilities: TaxLiabilities
}) => {
    const {taxLiabilities} = props;

    return (
        <div className="section-header">
            <h3>
                You have <b>{formatCurrency(taxLiabilities.totalTaxLiabilities)}</b> in tax liabilities:
            </h3>
        </div>);
}

export type SummaryTaxLiabilitiesRow = {
    description: string,
    presentValue: string,
}

const SummaryTaxLiabilitiesList = () => {
    const {taxLiabilities} = useAppSelector(selectGoalModel);

    const memoizedTable = useMemo(() => <Table
        resizable={false}
        showPagination={false}
        sortable={false}
        data={
            [
                {
                    description: "Estimated Deferred Tax Liability",
                    presentValue: `${formatCurrency(Math.abs(taxLiabilities.estimatedDeferredTaxLiability))}`,
                },
                {
                    description: "Estimated Unrealized Capital Gains Liability",
                    presentValue: `${formatCurrency(Math.abs(taxLiabilities.estimatedUnrealizedCapitalGainsLiability))}`,
                },
            ]
        }
        columns={
            [
                {
                    minWidth: 30,
                    maxWidth: 30,
                },
                {
                    accessor: 'description',
                    Cell: ({original}: {
                        original: SummaryTaxLiabilitiesRow
                    }) => {
                        return (
                            <div
                                className="display-flex flex-column justify-content-center height-100p">
                            <span
                                className="table-label-15px">{original.description}</span>
                            </div>
                        );
                    },
                    Header: 'Description'
                },
                {
                    accessor: 'presentValue',
                    Cell: ({original}: {
                        original: SummaryTaxLiabilitiesRow
                    }) => {
                        return (
                            <div
                                className="display-flex flex-column justify-content-center align-items-end height-100p">
                            <span
                                className="table-label-15px">{original.presentValue}</span>
                            </div>
                        );
                    },
                    Header: 'Present Value',
                    headerClassName: 'display-flex justify-content-end',
                },
                {
                    accessor: 'rowActionMenu',
                    className: "overflow-visible",
                    minWidth: 32,
                    maxWidth: 32,
                }
            ]
        }
    />, [taxLiabilities]);
    return (
        <>
            <section>
                <div className="goal-list">
                    <div className="accordion-tables">
                        <TaxLiabilitiesListHeader taxLiabilities={taxLiabilities}/>
                        <AccordionWithHeader
                            headerProps={
                                {
                                    className: "goal-list-header-grid",
                                    gridSpanClass: "grid-span-1"
                                }
                            }
                            accordionId="SummaryLifestyleGoalListAccordion"
                            renderHeader={true}
                            allItems={["TaxLiabilities"]}
                            columnTitles={["Liability Type", "Present Value"]}
                        >
                            <AccordionItem
                                uuid="TaxLiabilities"
                                accentColor="#FFDF77"
                                primaryText="Tax Liabilities"
                                rightAlignedContent={
                                    <div className="display-flex align-items-center"
                                         data-testid="tax-liabilities-accordion-item">
                                            <span className="font-lg paddingright-xl">
                                                {formatCurrency(taxLiabilities.totalTaxLiabilities)}
                                            </span>
                                    </div>
                                }
                            >
                                <div data-testid="tax-liabilities-table">{memoizedTable}</div>
                            </AccordionItem>
                        </AccordionWithHeader>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SummaryTaxLiabilitiesList;