import React from "react";
import GoalDetailsForm from "../components/GoalDetailsForm";
import {NonLifestyleGoalType} from "../models/GoalType";
import GoalTimeFrameForm from "./GoalTimeFrameForm";
import PresentValueService from "../PresentValueService";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import GoalGlidePath from "./GoalGlidePath";
import {DiscountRateType} from "../models/DiscountRate";
import {GoalFunding} from "./GoalFunding";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import useProfileEditableState from "../../hooks/useProfileEditableState";

interface NonLifestyleGoalFormProps {
    nonLifestyleGoal: NonLifestyleGoalType
    nonPortfolioFunded?: number,
    replaceNonLifestyleGoal: (updatedNonLifestyleGoal: NonLifestyleGoalType) => void
    typeDropdownItems: JSX.Element[]
    discountRateSelection: DiscountRateType
    lifestyleGoal?: LifestyleSpendingGoal
    replaceLifestyleGoal?: (updatedLifestyleGoal: LifestyleSpendingGoal) => void
    portfolioReserveIsSet?: boolean
    goalCharacteristics?: React.ReactNode
}


const NonLifestyleGoalForm = ({
                                  nonLifestyleGoal,
                                  nonPortfolioFunded = 0,
                                  replaceNonLifestyleGoal,
                                  typeDropdownItems,
                                  discountRateSelection,
                                  lifestyleGoal,
                                  replaceLifestyleGoal,
                                  portfolioReserveIsSet,
                                  goalCharacteristics
                              }: NonLifestyleGoalFormProps) => {
    const {id: profileId} = useParams<RouteWithId>();
    let beneficiaryName: string = ""
    if (nonLifestyleGoal.beneficiaryId) {
        const {proposal} = useAppSelector<GoalModelType>(selectGoalModel)
        const memberSelected = FamilyGoalUtils.getBeneficiaryFromProfile(proposal, nonLifestyleGoal.beneficiaryId);
        beneficiaryName = memberSelected.firstName + " " + memberSelected.lastName
    }

    const handleUpdateNonLifestyleGoal = (updatedNonLifestyleGoal: Partial<NonLifestyleGoalType['userInputs']>) => {
        replaceNonLifestyleGoal({
            ...nonLifestyleGoal,
            userInputs: {
                ...nonLifestyleGoal.userInputs,
                ...updatedNonLifestyleGoal
            }
        })
    };

    const handleGoalTypeChange = (e: any) => {
        handleUpdateNonLifestyleGoal({description: e.value, selectedGoalSubType: e.value})
    }

    const handleUpdatePresentValue = async (overrideNonLifestyleGoal?: NonLifestyleGoalType, tempLifestyleGoal?: LifestyleSpendingGoal) => {
        const inputNonLifestyleGoal = overrideNonLifestyleGoal
            ? overrideNonLifestyleGoal
            : nonLifestyleGoal;
        const originalLifestyleGoal = tempLifestyleGoal
            ? tempLifestyleGoal
            : lifestyleGoal;


        return PresentValueService.calculateLifestyleAndNonLSPresentValue(
            profileId,
            inputNonLifestyleGoal,
            nonPortfolioFunded,
            portfolioReserveIsSet ? undefined : originalLifestyleGoal
        )
            .then(presentValueResponse => {
                replaceNonLifestyleGoal({
                    ...inputNonLifestyleGoal,
                    calculatedFields: {
                        ...inputNonLifestyleGoal.calculatedFields,
                        ...presentValueResponse.nonLifestylePresentValue
                    }
                });
                if (replaceLifestyleGoal && originalLifestyleGoal) {
                    replaceLifestyleGoal({
                        ...originalLifestyleGoal,
                        calculatedFields: {
                            ...originalLifestyleGoal.calculatedFields,
                            ...presentValueResponse.lifestylePresentValue
                        }
                    });
                }
            })
    }

    const handleResetRiskControlAsset = async () => {
        const goalWithoutRiskControlOrRiskAsset = {
            ...nonLifestyleGoal,
            userInputs: {
                ...nonLifestyleGoal.userInputs,
                riskControl: null,
                riskAsset: null
            }
        };
        await PresentValueService.calculateLifestyleAndNonLSPresentValue(
            profileId,
            goalWithoutRiskControlOrRiskAsset,
            nonPortfolioFunded,
            portfolioReserveIsSet ? undefined : lifestyleGoal
        )
            .then(presentValueResponse => {
                replaceNonLifestyleGoal({
                    ...goalWithoutRiskControlOrRiskAsset,
                    calculatedFields: {
                        ...goalWithoutRiskControlOrRiskAsset.calculatedFields,
                        ...presentValueResponse.nonLifestylePresentValue
                    }
                });
                if (replaceLifestyleGoal && lifestyleGoal) {
                    replaceLifestyleGoal({
                        ...lifestyleGoal,
                        calculatedFields: {
                            ...lifestyleGoal.calculatedFields,
                            ...presentValueResponse.lifestylePresentValue
                        }
                    });
                }
            })
    }

    const profile = useProfileAndProposals(profileId).profile;
    const isActivePortfolioReserve = profile.isActivePortfolioReserve;

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    return (
        <div className={"family-goal-form"}>
            <GoalDetailsForm
                nonLifestyleGoal={nonLifestyleGoal}
                typeDropdownItems={typeDropdownItems}
                beneficiary={beneficiaryName ? beneficiaryName : undefined}
                updateNonLifestyleGoal={handleUpdateNonLifestyleGoal}
                handleGoalTypeChange={handleGoalTypeChange}
                updatePresentValue={handleUpdatePresentValue}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />

            <GoalTimeFrameForm nonLifestyleGoal={nonLifestyleGoal}
                               updateNonLifestyleGoal={handleUpdateNonLifestyleGoal}
                               updatePresentValue={handleUpdatePresentValue}
                               isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />

            {!isActivePortfolioReserve ? <GoalGlidePath nonLifestyleGoal={nonLifestyleGoal}
                                                        updateNonLifestyleGoal={handleUpdateNonLifestyleGoal}
                                                        updatePresentValue={handleUpdatePresentValue}
                                                        discountRateSelection={discountRateSelection}
                                                        resetRiskControlAsset={handleResetRiskControlAsset}
                                                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            /> : ''}
            <GoalFunding nonLifestyleGoal={nonLifestyleGoal}
                         updateNonLifestyleGoal={handleUpdateNonLifestyleGoal}
                         isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />
            {goalCharacteristics}
        </div>
    )
}

export default NonLifestyleGoalForm
