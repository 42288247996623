import React, {ReactElement, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {formatCurrency, formatNumberRoundedToWholeNumber} from "../../utils/format";
import ExcessAssets from "./ExcessAssets";
import {useHistory, useParams} from "react-router-dom";
import {kebabCase} from "../../utils/stringUtils";
import {getPlanSummary, selectIsPlanSummaryLoading, selectPlanSummary} from "./planSummarySlice";
import LoadingIndicator from "../../pages/LoadingIndicator";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {RouteWithId} from "../../routes/types";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import {Col, Container, Row} from "react-grid-system";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {Button, EmptyStateContainer, Icon, PresentationPaneHeader} from "../../components";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {CreatePlanSummaryNetWorthOverTimeGraphOptions} from "./PlanSummaryNetWorthOverTimeGraphUtils";
import {emptyInvestorGroup, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {calculateCNWInEstateTotalValue} from "../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {AssetsSummary} from "../../Assets/models/Assets";
import {selectClientAssets, setClientAssets} from "../../Assets/clientAssetsSlice";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {COLOR_NT_AQUA_800} from "../../constants/colors";
import {emptyEstateSummary, EstateSummary, EstateType} from "../../WealthTransfer/models/api";
import {wealthTransferApiClient} from "../../WealthTransfer/WealthTransferApiClient";
import {NO_OP} from "../../constants/common";

const PlanSummary = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const planSummary = useAppSelector(selectPlanSummary)!;
    const isPlanSummaryLoading = useAppSelector(selectIsPlanSummaryLoading)!;
    const {enableFutureValueOfExcessAssets} = useAppSelector(selectReleaseToggles)!;
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);

    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);

    const [estateSummary, setEstateSummary] = useState<EstateSummary>(emptyEstateSummary);

    usePageViewTimer('Plan Summary Page Load Timer (milliseconds)', isPlanSummaryLoading);

    const hasAssets = planSummary.totalGrossValue > 0;
    const hasGoals = planSummary.goalsTotalPresentValue > 0;
    const hasExcessAssets = planSummary.excessAssets >= 0;
    const navigateToAssetReliance = () => {
        history.push(`/Profile/${id}/ClientProfile/AssetReliance`);
    }

    const {
        approvedProfile: {displayName},
        profile: {portfolioReserveTargetLength}
    } = useProfileAndProposals(id);

    useEffect(() => {
        dispatch(getPlanSummary(id))
        clientManagementApiClient.getInvestorGroup(id)
            .then(newInvestorGroup => {
                setInvestorGroup(newInvestorGroup);
            })
        assetsApiClient.getAssetsSummary(id)
            .then(assetSummaryResponse => {
                dispatch(setClientAssets(assetSummaryResponse));
            })
        wealthTransferApiClient.getEstateSummary(id, EstateType.CURRENT)
            .then(estateSummaryResponse => {
                setEstateSummary(estateSummaryResponse);
            })
    }, [id]);

    if (isPlanSummaryLoading) {
        return <LoadingIndicator/>
    }

    const totalTaxLiabilitiesForAllAccounts = clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const netWealthAtCurrentAge = calculateCNWInEstateTotalValue(clientAssets!) + totalTaxLiabilitiesForAllAccounts;
    const graphOptions: Highcharts.Options = CreatePlanSummaryNetWorthOverTimeGraphOptions({
        investorGroup,
        netWealthAtCurrentAge,
        futureValueOfExcessAssetsAtAge: planSummary.futureValueOfExcessAssetsByAge,
    });

    return (
        <div className="plan-summary-page">
            <PresentationPaneHeader
                displayName={displayName}
                title="Plan Summary"
            />
            <article className="plan-summary-page__body">
                {/*this is the left column of the page*/}
                <ExcessAssets hasAssetsAndGoals={hasAssets && hasGoals}/>
                {/*this is the right column of the page*/}
                <div className="flex-grow-1">
                    <div className={"net-assets-goals-card"}>
                        <MonetaryValueCard
                            value={hasAssets ? planSummary.totalNetValue + planSummary.totalAssetPurchaseValue : '--'}
                            heading={"Net Assets"}
                            onClick={() =>
                                history.push({
                                    pathname: `/Profile/${id}/ClientProfile/AssetSummary`
                                })}/>
                        <MonetaryValueCard value={hasGoals ? planSummary.goalsTotalPresentValue : '--'}
                                           heading={"Goals"}
                                           onClick={() =>
                                               history.push({
                                                   pathname: `/Profile/${id}/ClientProfile/Goals/Summary`
                                               })}/>
                    </div>
                    <div
                        className={"proposed-asset-allocation-card"}
                        data-testid={"proposed-asset-allocation"}
                        onClick={() =>
                            history.push({
                                pathname: `/Profile/${id}/ClientProfile/AssetAllocation/CurrentVsProposed`
                            })}
                    >
                        <div
                            className={"proposed-asset-allocation-text"}
                            data-testid={"proposed-asset-allocation-text"}
                            tabIndex={0}>
                            <div className={"proposed-asset-allocation"}>Proposed Asset Allocation</div>
                            <div className={"total-portfolio"}>Total Portfolio</div>
                        </div>
                        <div className={"no-hover-donut"}>
                            <RiskDonut
                                donutSize={'sm'}
                                data={planSummary.proposedAllocation}
                            />
                        </div>
                        <div className={"risk-assets-and-risk-control"}>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'Risk Assets'}/>
                                </div>
                                <span
                                    className={"risk-percentage"}>{` ${formatNumberRoundedToWholeNumber(
                                    planSummary.proposedAllocation.totalRiskAssetsPercent
                                )}%`}</span>
                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                label={'Risk Control Assets'}/>
                                </div>
                                <span
                                    className={"risk-percentage"}>{` ${formatNumberRoundedToWholeNumber(
                                    planSummary.proposedAllocation.totalRiskControlAssetsPercent
                                )}%`}</span>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <PlanSummaryCard
                            onClick={() => {
                                portfolioReserveTargetLength == undefined ?
                                    history.push({pathname: `/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve`}) :
                                    history.push({pathname: `/Profile/${id}/ClientProfile/PortfolioReserve/ReviewPortfolioReserve`})
                            }}
                            header='Portfolio Reserve'
                            className='plan-summary-card'
                            subhead={<span
                                className='subhead'>Your Reserve Target length is <strong>{portfolioReserveTargetLength ?? 0} years</strong></span>}
                        />
                        <PlanSummaryCard
                            onClick={() => {
                                history.push({pathname: `/Profile/${id}/ClientProfile/WealthTransferPlanning`})
                            }}
                            header='Estimated Estate Tax'
                            className='plan-summary-card'
                            subhead={<span
                                className='subhead'>Your current estimated estate tax is <strong>{formatCurrency(estateSummary.estimatedEstateTax) ?? 0}</strong></span>}
                        />
                    </div>
                    {enableFutureValueOfExcessAssets &&
                        <PlanSummaryCard
                            onClick={() =>
                                hasExcessAssets ? history.push({
                                    pathname: `/Profile/${id}/ClientProfile/NetWorthOverTime`
                                }) : NO_OP}
                            header='Net Worth Over Time'
                            className={hasExcessAssets ? 'plan-summary-card' : 'plan-summary-card no-excess-assets'}
                            subhead={
                            hasExcessAssets ?
                                <div>
                                    <span
                                        className='subhead'>Your expected excess at the end of the planning period is <strong>{formatCurrency(planSummary.futureValueOfExcessAssets)}</strong>
                                    </span>
                                    <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
                                </div>
                                :
                                <EmptyStateContainer
                                    hideBorder={true}
                                    className="insufficient-assets-message"
                                    size="large"
                                    title='Your Net Worth Over Time cannot be displayed when there is an asset shortfall.'
                                    description={
                                        <Button
                                            className="asset-reliance-container"
                                            icon="right"
                                            size="medium"
                                            iconName="arrow_right"
                                            rounded
                                            onClick={() => navigateToAssetReliance()}>
                                            Asset Reliance
                                        </Button>
                                    }
                                />
                            }
                        />
                    }
                </div>
            </article>
            {planSummary.hasFutureAssetPurchase &&
                <footer className="future-asset-purchase-disclaimer">
                    <Icon name="info_outline" color={COLOR_NT_AQUA_800}/>
                    <span className="future-asset-purchase-disclaimer-text">
                        Future asset purchases are included in Net Assets, Excess Assets and Net Worth Over Time.
                    </span>
                </footer>
            }
        </div>
    );
};

type MonetaryValueCardProps = {
    value: number | string;
    onClick: () => void;
    heading: string;
}

const MonetaryValueCard: React.FC<MonetaryValueCardProps> = ({value, onClick, heading}) => {
    const kebabHeading = kebabCase(heading);
    return (
        <div className={`monetary-value-card ${kebabHeading}`} aria-label={heading} role='button' onClick={onClick}
             tabIndex={0}>
            <div className="sub-header" data-testid={`${kebabHeading}-label`}>{heading}</div>
            <span className="dollar-value" data-testid={`${kebabHeading}-value`}>
                    {typeof value === 'string' ? value : formatCurrency(value)}
            </span>
        </div>
    )
}

type PlanSummaryCardProps = {
    header: string;
    subhead: ReactElement
    onClick: () => void
    className: string
}

const PlanSummaryCard: React.FC<PlanSummaryCardProps> = ({header, subhead, onClick, className}) => {
    return (
        <Container tabIndex={0} style={{paddingLeft: '40px', marginRight: '32px'}} className={className}
                   aria-label={header} onClick={onClick}>
            <div>
                <Row>
                    <Col>
                        <h4 className='header'>{header}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {subhead}
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default PlanSummary;
