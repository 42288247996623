import {
    CurrencyInputWithLabel,
    DatePicker,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import React, {ChangeEvent, useEffect, useState} from "react";
import {EstateTaxExemptionsReadModel} from "../models/ui";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import {formatCurrency} from "../../utils/format";
import {EstateTaxExemptionsWriteModel} from "../models/api";
import moment, {Moment} from "moment";
import {DISPLAY_DATE_FORMAT, NO_OP} from "../../constants/common";
import {LifeStatus} from "../../ClientManagement/models/MemberType";
import {Checkbox} from "xps-react";
import {momentToISO} from "../../utils/dateUtils";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import deepEquals from "fast-deep-equal";

export interface EditTaxExemptionFormProps {
    initialFormData: EstateTaxExemptionsReadModel,
    onCancel: () => void,
    onSave: (updatedExemptions: EstateTaxExemptionsWriteModel) => void,
    onEvaluate: (updatedExemptions: EstateTaxExemptionsWriteModel) => void,
    getMaxExemption: (deceasedYear: number | null) => void
}

function fullName(member: { firstName: string, lastName: string }) {
    return `${member.firstName} ${member.lastName}`;
}

export function EditEstateTaxExemptionForm({
                                               initialFormData,
                                               onSave,
                                               onCancel,
                                               onEvaluate,
                                               getMaxExemption
                                           }: EditTaxExemptionFormProps) {
    const [formData, setFormData] = useState<EstateTaxExemptionsReadModel>(initialFormData);
    const [showAlertBannerForPrimary, setAlertBannerForPrimary] = useState<boolean>(false);
    const [showAlertBannerForPartner, setAlertBannerForPartner] = useState<boolean>(false);
    const [showAlertBannerForDeceasedDate, setAlertBannerForDeceasedDate] = useState<boolean>(false);
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);

    const [includeDeceasedPartnerChecked, setIncludeDeceasedPartnerChecked] = useState(
        initialFormData.partnerMemberExemption.member === null
        && initialFormData.partnerMemberExemption.deceasedDate !== null
    );
    useEffect(() => {
        const partnerMemberMaximumAllowedExemption = initialFormData.partnerMemberExemption.maximumAllowedTaxExemption ?? 0;
        const invalidPartnerUsedExemption = (formData.partnerMemberExemption.usedTaxExemption ?? 0) > partnerMemberMaximumAllowedExemption;
        const invalidPrimaryUsedExemption = formData.primaryMemberExemption.usedTaxExemption > initialFormData.primaryMemberExemption.maximumAllowedTaxExemption;
        setAlertBannerForPrimary(invalidPrimaryUsedExemption)
        setAlertBannerForPartner(invalidPartnerUsedExemption)
        const deceasedDateIsValid = validateDeceasedDate();
        if (!deceasedDateIsValid) {
            setAlertBannerForDeceasedDate(deceasedDateIsValid);
        }
    }, [
        initialFormData.partnerMemberExemption,
        initialFormData.primaryMemberExemption.maximumAllowedTaxExemption,
        formData.partnerMemberExemption.usedTaxExemption,
        formData.primaryMemberExemption.usedTaxExemption,
        formData.partnerMemberExemption.deceasedDate,
        includeDeceasedPartnerChecked
    ]);

    const buildTaxExemptionWriteModel = () => ({
        primaryMemberExemption: {
            usedTaxExemption: formData.primaryMemberExemption.usedTaxExemption
        },
        partnerMemberExemption: {
            usedTaxExemption: formData.partnerMemberExemption.usedTaxExemption,
            deceasedDate:  formData.partnerMemberExemption.deceasedDate,
        }
    });

    const onSaveClick = () => {
        const shouldBeSaved = !showAlertBannerForPrimary && !showAlertBannerForPartner && validateDeceasedDate();
        if (shouldBeSaved) {
            onSave(buildTaxExemptionWriteModel());
        }
    }

    const validateDeceasedDate = () => {
        const isInvalid = includeDeceasedPartnerChecked && !formData.partnerMemberExemption.deceasedDate;
        setAlertBannerForDeceasedDate(isInvalid);
        return !isInvalid;
    }

    const setDeceasedDate = (date: Moment | null) => {
        setFormData({
            ...formData,
            partnerMemberExemption: {
                ...formData.partnerMemberExemption,
                deceasedDate: date ? momentToISO(date) : null
            }
        })
    }

    const onDateChange = (date: Moment) => {
        setDeceasedDate(date);
        getMaxExemption(date ? date.year() : null)
        onEvaluate(({
            primaryMemberExemption: {
                usedTaxExemption: formData.primaryMemberExemption.usedTaxExemption
            },
            partnerMemberExemption: {
                usedTaxExemption: formData.partnerMemberExemption.usedTaxExemption,
                deceasedDate: date ? momentToISO(date) : null
            }
        }));
    }

    const onPartnerExemptionChange = (value: number | null) => {
        const newFormData = {
            ...formData,
            partnerMemberExemption: {
                ...formData.partnerMemberExemption,
                usedTaxExemption: (value)
            }
        }
        setFormData(newFormData);
    }


    const onBlur = () => {
        if (!showAlertBannerForPrimary && !showAlertBannerForPartner) {
            onEvaluate(buildTaxExemptionWriteModel());
        }
    }

    const primaryMemberExemptionAvailable = initialFormData.primaryMemberExemption.maximumAllowedTaxExemption - formData.primaryMemberExemption.usedTaxExemption;
    const dataEntryItems = [{
        label: `${initialFormData.primaryMemberExemption.member.firstName}'s Exemption Available`,
        value: formatCurrency(primaryMemberExemptionAvailable),
        testId: 'primary-member-exemption-available'
    }];

    if (initialFormData.partnerMemberExemption.maximumAllowedTaxExemption !== null) {
        const partnerMemberExemptionAvailable = initialFormData.partnerMemberExemption.maximumAllowedTaxExemption -
            (formData.partnerMemberExemption.usedTaxExemption ?? 0);
        const partnerItem = {
            label: `${initialFormData.partnerMemberExemption.member !== null ?
                initialFormData.partnerMemberExemption.member.firstName : "Spouse"}'s Exemption Available`,
            value: formatCurrency(partnerMemberExemptionAvailable),
            testId: 'partner-member-exemption-available'
        };
        const totalItem = {
            label: 'Total Exemption Available',
            testId: 'total-exemption-available',
            value: formatCurrency(primaryMemberExemptionAvailable + partnerMemberExemptionAvailable)
        };
        dataEntryItems.push(partnerItem, totalItem);
    }

    return <>
        <div className="side-drawer-form">
            <DataEntryHeader
                className='dataEntryHeader'
                title='Edit Estate Tax Exemption'
                primaryButtonText='Save'
                onPrimaryButtonClick={onSaveClick}
                secondaryButtonText='Cancel'
                onSecondaryButtonClick={() => {
                    const isFormChanged = !deepEquals(formData, initialFormData)
                    isFormChanged ? setShowDiscardChangesModal(true) : onCancel()
                }}
            />
            <div className="side-drawer-form-content">
                <article>
                    <section>
                        <UnderlinedHeader
                            primaryText={`${fullName(initialFormData.primaryMemberExemption.member)}'s Exemption Details`}/>
                        <CurrencyInputWithLabel
                            label='Exemption Used'
                            aria-label='Primary Client Exemption Used'
                            value={formData.primaryMemberExemption.usedTaxExemption}
                            emptyValue='0'
                            onChangeValue={(_e, value) => {
                                const newFormData = {
                                    ...formData,
                                    primaryMemberExemption: {
                                        ...formData.primaryMemberExemption,
                                        usedTaxExemption: (value as number)
                                    }
                                }
                                setFormData(newFormData);
                            }
                            }
                            onBlur={onBlur}
                            error={showAlertBannerForPrimary ? "Cannot exceed federal exemption limit." : undefined}
                        />
                        {initialFormData.partnerMemberExemption.member === null && <Checkbox
                            className="layout-data-entry-form__field"
                            name='include-a-deceased-spouse'
                            value='Include a deceased spouse'
                            checked={includeDeceasedPartnerChecked}
                            aria-label='Include a deceased spouse'
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setIncludeDeceasedPartnerChecked(e.target.checked);
                                const newFormData = {
                                    ...formData,
                                    partnerMemberExemption: {
                                        ...formData.partnerMemberExemption,
                                        deceasedDate: null,
                                        usedTaxExemption: null
                                    }
                                }
                                setFormData(newFormData);
                                getMaxExemption(null);
                                onEvaluate(({
                                    primaryMemberExemption: {
                                        usedTaxExemption: formData.primaryMemberExemption.usedTaxExemption
                                    },
                                    partnerMemberExemption: {
                                        usedTaxExemption: null,
                                        deceasedDate: null
                                    }
                                }));
                            }}
                        />}
                    </section>
                    {includeDeceasedPartnerChecked &&
                        <section>
                            <UnderlinedHeader primaryText={`Spouse's Exemption Details`}
                                              secondaryContent={<RequiredFieldsSubheader/>}/>
                            <div className="layout-data-entry-form__field">
                                <label> <b>Deceased as of <RedAsterisk/> </b></label>
                                <DatePicker date={formData.partnerMemberExemption.deceasedDate !== null
                                    ? moment(formData.partnerMemberExemption.deceasedDate)
                                    : undefined}
                                            className=""
                                            displayFormat={DISPLAY_DATE_FORMAT}
                                            isOutsideRange={(date: Moment) => date.isAfter(moment())}
                                            hideKeyboardShortcutsPanel
                                            id="deceasedDateInput"
                                            onDateChange={(date: Moment) => {
                                                let validDeceasedDate = date;
                                                const currentDate = moment();
                                                if (date !== undefined && date !== null && date.diff(currentDate) > 0) {
                                                    validDeceasedDate = moment.min(date, currentDate)
                                                        .subtract(Math.floor(29 * Math.random()) + 1, 'days')
                                                }
                                                onDateChange(validDeceasedDate);
                                            }}
                                            error={showAlertBannerForDeceasedDate ? "This field is required." : undefined}/>
                            </div>
                            <CurrencyInputWithLabel
                                label='Exemption Used'
                                aria-label='Partner Exemption Used'
                                value={formData.partnerMemberExemption.usedTaxExemption}
                                onChangeValue={(_e, value) => {
                                    onPartnerExemptionChange(value as number)
                                }}
                                emptyValue='0'
                                onBlur={onBlur}
                                error={showAlertBannerForPartner ? "Cannot exceed federal exemption limit." : undefined}
                            />
                        </section>
                    }
                    {initialFormData.partnerMemberExemption.member &&
                        <section>
                            <UnderlinedHeader
                                primaryText={`${fullName(initialFormData.partnerMemberExemption.member)}'s Exemption Details`}/>
                            {initialFormData.partnerMemberExemption?.member?.lifeStatus === LifeStatus.Deceased && formData.partnerMemberExemption.deceasedDate &&
                                <div className="layout-data-entry-form__field">
                                    <label> <b>Deceased as of </b></label>
                                    <DatePicker disabled={true}
                                                date={moment(formData.partnerMemberExemption.deceasedDate)}
                                                className=""
                                                displayFormat={DISPLAY_DATE_FORMAT}
                                                hideKeyboardShortcutsPanel
                                                id="deceasedDateInput"
                                                onDateChange={NO_OP}/>
                                </div>
                            }
                            <CurrencyInputWithLabel
                                label='Exemption Used'
                                aria-label='Partner Exemption Used'
                                value={formData.partnerMemberExemption.usedTaxExemption}
                                emptyValue='0'
                                onChangeValue={(_e, value) => onPartnerExemptionChange(value as number)}
                                onBlur={onBlur}
                                error={showAlertBannerForPartner ? "Cannot exceed federal exemption limit." : undefined}
                            />
                        </section>
                    }
                </article>
                <aside>
                    <DataEntrySummary
                        title='Exemption Available'
                        items={dataEntryItems}
                    />
                </aside>
            </div>
        </div>
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={showDiscardChangesModal}
            headerText={'Discard changes?'}
            size="small"
            buttons={
                [
                    {
                        text: 'Keep Editing',
                        onClick: (() => setShowDiscardChangesModal(false)),
                    },
                    {
                        text: 'Discard Changes',
                        onClick: (() => onCancel()),
                        destructive: true,
                        primary: true,
                    }
                ]
            }
        >
            <div className="font-md">{`Any entered data on the Edit Estate Tax Exemption page will not be saved.`}</div>
        </ModalWrapper>
    </>
}
