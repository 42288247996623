import {ReviseAssetSubclassesSummary} from "./ReviseAssetAllocation/ReviseAssetSubclassesSummary";

export type ReviseAssetAllocationResponse = {
    asOfDate: string | null,
    totalCurrentRiskAssetsAmount: number,
    totalCurrentRiskControlAssetsAmount: number,
    totalCurrentRiskAssetsPercent: number,
    totalCurrentRiskControlAssetsPercent: number,

    totalRecommendedProposedRiskAssetsAmount: number,
    totalRecommendedProposedRiskControlAssetsAmount: number,
    totalRecommendedProposedRiskAssetsPercent: number,
    totalRecommendedProposedRiskControlAssetsPercent: number,

    totalProposedRiskAssetsAmount: number,
    totalProposedRiskControlAssetsAmount: number,
    totalProposedRiskAssetsPercent: number,
    totalProposedRiskControlAssetsPercent: number,

    totalProposedRiskAssetsPercentOfTotalPortfolio: number,
    totalProposedRiskControlAssetsPercentOfTotalPortfolio: number,

    totalDifferenceAmountRiskAssets: number,
    totalDifferenceAmountRiskControlAssets: number,

    riskAssets: ReviseAssetSubclassesSummary[],
    riskControlAssets: ReviseAssetSubclassesSummary[],
    goalsSummaryForOneYear: GoalsSummaryForOneYear
};

export type GoalsSummaryForOneYear = {
    lifestyle: number,
    discretionary: number,
    philanthropic: number,
    family: number,
    totalGoalsPresentValueForNextYear: number
};


export type ProposedAllocationEntry = {
    assetSubClass: string,
    proposedAllocationPercent: number | null,
    proposedAllocationAmount?: number | null,
    excludeSubclass: boolean,
    excludedProposedAllocation: number,
    editedManually: boolean
}

export type RevisedProposedAllocation = {
    riskAssets: ProposedAllocationEntry[],
    riskControlAssets: ProposedAllocationEntry[]
};

export const EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE: ReviseAssetAllocationResponse= {
    asOfDate: null,
    totalCurrentRiskAssetsAmount: 0,
    totalCurrentRiskControlAssetsAmount: 0,
    totalCurrentRiskAssetsPercent: 0,
    totalCurrentRiskControlAssetsPercent: 0,

    totalRecommendedProposedRiskAssetsAmount: 0,
    totalRecommendedProposedRiskControlAssetsAmount: 0,
    totalRecommendedProposedRiskAssetsPercent: 0,
    totalRecommendedProposedRiskControlAssetsPercent: 0,

    totalProposedRiskAssetsAmount: 0,
    totalProposedRiskControlAssetsAmount: 0,
    totalProposedRiskAssetsPercent: 0,
    totalProposedRiskControlAssetsPercent: 0,

    totalProposedRiskAssetsPercentOfTotalPortfolio: 0,
    totalProposedRiskControlAssetsPercentOfTotalPortfolio: 0,

    totalDifferenceAmountRiskAssets: 0,
    totalDifferenceAmountRiskControlAssets: 0,

    riskAssets: [],
    riskControlAssets: [],
    goalsSummaryForOneYear: {
        lifestyle: 0,
        discretionary: 0,
        philanthropic: 0,
        family: 0,
        totalGoalsPresentValueForNextYear: 0
    }
};

export type AlertMessageForRevisedAllocation = {
    assetClassification: string,
    totalProposedAllocationPercent: number,
    totalProposedAllocationAmount: number,
    differenceInTotalProposedAllocationPercent: number,
    differenceInTotalProposedAllocationAmount: number,
}

export enum DataDisplayView {
    PERCENTAGE_VIEW = 'Percentage View',
    DOLLAR_VIEW = 'Dollar View'
}

export type ExcludeSubclassRequest = {
    revisedAssetAllocation: ReviseAssetAllocationResponse,
    assetClassification: AssetClassification,
    subclassName: string,
    excludeSubclass: boolean
}

export type AllocateRemainingAssetsProRataRequest = {
    revisedAssetAllocation: ReviseAssetAllocationResponse,
    assetClassification: AssetClassification
}

export enum AssetClassification {
    RISK_ASSET = "RISK_ASSET",
    RISK_CONTROL = "RISK_CONTROL"
}
