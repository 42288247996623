import {useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {validate as isValidUUID} from "uuid";
import {useAppInsights} from "../AppInsights";
import {msalUtils} from "../MsalUtils";
import {useMsal} from "@azure/msal-react";

export const RouteTracker = () => {
    const location = useLocation();
    const appInsights = useAppInsights();
    const msal=useMsal();
    const oid=msalUtils.getOid(msal);
    const lanId=msalUtils.getLanId(msal);
    const accTitle = msalUtils.getAccountTitle(msal);

    useEffect(() => {
        let screenName = location.pathname.substring(location.pathname.lastIndexOf('/')+1);
        if (!screenName) {
            screenName = 'PartnerDashboard';
        } else if (isValidUUID(screenName)) {
            screenName = 'ProfileDetails';
        }
        appInsights.trackPageView({
            name: screenName,
            properties: {
                unique_user_id: oid,
                lan_id: lanId,
                title: accTitle
            }
        });
    }, [location.pathname])
    return <></>
}