import React, {useContext, useEffect, useState} from "react";
import PersonalLiabilityForm from "./PersonalLiabilityForm";
import {PersonalLiabilityFormData} from "../models/PersonalLiability";
import {assetsApiClient} from "../AssetsApiClient";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {ISO8601_DATE_FORMAT} from "../../constants/common";
import AddFormContainer from "../AddFormContainer";
import {selectClientAssets} from "../clientAssetsSlice";
import AssetsViewContext from "../common/AssetsViewContext";
import {getDefaultOwnershipDetails} from "../formHelpers";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {LegalEntityFormData} from "../models/Ownership";
import {mapFormDataToCreatePersonalLiabilityRequest} from "./mappers";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";

export default function AddPersonalLiability() {
    const profile = useAppSelector(selectProfile);
    const viewType = useContext(AssetsViewContext);
    const {personalAssets} = useAppSelector(selectClientAssets)!;
    const history = useHistory();
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>([]);

    useEffect(() => {
        Promise.all([
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)
        ])
            .then(([memberGroupResponse, legalEntitiesResponse]) => {
                setMemberGroup(memberGroupResponse);
                updateLegalEntities(legalEntitiesResponse)
            }).catch(error => console.error('Could not fetch personal liability data', error.message));
    }, [])

    const defaultPersonalLiability: PersonalLiabilityFormData = {
        liabilityType: personalAssets.data.length > 0 ? "Personal" : "Other",
        type: "Mortgage",
        collateralId: null,
        description: "Mortgage",
        institution: "",
        loanBalance: 0,
        loanType: "Fixed",
        paymentFrequency: "Monthly",
        interestOnly: true,
        interestRate: "0.00",
        maturityDate: moment().format(ISO8601_DATE_FORMAT),
        soleOwnerType: 'Individual',
        ...getDefaultOwnershipDetails(profile.primaryContact.id!)
    };

    const handleSave = async (formData: PersonalLiabilityFormData) => {
        await assetsApiClient.postPersonalLiability(mapFormDataToCreatePersonalLiabilityRequest(formData, profile.id));
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    return (
        <div className="personal-liability">
            <AddFormContainer
                modalTitle="Liability"
                form={(handleCancel) => (
                    <PersonalLiabilityForm
                        defaultPersonalLiability={defaultPersonalLiability}
                        formatTitle={() => 'Add Liability'}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                        memberGroup={memberGroup!}
                        legalEntities={legalEntities}
                        updateLegalEntities={updateLegalEntities}
                    />
                )}
            />
        </div>);
}
