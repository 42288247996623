import React, {useEffect, useState} from "react";
import {GoalsPrioritizationData, GoalsPrioritizationProps, splitGoalsPrioritizationData} from "./reportUtil";
import PrintViewWrapper from "../PrintViewWrapper";
import {PresentationPaneHeader} from "../../../components";
import {InvestableInsufficiencyCard} from "../../../Goals/Prioritization/InvestableInsufficiencyCard";
import NonLifestyleGoalsPrioritizationTable from "../../../Goals/Prioritization/NonLifestyleGoalsPrioritizationTable";
import {NonLifestyleGoalRow} from "../../../Goals/Prioritization/Prioritization";
import PrioritizationUtils from "../../../Goals/Prioritization/PrioritizationUtils";
import {getPortfolioAlignedGoalsAndLiabilitiesValue} from "../../../Portfolio/portfolioUtils";
import {PrioritizationType} from "../../../Goals/models/GoalType";
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import {LifestyleGoalTableReport} from "./LifestyleGoalTableReport";
import {AssetAllocationCardReport} from "./AssetAllocationCardReport";
import NonLifeStyleGoalsFBNITable from "../../../Goals/Prioritization/NonLifeStyleGoalsFBNITable";
import {NO_OP} from "../../../constants/common";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import {useAppSelector} from "../../../store/hooks";
import {selectGoalModel} from "../../../Goals/controller/GoalsModelSlice";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import {
    EMPTY_PROPOSED_ALLOCATION_RESPONSE,
    ProposedAllocationResponse
} from "../../../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import {
    CurrentAllocationResponse,
    EMPTY_CURRENT_ALLOCATION_RESPONSE
} from "../../../ClientManagement/AssetAllocation/CurrentAllocationResponse";
import LoadingIndicator from "../../../pages/LoadingIndicator";

const GoalsPrioritizationContentReport = ({displayName}: GoalsPrioritizationProps) => {
    const {id} = useParams<RouteWithId>();
    const goalModel = useAppSelector<GoalModelType>(selectGoalModel);
    const [proposedAssetAllocation, setProposedAssetAllocation] = useState<ProposedAllocationResponse>(EMPTY_PROPOSED_ALLOCATION_RESPONSE);
    const [currentAssetAllocation, setCurrentAssetAllocation] = useState<CurrentAllocationResponse>(EMPTY_CURRENT_ALLOCATION_RESPONSE);
    const [isLoading, setIsLoading] = useState(true);

    const initialAllGoal: NonLifestyleGoalRow[] = PrioritizationUtils.makeNonLifestyleGoalListTableRowData(goalModel!.nonLifestyleGoals, [...Object.values(goalModel!.familyGoals)], goalModel!.investorGroup)
    const fundedByPortfolioGoals: NonLifestyleGoalRow[] = initialAllGoal.filter(s => !s.isFundedByNonInvestableAssets)

    let {needs, totalNeedsPresentValue, availableAssetsForNeeds} = getNeeds(fundedByPortfolioGoals, goalModel!);
    let wants = getWants(fundedByPortfolioGoals, availableAssetsForNeeds, totalNeedsPresentValue);
    let fundedByNonInvestables: NonLifestyleGoalRow[] = PrioritizationUtils.sortNonLifeStyleGoalList(initialAllGoal.filter(s => s.isFundedByNonInvestableAssets))

    fundedByNonInvestables = PrioritizationUtils.addFbniLifestyleGoal(goalModel!, fundedByNonInvestables);

    const totalPortfolioAssetsValue = goalModel!.assets.totalInvestableValue;
    const portfolioAlignedGoalAndTaxLiabilitiesValue = getPortfolioAlignedGoalsAndLiabilitiesValue({
        ...goalModel!,
        lifestylePresentValue: goalModel!.lifestyleSpendingGoal.calculatedFields
    });
    const isInvestablySufficient: boolean = (totalPortfolioAssetsValue - portfolioAlignedGoalAndTaxLiabilitiesValue) >= 0;

    const isActivePortfolioReserve = goalModel!.proposal.isActivePortfolioReserve;
    let activePortfolioReserveHeader = isActivePortfolioReserve ? 'The Portfolio Reserve is on' : '';

    const [splittedGoalsPrioritization, setSplittedGoalsPrioritization] = useState<Array<GoalsPrioritizationData>>([]);

    const goalsPrioritizationDetails: GoalsPrioritizationData = {
        needs: needs,
        wants: wants,
        fundedByNonInvestables: fundedByNonInvestables
    }

    useEffect(() => {
        Promise.all([
            wealthManagementApiClient.getProposedAllocation(id),
            wealthManagementApiClient.getCurrentAllocation(id)
        ])
            .then(([
                       proposedAllocationResponse,
                       currentAllocationResponse
                   ]) => {

                setCurrentAssetAllocation(currentAllocationResponse)
                setProposedAssetAllocation(proposedAllocationResponse)
                setIsLoading(false)
            }).catch(() => setIsLoading(false));

    }, [id]);

    useEffect(() => {
        const defaultData = {
            needs: undefined,
            wants: undefined,
            fundedByNonInvestables: undefined
        }
        if (needs.length === 0 && wants.length === 0 && fundedByNonInvestables.length === 0) {
            setSplittedGoalsPrioritization([{
                needs: [],
                wants: [],
                fundedByNonInvestables: []
            }]);
        } else {
            splitGoalsPrioritizationData(goalsPrioritizationDetails, defaultData).then((res: Array<GoalsPrioritizationData>) => {
                setSplittedGoalsPrioritization(res);
            });
        }

    }, [goalModel]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (<div>
            {splittedGoalsPrioritization.map((goalsPrioritizationDetailsData, index) =>
                <PrintViewWrapper displayName={displayName!} key={"page-number-" + index}>
                    <div className="prioritization-page-report">
                        <PresentationPaneHeader displayName={''} title={`Prioritize Your Goals${index > 0 ? ' (continued)' : ''}`} />
                        <div className="activated">
                            {!isInvestablySufficient && isActivePortfolioReserve &&
                                <span><i data-testid="reliant-on-market-recov-icon"
                                         className="icons dds-icons icon--size-18 icon--warning paddingright-md">error</i></span>}
                            <span>{activePortfolioReserveHeader}</span>
                        </div>
                        <div className="goal-prioritization-cards">
                            <InvestableInsufficiencyCard key={"InvestableInsufficiencyCard" + index}
                                                         totalPortfolioAssetsValue={totalPortfolioAssetsValue!}
                                                         portfolioAlignedGoalAndTaxLiabilitiesValue={portfolioAlignedGoalAndTaxLiabilitiesValue!}
                                                         isActivePortfolioReserve={isActivePortfolioReserve!}
                            />
                            <AssetAllocationCardReport
                                currentAssetAllocation={currentAssetAllocation}
                                proposedAssetAllocation={proposedAssetAllocation}
                            />
                        </div>

                        {index == 0 && <div className={"goal-prioritization-container"}>
                            <div className={'goal-prioritization-container title'}>{"Core Lifestyle"}</div>
                            <LifestyleGoalTableReport key={"LifestyleGoalTableReport-" + index}
                                                      displayProgressBar={isActivePortfolioReserve!}
                                                      goalModel={goalModel}/>
                        </div>}


                        {index == 0 && needs && needs.length === 0 && <div className={"goal-prioritization-container"}>
                            <div className={'goal-prioritization-container title'}>{"Needs"}</div>
                            <div className={'goal-prioritization-container no-goals-warning'}
                                 data-testid={"goal-fundedByPortfolioGoals-list"}>There are currently no
                                discretionary,
                                philanthropic, or family
                                goals entered.
                            </div>
                        </div>}

                        {goalsPrioritizationDetailsData.needs && goalsPrioritizationDetailsData.needs!.length > 0 &&
                            <div className={"goal-prioritization-container"}>

                                <div className="display-flex"><span
                                    className={'goal-prioritization-container title'}>{"Needs"}</span> {goalsPrioritizationDetailsData.isNeedsFirstPage === false &&
                                    <span
                                        className={'goal-prioritization-container title-continued'}>{"(continued)"}</span>}
                                </div>

                                <NonLifestyleGoalsPrioritizationTable
                                    key={"NonLifestyleGoalsPrioritizationTable" + index}
                                    goalsForDisplay={goalsPrioritizationDetailsData.needs!}
                                    tableClassName={`${(goalsPrioritizationDetailsData.needs!.length === 1) ? 'goals-table-single-row' : 'goals-table'}`}
                                    profileId={id!}
                                    name="needs"
                                    displayProgressBar={isActivePortfolioReserve}
                                    handleDropDownOnClick={NO_OP}
                                    handleDeleteOnClick={NO_OP}/>

                            </div>}


                        {goalsPrioritizationDetailsData.wants && goalsPrioritizationDetailsData.wants.length === 0 &&
                            <div className={"goal-prioritization-container"}>
                                <div className={'goal-prioritization-container title'}>{"Wants"}</div>
                                <div className={'goal-prioritization-container no-goals-warning'}
                                     data-testid={"goal-wants-list"}>There are no wants currently.
                                </div>
                            </div>}

                        {goalsPrioritizationDetailsData.wants && goalsPrioritizationDetailsData.wants?.length > 0 &&
                            <div className={"goal-prioritization-container"}>
                                <div className="display-flex"><span
                                    className={'goal-prioritization-container title'}>{"Wants"}</span> {goalsPrioritizationDetailsData.isWantsFirstPage === false &&
                                    <span
                                        className={'goal-prioritization-container title-continued'}>{"(continued)"}</span>}
                                </div>

                                <NonLifestyleGoalsPrioritizationTable
                                    goalsForDisplay={goalsPrioritizationDetailsData.wants!}
                                    tableClassName={`${(goalsPrioritizationDetailsData.wants!.length === 1) ? 'goals-wants-table-single-row' : 'goals-wants-table'}`}
                                    profileId={id!}
                                    name="wants"
                                    displayProgressBar={isActivePortfolioReserve}
                                    handleDropDownOnClick={NO_OP}
                                handleDeleteOnClick={NO_OP}/>

                            </div>}

                        {goalsPrioritizationDetailsData.fundedByNonInvestables && goalsPrioritizationDetailsData.fundedByNonInvestables.length === 0 &&
                            <div className={"goal-prioritization-container"}>
                                <div
                                    className={'goal-prioritization-container title'}>{"Funded by Non-Investable Assets"}</div>

                                <div className={'goal-prioritization-container no-goals-warning'}
                                     data-testid={"goal-fbni-list"}>There are currently no goals funded by
                                    Non-Investable
                                    Assets.
                                </div>
                            </div>}


                        {goalsPrioritizationDetailsData.fundedByNonInvestables && goalsPrioritizationDetailsData.fundedByNonInvestables!.length > 0 &&
                            <div className={"goal-prioritization-container"}>

                                <div className="display-flex"><span
                                    className={'goal-prioritization-container title'}>{"Funded by Non-Investable Assets"}</span> {goalsPrioritizationDetailsData.isFundedByNonInvestablesFirstPage === false &&
                                    <span
                                        className={'goal-prioritization-container title-continued'}>{"(continued)"}</span>}
                                </div>


                                <NonLifeStyleGoalsFBNITable
                                    goalsForDisplay={goalsPrioritizationDetailsData.fundedByNonInvestables!}
                                    tableClassName={`${(goalsPrioritizationDetailsData.fundedByNonInvestables!.length === 1) ? 'goals-fbni-table-single-row' : 'goals-fbni-table'}`}
                                    displayProgressBar={isActivePortfolioReserve}/>

                            </div>}

                    </div>
                </PrintViewWrapper>
            )}

        </div>
    );

}

function getNeeds(fundedByPortfolioGoals: NonLifestyleGoalRow[], goalModel: GoalModelType) {
    let needs: NonLifestyleGoalRow[] = PrioritizationUtils.sortNonLifeStyleGoalList(fundedByPortfolioGoals.filter(s => s.savedGoal!.prioritizationType === PrioritizationType.NEEDS))
    const totalNeedsPresentValue = PrioritizationUtils.getNonLifestyleGoalTotalPresentValue(needs);
    const availableAssetsForNeeds = goalModel.assets.totalInvestableValue - goalModel.taxLiabilities.totalTaxLiabilitiesFundedByPortfolio - goalModel.lifestyleSpendingGoal.calculatedFields.requiredPortfolioSpendingPresentValue;
    needs = addFundedByPortfolioPercentageToNeedsOrWants(needs, availableAssetsForNeeds, totalNeedsPresentValue);
    return {needs, totalNeedsPresentValue, availableAssetsForNeeds};
}

function getWants(fundedByPortfolioGoals: NonLifestyleGoalRow[], availableAssetsForNeeds: number, totalNeedsPresentValue: number) {
    let wants: NonLifestyleGoalRow[] = PrioritizationUtils.sortNonLifeStyleGoalList(fundedByPortfolioGoals.filter(s => s.savedGoal!.prioritizationType === PrioritizationType.WANTS))
    const totalWantsPresentValue = PrioritizationUtils.getNonLifestyleGoalTotalPresentValue(wants);
    const availableAssetsForWants = availableAssetsForNeeds - totalNeedsPresentValue;
    return addFundedByPortfolioPercentageToNeedsOrWants(wants, availableAssetsForWants, totalWantsPresentValue);
}

function addFundedByPortfolioPercentageToNeedsOrWants(nonLifestyleGoalRows: NonLifestyleGoalRow[], availableAssets: number, totalPVPerPrioritizationType: number): NonLifestyleGoalRow[] {
    if (availableAssets <= 0) {
        return nonLifestyleGoalRows.map(nonLifestyleGoalRow => {
            nonLifestyleGoalRow.fundedByPortfolioPercentage = 0;
            return nonLifestyleGoalRow
        })
    }
    if (totalPVPerPrioritizationType > availableAssets) {
        const fundedByPortfolioPercentageAttr = PrioritizationUtils.getFundedByPortfolioPercentageAttributes(nonLifestyleGoalRows, availableAssets);
        return PrioritizationUtils.updateNonLSRowsWithFundedByPortfolioPercentage(nonLifestyleGoalRows, fundedByPortfolioPercentageAttr)
    }

    return nonLifestyleGoalRows.map(nonLifestyleGoalRow => {
        nonLifestyleGoalRow.fundedByPortfolioPercentage = 100;
        return nonLifestyleGoalRow
    })
}

export default GoalsPrioritizationContentReport;