import React, {useContext, useEffect, useState} from 'react';
import {StandaloneAccountForm} from "./StandaloneAccountForm";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithAssetId} from "src/routes/types";
import {assetsApiClient} from "../AssetsApiClient";
import {AccountDetailsType, StandaloneAccount, StandaloneAccountFormData} from "../models/StandaloneAccount";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import EditFormContainer from "../EditFormContainer";
import {mapToOwnershipDetailsFormData, mapToOwnershipWriteModel} from "../Ownership/mappers";
import {TaxDetailsType} from "../models/TaxDetails";
import {AssetClassifications} from "../models/AssetClassifications";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import AssetsViewContext from "../common/AssetsViewContext";
import {selectClientAssets} from "../clientAssetsSlice";
import {
    getTaxableLiabilityPaidByPortfolioForDeferredAccounts,
    getTaxableLiabilityPaidByPortfolioForTaxableAccounts
} from "../formHelpers";

function EditStandaloneAccount() {
    const history = useHistory();
    const [standaloneAccount, setStandaloneAccount] = useState<StandaloneAccount>();

    const viewType = useContext(AssetsViewContext);

    const {assetId} = useParams<RouteWithAssetId>();
    const profile = useAppSelector(selectProfile);
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>();
    const {accounts, partiallyOwnedInvestmentAccounts, investmentProgram} = useAppSelector(selectClientAssets)!;
    const taxableLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForTaxableAccounts(accounts, investmentProgram, partiallyOwnedInvestmentAccounts)! ;
    const deferredLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForDeferredAccounts(accounts, investmentProgram, partiallyOwnedInvestmentAccounts)! ;

    useEffect(() => {
        Promise.all([
            assetsApiClient.getAccount(profile.id, assetId),
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)
        ])
            .then(([standaloneAccountResponse, memberGroupResponse, legalEntitiesResponse]) => {
                if (!standaloneAccountResponse) {
                    throw new Error("Account is undefined");
                }
                setStandaloneAccount(standaloneAccountResponse);
                setMemberGroup(memberGroupResponse);
                updateLegalEntities(legalEntitiesResponse)
            }).catch(error => console.error('Could not fetch standalone account data', error.message));
    }, [assetId]);

    useEffect(() => {
        assetsApiClient.getAssetClassifications().then(
            (assetClassificationResponse) => {
                setClassifications(assetClassificationResponse);
            }
        )
    },[])

    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const handleSave = async (accountDetails: AccountDetailsType, taxDetails: TaxDetailsType, ownershipDetails: OwnershipDetailsFormData) => {
        const accountIdFromResponse = await saveAccount(accountDetails, taxDetails, ownershipDetails);
        if (accountIdFromResponse) {
            navigateToAssetsView();
        }
    };

    const handleDelete = async () => {
        const response = await assetsApiClient.deleteAccount(profile.id, assetId);
        if (response.status === 200) {
            navigateToAssetsView();
        }
    };

    const onEditHoldingsClick = async (accountDetails: AccountDetailsType, taxDetails: TaxDetailsType, ownershipDetails: OwnershipDetailsFormData) => {
        const accountIdFromResponse = await saveAccount(accountDetails, taxDetails, ownershipDetails);
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/StandaloneAccount/${accountIdFromResponse}/Holdings`);
    };

    const saveAccount = async (accountDetails: AccountDetailsType, updatedTaxDetails: TaxDetailsType, ownershipDetails: OwnershipDetailsFormData) => {
        if (!standaloneAccount) return;
        const standaloneAccountWithoutPersonalLiabilities = {
            ...standaloneAccount,
            personalLiabilities: []
        }
        const account = await assetsApiClient.postAccounts({
            ...standaloneAccountWithoutPersonalLiabilities,
            ...mapToOwnershipWriteModel(ownershipDetails),
            ...accountDetails,
            ...updatedTaxDetails
        });
        return account.id;
    }

    if (standaloneAccount && classifications) {
        return (
            <div className="standalone-account asset-form">
                <EditFormContainer
                    modalTitle="Asset"
                    assetType="Standalone Account"
                    assetDescription={standaloneAccount.name}
                    handleDelete={handleDelete}
                    form={(handleCancel) => (
                        <StandaloneAccountForm
                            formatTitle={(accountName => `Edit ${accountName}`)}
                            handleSave={handleSave}
                            handleCancel={handleCancel}
                            initialStandaloneAccount={mapToFormData(standaloneAccount)}
                            onClickEditHoldings={onEditHoldingsClick}
                            classifications={classifications}
                            memberGroup={memberGroup!}
                            legalEntities={legalEntities!}
                            updateLegalEntities={updateLegalEntities}
                            liabilityPaidByPortfolioForTaxableAccounts={taxableLiabilityPaidByPortfolio}
                            liabilityPaidByPortfolioForDeferredAccounts={deferredLiabilityPaidByPortfolio}
                        />
                    )}
                    liabilities={standaloneAccount.personalLiabilities && standaloneAccount.personalLiabilities.map(
                        liability => liability.description
                    )}
                />
            </div>
        );
    } else {
        return <LoadingIndicator/>;
    }
}

function mapToFormData(standaloneAccount: StandaloneAccount): StandaloneAccountFormData {
    return {
        ...standaloneAccount,
        ...mapToOwnershipDetailsFormData(standaloneAccount),
    };
}

export default EditStandaloneAccount;
