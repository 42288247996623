import {InvestorGroupMemberType, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import React from "react";
import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../../Goals/controller/GoalsModelSlice";
import {GoalModelType} from "../../Goals/models/GoalModelType";
import classNames from "classnames";

export type PlanningPeriodOptions = {
    wasGoalTimeFrameAdjusted: boolean;
}

const PlanningPeriod = (options: Partial<PlanningPeriodOptions>) => {
    const { investorGroup } = useAppSelector<GoalModelType>(selectGoalModel)
    return (
        <>
            <label className="planning-period">
                <b>Planning Period</b>
            </label>
            <div className={classNames('planning-period', {'error-color': options.wasGoalTimeFrameAdjusted}) }>
                <span role="note" aria-label="planningPeriod">
                    {getMember(investorGroup)}
                </span>
            </div>
        </>
    );
}

export function getMember(investorGroup: InvestorGroupType): string | undefined {
    return investorGroup.planningPeriod.numberOfYears + " Years " +
        "(" +
        ((investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PRIMARY)
            ? investorGroup.primaryMember.firstName : investorGroup.partnerMember?.firstName)
        + ")"
}

export default PlanningPeriod;
