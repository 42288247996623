import React, {useEffect, useState} from "react";
import LoadingIndicator from "../pages/LoadingIndicator";
import FamilyTree from "../ClientManagement/FamilyTree/FamilyTree";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import useProfileAndProposals from "../hooks/useProfileAndProposals";
import {reportingApiClient} from "./ReportingApiClient";
import {ClientProfilePageConfigForFamilyTree, FamilyTreeScreenShotDetails, ReportConfig} from "./models/Reporting";
import SetPortfolioReserve from "../Goals/PortfolioReserve/SetPortfolioReserve";
import PortfolioReserve from "../Goals/PortfolioReserve/PortfolioReserve";
import AssetReliance from "../ClientManagement/AssetReliance/AssetReliance";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {
    selectFamilyTree,
    setFamilyTreeViewportBounds,
    setIsExtendedFamilySelected,
    setIsOtherMembersSelected
} from "../ClientManagement/FamilyTree/FamilyTreeSlice";
import PrintViewWrapper from "./DetailedEmailReports/PrintViewWrapper";
import AssetRelianceContainer from "./DetailedEmailReports/AssetRelianceReport/AssetRelianceContainer";
import AgendaContainer from "./DetailedEmailReports/AgendaDetailReport/AgendaContainer";
import CoverPage from "./CoverPage";
import DisclaimerPages from "./DisclaimerPages";
import AssetsReports from "./DetailedEmailReports/Assets";
import WealthTransferReport from "./DetailedEmailReports/WealthTransferReport/WealthTransferReport";
import {EstateType} from "../WealthTransfer/models/api";
import GoalsSummaryReport from "./GoalsSummaryReport";
import {ProfileResponse} from "../ClientManagement/models/ProfileResponse";
import ReserveAndInvestmentPlan from "./ReserveAndInvestmentPlan";
import AssetTitlingReport from "./DetailedEmailReports/AssetTitlingReport";
import * as releaseToggleClientApi from "../ReleaseToggles/ReleaseToggleApiClient";
import {ReleaseTogglesType} from "../ReleaseToggles/ReleaseToggles";
import {setReleaseToggles} from "../ReleaseToggles/releaseTogglesSlice";
import PlanSummaryWrapper from "./PlanSummaryWrapper";
import NetWorthOverTimeWrapper from "./NetWorthOverTimeReport/NetWorthOverTimeWrapper";
import AssetRelianceSummaryReport from "./DetailedEmailReports/AssetRelianceReport/AssetRelianceSummaryReport";
import AssetRelianceReports from "./DetailedEmailReports/AssetRelianceReport";

type PageComponentProps = {
    displayName: string,
    portfolioReserveTarget: number | undefined,
    profileData: ProfileResponse,
    approvedProfileData: ProfileResponse
}
type PageType = {
    PageComponent: React.FC<PageComponentProps>,
    isEnabled: boolean,
    familyTreeScreenShotDetails?: FamilyTreeScreenShotDetails | null
}

const FamilyTreeReport = ({familyTreeConfig}: { familyTreeConfig: ClientProfilePageConfigForFamilyTree }) => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const familyTree = useAppSelector(selectFamilyTree);

    useEffect(() => {
        if (familyTreeConfig.familyTreeScreenShotDetails) {
            const {
                familyMembersDetails,
                isOtherMembersSelected,
                isExtendedFamilySelected
            } = familyTreeConfig.familyTreeScreenShotDetails;
            familyMembersDetails && dispatch(setFamilyTreeViewportBounds(familyMembersDetails));
            dispatch(setIsOtherMembersSelected(isOtherMembersSelected));
            dispatch(setIsExtendedFamilySelected(isExtendedFamilySelected));
        }
        setIsLoading(false);
    }, [familyTreeConfig, familyTree]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return <FamilyTree/>
}

const ReviewPortfolioReserve = ({portfolioReserveTargetLength}: { portfolioReserveTargetLength: number | undefined }) => {
    const isPortfolioReserveSet = portfolioReserveTargetLength !== undefined && portfolioReserveTargetLength !== null;
    return (isPortfolioReserveSet ? <PortfolioReserve showTargetLabel={false}/> : <SetPortfolioReserve/>);
}

const ReportingPrintView = () => {
    const {id} = useParams<RouteWithId>();
    const {profile, approvedProfile} = useProfileAndProposals(id);
    const displayName = approvedProfile.displayName;
    const {portfolioReserveTargetLength} = profile;
    const [pages, setPages] = useState<PageType[]>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        reportingApiClient.getReportConfig().then((reportConfig) => {
            setPages(getPages(reportConfig));
        });

        releaseToggleClientApi.getReleaseToggles()
            .then((updatedReleaseToggles: ReleaseTogglesType) => {
                dispatch(setReleaseToggles({...updatedReleaseToggles}));
            })

    }, []);


    if (!pages || !displayName) {
        return <LoadingIndicator/>
    }

    function getPages(reportConfig: ReportConfig): PageType[] {
        return [
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}
                    showHeader={false}
                    showFooter={false}
                >
                    <CoverPage
                        displayName={displayNameForScreen}
                    />
                </PrintViewWrapper>,
                isEnabled: reportConfig.coverPage.isEnabled
            },
            {
                PageComponent: () => {
                    return <AgendaContainer/>
                },
                isEnabled: reportConfig.agenda.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}><FamilyTreeReport
                    familyTreeConfig={reportConfig.familyTree}/></PrintViewWrapper>,
                isEnabled: reportConfig.familyTree.isEnabled,
                familyTreeScreenShotDetails: reportConfig.familyTree.familyTreeScreenShotDetails
            },
            {
                PageComponent: ({profileData, approvedProfileData}) => {
                    return <AssetsReports reportConfig={reportConfig}
                                          profile={profileData} approvedProfile={approvedProfileData}/>;
                },
                isEnabled: isAnyAssetPageEnabled(reportConfig)
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <GoalsSummaryReport reportConfig={reportConfig}
                                                                                            displayName={displayNameForScreen}/>,
                isEnabled: isAnyGoalsPageEnabled(reportConfig)
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}>
                    <SetPortfolioReserve/></PrintViewWrapper>,
                isEnabled: reportConfig.reviewPortfolioReserveDefinition.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen, portfolioReserveTarget}) => <PrintViewWrapper
                    displayName={displayNameForScreen}>
                    <ReviewPortfolioReserve
                        portfolioReserveTargetLength={portfolioReserveTarget}/>
                </PrintViewWrapper>,
                isEnabled: reportConfig.reviewPortfolioReserve.isEnabled
            },
            {
                PageComponent: ({approvedProfileData}) => {
                    return <ReserveAndInvestmentPlan reportConfig={reportConfig}
                                                     approvedProfile={approvedProfileData}/>;
                },
                isEnabled: isAnyReserveOrInvestmentPlanPageEnabled(reportConfig)
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}> <PlanSummaryWrapper/></PrintViewWrapper>,
                isEnabled: reportConfig.planSummary.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) => <PrintViewWrapper
                    displayName={displayNameForScreen}> <NetWorthOverTimeWrapper
                    discountRate={reportConfig.netWorthOverTime.netWorthOverTimeOptions!.discountRate}
                    isInsuranceIncluded={reportConfig.netWorthOverTime.netWorthOverTimeOptions!.insuranceIncluded}/></PrintViewWrapper>,
                isEnabled: reportConfig.netWorthOverTime.isEnabled
            },
            {
                PageComponent: ({profileData, approvedProfileData}) =>
                    <AssetRelianceReports reportConfig={reportConfig}
                                          profile={profileData} approvedProfile={approvedProfileData}/>,
                isEnabled: isAnyAssetReliancePageEnabled(reportConfig)
            },
            {
                PageComponent: () => <AssetTitlingReport reportConfig={reportConfig}/>,
                isEnabled: reportConfig.assetTitlingAndDistribution.isEnabled || reportConfig.assetTitlingAndDistributionDetailed.isEnabled
            },
            {
                PageComponent: ({profileData, approvedProfileData}) => <WealthTransferReport
                    estateType={EstateType.CURRENT} profile={profileData} approvedProfile={approvedProfileData}/>
                ,
                isEnabled: reportConfig.wealthTransferCurrentValueDetailed.isEnabled
            },
            {
                PageComponent: ({profileData, approvedProfileData}) => <WealthTransferReport
                    profile={profileData} approvedProfile={approvedProfileData}
                    estateType={EstateType.FUTURE}/>
                ,
                isEnabled: reportConfig.wealthTransferFutureValueDetailed.isEnabled
            },
            {
                PageComponent: ({displayName: displayNameForScreen}) =>
                    <DisclaimerPages displayName={displayNameForScreen}/>,
                isEnabled: reportConfig.disclaimerPage.isEnabled
            }
        ]
    }

    return (
        <div className='preview-and-send presentation-viewport' data-theme="print">
            {pages.map(({PageComponent, isEnabled}) => {
                if (isEnabled) {
                    return <PageComponent displayName={displayName}
                                          portfolioReserveTarget={portfolioReserveTargetLength}
                                          profileData={profile}
                                          approvedProfileData={approvedProfile}
                    />
                }
                return <></>;
            })}
        </div>
    );
};

export default ReportingPrintView;

function isAnyAssetPageEnabled(reportConfig: ReportConfig): boolean {
    return reportConfig.assetsSummary.isEnabled
        || reportConfig.assetsSummaryDetailed.isEnabled
        || reportConfig.currentNetWorth.isEnabled
        || reportConfig.currentNetWorthDetailed.isEnabled
        || reportConfig.currentNetWorthAtDeath.isEnabled
        || reportConfig.currentNetWorthAtDeathDetailed.isEnabled
}

function isAnyGoalsPageEnabled(reportConfig: ReportConfig): boolean {
    return reportConfig.goalsSummary.isEnabled
        || reportConfig.goalsSummaryDetailed.isEnabled
        || reportConfig.goalsPrioritization.isEnabled
}

function isAnyReserveOrInvestmentPlanPageEnabled(reportConfig: ReportConfig): boolean {
    return reportConfig.monitorPortfolioReserve.isEnabled
        || reportConfig.currentVsProposed.isEnabled
        || reportConfig.currentVsProposedDetailed.isEnabled
}

function isAnyAssetReliancePageEnabled(reportConfig: ReportConfig): boolean {
    return reportConfig.assetReliance.isEnabled
        || reportConfig.assetRelianceDetailed.isEnabled
        || reportConfig.assetRelianceWithFutureValue.isEnabled
        || reportConfig.assetRelianceDetailedWithFutureValue.isEnabled
}