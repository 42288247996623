import React from 'react';
import DataEntrySummary, {DataEntrySummaryItem} from "../../components/DataEntry/DataEntrySummary";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import {formatCurrency, formatPercentWithWholeNumberAndTwoDecimals} from "../../utils/format";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import {generateErrorableItem} from "../../components/DataEntry/DataEntryUtils";
import goalUtils from "../Summary/GoalUtils";
import {DiscountRateType} from "../models/DiscountRate";

export default function LifestyleSpendingReserveInformation({
                                                                lifestyleSpendingGoal,
                                                                profile,
                                                                portfolioReserve,
                                                                hasError,
                                                                rateType
                                                            }: {
    lifestyleSpendingGoal: LifestyleSpendingGoal,
    profile: ProfileResponse,
    portfolioReserve: PortfolioReserveResponse,
    hasError: boolean,
    rateType: DiscountRateType
}) {
    const remainingPortfolioReserveYears = profile.isActivePortfolioReserve ?  goalUtils.getRemainingPortfolioReserveLength(profile.portfolioReserveTargetLength, profile.portfolioReserveActivationDate) : profile.portfolioReserveTargetLength
    const reserveTargetText = profile.isActivePortfolioReserve ? 'Expected Reserve Length': 'Reserve Target Length'

    let discountRateItemText = rateType == DiscountRateType.TRIPLE_NET ? 'Triple Net Discount Rate' : 'Gross Discount Rate'
    let discountRateItemValue = rateType == DiscountRateType.TRIPLE_NET ? portfolioReserve.tripleNetDiscountRate : portfolioReserve.grossDiscountRate

    let reserveTargetAmountItem: DataEntrySummaryItem = generateErrorableItem(
        'Reserve Target Amount',
        formatCurrency(lifestyleSpendingGoal.calculatedFields.reserveTargetAmount),
        hasError);
    let fundedByPortfolioReserveItem: DataEntrySummaryItem = generateErrorableItem(
        'Funded by Portfolio Reserve',
        formatCurrency(lifestyleSpendingGoal.calculatedFields.portfolioReservePresentValue),
        hasError);
    let fundedByInflowsItem: DataEntrySummaryItem = generateErrorableItem(
        'Funded by Inflows',
        formatCurrency(lifestyleSpendingGoal.calculatedFields.portfolioReserveAlignedInflowPresentValue),
        hasError);
    let reserveDiscountRateItem: DataEntrySummaryItem = generateErrorableItem(
        discountRateItemText,
        formatPercentWithWholeNumberAndTwoDecimals(discountRateItemValue)
        , hasError);
    return (
        <section>
            <DataEntrySummary title="Reserve Information"
                              items={[
                                  {
                                      label: reserveTargetText,
                                      value: (remainingPortfolioReserveYears + ' years'),
                                      testId: "reserve-target-length",
                                  },
                                  reserveTargetAmountItem,
                                  fundedByPortfolioReserveItem,
                                  fundedByInflowsItem,
                                  reserveDiscountRateItem
                              ]}
            />
        </section>
    )
}
