import {DomesticTrustAccount, LegalAgreement} from "../../models/InvestmentProgram";
import React from "react";
import {formatCurrency} from "../../../utils/format";
import {Button} from "../../../components";

export type PartiallyOwnedInvestmentSearchResultsProps = {
    legalAgreement: LegalAgreement,
    onAddAccount: (legalAgreement: LegalAgreement, account: DomesticTrustAccount) => void
};

export function PartiallyOwnedInvestmentSearchResults({legalAgreement, onAddAccount}: PartiallyOwnedInvestmentSearchResultsProps) {
    const noValue = <span className="no-value">&ndash;&ndash;</span>;

    return (
        <div aria-label="Search Results" className="partially-owned-investment-table">
            <div className="partially-owned-investment-table-header partially-owned-investment-grid">
                <span className="marginleft-md">Name</span>
                <span className="textalign-right">Account</span>
                <span className="marginleft-xl textalign-left">Entity Type</span>
                <span className="textalign-right">Legal Agreement</span>
                <span className="textalign-left">Tax Status</span>
                <span className="textalign-right">As of Date</span>
                <span className="textalign-right marginright-md">Present Value</span>
            </div>
            {
                legalAgreement.domesticTrustAccounts.map(account => (
                    <div role="row" key={account.financialAccountPersistentId}>
                        <div
                            aria-label={`Account ${account.accountNumber}`}
                            className="partially-owned-investment-table-row partially-owned-investment-grid"
                        >
                            <span className="marginleft-md">{legalAgreement.name}</span>
                            <span className="textalign-right">{account.accountNumber}</span>
                            <span className="marginleft-xl textalign-left">{legalAgreement.entityType}</span>
                            <span className="textalign-right">{legalAgreement.legalAgreementNumber}</span>
                            <span className="textalign-left">{legalAgreement.taxStatus}</span>
                            <span className="textalign-right">{account.asOfDate}</span>
                            <span className="textalign-right marginright-md">
                                {account.marketValue !== null ? `${formatCurrency(account.marketValue)}` : noValue}
                            </span>
                        </div>
                        <div
                            aria-label={`Add Account to Profile: ${account.accountNumber}`}
                            className="partially-owned-investment-table-row add-account-row">
                            <Button
                                className="search-button marginleft-sm"
                                icon="left"
                                iconName="add"
                                kind="primary"
                                onClick={() => onAddAccount(legalAgreement, account)}
                                size="medium"
                                type="button"
                            >
                                Add Account to Profile
                            </Button>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}