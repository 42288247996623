import {EstateSummary, EstateType} from "./models/api";
import {mapEstateSummaryToGroupedTableData} from "./models/mappers";
import {Accordion, AccordionItem, AccordionItemListHeader} from "../components/Accordion";
import classNames from "classnames";
import {COLOR_ESTATE_BENEFICIARIES, COLOR_TAXES} from "../constants/colors";
import {AccordionItemPresentValueHeader} from "../components/Accordion/AccordionItemPresentValueHeader";
import {GroupedTable} from "../components/Table/GroupedTable";
import {EstateTaxRunningTotal} from "./components/EstateTaxRunningTotal";
import React from "react";

export function WealthTransferAccordion({estateType, estateSummary}: {estateType: EstateType, estateSummary: EstateSummary }) {
    const doBeneficiariesExist = estateSummary.memberBeneficiaries.length > 0 || estateSummary.philanthropicBeneficiaries.length > 0;
    const groupedTableData = mapEstateSummaryToGroupedTableData(estateSummary);
    const stateEstateTaxDeduction = estateSummary.stateTaxSummary.estimatedEstateTax > 0 ? [{
        label: "State Estate Tax Deduction", amount: estateSummary.stateTaxSummary.estimatedEstateTax
    }] : [];
    return <section>
        <AccordionItemListHeader
            testId={"wealth-transfer-accordion-header"}
            firstColumnTitle="Transferred To"
            lastColumnTitle={estateType === EstateType.CURRENT ? "Present Value" : "Future Value"}
            className={classNames("estate-summary-grid", "estate-grid-table")}/>
        <Accordion>
            <AccordionItem
                uuid="estate-summary-beneficiaries"
                primaryText="Beneficiaries"
                accentColor={COLOR_ESTATE_BENEFICIARIES}
                rightAlignedContent={<AccordionItemPresentValueHeader
                    value={estateSummary.beneficiaryTotalValue}/>}
                expandable={doBeneficiariesExist}>
                {doBeneficiariesExist && <GroupedTable
                    columnHeaders={["Name", "Present Value"]}
                    tableData={groupedTableData}/>}
            </AccordionItem>
            <AccordionItem
                uuid="estate-summary-estimated-estate-tax"
                primaryText="Estimated Estate Tax"
                accentColor={COLOR_TAXES}
                rightAlignedContent={<AccordionItemPresentValueHeader
                    value={estateSummary.estimatedEstateTax}/>}>
                <div role="table" className="grouped-table-grid grouped-table">
                    <span role="columnheader" className="grid-span-4 justify-content-start">Type</span>
                    <span role="columnheader" className="justify-content-end">Present Value</span>
                </div>
                <EstateTaxRunningTotal
                    taxType={"State"}
                    estateSummary={estateSummary}
                    effectiveTaxRate={estateSummary.stateTaxSummary.effectiveTaxRate}
                    estimatedEstateTax={estateSummary.stateTaxSummary.estimatedEstateTax}
                    additionalExemptionsOrDeductions={[{
                        label: "State Exemption",
                        amount: estateSummary.stateTaxSummary.totalAvailableExemption
                    }]}
                    taxableEstateValue={estateSummary.stateTaxSummary.taxableEstateValue}
                />
                <EstateTaxRunningTotal
                    taxType={"Federal"}
                    estateSummary={estateSummary}
                    effectiveTaxRate={estateSummary.federalTaxSummary.effectiveTaxRate}
                    estimatedEstateTax={estateSummary.federalTaxSummary.estimatedEstateTax}
                    additionalExemptionsOrDeductions={stateEstateTaxDeduction.concat([{
                        label: "Available Exemption",
                        amount: estateSummary.federalTaxSummary.totalAvailableExemption
                    }])}
                    taxableEstateValue={estateSummary.federalTaxSummary.taxableEstateValue}
                />
            </AccordionItem>
        </Accordion>
    </section>;
}