import React, {useContext, useEffect, useState} from "react";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {toDisplayDateFormat} from "../../utils/dateUtils";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithProfileIdAndAssetId} from "../../routes/types";
import {
    CalculateStockGrantRequest,
    CalculateStockOptionRequest,
    EquityCompensationStockType,
    StockGrant,
    StockGrantsTotal,
    StockGrantType,
    StockGrantWriteModel,
    StockOption,
    StockOptionsAndGrantsResponse,
    StockOptionTotals,
    StockOptionType,
    StockOptionWriteModel
} from "../models/EquityCompensation";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {formatCurrency, formatCurrencyRoundedToTwoDecimals} from "../../utils/format";
import {assetsApiClient} from "../AssetsApiClient";
import {Accordion, AccordionItem, Button, Icon,} from "../../components";
import {ISO8601_DATE_FORMAT} from "../../constants/common";
import StockOptionTable from "./StockOptionTable";
import moment from "moment";
import AssetsViewContext from "../common/AssetsViewContext";
import StockGrantTable from "./StockGrantTable";
import {TableCell} from "src/components/Table/TableCell";
import {QuickSlide} from "../../Goals/PortfolioReserve/QuickSlide/QuickSlide";
import deepEquals from "fast-deep-equal";
import DiscardAssetModal from "../DiscardAssetModal";
import {setClientAssets} from "../clientAssetsSlice";
import {useAppDispatch} from "src/store/hooks";
import useProfileEditableState from "../../hooks/useProfileEditableState";

const EQUITY_COMPENSATION_ACCENT_COLOR = "#9CC8CB";
const STOCK_OPTIONS_ACCORDION_ITEM = 'stock-options-accordion-item';
const STOCK_GRANTS_ACCORDION_ITEM = 'stock-grants-accordion-item';

export const EMPTY_VALUE = "--";

const AddOptionsAndGrants: React.FC = () => {
    const [isColumnsHidden, setIsColumnHidden] = useState(false);
    const {profileId, assetId} = useParams<RouteWithProfileIdAndAssetId>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [stockOptions, setStockOptions] = useState<StockOption[]>([]);
    const [stockGrants, setStockGrants] = useState<StockGrant[]>([]);
    const [stockOptionsAndGrants, setStockOptionsAndGrants] = useState<StockOptionsAndGrantsResponse | null>(null);
    const [stockOptionTotals, setStockOptionTotals] = useState<StockOptionTotals>();
    const [stockGrantsTotal, setStockGrantsTotal] = useState<StockGrantsTotal>();
    const [expandedAccordions, setExpandedAccordions] = useState<string[]>([STOCK_OPTIONS_ACCORDION_ITEM, STOCK_GRANTS_ACCORDION_ITEM]);
    const [deletedStockOptions, setDeletedStockOptions] = useState<string[]>([]);
    const [deletedStockGrants, setDeletedStockGrants] = useState<string[]>([]);
    const [showQuickSlide, setShowQuickSlide] = useState(false);
    const [showQuickSlideGrants, setShowQuickSlideGrants] = useState(false);
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const viewType = useContext(AssetsViewContext);

    useEffect(() => {
        assetsApiClient.getStockOptionsAndGrants(profileId, assetId).then((response: StockOptionsAndGrantsResponse) => {
            setStockOptionsAndGrants(response);
            setStockOptions(response.stockOptions);
            setStockGrants(response.stockGrants);
        });
    }, [profileId, assetId]);

    useEffect(() => {
        setStockOptionTotals({
            totalGrossPotentialValue: calculateStockOptionTotal(stockOptions, 'grossPotentialValue'),
            totalGrossExercisedValue: calculateStockOptionTotal(stockOptions, 'grossExercisableValue'),
            totalAfterTaxPotentialValue: calculateStockOptionTotal(stockOptions, 'afterTaxPotentialValue'),
            totalAfterTaxUnvestedMarketValue: calculateStockOptionTotal(stockOptions, 'afterTaxUnvestedMarketValue'),
            totalAfterTaxExercisedValue: calculateStockOptionTotal(stockOptions, 'afterTaxExercisableValue')
        })
    }, [stockOptions])

    const handleAddStockOption = () => {
        if (!stockOptionsAndGrants) {
            return;
        }
        setExpandedAccordions([...expandedAccordions]);
        const newStockOption: StockOption = {
            id: null,
            type: StockOptionType.NQSO,
            grantDate: moment().utc().format(ISO8601_DATE_FORMAT),
            expirationDate: moment().add(5, 'years').utc().format(ISO8601_DATE_FORMAT),
            grantDescription: "",
            exercisePrice: "0.00",
            sharesGranted: 0,
            sharesExercised: 0,
            sharesVested: 0,
            sharesUnvested: 0,
            grossPotentialValue: 0,
            grossExercisableValue: 0,
            afterTaxPotentialValue: 0,
            afterTaxUnvestedMarketValue: 0,
            afterTaxExercisableValue: 0,
            vested: false,
            createdAt: null,
            updatedAt: null
        };
        setStockOptions([
            ...stockOptions,
            newStockOption
        ])
    }

    const handleUpdateStockOption = (updatedStockOption: StockOption, stockIndex: number, refreshCalculations: boolean) => {
        if (!stockOptionsAndGrants) return;

        let updatedStockOptions = [...stockOptions];
        updatedStockOptions[stockIndex] = updatedStockOption;
        setStockOptions(updatedStockOptions);

        if (refreshCalculations) {
            const calculateStockOptionRequest: CalculateStockOptionRequest = mapToCalculateStockOptionRequest(
                updatedStockOption,
                stockOptionsAndGrants.stockPrice,
                stockOptionsAndGrants.effectiveTaxRate,
                stockOptionsAndGrants.capitalGainsTaxRate
            );
            assetsApiClient.calculateStockOption(profileId, calculateStockOptionRequest).then((res) => {
                updatedStockOptions = [...stockOptions];
                updatedStockOptions[stockIndex] = res;
                setStockOptions(updatedStockOptions);
            })
        }
    }

    const handleDeleteStockOption = (stockIndex: number) => {
        const updatedStockOptions = [...stockOptions];
        const deletedStockOptionId = updatedStockOptions[stockIndex].id;
        updatedStockOptions.splice(stockIndex, 1);
        setStockOptions(updatedStockOptions);
        if (deletedStockOptionId) {
            setDeletedStockOptions([
                ...deletedStockOptions,
                deletedStockOptionId
            ])
        }
    }

    useEffect(() => {
        setStockGrantsTotal({
            totalUnvestedMarketValue: calculateStockGrantTotal(stockGrants, 'grossUnvestedValue'),
            totalAfterTaxUnvestedMarketValue: calculateStockGrantTotal(stockGrants, 'afterTaxUnvestedMarketValue'),
        })
    }, [stockGrants])

    const handleAddStockGrant = () => {
        if (!stockOptionsAndGrants) {
            return;
        }

        setExpandedAccordions([...expandedAccordions]);
        const newStockGrant: StockGrant = {
            id: null,
            type: StockGrantType.PSU,
            grantDate: moment().utc().format(ISO8601_DATE_FORMAT),
            expirationDate: moment().add(5, 'years').utc().format(ISO8601_DATE_FORMAT),
            grantDescription: "",
            sharesGranted: 0,
            sharesVested: 0,
            sharesUnvested: 0,
            afterTaxUnvestedMarketValue: 0,
            grossUnvestedValue: 0,
            vested: false,
            createdAt: null,
            updatedAt: null
        };
        setStockGrants([
            ...stockGrants,
            newStockGrant
        ]);
    }

    const handleUpdateStockGrant = (updatedStockGrant: StockGrant, stockIndex: number, updateCalculatedCol: boolean = false) => {
        if (!stockOptionsAndGrants) return;

        let updatedStockGrants = [...stockGrants];
        updatedStockGrants[stockIndex] = updatedStockGrant;
        setStockGrants(updatedStockGrants);

        if (updateCalculatedCol) {
            const calculateStockGrantRequest: CalculateStockGrantRequest = mapToCalculateStockGrantRequest(
                updatedStockGrant,
                stockOptionsAndGrants.stockPrice,
                stockOptionsAndGrants.effectiveTaxRate,
            );
            assetsApiClient.calculateStockGrant(profileId, calculateStockGrantRequest).then((res) => {
                updatedStockGrants = [...stockGrants];
                updatedStockGrants[stockIndex] = res;
                setStockGrants(updatedStockGrants);
            })
        }
    }

    const handleDeleteStockGrant = (stockIndex: number) => {
        const updatedStockGrants = [...stockGrants];
        const deletedStockGrantId = updatedStockGrants[stockIndex].id;
        updatedStockGrants.splice(stockIndex, 1);
        setStockGrants(updatedStockGrants);
        if (deletedStockGrantId) {
            setDeletedStockGrants([
                ...deletedStockGrants,
                deletedStockGrantId
            ])
        }
    }

    const saveOptionsAndGrants = async () => {
        const stockOptionsRequest = mapStockOptionsToStockOptionsWriteModel(stockOptions);
        const stockGrantsRequest = mapStockGrantsToStockGrantsWriteModel(stockGrants);

        const stockOptionsAndGrantsRequest = {
            stockOptions: stockOptionsRequest,
            stockGrants: stockGrantsRequest,
            deletedStockOptions,
            deletedStockGrants
        }
        return await assetsApiClient.postStockOptionsAndGrants(profileId, assetId, stockOptionsAndGrantsRequest);
    }

    const handleSaveButton = async () => {
        saveOptionsAndGrants().then(() => {
            navigateToEditEquityCompensation();
        })
    }

    const handleCancelButton = () => {

        const isFormChanged = !deepEquals(stockOptionsAndGrants?.stockOptions, stockOptions) || !deepEquals(stockOptionsAndGrants?.stockGrants, stockGrants);
        setShowDiscardChangesModal(isFormChanged);
        if (!isFormChanged)
            navigateToEditEquityCompensation();
    }

    const navigateToEditEquityCompensation = () => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditEquityCompensation/${assetId}`);
    }

    const handleOptionEditVesting = (stockIndex: number, stockType: EquityCompensationStockType) => {
        saveOptionsAndGrants().then((res) => {
            const stockId = res.stockOptions[stockIndex].id;
            if (stockId) {
                navigateToEditVesting(stockId, stockType);
                refreshAssets();
            }
        })
    }

    const handleGrantEditVesting = (stockIndex: number, stockType: EquityCompensationStockType) => {
        saveOptionsAndGrants().then((res) => {
            const stockId = res.stockGrants[stockIndex].id;
            if (stockId) {
                navigateToEditVesting(stockId, stockType);
                refreshAssets();
            }
        })
    }
    const navigateToEditVesting = (stockId: string, stockType: EquityCompensationStockType) => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EquityCompensation/${assetId}/${stockType}/${stockId}`);
    }

    const handleClearOptionVesting = (stockIndex: number, stockId: string, stockType: EquityCompensationStockType) => {
        clearVestingSchedule(stockId, stockType).then((res) => {
            let updatedStockOptions = [...stockOptions];
            updatedStockOptions[stockIndex] = {
                ...updatedStockOptions[stockIndex],
                vested: false,
                sharesUnvested: res.sharesGranted,
                grossPotentialValue: res.grossPotentialValue || 0,
                grossExercisableValue: res.grossExercisableValue || 0,
                afterTaxPotentialValue: res.afterTaxPotentialValue || 0,
                afterTaxUnvestedMarketValue: res.afterTaxUnvestedMarketValue || 0,
                afterTaxExercisableValue: res.afterTaxExercisableValue || 0
            }
            setStockOptions(updatedStockOptions);
        }).catch((error) => console.log(error));
    }
    const handleClearGrantVesting = (stockIndex: number, stockId: string, stockType: EquityCompensationStockType) => {
        clearVestingSchedule(stockId, stockType).then((res) => {
            let updatedStockGrants = [...stockGrants];
            updatedStockGrants[stockIndex] = {
                ...updatedStockGrants[stockIndex],
                vested: false,
                sharesUnvested: res.sharesGranted,
                grossUnvestedValue: res.grossUnvestedValue || 0,
                afterTaxUnvestedMarketValue: res.afterTaxUnvestedMarketValue || 0
            }
            setStockGrants(updatedStockGrants);
        }).catch((error) => console.log(error));
    }
    const clearVestingSchedule = async (stockId: string, stockType: EquityCompensationStockType) => {
        return assetsApiClient.postVestingPeriods(profileId, assetId, stockType, stockId, []);
    }

    const refreshAssets = () => {
        assetsApiClient.getAssetsSummary(profileId).then((assetsResponse) => dispatch(setClientAssets(assetsResponse)));
    }

    if (!stockOptionsAndGrants) {
        return <LoadingIndicator/>
    }

    return <div className="stock-options-and-grants">
        <DataEntryHeader
            className='dataEntryHeader'
            title={`Edit ${stockOptionsAndGrants.entityOrAccountName}`}
            onPrimaryButtonClick={handleSaveButton}
            onSecondaryButtonClick={handleCancelButton}
            primaryButtonText="Save"
            disablePrimaryButton={isProfileWithProposalsOrArchived}
            secondaryButtonText="Cancel"
            SubtitleComponent={() => <span className='stock-options-and-grants__subtitle'>
                {`As of ${toDisplayDateFormat(stockOptionsAndGrants.asOfDate)}  |  Current stock price: ${formatCurrencyRoundedToTwoDecimals(stockOptionsAndGrants?.stockPrice)}`}
            </span>}
        />
        <section className="stock-options-and-grants__list">
            <Accordion expanded={expandedAccordions}>
                <AccordionItem
                    uuid={STOCK_OPTIONS_ACCORDION_ITEM}
                    HeaderComponent={({expanded}) => (
                        <StockOptionsAndGrantsHeader
                            setIsColumnHidden={setIsColumnHidden}
                            isColumnsHidden={isColumnsHidden}
                            expanded={expanded}
                            stockType="OPTION"
                            onAddStock={handleAddStockOption}
                            setShowQuickSlide={setShowQuickSlide}
                            setShowQuickSlideGrants={setShowQuickSlideGrants}
                        />
                    )}
                    className="stock-options-and-grants__accordion-item"
                    accentColor={EQUITY_COMPENSATION_ACCENT_COLOR}
                >
                    <StockOptionTable
                        isColumnsHidden={isColumnsHidden}
                        setIsColumnHidden={setIsColumnHidden}
                        stockOptions={stockOptions}
                        updateStockOption={handleUpdateStockOption}
                        handleEditVesting={(stockIndex) => handleOptionEditVesting(stockIndex, EquityCompensationStockType.STOCK_OPTION)}
                        handleClearVesting={(stockIndex, stockId) => handleClearOptionVesting(stockIndex, stockId, EquityCompensationStockType.STOCK_OPTION)}
                        deleteStockOption={handleDeleteStockOption}
                    />
                </AccordionItem>
            </Accordion>

            <StockOptionsFooter stockOptionTotals={stockOptionTotals!}
                                isColumnsHidden={isColumnsHidden}/>

            <Accordion className="marginbottom-xl" expanded={expandedAccordions}>
                <AccordionItem
                    uuid={STOCK_GRANTS_ACCORDION_ITEM}
                    HeaderComponent={({expanded}) => (
                        <StockOptionsAndGrantsHeader
                            setIsColumnHidden={setIsColumnHidden}
                            isColumnsHidden={isColumnsHidden}
                            expanded={expanded}
                            stockType="GRANT"
                            onAddStock={handleAddStockGrant}
                            setShowQuickSlide={setShowQuickSlide}
                            setShowQuickSlideGrants={setShowQuickSlideGrants}
                        />
                    )}
                    className="stock-options-and-grants__accordion-item"
                    accentColor={EQUITY_COMPENSATION_ACCENT_COLOR}
                >
                    <StockGrantTable
                        stockGrants={stockGrants}
                        updateStockGrant={handleUpdateStockGrant}
                        handleEditVesting={(stockIndex) => handleGrantEditVesting(stockIndex, EquityCompensationStockType.STOCK_GRANT)}
                        handleClearVesting={(stockIndex, stockId) => handleClearGrantVesting(stockIndex, stockId, EquityCompensationStockType.STOCK_GRANT)}
                        deleteStockGrant={handleDeleteStockGrant}
                    />
                </AccordionItem>
            </Accordion>
            {stockGrantsTotal && <StockGrantsFooter stockGrantsTotal={stockGrantsTotal}/>}
        </section>
        {showQuickSlide && EquityCompensationStockType.STOCK_OPTION && <QuickSlide
            title="Stock Options"
            children={<>
                <div>
                    Shares Vested represents the amount calculated from the Vesting schedule.
                    To access Vesting schedule, click settings icon.
                </div>
                <div className='stock-option-quickslide-item'>
                    Shares Unvested represents the difference between Shares granted and Shares Vested.
                </div>
                <div className='stock-option-quickslide-item'>
                    Gross Potential Value is calculated as
                    <br/>
                    <code>
                        (Shares Granted - Shares Exercised) * (Stock price - Exercise Price)
                    </code>
                </div>
                <div className='stock-option-quickslide-item'>
                    Gross Exercisable Value is calculated as
                    <br/>
                    <code>
                        (Shares Vested - Shares Exercised) * (Stock Price- Exercised price)
                    </code>
                </div>

                <div className='stock-option-quickslide-item'>
                    If the Option is an ISO, the after tax potential, Unvested and Exercisable values are net of the
                    long-term capital gains rate.
                </div>

                <div className='stock-option-quickslide-item'>
                    If the option is a non-qualified, the after tax potential,Unvested and Exercisable values are net of
                    effective tax rate as designated on the configuration page.
                </div>
            </>}
            closeHandler={() => setShowQuickSlide(false)}

        ></QuickSlide>}
        {showQuickSlideGrants && EquityCompensationStockType.STOCK_GRANT && <QuickSlide
            title="Stock Grants"
            children={<>
                <div>
                    Shares Vested represents the amount calculated from the Vesting schedule.
                    To access Vesting schedule, click settings icon.
                </div>

                <div className='stock-option-quickslide-item'>
                    Shares Unvested represents the difference between Shares Granted and Shares Vested.
                </div>

                <div className='stock-option-quickslide-item'>
                    Gross Potential Value is calculated as
                    <br/>
                    <code>
                        (Shares Granted - Shares Exercised) * (Stock Price - Exercise Price).
                    </code>
                </div>

                <div className='stock-option-quickslide-item'>
                    Unvested Market Value is calculated as
                    <br/>
                    <code>
                        Shares Unvested * Stock Price
                    </code>
                </div>

                <div className='stock-option-quickslide-item'>
                    After Tax Unvested Market Value is net of effective tax rate as
                    designated in the stock grant's settings.
                </div>


            </>}
            closeHandler={() => setShowQuickSlideGrants(false)}

        ></QuickSlide>}

        <DiscardAssetModal
            isOpen={showDiscardChangesModal}
            title={`Discard changes?`}
            content={`Any data entered in this stock will not be saved.`}
            onClickKeepEditing={() => setShowDiscardChangesModal(false)}
            onClickDiscardChanges={navigateToEditEquityCompensation}
        />

    </div>
};

type StockOptionsAndGrantsHeaderProps = {
    expanded: boolean,
    stockType: "OPTION" | "GRANT",
    onAddStock: () => void,
    setIsColumnHidden: (k: boolean) => void,
    isColumnsHidden: boolean
    setShowQuickSlide: (showQuickSlide: boolean) => void
    setShowQuickSlideGrants: (showQuickSlideGrants: boolean) => void

}

function StockOptionsAndGrantsHeader({
                                         expanded,
                                         stockType,
                                         onAddStock,
                                         setShowQuickSlide,
                                         setShowQuickSlideGrants
                                     }: StockOptionsAndGrantsHeaderProps) {
    const title = stockType === "OPTION" ? "Stock Options" : "Stock Grants";
    return (
        <div className="accordion-header-content display-flex" role="row"
             data-testid="asset-classification-header"
        >
            <span role="cell" aria-label={`${title} header`}
                  className="paddingleft-md marginright-lg display-flex align-items-center">
              <Icon name={expanded ? 'chevron_down' : 'chevron_right'} size="large"/>
              <span className="paddingleft-md">
                  <div
                      className="h4 fontweight-normal margin-none">{title}</div>
              </span>
            </span>
            <Button
                icon="only"
                iconName="info_outline_title"
                kind="borderless"
                onClick={(e) => {
                    e.stopPropagation();

                    if (stockType === "OPTION") {
                        setShowQuickSlide(true);
                        setShowQuickSlideGrants(false)
                    } else {
                        setShowQuickSlideGrants(true)
                        setShowQuickSlide(false);
                    }
                }}
                size="small"
            />
            <Button onClick={(e) => {
                e.stopPropagation();
                onAddStock();
            }}
                    size="small"
                    icon="left"
                    iconName="add"
                    kind="borderless"
            >
                {stockType === "OPTION" ? "Add Stock Option" : "Add Stock Grant"}
            </Button>
        </div>
    )
}

function StockOptionsFooter({
                                stockOptionTotals,
                                isColumnsHidden
                            }: { stockOptionTotals: StockOptionTotals, isColumnsHidden: boolean }) {
    return <div role="row"
                className={`${isColumnsHidden ? 'stock-options-grid-column-hidden' : 'stock-options-grid'} stock-options-grid-table__footer`}
                tabIndex={-1}>
        <span role="cell" className="textalign-right total-stock-options-label">Total Stock Options</span>
        {!isColumnsHidden && (<>
            <TableCell
                text={formatOptionalAmount(stockOptionTotals.totalGrossPotentialValue)}
                className="textalign-right"
                ariaLabel={`Total Gross Potential Value`}
            />
            <TableCell
                text={formatOptionalAmount(stockOptionTotals.totalGrossExercisedValue)}
                className="textalign-right"
                ariaLabel={`Total Gross Exercisable Value`}
            />
            <TableCell
                text={formatOptionalAmount(stockOptionTotals.totalAfterTaxPotentialValue)}
                className="textalign-right"
                ariaLabel={`Total After Tax Potential Value`}
            />
        </>)}
        <TableCell
            text={formatOptionalAmount(stockOptionTotals.totalAfterTaxUnvestedMarketValue)}
            className="textalign-right"
            ariaLabel={`Total After Tax Unvested Value`}
        />
        <TableCell
            text={formatOptionalAmount(stockOptionTotals.totalAfterTaxExercisedValue)}
            className="textalign-right"
            ariaLabel={`Total After Tax Exercisable Value`}
        />
    </div>;
}

function StockGrantsFooter({stockGrantsTotal}: { stockGrantsTotal: StockGrantsTotal }) {
    return <div role="row" className="stock-grants-grid stock-grants-grid-table__footer" tabIndex={-1}>
        <span role="cell" className="textalign-right total-stock-grants-label">Total Stock Grants</span>
        <TableCell
            text={formatOptionalAmount(stockGrantsTotal.totalUnvestedMarketValue)}
            className="textalign-right"
            ariaLabel={`Total unvested market Value`}
        />
        <TableCell
            text={formatOptionalAmount(stockGrantsTotal.totalAfterTaxUnvestedMarketValue)}
            className="textalign-right"
            ariaLabel={`Total after tax unvested market Value`}
        />
    </div>;
}

function mapStockOptionsToStockOptionsWriteModel(stockOptions: StockOption[]): StockOptionWriteModel[] {
    return stockOptions.map(({
                                 id,
                                 type,
                                 grantDate,
                                 expirationDate,
                                 grantDescription,
                                 exercisePrice,
                                 sharesGranted,
                                 sharesExercised,
                                 createdAt,
                                 updatedAt
                             }: StockOption) => ({
        id,
        type,
        grantDate,
        expirationDate,
        grantDescription,
        exercisePrice,
        sharesGranted,
        sharesExercised,
        createdAt,
        updatedAt
    }));
}

function mapStockGrantsToStockGrantsWriteModel(stockGrants: StockGrant[]): StockGrantWriteModel[] {
    return stockGrants.map(({
                                id,
                                type,
                                grantDate,
                                expirationDate,
                                grantDescription,
                                sharesGranted,
                                createdAt,
                                updatedAt
                            }: StockGrant) => ({
        id,
        type,
        grantDate,
        expirationDate,
        grantDescription,
        sharesGranted,
        createdAt,
        updatedAt
    }));
}

const mapToCalculateStockOptionRequest = ({
                                              id,
                                              type,
                                              grantDate,
                                              expirationDate,
                                              grantDescription,
                                              sharesGranted,
                                              exercisePrice,
                                              sharesExercised,
                                              sharesVested,
                                              vested,
                                              createdAt,
                                              updatedAt
                                          }: StockOption, stockPrice: number, effectiveTaxRate: number, capitalGainsTaxRate: number): CalculateStockOptionRequest => ({
    id,
    type,
    grantDate,
    expirationDate,
    grantDescription,
    sharesGranted,
    exercisePrice,
    sharesExercised,
    sharesVested,
    vested,
    stockPrice,
    effectiveTaxRate,
    capitalGainsTaxRate,
    createdAt,
    updatedAt
});

const mapToCalculateStockGrantRequest = ({
                                             id,
                                             type,
                                             grantDate,
                                             expirationDate,
                                             grantDescription,
                                             sharesGranted,
                                             sharesVested,
                                             vested,
                                             assetId: equityCompensationId,
                                             createdAt,
                                             updatedAt
                                         }: StockGrant, stockPrice: number, effectiveTaxRate: number): CalculateStockGrantRequest => ({
    id,
    type,
    equityCompensationId,
    grantDate,
    expirationDate,
    grantDescription,
    sharesGranted,
    sharesVested,
    vested,
    stockPrice,
    effectiveTaxRate,
    createdAt,
    updatedAt
});

const calculateStockOptionTotal = (stockOptions: StockOption[], key: keyof StockOption) => {
    return stockOptions.reduce((accumulator, stockOption) => {
        const value = stockOption[key] as number || 0;
        return accumulator + value;
    }, 0);
}

const calculateStockGrantTotal = (stockGrants: StockGrant[], key: keyof StockGrant) => {
    return stockGrants.reduce((accumulator, stockGrant) => {
        const value = stockGrant[key] as number || 0;
        return accumulator + value;
    }, 0);
}

export const formatOptionalAmount = (value: number | null) => {
    return value ? formatCurrency(value) : EMPTY_VALUE
}

export default AddOptionsAndGrants;
