import CustomModal from "../../components/Modal/Custom/CustomModal";
import {formatCurrency} from "../../utils/format";
import React from "react";
import {PartiallyOwnedInvestmentAccountReadModel} from "../models/PartiallyOwnedInvestmentAccount";

interface DeleteModalForLegalAgreementProps {
    isOpen: boolean,
    partiallyOwnedInvestmentAccount: PartiallyOwnedInvestmentAccountReadModel,
    onConfirm: (id: string) => void,
    onCancel: () => void,
}

const DeletePartiallyOwnedInvestmentAccountConfirmationModal = (
    {isOpen,partiallyOwnedInvestmentAccount,onConfirm,onCancel}: DeleteModalForLegalAgreementProps) => {

    return <CustomModal
        isOpen={isOpen}
        title={
            <h1 className="h3 display-inlineblock">
                {`Remove ${partiallyOwnedInvestmentAccount?.name} from this profile?`}
            </h1>
        }
        size='large'
        content={
        <div className='partially-owned-investment font-default'>
            {partiallyOwnedInvestmentAccount
                && partiallyOwnedInvestmentAccount.domesticTrustAccounts
                && partiallyOwnedInvestmentAccount.domesticTrustAccounts.length > 0 &&
                <div>
                    <div className="remove-partially-owned-investment-grid">
                        <span className="fontweight-500">Partially Owned Investment Account</span>
                        <span className="fontweight-500">Account #</span>
                        <span className="textalign-right fontweight-500">Legal Agreement #</span>
                        <span className="textalign-right fontweight-500">Market Value</span>
                    </div>
                        <div className="remove-partially-owned-investment-grid"
                             key={partiallyOwnedInvestmentAccount.id}>
                            <span>{partiallyOwnedInvestmentAccount.name}</span>
                            <span>{partiallyOwnedInvestmentAccount.domesticTrustAccounts[0].accountNumber}</span>
                            <span
                                className="textalign-right">{partiallyOwnedInvestmentAccount.number}</span>
                            <span
                                className="textalign-right">{formatCurrency(partiallyOwnedInvestmentAccount.marketEstateValue.inEstateValue)}</span>
                        </div>
                </div>
            }
        </div>}
        onClickConfirm={() => onConfirm(partiallyOwnedInvestmentAccount.id)}
        onClickCancel={onCancel}
        confirmText='Remove Partially Owned Investment Account'
        cancelText='Cancel'
        confirmButtonDestructive
    />;
}

export default DeletePartiallyOwnedInvestmentAccountConfirmationModal;