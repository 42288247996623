import {Icon, PresentationPaneHeader} from "../../components";
import ExcessAssets from "../../Plans/PlanSummary/ExcessAssets";
import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import {formatCurrency, formatNumberRoundedToWholeNumber} from "../../utils/format";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {COLOR_NT_AQUA_800} from "../../constants/colors";
import React, {ReactElement} from "react";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import {selectPlanSummary} from "../../Plans/PlanSummary/planSummarySlice";
import {kebabCase} from "../../utils/stringUtils";
import {Col, Container, Row} from "react-grid-system";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {calculateCNWInEstateTotalValue} from "../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {
    CreatePlanSummaryNetWorthOverTimeGraphOptions
} from "../../Plans/PlanSummary/PlanSummaryNetWorthOverTimeGraphUtils";
import {AssetsSummary} from "../../Assets/models/Assets";
import {selectClientAssets} from "../../Assets/clientAssetsSlice";
import {selectAssetRelianceReportResponse} from "../DetailedEmailReports/AssetRelianceReport/AssetRelianceReportSlice";
import {EstateSummary} from "../../WealthTransfer/models/api";

interface PlanSummaryReportProps {
    id: string;
    estateSummary: EstateSummary;
}

const PlanSummaryReport = ({id, estateSummary}: PlanSummaryReportProps) => {
    const history = useHistory();
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);
    const planSummary = useAppSelector(selectPlanSummary)!;
    const {enableFutureValueOfExcessAssets} = useAppSelector(selectReleaseToggles)!;
    const assetRelianceData = useAppSelector(selectAssetRelianceReportResponse);
    const investorData = assetRelianceData.investorGroupResponse;
    const {
        approvedProfile: {displayName},
        profile: {portfolioReserveTargetLength}
    } = useProfileAndProposals(id);
    const hasAssets = planSummary.totalGrossValue > 0;
    const hasGoals = planSummary.goalsTotalPresentValue > 0;
    const totalTaxLiabilitiesForAllAccounts = clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const netWealthAtCurrentAge = calculateCNWInEstateTotalValue(clientAssets!) + totalTaxLiabilitiesForAllAccounts;
    const graphOptions: Highcharts.Options = CreatePlanSummaryNetWorthOverTimeGraphOptions({
        investorGroup: investorData,
        netWealthAtCurrentAge,
        futureValueOfExcessAssetsAtAge: planSummary.futureValueOfExcessAssetsByAge,
    });

    return (
        <div className="plan-summary-page">
            <PresentationPaneHeader
                displayName={displayName}
                title="Plan Summary"
            />
            <article className="plan-summary-page__body">
                {/*this is the left column of the page*/}
                <ExcessAssets hasAssetsAndGoals={hasAssets && hasGoals}/>
                {/*this is the right column of the page*/}
                <div className="flex-grow-1">
                    <div className={"net-assets-goals-card"}>
                        <MonetaryValueCard
                            value={hasAssets ? planSummary.totalNetValue + planSummary.totalAssetPurchaseValue : '--'}
                            heading={"Net Assets"}
                            onClick={() =>
                                history.push({
                                    pathname: `/Profile/${id}/ClientProfile/AssetSummary`
                                })}/>
                        <MonetaryValueCard value={hasGoals ? planSummary.goalsTotalPresentValue : '--'}
                                           heading={"Goals"}
                                           onClick={() =>
                                               history.push({
                                                   pathname: `/Profile/${id}/ClientProfile/Goals/Summary`
                                               })}/>
                    </div>
                    <div
                        className={"proposed-asset-allocation-card"}
                        data-testid={"proposed-asset-allocation"}
                        onClick={() =>
                            history.push({
                                pathname: `/Profile/${id}/ClientProfile/AssetAllocation/CurrentVsProposed`
                            })}
                    >
                        <div
                            className={"proposed-asset-allocation-text"}
                            data-testid={"proposed-asset-allocation-text"}
                            tabIndex={0}>
                            <div className={"proposed-asset-allocation"}>Proposed Asset Allocation</div>
                            <div className={"total-portfolio"}>Total Portfolio</div>
                        </div>
                        <div className={"no-hover-donut"}>
                            <RiskDonut
                                donutSize={'sm'}
                                data={planSummary.proposedAllocation}
                            />
                        </div>
                        <div className={"risk-assets-and-risk-control"}>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'Risk Assets'}/>
                                </div>
                                <span
                                    className={"risk-percentage"}>{` ${formatNumberRoundedToWholeNumber(
                                    planSummary.proposedAllocation.totalRiskAssetsPercent
                                )}%`}</span>
                            </div>
                            <div className={"risk"}>
                                <div className={"risk-legend-label"}>
                                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}
                                                label={'Risk Control Assets'}/>
                                </div>
                                <span
                                    className={"risk-percentage"}>{` ${formatNumberRoundedToWholeNumber(
                                    planSummary.proposedAllocation.totalRiskControlAssetsPercent
                                )}%`}</span>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <PlanSummaryCard
                            onClick={() => {
                                portfolioReserveTargetLength == undefined ?
                                    history.push({pathname: `/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve`}) :
                                    history.push({pathname: `/Profile/${id}/ClientProfile/PortfolioReserve/ReviewPortfolioReserve`})
                            }}
                            header='Portfolio Reserve'
                            className='plan-summary-card'
                            subhead={<span
                                className='subhead'>Your Reserve Target length is <strong>{portfolioReserveTargetLength ?? 0} years</strong></span>}
                        />
                        <PlanSummaryCard
                            onClick={() => {
                                history.push({pathname: `/Profile/${id}/ClientProfile/WealthTransferPlanning`})
                            }}
                            header='Estimated Estate Tax'
                            className='plan-summary-card'
                            subhead={<span
                                className='subhead'>Your current estimated estate tax is <strong>{formatCurrency(estateSummary.estimatedEstateTax) ?? 0}</strong></span>}
                        />
                    </div>
                    {enableFutureValueOfExcessAssets &&
                        <PlanSummaryCard
                            onClick={() =>
                                history.push({
                                    pathname: `/Profile/${id}/ClientProfile/NetWorthOverTime`
                                })}
                            header='Net Worth Over Time'
                            className='plan-summary-card'
                            subhead={
                                <div>
                                <span
                                    className='subhead'>Your expected excess at the end of the planning period is <strong>{formatCurrency(planSummary.futureValueOfExcessAssets)}</strong>
                                </span>
                                    <HighchartsReact highcharts={Highcharts} options={graphOptions}/>
                                </div>
                            }
                        />}
                </div>
            </article>
            {planSummary.hasFutureAssetPurchase &&
                <footer className="future-asset-purchase-disclaimer">
                    <Icon name="info_outline" color={COLOR_NT_AQUA_800}/>
                    <span className="future-asset-purchase-disclaimer-text">
                        Future asset purchases are included in Net Assets, Excess Assets and Net Worth Over Time.
                    </span>
                </footer>
            }
        </div>
    );
}


type MonetaryValueCardProps = {
    value: number | string;
    onClick: () => void;
    heading: string;
}

const MonetaryValueCard: React.FC<MonetaryValueCardProps> = ({value, onClick, heading}) => {
    const kebabHeading = kebabCase(heading);
    return (
        <div className={`monetary-value-card ${kebabHeading}`} aria-label={heading} role='button' onClick={onClick}
             tabIndex={0}>
            <div className="sub-header" data-testid={`${kebabHeading}-label`}>{heading}</div>
            <span className="dollar-value" data-testid={`${kebabHeading}-value`}>
                    {typeof value === 'string' ? value : formatCurrency(value)}
            </span>
        </div>
    )
}

type PlanSummaryCardProps = {
    header: string;
    subhead: ReactElement
    onClick: () => void
    className: string
}

const PlanSummaryCard: React.FC<PlanSummaryCardProps> = ({header, subhead, onClick, className}) => {
    return (
        <Container tabIndex={0} style={{paddingLeft: '40px', marginRight: '32px'}} className={className}
                   aria-label={header} onClick={onClick}>
            <div>
                <Row>
                    <Col>
                        <h4 className='header'>{header}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {subhead}
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default PlanSummaryReport;
