import React from "react";
import {DataDisplayView, FundingDisplayOptions, GoalFundingSummary} from "./models/Funding";
import { FundingAccordionItemHeader } from "./FundingAccordionItemHeader";
import { AccordionItem } from "src/components";
import {
    formatFundingCurrency, formatFundingPercentage,
    isDollarView
} from "./fundingUtils";
import GoalFundingTable from "./GoalFundingTable";

type FundingTableSummaryProps = {
    goalsSummary: GoalFundingSummary;
    ageIndex: number;
    title: string;
    color: string;
    displayOptions: FundingDisplayOptions;
    displayView: DataDisplayView;
};

const FundingTableSummary: React.FC<FundingTableSummaryProps> = ({
    goalsSummary,
    ageIndex,
    title,
    color,
    displayOptions,
    displayView
}) => {
    const dollarView = isDollarView(displayView);
    if(!goalsSummary.goals.length) {
        return <></>
    }
    return (
        <AccordionItem
            uuid={title}
            accentColor={color}
            HeaderComponent={({expanded, toggle}) =>
                <FundingAccordionItemHeader
                    title={title}
                    expanded={expanded}
                    fundedByRiskAssets={dollarView
                        ? formatFundingCurrency(goalsSummary.fundedByRiskAssetsAtYear?.[ageIndex])
                        : formatFundingPercentage(goalsSummary.fundedByRiskAssetsAtYearPercentage?.[ageIndex])}
                    fundedByRiskControl={dollarView
                        ? formatFundingCurrency(goalsSummary.fundedByRiskControlAtYear?.[ageIndex])
                        : formatFundingPercentage(goalsSummary.fundedByRiskControlAtYearPercentage?.[ageIndex])}
                    fundedByPortfolio={formatFundingCurrency(goalsSummary.fundedByPortfolioAtYear?.[ageIndex])}
                    fundedByNonInvestable={formatFundingCurrency(goalsSummary.fundedByNonInvestableAtYear?.[ageIndex])}
                    fundedByInflows={formatFundingCurrency(goalsSummary.fundedByInflowsAtYear?.[ageIndex])}
                    presentValue={formatFundingCurrency(goalsSummary.presentValueAtYear?.[ageIndex])}
                    gridClassName="funding-grid"
                    toggle={toggle}
                    displayOptions={displayOptions}
                />
        }
        >
            <GoalFundingTable
                goals={goalsSummary.goals}
                ageIndex={ageIndex}
                goalType={title}
                displayOptions={displayOptions}
                dollarView={dollarView}
            />
        </AccordionItem>
    );
};

export default FundingTableSummary;
