import {AssetTitlingAccordionItem} from "../../../AssetTitling/mappers";

const TOTAL_PAGE_HEIGHT = 1055;
const PAGE_PADDING_HEIGHT = 96;
const SECTION_HEADER_HEIGHT = 34;
const ACCORDION_HEADER_HEIGHT = 53;
const TABLE_HEADER_HEIGHT = 32;
const TABLE_ROW_HEIGHT = 43;
const PAGE_DISCLAIMER_SECTION_TITLE = 54;
const SECTION_BOTTOM_PADDING = 25;

const setPageData = async (pageData: any,
                           pageScore: number,
                           data: any,
                           calculatedData: any) => {
    let updatedPageScore = pageScore + (data.tableData[0].items.length * TABLE_ROW_HEIGHT);

    if (updatedPageScore < TOTAL_PAGE_HEIGHT) {
        pageData.push(data);
    } else {
        const remainingPageSpace = TOTAL_PAGE_HEIGHT - pageScore;
        let noOfDataPageCanFit = 0;
        if (remainingPageSpace > 0) {
            noOfDataPageCanFit = Math.floor(remainingPageSpace / TABLE_ROW_HEIGHT);
            if (noOfDataPageCanFit > 0) {
                const slicedData = data.tableData[0].items.slice(0, noOfDataPageCanFit)
                const dataThatCanFitInCurrentPage = {
                    ...data,
                    tableData: [{...data.tableData, items: slicedData}]
                };

                pageData.push(dataThatCanFitInCurrentPage);
            }
        }

        // Push Current Page
        calculatedData.push(pageData);

        // Creating New Page
        updatedPageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + PAGE_DISCLAIMER_SECTION_TITLE;
        pageData = [];
        // For rest of the data, split the remaining data recursively until all are fit into 1 or more pages
        const remainingAssets = {
            ...data,
            primaryText: `${data.primaryText} (continued)`,
            tableData: [{...data.tableData, items: data.tableData[0].items.slice(noOfDataPageCanFit)}]
        };

        if (remainingAssets.tableData.length > 0) {
            const result = await setPageData(pageData, updatedPageScore, remainingAssets, calculatedData);
            updatedPageScore = result.pageScore;
            pageData = result.pageData;
        }
    }
    return {pageScore: updatedPageScore, pageData};
}

export const splitAssetTitlingData = async (mainData: AssetTitlingAccordionItem[]): Promise<Array<AssetTitlingAccordionItem[]>> => {
    const calculatedData: Array<any> = [];

    let pageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + PAGE_DISCLAIMER_SECTION_TITLE;
    let pageData: any[] = [];

    for (const assetTitlingAccordionItem of mainData) {
        if (assetTitlingAccordionItem) {
            pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + SECTION_BOTTOM_PADDING;
            if (assetTitlingAccordionItem.hasData) {
                const result = await setPageData(pageData, pageScore, assetTitlingAccordionItem, calculatedData);
                pageScore = result.pageScore;
                pageData = result.pageData;
            } else {
                pageData.push(assetTitlingAccordionItem);
            }
        }
    }

    if (pageData.length) {
        calculatedData.push(pageData);
    }
    return calculatedData;
}