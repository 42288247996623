import React from "react";
import {UnderlinedHeader} from "../../components";
import {Checkbox} from "xps-react";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import GoalUtils from "../Summary/GoalUtils";


type GoalCharacteristicsProps = {
    nonLifestyleGoal: NonLifestyleGoalType,
    replaceNonLifestyleGoal: (nonLifestyleGoal: NonLifestyleGoalType) => void,
    isProfileWithProposalsOrArchived?: boolean
}

export const GoalCharacteristics = ({
                                        nonLifestyleGoal,
                                        replaceNonLifestyleGoal,
                                        isProfileWithProposalsOrArchived = false
                                    }: GoalCharacteristicsProps) => {
    const {
        getHasGoalAlreadyStarted
    } = GoalUtils

    const goalHasStarted = (getHasGoalAlreadyStarted(nonLifestyleGoal) )

    const isDiscretionaryGoal = nonLifestyleGoal.goalType === GoalType.DISCRETIONARY;

    const handleUpdateAchieved = (isAchieved: boolean) => {
        replaceNonLifestyleGoal({
            ...nonLifestyleGoal,
            userInputs: {
                ...nonLifestyleGoal.userInputs,
                isAchieved
            }
        })
    };

    const handleUpdateAssetPurchase = (isAssetPurchase: boolean) => {
        replaceNonLifestyleGoal({
            ...nonLifestyleGoal,
            userInputs: {
                ...nonLifestyleGoal.userInputs,
                isAssetPurchase
            }
        })
    };

    return <section aria-label="Goal Characteristics">
        <UnderlinedHeader
            primaryText='Goal Characteristics'
            className="goal-timeframe-underlined-header"
        />

        <div>
            {isDiscretionaryGoal && <Checkbox
                name={"Asset Purchase"}
                value="Asset Purchase"
                checked={nonLifestyleGoal.userInputs.isAssetPurchase}
                aria-label={"Asset Purchase"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdateAssetPurchase(e.target.checked)}
                disabled={isProfileWithProposalsOrArchived}
            />}
            <Checkbox
                name={"Mark As Achieved"}
                value="Mark As Achieved"
                checked={nonLifestyleGoal.userInputs.isAchieved}
                aria-label={"Mark As Achieved"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdateAchieved(e.target.checked)}
                disabled={!goalHasStarted || isProfileWithProposalsOrArchived}
            />
        </div>

    </section>
}
