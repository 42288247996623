import {MemberType} from "./MemberType";
import {FamilyRelationshipType, RelationshipStatusType} from "./FamilyRelationshipType";
import {FamilyMode} from "../FamilyTree/AddFamilyMember/FamilyMode";
import {SiblingGroupPosition} from "../FamilyTree/GenerateNodeLinkData";

export enum FamilyTreeNodeStyle {
    DEFAULT = 'DEFAULT',
    PRIMARY_CONTACT = 'PRIMARY_CONTACT',
    PRIMARY_LEGAL_PARTNER = 'PRIMARY_LEGAL_PARTNER',
    DECEASED = 'DECEASED',
    DOTTED = 'DOTTED',
    BUTTON = 'BUTTON'
}

export type FamilyTreeType = {
    profileId: string,
    displayName: string,
    primaryContact: MemberType,
}

export type NodeOnClickType = (memberData: MemberType, familyMode: FamilyMode) => void;

type BaseFamilyTreeNodeType = {
    key: string;
    centerRelativeToPartKey?: string;
    group?: string;
}

type MemberFamilyTreeNode = {
    name: string;
    age: number;
    siblingGroup: SiblingGroupPosition;
    generation: number;
}

export type FamilyTreeMemberNodeType = BaseFamilyTreeNodeType & MemberFamilyTreeNode & {
    subtitle: string;
    primary: boolean;
    style: FamilyTreeNodeStyle;
    relationshipType?: FamilyRelationshipType;
    onClick?: () => void;
    parentRelationshipType?: RelationshipStatusType;
};

export type FamilyTreeGroupNodeDataType = BaseFamilyTreeNodeType & {
    isGroup: boolean;
    category: TemplateCategory;
}

export type FamilyTreePartnerRelationshipGroupNodeDataType = FamilyTreeGroupNodeDataType & MemberFamilyTreeNode;

export type FamilyTreeButtonNodeType = BaseFamilyTreeNodeType & {
    name: string;
    category: TemplateCategory;
    style?: string;
    onClick?: () => void;
};

export type FamilyTreeNode = FamilyTreeMemberNodeType | FamilyTreeGroupNodeDataType | FamilyTreeButtonNodeType;

export enum TemplateCategory {
    DEFAULT = "",
    ADD_OTHER_MEMBERS_BUTTON = "AddOtherMembersButton",
    IMMEDIATE_FAMILY = 'ImmediateFamily',
    OTHER_MEMBERS_WRAPPER = 'OtherMembersWrapper',
    OTHER_MEMBERS_CONTENT = 'OtherMembersContent',
    PARTNER_RELATIONSHIP = 'PartnerRelationship',
    EXTENDED_FAMILY = 'ExtendedFamily'
}

export interface FamilyTreeLink {
    from: string,
    to: string
}

export interface FamilyTreeChartData {
    familyTreeNodeData: FamilyTreeNode[],
    familyTreeLinkData: FamilyTreeLink[]
}

export const instanceOfFamilyTreeNodeType = (node: FamilyTreeNode): node is FamilyTreeMemberNodeType => {
    return !('isGroup' in node);
}

export type FamilyTreeViewportBounds = {
    scale: number,
    position: Position
}

export type Position = {
    x: number,
    y: number
}
