import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem} from "../../../components";
import React from "react";
import useProfileEditableState from "../../../hooks/useProfileEditableState";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";

type AccountActionMenuProps = {
    onRemoveInvestmentProgram: () => void,
    onRefreshInvestmentProgramHoldings: () => void
    investmentProgramName: string,
    isActiveClient?: boolean
}

const AccountActionMenu = ({onRemoveInvestmentProgram, onRefreshInvestmentProgramHoldings, investmentProgramName, isActiveClient}
                               : AccountActionMenuProps) => {
    const ipName = investmentProgramName?.length > 20 ? investmentProgramName?.substring(0, 20) + "..." : investmentProgramName;
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    return (
        <TableActionDropdownMenu ariaLabel={`Accounts Menu`}>
            <DropdownItem
                itemText="Refresh Holdings"
                value="Refresh Holdings"
                onClick={onRefreshInvestmentProgramHoldings}
            />
            <DropdownItem
                itemText={`Remove ${ipName}`}
                value="Remove Investment Program"
                onClick={onRemoveInvestmentProgram}
                disabled={isProfileWithProposalsOrArchived && isActiveClient}
            />
        </TableActionDropdownMenu>
    )
};

export default AccountActionMenu;