import React from 'react';
import {AssetsSummary} from "../../models/Assets";
import {formatCurrency} from "../../../utils/format";
import {calculateCNWInEstateTotalValue,} from "../../AssetSummary/common/AssetSummaryCalculator";
import AssetList from "./AssetList";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {DropdownItem, PageActionMenu} from "../../../components";
import {NO_OP} from "../../../constants/common";
import {hasInEstateAssets} from "../../AssetSummary/common/utils";
import AddAssetButtonDropdown from "../../AssetSummary/common/AddAssetButtonDropdown";
import {DeleteModalProps} from "../../common/AssetsView";


export interface InEstateCurrentNetWorthProps {
    profileId: string,
    assets: AssetsSummary;
    investorGroup: InvestorGroupType;
    memberGroup: MemberGroup;
    showDeathBenefit?: boolean;
    toggleShowDeathBenefit?: () => void;
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void
    onRemoveInvestmentProgram: () => void;
    onRefreshInvestmentProgramHoldings: () => void;
}

const InEstateCurrentNetWorth = ({
                                     profileId,
                                     assets,
                                     investorGroup,
                                     memberGroup,
                                     showDeathBenefit,
                                     toggleShowDeathBenefit = NO_OP,
                                     onRemoveAssetClick,
                                     onRemoveInvestmentProgram,
                                     onRefreshInvestmentProgramHoldings
                                 }: InEstateCurrentNetWorthProps) => {


    return <>
        <div className="section-header">
            <h3>
                <div>Your current net worth
                    is <b>{formatCurrency(calculateCNWInEstateTotalValue(assets, showDeathBenefit))}</b>:
                </div>
                {!hasInEstateAssets(assets) &&
                    <div className="condensed-subtitle">Begin by entering assets or entities</div>}
            </h3>
            <div>
                <PageActionMenu className="marginright-md" panelWidth={240}>
                    <DropdownItem className="page-action-menu-options"
                                  value="showAtDeathTodayTotals"
                                  itemText="Show At Death Today totals"
                                  onClick={toggleShowDeathBenefit}
                    >
                        <input
                            id="showAtDeathTodayTotals"
                            name="show-at-death-today-totals"
                            type="checkbox"
                            aria-label="show-at-death-today-totals"
                            checked={showDeathBenefit}
                            onChange={toggleShowDeathBenefit}
                        />
                    </DropdownItem>
                </PageActionMenu>
                <AddAssetButtonDropdown assets={assets}
                                        investorGroup={investorGroup}
                                        memberGroup={memberGroup}
                                        showFutureInflowButtons={false}/>
            </div>
        </div>
        <AssetList profileId={profileId}
                   assetsData={assets}
                   investorGroup={investorGroup}
                   showDeathBenefit={showDeathBenefit}
                   onRemoveAssetClick={onRemoveAssetClick}
                   onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                   onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
        />
    </>;
}

export default InEstateCurrentNetWorth;