import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import React from "react";
import {AgeRange} from "./FamilyGoalUtils";
import {
    GoalSubType,
    GoalType,
    newNonLifestyleGoal,
    NonLifeStyleGoalRequest,
    NonLifestyleGoalType,
} from "../models/GoalType";
import {InvestorGroupMemberType, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import moment from "moment";
import {ISO8601_DATE_FORMAT} from "../../constants/common";
import {LifeStatus, MemberType} from "../../ClientManagement/models/MemberType";
import {findAllRelationshipTypesFor} from "../../ClientManagement/FamilyTree/RelationshipTypeUtil";
import {isLegalPartnerRelationship} from "../../ClientManagement/FamilyRelationshipService";
import {DropdownItem} from "../../components";
import {formatNumberRoundedToOneDecimal} from "../../utils/format";
import {calculateYearsBetween, getTodayDate} from "../../utils/dateUtils";

function getGoalSubTypeDropdownItems(values: GoalSubType[]): JSX.Element[] {
    return values.map((goalType: GoalSubType) => {
        return <DropdownItem style={{width: "100%"}} key={goalType}
                             itemText={goalType}
                             value={goalType}/>
    })
}

function getNonLifestyleGoalCumulativeTotalPresentValue(nonLifestyleGoals: NonLifestyleGoalType[]): number {
    return nonLifestyleGoals
        .map((nonLifestyleGoal) => nonLifestyleGoal.calculatedFields.presentValue)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
}


function initializeNonLifestyleGoal(profileId: string, goalType: GoalType, selectedGoalSubType: GoalSubType, investorGroup: InvestorGroupType, beneficiaryId?: string): NonLifestyleGoalType {
    const {startDate, endDate} = getDefaultTimeframe(investorGroup)
    return newNonLifestyleGoal(profileId, goalType, selectedGoalSubType, startDate, endDate, beneficiaryId);
}

const getDefaultTimeframe = (investorGroup: InvestorGroupType): { startDate: string, endDate: string } => {
    const startMoment = moment(getInvestorGroupBirthdate(investorGroup)).year(investorGroup.planningPeriod.startYear);
    const startDate = startMoment.format(ISO8601_DATE_FORMAT);
    const endDate = moment(startDate).add(1, 'years').format(ISO8601_DATE_FORMAT)
    return {startDate, endDate}
}

const getInvestorGroupBirthdate = (investorGroup: InvestorGroupType): string => {
    return investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PRIMARY
        ? investorGroup.primaryMember.birthdate
        : investorGroup.partnerMember!.birthdate;
}

const getRemainingPortfolioReserveLength = (initialReserveTarget?: number | null, reserveActivationDate?: string | null): number => {
    if(initialReserveTarget === undefined || initialReserveTarget === null  ) {
        return 0
    }else if(reserveActivationDate === undefined || reserveActivationDate === null){
        return initialReserveTarget
    }
    const yearsSinceReserveActivation: number = Number(formatNumberRoundedToOneDecimal(calculateYearsBetween(reserveActivationDate, getTodayDate())))
    const remainingPortfolioReserveYears = reserveActivationDate ?  initialReserveTarget - yearsSinceReserveActivation: 0
    return Math.max(Number(formatNumberRoundedToOneDecimal(remainingPortfolioReserveYears)), 0)
}

const getInvestorGroupAgeRangeForFamilyGoal = (investorGroup: InvestorGroupType, nonLifestyleGoal: NonLifestyleGoalType): AgeRange => {
    const clientBirthDate = getInvestorGroupBirthdate(investorGroup)

    const startAge = getAgeForDOBAtSpecificDate(clientBirthDate, nonLifestyleGoal.userInputs.startDate)
    const endAge = getAgeForDOBAtSpecificDate(clientBirthDate, nonLifestyleGoal.userInputs.endDate)

    return {startAge, endAge}
}

const getMemberAgeRangeForFamilyGoal = (member: MemberType, nonLifestyleGoal: NonLifestyleGoalType): AgeRange => {
    const startAge = getAgeForDOBAtSpecificDate(moment(member.birthdate!).format(ISO8601_DATE_FORMAT), nonLifestyleGoal.userInputs.startDate)
    const endAge = getAgeForDOBAtSpecificDate(moment(member.birthdate!).format(ISO8601_DATE_FORMAT), nonLifestyleGoal.userInputs.endDate)

    return {startAge, endAge}
}

function getBeneficiaryFromProfile(profile: ProfileResponse, beneficiaryId: string): MemberType {
    const memberSelected = getSortedFamilyMembersForProfile(profile).find(member => {
        return member.id === beneficiaryId
    });
    if (!memberSelected) {
        throw new Error('MemberSelected is undefined')
    }
    return memberSelected;
}

const getYearsOfFlowAndYearsUntilFlow = (nonLifestyleGoal: NonLifestyleGoalType, investorGroup: InvestorGroupType): {
    yearsAchieved: number,
    yearsOfFlow: number,
    yearsUntilFlow: number,
} => {
    const yearsOfSpending = moment(nonLifestyleGoal.userInputs.endDate).year() - moment(nonLifestyleGoal.userInputs.startDate).year();
    const yearsAchieved = Math.max(investorGroup.planningPeriod.startYear - moment(nonLifestyleGoal.userInputs.startDate).year(), 0);
    const goalHasStarted = yearsAchieved > 0;

    const yearsOfFlow = Math.max(yearsOfSpending - yearsAchieved, 1);
    const yearsUntilFlow = goalHasStarted
        ? 0
        : moment(nonLifestyleGoal.userInputs.startDate).year() - investorGroup.planningPeriod.startYear;

    return {yearsAchieved, yearsOfFlow, yearsUntilFlow}
}

const getHasGoalAlreadyStarted = (nonLifestyleGoal: NonLifestyleGoalType): boolean => {
    const currentTime = moment();
    return moment(nonLifestyleGoal.userInputs.startDate).isBefore(currentTime);
}

const getHasGoalAlreadyCompleted = (nonLifestyleGoal: NonLifestyleGoalType): boolean => {
    const currentTime = moment()
    return moment(nonLifestyleGoal.userInputs.endDate).isBefore(currentTime);
}

const getFormattedCalculationGoals = (nonLifestyleGoal: NonLifestyleGoalType, investorGroup: InvestorGroupType): {
    annualFlow: number,
    yearsOfFlow: number,
    yearsUntilFlow: number
} => {
    const yearsOfFlow = moment(nonLifestyleGoal.userInputs.endDate).diff(moment(nonLifestyleGoal.userInputs.startDate), "years");
    const yearsUntilFlow = moment(nonLifestyleGoal.userInputs.startDate).year() - investorGroup.planningPeriod.startYear;
    const annualFlow = nonLifestyleGoal.userInputs.annualFlow
    return {annualFlow, yearsOfFlow, yearsUntilFlow}
}

const getAgeForDOBAtSpecificDate = (dob: string, date: string): number => {
    return moment(date).diff(dob, 'years');
}

function getSortedFamilyMembersForProfile(profile: ProfileResponse): MemberType[] {
    const primaryContactLegalPartnerId = getPartnerIdForPrimary(profile);
    return findAllRelationshipTypesFor(profile.primaryContact as MemberType)
        .filter((familyRelationship) => {
            return (
                familyRelationship.fromMember.lifeStatus === LifeStatus.Living &&
                familyRelationship.id !== primaryContactLegalPartnerId // This filters Spouse and Domestic Partner for Primary ONLY
            )
        })
        .map((familyRelationship) => familyRelationship.fromMember)
        .sort((member1, member2) => {
            return member2.age - member1.age
        });
}

function getPartnerIdForPrimary(profile: ProfileResponse): string {
    const partners = profile.primaryContact.family.filter(({type}) => isLegalPartnerRelationship(type));
    const partnerId = partners.length > 0 ? partners[0].id : ""
    return !!partnerId ? partnerId : "";
}

function toNonLifestyleGoalRequest(nonLifestyleGoal: NonLifestyleGoalType): NonLifeStyleGoalRequest {
    return {
        userInputs: nonLifestyleGoal.userInputs,
        ownerId: nonLifestyleGoal.ownerId,
        id: nonLifestyleGoal.id,
        goalType: nonLifestyleGoal.goalType
    }
}

function getTotalNonLifestyleRiskControlForNonLSGoals(nonLifestyleGoals: NonLifestyleGoalType[]): number {
    let totalRiskControlValue: number = 0;
    totalRiskControlValue += Object.values(nonLifestyleGoals)
        .map((goal) => (goal.userInputs.riskControl === null ?
            goal.calculatedFields.presentValue * (goal.calculatedFields.riskControl / 100)
            : goal.calculatedFields.presentValue * (goal.userInputs.riskControl / 100))
        )
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    return totalRiskControlValue
}

function getTotalPresentValueForNonLSGoals(nonLifestyleGoals: NonLifestyleGoalType[]): number {
    return Object.values(nonLifestyleGoals)
        .map((nonLifestyleGoal) => nonLifestyleGoal.calculatedFields.presentValue)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
}



const GoalUtils = {
    getNonLifestyleGoalCumulativeTotalPresentValue,
    getGoalSubTypeDropdownItems,
    initializeNonLifestyleGoal,
    getInvestorGroupAgeRangeForFamilyGoal,
    getMemberAgeRangeForFamilyGoal,
    getYearsOfFlowAndYearsUntilFlow,
    getHasGoalAlreadyStarted,
    getHasGoalAlreadyCompleted,
    getFormattedCalculationGoals,
    getBeneficiaryFromProfile,
    getDefaultTimeframe,
    toNonLifestyleGoalRequest,
    getTotalNonLifestyleRiskControlForNonLSGoals,
    getTotalPresentValueForNonLSGoals,
    getPartnerIdForPrimary,
    getRemainingPortfolioReserveLength
};
export default GoalUtils;