import {useEffect, useState} from "react";
import {partnerApiClient} from "../ClientManagement/PartnerApiClient";
import {
    AgendaDetails,
    AgendaItem,
    Presenter,
    ServiceTeamMemberAgenda,
    SplitAgendaDetails
} from "./models/AgendaDetails";
import {useAppSelector} from "../store/hooks";
import {selectResourceCode} from "../ClientManagement/ClientProfile/activeProfileSlice";
import {selectApprovedProfile} from "../ClientManagement/ClientProfile/approvedProfileSlice";
import {clientManagementApiClient} from "../ClientManagement/ClientManagementApiClient";
import {isProposal} from "../ClientManagement/models/isProposal";
import {agendaApiClient} from "./AgendaApiClient";
import {generateDefaultAgenda} from "./__testUtils__/generators";
import {splitAgendaEntries, splitPresenters, updateNtPresenterDetails} from "./AgendaUtils";
import {ProfileResponse} from "../ClientManagement/models/ProfileResponse";

const useAgendaDetails = (profileId: string) => {
    const resourceCode = useAppSelector(selectResourceCode);
    const approvedProfile = useAppSelector(selectApprovedProfile);
    const [partners, setPartners] = useState<ServiceTeamMemberAgenda[]>([]);
    const [approvedProfileFromApi, setApprovedProfileFromApi] = useState<ProfileResponse>();
    const [agendaSummary, setAgendaSummary] = useState<AgendaDetails | null>(null);
    const [splittedData, setSplittedData] = useState<Array<SplitAgendaDetails>>([]);
    const displayName: string = approvedProfile?.displayName || "";
    const [isPresentersContinued, setIsPresentersContinued] = useState<boolean>();

    useEffect(() => {
        if (resourceCode) {
            partnerApiClient.getServiceTeam(resourceCode)
                .then((response: ServiceTeamMemberAgenda[]) => {
                    if (response.length > 0) {
                        setPartners(response);
                    }
                }).catch(reason => {
                    return reason;
                }
            )
        } else {
            if (approvedProfile?.owner) {
                partnerApiClient.getPartnerDetail(approvedProfile.owner)
                    .then((response: ServiceTeamMemberAgenda) => {
                        setPartners([response]);
                    }).catch(reason => {
                        return reason;
                    }
                );
            }
        }
    }, [resourceCode, approvedProfile?.owner]);

    useEffect(() => {
        if (!approvedProfile?.id) {
            clientManagementApiClient.getProfile(profileId)
                .then(newProfile => {
                    if (isProposal(newProfile)) {
                        clientManagementApiClient.getProfile(newProfile.profileIdForProposal)
                            .then(newApprovedProfile => setApprovedProfileFromApi(newApprovedProfile))
                            .catch(error => {
                                console.error('Could not fetch profile for proposal', error.message)
                            });
                    } else {
                        setApprovedProfileFromApi(newProfile);
                    }
                })
        } else {
            setApprovedProfileFromApi(approvedProfile);
        }
    }, [profileId, approvedProfile?.id]);

    useEffect(() => {
        if (approvedProfileFromApi?.id) {
            agendaApiClient.getAgenda(approvedProfileFromApi.id).then(
                agenda => {
                    if (!agenda.presenters.length) {
                        agenda.presenters = generateDefaultAgenda().presenters
                    }
                    agenda.clientProfileName = agenda.clientProfileName || displayName;
                    setAgendaSummary(agenda);
                }
            )
        }
    }, [approvedProfileFromApi?.id]);

    useEffect(() => {

        if (agendaSummary) {
            const sortedAgendaEntries = [...agendaSummary.agendaItems].sort((a: AgendaItem, b: AgendaItem) => a.itemSequenceNo - b.itemSequenceNo);
            const calculatedData: Array<any> = [];

            let agendaMultiPageData: Array<any> = splitAgendaEntries(sortedAgendaEntries, calculatedData);

            if (agendaSummary.presenters.length) {

                const updatedPresenters: Presenter[] = [];
                agendaSummary?.presenters.forEach(({
                                                       presenterId,
                                                       presenterName,
                                                       presenterType,
                                                       presenterTitle
                                                   }) => {
                    if (presenterType === 'NT_PRESENTER') {
                        updateNtPresenterDetails(presenterId, presenterTitle, updatedPresenters, presenterType, presenterName, partners);
                    } else {
                        if (presenterName || presenterTitle) {
                            updatedPresenters.push({
                                presenterType,
                                presenterName: presenterName,
                                presenterTitle: presenterTitle
                            })
                        }
                    }
                });
                updatedPresenters.sort((presenter) => (presenter.presenterType === 'NT_PRESENTER' ? -1 : 1));
                let calculatedPresenters: Array<Presenter[]> = [];
                calculatedPresenters = splitPresenters(updatedPresenters, calculatedPresenters);

                if (calculatedPresenters.length === 1) {
                    agendaMultiPageData.forEach((value, index) => {
                        agendaMultiPageData[index]['presenters'] = calculatedPresenters[0];
                    });
                } else {
                    calculatedPresenters.forEach((value, index) => {
                        if (agendaMultiPageData[index]) {
                            agendaMultiPageData[index]['presenters'] = value;
                        } else {
                            agendaMultiPageData.push({
                                "agendaItems": [],
                                'presenters': value
                            });
                        }
                    })
                }
                setIsPresentersContinued(calculatedPresenters.length > 1);

            }
            setSplittedData(agendaMultiPageData);
        }

    }, [agendaSummary, partners]);

    return {
        agendaSummary,
        splittedData,
        displayName,
        isPresentersContinued
    }
}

export default useAgendaDetails;