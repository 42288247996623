import {UnderlinedHeader} from "../../../components";
import {LegalEntityFormData, OwnershipCategory, OwnershipDetailsFormData, SoleOwnerType} from "../../models/Ownership";
import {SoleOwnerTypeDropdown} from "./SoleOwnerTypeDropdown";
import {DropdownOnChangeData} from "../../../components/Dropdown/Dropdown";
import OwnershipCategoryDropdown from "../components/OwnershipCategoryDropdown";
import {IndividualOwnerDropdown} from "./IndividualOwnerDropdown";
import {LegalEntityOwnerDropdown} from "./LegalEntityOwnerDropdown";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import React, {ReactElement} from "react";

export type SoleOwnershipProps = {
    formData: OwnershipDetailsFormData,
    onFormDataChange: (updatedFormData: OwnershipDetailsFormData) => void,
    totalAssetValue?: number | null,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityFormData[],
    onAddLegalEntity: (ownerEntity: LegalEntityFormData) => void,
    disableOutOfEstate?: boolean,
    subheader?: ReactElement
    isProfileWithProposalsOrArchived?: boolean
    isPartiallyOwned?: boolean
}

export default function SoleOwnership({
                                          formData,
                                          onFormDataChange,
                                          totalAssetValue,
                                          memberGroup,
                                          legalEntities,
                                          onAddLegalEntity,
                                          disableOutOfEstate = false,
                                          subheader,
                                          isProfileWithProposalsOrArchived = false,
                                          isPartiallyOwned
                                      }: SoleOwnershipProps) {
    const estateStatus = () => {
        const {soleOwnerType, memberOwnerships} = formData;
        const {primaryMember, partnerMember} = memberGroup;
        if (memberOwnerships.length > 0) {
            const selectedMemberId = memberOwnerships[0].memberId;
            const isInEstate = selectedMemberId === primaryMember.id || selectedMemberId === partnerMember?.id;
            if (soleOwnerType === "Individual" && isInEstate) {
                return "100.00% IN ESTATE";
            }
        }
        return "100.00% OUT OF ESTATE";
    }

    const handleOwnershipCategoryChange = (data: DropdownOnChangeData<OwnershipCategory>) => {
        onFormDataChange({
            ...formData,
            ownershipCategory: data.value
        });
    };

    const handleSoleOwnerTypeChange = ({value: newOwnerType}: DropdownOnChangeData<SoleOwnerType>) => {
        const updatedFormData = {
            ...formData,
            soleOwnerType: newOwnerType
        };

        if (newOwnerType !== "Individual") {
            updatedFormData.memberOwnerships = [];
            updatedFormData.nonClientMemberOwnerships = [];

            const entityName = formData.legalEntityOwnerships.length ? formData.legalEntityOwnerships[0].name : "";
            updatedFormData.legalEntityOwnerships = [{
                type: newOwnerType,
                name: entityName,
                percentage: DEFAULT_SOLE_OWNERSHIP_PERCENTAGE,
                showWithinOutOfEstateReport: false
            }];
        }

        if (newOwnerType === "Individual") {
            updatedFormData.memberOwnerships = [{
                memberId: memberGroup.primaryMember.id,
                percentage: DEFAULT_SOLE_OWNERSHIP_PERCENTAGE,
                isRevocableTrust: false,
            }];

            updatedFormData.legalEntityOwnerships = [];
        }

        onFormDataChange(updatedFormData)
    };

    const estatePercentageClass = isPartiallyOwned ? "error-text" : "";

    return (
        <section className="ownership-details-section">
            <UnderlinedHeader
                className="ownership-section-header"
                primaryText="Ownership"
                rightAlignedContent={<span className={estatePercentageClass}> {estateStatus()} </span>}
            />
            {subheader}
            <OwnershipCategoryDropdown
                value={formData.ownershipCategory}
                onChange={handleOwnershipCategoryChange}
                partnerMember={memberGroup.partnerMember ?? undefined}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />

            <SoleOwnerTypeDropdown
                value={formData.soleOwnerType!}
                onChangeSoleOwnerType={handleSoleOwnerTypeChange}
                disableOutOfEstate={disableOutOfEstate}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />

            {formData.soleOwnerType === 'Individual'
                ? <IndividualOwnerDropdown memberGroup={memberGroup}
                                           formData={formData}
                                           onFormDataChange={onFormDataChange}
                                           totalAssetValue={totalAssetValue}
                                           disableOutOfEstate={disableOutOfEstate}
                                           isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />
                : <LegalEntityOwnerDropdown
                    onChange={(legalEntityOwnership) => {
                        onFormDataChange({
                            ...formData,
                            legalEntityOwnerships: [legalEntityOwnership],
                            soleOwnerType: legalEntityOwnership.type
                        });
                    }}
                    legalEntityOwnerships={formData.legalEntityOwnerships}
                    legalEntityType={formData.soleOwnerType!}
                    legalEntities={legalEntities}
                    onAddLegalEntity={onAddLegalEntity}
                    totalAssetValue={totalAssetValue}
                    isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}/>}
        </section>
    );

}

export const DEFAULT_SOLE_OWNERSHIP_PERCENTAGE = "100.00";
