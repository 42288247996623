import React from "react";
import {
    COLOR_ASSET_SHORTFALL_ACCENT,
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS_ACCENT,
    COLOR_GOALS,
    COLOR_NT_ORANGE_800,
    COLOR_NT_PRIMARY_AQUA,
    COLOR_PROJECTED_ASSETS,
    COLOR_WHITE
} from "../../constants/colors";

export enum assetRelianceBarChartLegend {
    NET_ASSETS = "NET ASSETS",
    GOALS = "GOALS",
    EXCESS_ASSETS = "EXCESS ASSETS",
    ASSET_SHORTFALL = "ASSET SHORTFALL"
}

interface LegendProps {
    legend: assetRelianceBarChartLegend;
    label?: string;
    children?: React.ReactChild
}

const AssetRelianceBarChartLegend: React.FC<LegendProps> = ({legend, label, children}) => {
    let legendIconColor;
    let legendLabelColor;
    switch (legend) {
        case assetRelianceBarChartLegend.NET_ASSETS:
            legendIconColor = COLOR_ASSETS_ACCOUNTS
            legendLabelColor = COLOR_NT_PRIMARY_AQUA
            break;
        case assetRelianceBarChartLegend.GOALS:
            legendIconColor = COLOR_GOALS
            legendLabelColor = COLOR_NT_ORANGE_800
            break;
        case assetRelianceBarChartLegend.EXCESS_ASSETS:
            legendIconColor = COLOR_EXCESS_ASSETS_ACCENT
            legendLabelColor = COLOR_NT_PRIMARY_AQUA
            break;
        case assetRelianceBarChartLegend.ASSET_SHORTFALL:
            legendIconColor = COLOR_ASSET_SHORTFALL_ACCENT
            legendLabelColor = COLOR_PROJECTED_ASSETS
            break;
        default:
            legendIconColor = COLOR_WHITE
            legendLabelColor = COLOR_WHITE
            break;
    }

    return (
        <>
            <span style={{backgroundColor: legendIconColor}} className="asset-reliance-barchart-legend-container"/>
            {label &&
                <span style={{color: legendLabelColor}} className={"barchart-label"}>
                    {label}
                </span>
            }
            {children}
        </>
    )
}

export default AssetRelianceBarChartLegend;