import React, {ChangeEvent} from "react";
import ClickableYearsInput, {IconState} from "../../../components/YearsInput/ClickableYearsInput";
import {PortfolioReserveResponse} from "../../models/PortfolioReserve";
import {COLOR_PROJECTED_ASSETS} from "../../../constants/colors";
import {ButtonWithTooltip} from "../../../components/ButtonWithTooltip";
import ReserveTargetDetails from "./ReserveTargetDetails";
import {ProfileResponse} from "../../../ClientManagement/models/ProfileResponse";
import {PortfolioReserveOptions} from "../models/PortfolioReserveOptions";
import {formatCurrency} from "../../../utils/format";
import {DropdownItem, Icon, PageActionMenu, Popover, UnderlinedHeader} from "../../../components";
import {PlanningPeriodType} from "../../../ClientManagement/models/InvestorGroupType";

import {ReleaseTogglesType} from "../../../ReleaseToggles/ReleaseToggles";
import {Switch} from "../../../components/DisablableSwitch/Switch";
import {NO_OP} from "../../../constants/common";
import {selectPortfolioReserveButtonStates, setPortfolioReserveOptions} from "../PortfolioReserveSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";

type HowManyYearsRowProps = {
    prTargetInputYears: number | undefined,
    profile: ProfileResponse,
    element: JSX.Element,
    onClickPortfolioReserveTargetLength: (e: React.MouseEvent<HTMLElement>) => void,
    onClickLifestyleMinimumRiskInput: (e: React.MouseEvent<HTMLElement>) => void,
    onIncrementTargetLength: () => Promise<void>,
    onDecrementTargetLength: () => Promise<void>,
    onBlurTargetLength: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
    portfolioReserveTargetLengthInputIconState: IconState.PENCIL | IconState.ARROWS | IconState.NONE,
    lifestyleMinimumRiskInputIconState: IconState.PENCIL | IconState.ARROWS | IconState.NONE,
    editablePortfolioReserve: undefined | boolean,
    onChangeTargetLength: (value: number) => void,
    navigatedBeforeSavingPortfolioReserve: undefined | boolean,
    clickedInputYear: undefined | boolean,
    clickedLifestyleMinimumRiskInput: undefined | boolean,
    displayInsufficientWarning: boolean,
    displayInsufficientWarningForMinimumRiskYears: boolean,
    yearsInputWidth: React.CSSProperties,
    initialPortfolioReserveTargetLength: React.MutableRefObject<number>,
    clientProfileHasActivePortfolioReserve: boolean,
    archiveReadOnly: boolean,
    onClickSetPortfolioReserve: () => Promise<void>,
    portfolioReserveOptions: PortfolioReserveOptions,
    editPortfolioReserve: PortfolioReserveResponse,
    riskDescriber: string,
    onIncrementMinimumRisk: () => Promise<void>,
    onDecrementMinimumRisk: () => Promise<void>,
    onBlurMinimumRisk: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>,
    minimumRisk: number | undefined,
    onChangeMinimumRisk: (_event: React.ChangeEvent<HTMLInputElement>, value: number) => void,
    initialMinimumRiskReserveLength: React.MutableRefObject<number>,
    investableInsufficentAmount: number,
    investableInsufficientAmountForMinimumRiskYears: number,
    planningPeriod: PlanningPeriodType,
    disableSetPortfolioReserveButton: boolean,
    onToggle?: () => void,
    isOn?: boolean,
    isValidReserveTargetLength?: boolean,
    releaseToggle: ReleaseTogglesType | undefined,
    toggledFBNI: boolean
}

function FBNIRow(onToggle: (() => void) | undefined, isOn: boolean | undefined, enableFBNIToggle: boolean) {
    return <>
        <div style={{paddingTop: "15px"}}>
            <div>
                <UnderlinedHeader
                    className="pr-spending-section-header"
                />
            </div>

            <div style={{display: "flex", paddingTop: "20px", paddingRight: "10px"}}>
                <div className={"activate-pr-tooltip_button"} data-testid={'activate-pr-tooltip-button'}>

                    <i className="dds-icons icon--warning color-text--error icon--size-16">info</i>
                </div>
                &nbsp;&nbsp;
                &nbsp;
                <div>
                    <label>Fund Lifestyle Spending Using <br/>Non-Investable Assets</label>
                </div>
            </div>
            <div className="pr-funded-by-non-investable-assets-slider-container">
                            <span className="pr-funded-by-non-investable-assets-slider"
                                  data-testid="pr-funded-by-non-investable-assets-slider">
                                <Switch
                                    id={"Switch_01"}
                                    isOn={isOn}
                                    offText="OFF"
                                    onText="ON"
                                    stateTextPosition="right"
                                    isDisabled={!enableFBNIToggle}
                                    FBNIToggle={onToggle}
                                />
                            </span>
            </div>
        </div>
    </>
}

export function HowManyYearsRow({
                                    prTargetInputYears,
                                    profile,
                                    element,
                                    onClickPortfolioReserveTargetLength,
                                    onClickLifestyleMinimumRiskInput,
                                    onIncrementTargetLength,
                                    onDecrementTargetLength,
                                    onBlurTargetLength,
                                    portfolioReserveTargetLengthInputIconState,
                                    lifestyleMinimumRiskInputIconState,
                                    editablePortfolioReserve,
                                    onChangeTargetLength,
                                    navigatedBeforeSavingPortfolioReserve,
                                    clickedInputYear,
                                    clickedLifestyleMinimumRiskInput,
                                    displayInsufficientWarning,
                                    displayInsufficientWarningForMinimumRiskYears,
                                    yearsInputWidth,
                                    initialPortfolioReserveTargetLength,
                                    clientProfileHasActivePortfolioReserve,
                                    archiveReadOnly,
                                    onClickSetPortfolioReserve,
                                    portfolioReserveOptions,
                                    editPortfolioReserve,
                                    riskDescriber,
                                    onIncrementMinimumRisk,
                                    onDecrementMinimumRisk,
                                    onBlurMinimumRisk,
                                    minimumRisk,
                                    onChangeMinimumRisk,
                                    initialMinimumRiskReserveLength,
                                    investableInsufficentAmount,
                                    investableInsufficientAmountForMinimumRiskYears,
                                    planningPeriod,
                                    disableSetPortfolioReserveButton,
                                    onToggle,
                                    isOn,
                                    isValidReserveTargetLength,
                                    releaseToggle,
                                    toggledFBNI
                                }: HowManyYearsRowProps) {
    const dispatch = useAppDispatch();
    const isInsufficientWarningDisplayed = (displayInsufficientWarning ||
        displayInsufficientWarningForMinimumRiskYears)
    const portfolioReserveButtonState = useAppSelector(selectPortfolioReserveButtonStates);
    const hasFBNIValue = (editPortfolioReserve.lifestylePresentValues.reserveTarget.presentValueForInsufficientYears > 0
        || editPortfolioReserve.lifestylePresentValues.minimumRisk.presentValueForInsufficientYears > 0)
    const enableFBNIToggle = !clientProfileHasActivePortfolioReserve && (isInsufficientWarningDisplayed || hasFBNIValue);

    const handleTargetDetailsCheckbox = (_e: ChangeEvent<HTMLInputElement>) => {
        let selectedPortfolioReserveOptions = {...portfolioReserveOptions}
        selectedPortfolioReserveOptions.showReserveTargetDetails = !selectedPortfolioReserveOptions.showReserveTargetDetails;
        dispatch(setPortfolioReserveOptions(selectedPortfolioReserveOptions));
    }
    const isAllRowsExpanded = portfolioReserveButtonState.excessAssets && portfolioReserveButtonState.portfolioRisk && portfolioReserveButtonState.assetAllocation;
    const toolTipMessage = clientProfileHasActivePortfolioReserve ? "The Portfolio Reserve must be turned off to set a new target." : "All sections below must be expanded at least once to set a new target";
    const shouldHideToolTip = clientProfileHasActivePortfolioReserve ? false : isAllRowsExpanded;
    return <>
        <div className="edit-portfolio-reserve-page__table__column">
            <div style={{display: "flex"}}>
                <div style={{paddingTop: "35px", paddingBottom: "150px"}}
                     className="edit-portfolio-reserve-page__table__column__content">
                    How many years of lifestyle spending would I like to protect?
                </div>
                <PageActionMenu data-testid="reserve-target-page-action" className={"pr-page-action-menu"}
                                panelWidth={220} aria-label="show-monte-carlo-options">
                    <DropdownItem className="reserve-page-action-menu-options" onClick={NO_OP}>
                        Show Reserve Target Details
                        <input
                            id="showReserveTargetDetails"
                            name="show-reserve-target-details"
                            type="checkbox"
                            aria-label="show-reserve-target-details"
                            key={"show-reserve-target-details"}
                            onClick={(e) => e.stopPropagation()}
                            style={{marginLeft: '15px'}}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleTargetDetailsCheckbox(e)
                            }}
                            checked={portfolioReserveOptions.showReserveTargetDetails}
                        />
                    </DropdownItem>
                </PageActionMenu>
            </div>
            {releaseToggle?.enableFBNIEditPR && (isInsufficientWarningDisplayed || hasFBNIValue) && FBNIRow(onToggle, isOn, enableFBNIToggle)}
        </div>
        <div
            className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered paddingbottom-lg">
            <div data-testid="portfolioReserveTargetLengthYearsInputContainer">
                <div style={{
                    width: "320px",
                    fontSize: "24px",
                    lineHeight: "30px",
                    fontWeight: "370",
                    paddingTop: prTargetInputYears === profile.portfolioReserveTargetLength ? "40px" : "25px"
                }}>{element}</div>
                <ClickableYearsInput
                    name="portfolioReserveTargetLength"
                    onClick={onClickPortfolioReserveTargetLength}
                    onUpArrowClick={onIncrementTargetLength}
                    onDownArrowClick={onDecrementTargetLength}
                    onBlur={onBlurTargetLength}
                    iconState={portfolioReserveTargetLengthInputIconState}
                    disabledAndReadOnly={!editablePortfolioReserve}
                    value={prTargetInputYears}
                    onChangeValue={(_event, value) => onChangeTargetLength(value)}
                    inputStyle={{
                        textAlign: "right",
                        paddingRight: "20%",
                    }}
                    error={navigatedBeforeSavingPortfolioReserve ? "Please confirm the Reserve Target Length." : ""}
                    upArrowClassName={"dds-icons input__icon input__icon__increase-support-button"}
                    downArrowClassName={"dds-icons input__icon input__icon__decrease-support-button"}
                    inputClassName={editablePortfolioReserve && !clickedInputYear ? "edit-portfolio-reserve-page__target-length-input editable-target-border" : "edit-portfolio-reserve-page__target-length-input"}
                />
                {displayInsufficientWarning &&
                    <InvestablyInsufficientWarning yearsInputWidth={yearsInputWidth}
                                                   value={investableInsufficentAmount}/>
                }
                <div style={{...yearsInputWidth, fontSize: "15px"}}>Your current asset allocation supports
                    a <span
                        style={{
                            fontWeight: "500",
                            color: "#05676E"
                        }}>{initialPortfolioReserveTargetLength.current} year </span>
                    reserve.
                    <div className="paddingtop-xxl">
                        <ButtonWithTooltip
                            rounded
                            size="medium"
                            kind="primary"
                            disabled={
                                disableSetPortfolioReserveButton ||
                                clientProfileHasActivePortfolioReserve ||
                                (!clickedInputYear && !toggledFBNI) ||
                                !isAllRowsExpanded ||
                                clickedLifestyleMinimumRiskInput ||
                                archiveReadOnly
                            }
                            toolTip={{
                                "aria-label": "set-portfolio-reserve-hover-target",
                                arrow: false,
                                content: toolTipMessage,
                                direction: "bottom",
                                hide: shouldHideToolTip
                            }}
                            onClick={onClickSetPortfolioReserve}
                        >
                            Set Portfolio Reserve
                        </ButtonWithTooltip>
                    </div>
                </div>
                <div style={{...yearsInputWidth, marginTop: "32px"}}>
                    {portfolioReserveOptions.showReserveTargetDetails &&
                        <ReserveTargetDetails
                            presentValue={editPortfolioReserve.lifestylePresentValues.reserveTarget}
                            planningPeriod={planningPeriod}
                        />}
                </div>
            </div>
        </div>
        <div
            className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered">
            <div data-testid="lifestyleMinimumRiskYearsInputContainer">
                <div style={{
                    width: "320px",
                    fontSize: "24px",
                    fontWeight: "370",
                    lineHeight: "30px",
                    paddingTop: "40px",
                    paddingBottom: prTargetInputYears === profile.portfolioReserveTargetLength ? "0px" : "15px"
                }}>
                    What if I take on <span style={{fontWeight: "410"}}>{riskDescriber}</span>?
                </div>
                <ClickableYearsInput
                    name="lifestyleMinimumRisk"
                    onClick={onClickLifestyleMinimumRiskInput}
                    onUpArrowClick={onIncrementMinimumRisk}
                    onDownArrowClick={onDecrementMinimumRisk}
                    onBlur={onBlurMinimumRisk}
                    iconState={lifestyleMinimumRiskInputIconState}
                    disabledAndReadOnly={!editablePortfolioReserve && !isValidReserveTargetLength}
                    value={minimumRisk}
                    onChangeValue={onChangeMinimumRisk}
                    inputStyle={{
                        textAlign: "right",
                        paddingRight: "20%",
                    }}
                    upArrowClassName={"dds-icons icon  input__icon input__icon__increase-risk-button"}
                    downArrowClassName={"dds-icons icon  input__icon input__icon__decrease-risk-button"}
                    inputClassName={"edit-portfolio-reserve-page__target-length-input"}
                />
                {displayInsufficientWarningForMinimumRiskYears &&
                    <InvestablyInsufficientWarning yearsInputWidth={yearsInputWidth}
                                                   value={investableInsufficientAmountForMinimumRiskYears}/>
                }
                {navigatedBeforeSavingPortfolioReserve && <div style={{height: "24px"}}></div>}
                {displayInsufficientWarningForMinimumRiskYears && <div style={{height: "52px"}}></div>}
                <div style={{...yearsInputWidth, fontSize: "15px"}}>The Lifestyle Minimum-Risk Portfolio
                    Reserve
                    is
                    <span style={{
                        fontWeight: "500",
                        color: "#05676E"
                    }}> {initialMinimumRiskReserveLength.current} years.</span>
                </div>
                <div style={{...yearsInputWidth, marginTop: "32px"}}>
                    {portfolioReserveOptions.showReserveTargetDetails &&
                        <ReserveTargetDetails
                            presentValue={editPortfolioReserve.lifestylePresentValues.minimumRisk}
                            planningPeriod={planningPeriod}
                        />}
                </div>
            </div>
        </div>
    </>;
}

function InvestablyInsufficientWarning(props: { yearsInputWidth: React.CSSProperties, value: number }) {
    return <div className={"display-flex"}>
        <Popover
            content={
                <div>
                    <span>To achieve positive investable excess, consider the following:</span>
                    <ul className={"marginleft-xl"}>
                        <li>Reduce the Portfolio Reserve Target length</li>
                        <li>Fund goals with non-investable assets</li>
                        <li>Reconsider goals and spending amounts</li>
                    </ul>
                </div>
            }
            arrow={false}
            direction="top"
            aria-label="investably-insufficient-popover"
            width={"410px"}
        >
            <Icon color={COLOR_PROJECTED_ASSETS} className="popover-trigger popoverContainer"
                  name="status_info_outline" type="info" ariaHidden={false}
                  ariaLabel="investably insufficient"/>
        </Popover>
        <div style={{
            ...props.yearsInputWidth,
            color: COLOR_PROJECTED_ASSETS,
            fontSize: "12px",
            marginBottom: "12px"
        }}>
            This Reserve Target will create an investable shortfall
            of {formatCurrency(props.value)}.
        </div>
    </div>;
}