import {AssetsSummary, CurrentNetWorthAsset} from "../../models/Assets";
import {COLOR_ASSETS_ACCOUNTS} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../components";
import React, {ReactNode, useContext} from "react";
import {assetListData} from "../../AssetSummary/common/utils";
import {
    calculateAllAccountsInEstateTotalMarketValue,
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue
} from "../../AssetSummary/common/AssetSummaryCalculator";
import {InvestorGroupMember, InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../AssetSummary/common/AssetAccordionContentHeader";
import {TableCell} from "../../../components/Table/TableCell";
import {LegalAgreement} from "../../models/InvestmentProgram";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {StandaloneAccountRow} from "../../AssetSummary/InEstate/StandaloneAccountRow";
import {LegalAgreementRow} from "../../AssetSummary/InEstate/LegalAgreementRow";
import {showOwnershipType} from "../../Ownership/helpers";
import {getOwnershipDescription} from "./ownershipUtils";
import {useHistory} from "react-router-dom";
import AssetsViewContext from "../../common/AssetsViewContext";
import {DeleteModalProps} from "../../common/AssetsView";
import AccountActionMenu from "../../AssetSummary/InEstate/AccountActionMenu";
import {shortenName} from "../../common/textUtils";
import {PartiallyOwnedInvestmentAccountReadModel} from "../../models/PartiallyOwnedInvestmentAccount";
import {PartiallyOwnedInvestmentAccountRow} from "../../AssetSummary/InEstate/PartiallyOwnedInvestmentAccountRow";
import {NO_OP} from "../../../constants/common";

export interface InEstateAccountsProps {
    profileId: string,
    assetsData: AssetsSummary;
    investorGroup: InvestorGroupType;
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void;
    onRemoveInvestmentProgram: () => void;
    onRefreshInvestmentProgramHoldings: () => void;
}


const Accounts = ({
                      profileId,
                      assetsData,
                      investorGroup,
                      onRemoveAssetClick,
                      onRemoveInvestmentProgram,
                      onRefreshInvestmentProgramHoldings
                  }: InEstateAccountsProps) => {

    const history = useHistory();
    const viewType = useContext(AssetsViewContext);

    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        hasInEstateAccount,
        hasInEstateInvestmentProgram,
        hasInEstatePartiallyOwnedInvestmentAccounts,
        inEstatePartiallyOwnedInvestmentAccounts,
        inEstateStandaloneAccounts,
        inEstateLegalAgreements
    } = assetListData(assetsData);

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;

    const currentNetWorthStandaloneAccounts: CurrentNetWorthAsset[] = inEstateStandaloneAccounts.map(account => ({
        id: account.id,
        name: account.name,
        presentValue: account.marketValue.totalValue,
        assetType: "standaloneAccount",
        ownershipCategory: account.ownershipCategory,
        memberOwnerships: account.memberOwnerships
    }));

    const currentNetWorthLegalAgreements: CurrentNetWorthAsset[] = inEstateLegalAgreements.map(legalAgreement => ({
        id: legalAgreement.id,
        name: legalAgreement.name,
        presentValue: legalAgreement.marketValue,
        assetType: "investmentProgram",
        ownershipCategory: legalAgreement.ownershipCategory,
        memberOwnerships: legalAgreement.memberOwnerships
    }));

    const currentNetWorthPartiallyOwnedInvestmentAccounts: CurrentNetWorthAsset[] = inEstatePartiallyOwnedInvestmentAccounts.map(legalAgreement => ({
        id: legalAgreement.id,
        name: legalAgreement.name,
        presentValue: legalAgreement.marketEstateValue.totalValue,
        assetType: "partiallyOwnedInvestmentAccount",
        ownershipCategory: legalAgreement.ownershipCategory,
        memberOwnerships: legalAgreement.memberOwnerships
    }));

    function generateAssetPopOverContent(account: StandaloneAccount | LegalAgreement | PartiallyOwnedInvestmentAccountReadModel) {
        const ownershipDescription = getOwnershipDescription(account, primaryMember, partnerMember)
        return ownershipDescription ? <>{ownershipDescription}</> : undefined;
    }

    function calculateMemberOwnedAccountAndIPValue(member: InvestorGroupMember) {
        return calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthLegalAgreements)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthPartiallyOwnedInvestmentAccounts);
    }

    function calculateJointlyOwnedAccountAndIPValue() {
        return calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthLegalAgreements)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthPartiallyOwnedInvestmentAccounts);
    }

    const actionMenu: ReactNode = assetsData.investmentProgram
        ? <AccountActionMenu onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                             onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                             investmentProgramName={assetsData.investmentProgram.name}/>
        : undefined;

    return (
        <>
            {(hasInEstateAccount || hasInEstateInvestmentProgram || hasInEstatePartiallyOwnedInvestmentAccounts) &&
                <AccordionItemWithActionMenu
                    uuid="Accounts"
                    accentColor={COLOR_ASSETS_ACCOUNTS}
                    HeaderComponent={({expanded}) => {
                        const totalInEstateMarketValue = calculateAllAccountsInEstateTotalMarketValue(
                            assetsData.accounts.data,
                            assetsData.investmentProgram,
                            assetsData.partiallyOwnedInvestmentAccounts);

                        return <AssetAccordionHeader
                            expanded={expanded}
                            title="Accounts"
                            formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.primaryMember)) : undefined}
                            formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.partnerMember)) : undefined}
                            formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateJointlyOwnedAccountAndIPValue()) : undefined}
                            formattedTotalPresentValue={getFormattedTotalPresentValue(totalInEstateMarketValue)}
                            gridClassName={"current-net-worth-grid"}
                        />
                    }
                    }
                    actionMenu={actionMenu}
                >

                    <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                         aria-label="accounts-table">
                        <AssetAccordionContentHeader investorGroup={investorGroup}/>

                        {hasInEstateInvestmentProgram &&
                            <>
                                {inEstateLegalAgreements.map((legalAgreement: LegalAgreement) => {
                                    return <LegalAgreementRow legalAgreement={legalAgreement}
                                                              actionsDisabled={false}
                                                              onClickEdit={id => {
                                                                  history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditLegalAgreement/${id}`);
                                                              }}
                                                              onClickViewHoldings={(legalAgreementId: string) => {
                                                                  history.push(`/Profile/${profileId}/ClientProfile/${viewType}/LegalAgreementHoldings/${legalAgreementId}`);
                                                              }}
                                                              key={legalAgreement.id}
                                                              gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                              renderLegalAgreementDetails={(agreement: LegalAgreement) => {
                                                                  return <>
                                                                      <TableCell text={shortenName(agreement.name)}
                                                                                 className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                                 popoverContent={generateAssetPopOverContent(agreement)}
                                                                                 popoverWidth={"288px"}
                                                                                 subtext={showOwnershipType(agreement.ownershipCategory)}
                                                                      />
                                                                      {investorGroup.partnerMember && <>
                                                                          <TableCell
                                                                              text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, agreement.memberOwnerships, agreement.ownershipCategory, agreement.marketValue))}
                                                                              className="textalign-right"/>
                                                                          <TableCell
                                                                              text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, agreement.memberOwnerships, agreement.ownershipCategory, agreement.marketValue))}
                                                                              className="textalign-right"/>
                                                                          <TableCell
                                                                              text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(agreement.ownershipCategory, agreement.marketValue))}
                                                                              className="textalign-right"/>
                                                                      </>
                                                                      }
                                                                  </>
                                                              }}/>;
                                })}
                            </>
                        }

                        {hasInEstateAccount &&
                            <>
                                {inEstateStandaloneAccounts.map((standaloneAccount: StandaloneAccount) => {
                                    return <StandaloneAccountRow standaloneAccount={standaloneAccount}
                                                                 actionsDisabled={false}
                                                                 onClickEdit={(accountId: string) => {
                                                                     history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditStandaloneAccount/${accountId}`);
                                                                 }}
                                                                 onClickDelete={(standaloneAccountDelete: StandaloneAccount) => {
                                                                     onRemoveAssetClick({
                                                                         showDeleteModal: true,
                                                                         modalTitle: "Asset",
                                                                         assetDescription: standaloneAccountDelete.name,
                                                                         assetType: "standalone account",
                                                                         assetId: standaloneAccountDelete.id,
                                                                     });
                                                                 }}
                                                                 onClickViewHoldings={(accountId: string) => {
                                                                     history.push(`/Profile/${profileId}/ClientProfile/${viewType}/StandaloneAccount/${accountId}/Holdings`);
                                                                 }}
                                                                 key={standaloneAccount.id}
                                                                 gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                                 renderStandaloneAccountDetails={(account: StandaloneAccount) => {
                                                                     return <>
                                                                         <TableCell text={shortenName(account.name)}
                                                                                    subtext={showOwnershipType(account.ownershipCategory)}
                                                                                    className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                                    popoverContent={generateAssetPopOverContent(account)}
                                                                                    popoverWidth={"288px"}
                                                                         />
                                                                         {investorGroup.partnerMember && <>
                                                                             <TableCell
                                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, account.memberOwnerships, account.ownershipCategory, account.marketValue.totalValue))}
                                                                                 className="textalign-right"/>
                                                                             <TableCell
                                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, account.memberOwnerships, account.ownershipCategory, account.marketValue.totalValue))}
                                                                                 className="textalign-right"/>
                                                                             <TableCell
                                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(account.ownershipCategory, account.marketValue.totalValue))}
                                                                                 className="textalign-right"/>
                                                                         </>
                                                                         }
                                                                     </>
                                                                 }}/>;
                                })}
                            </>
                        }

                        {hasInEstatePartiallyOwnedInvestmentAccounts &&
                            <>

                                {inEstatePartiallyOwnedInvestmentAccounts.map((partiallyOwnedInvestmentAccount: PartiallyOwnedInvestmentAccountReadModel) => {
                                    return <PartiallyOwnedInvestmentAccountRow
                                        partiallyOwnedInvestmentAccount={partiallyOwnedInvestmentAccount}
                                        actionsDisabled={false}
                                        onClickEdit={(accountId: string) => {
                                            history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditPartiallyOwnedInvestment/${accountId}`);
                                        }}
                                        onClickDelete={(account: PartiallyOwnedInvestmentAccountReadModel) => {
                                            onRemoveAssetClick({
                                                showDeleteModal: true,
                                                modalTitle: "Asset",
                                                assetDescription: account.name,
                                                assetType: "partially owned investment account",
                                                assetId: account.id,
                                            });
                                        }}
                                        onClickViewHoldings={NO_OP}
                                        key={partiallyOwnedInvestmentAccount.id}
                                        gridClassName={"current-net-worth-grid-with-actionmenu"}
                                        renderPartiallyOwnedInvestmentAccountDetails={(account: PartiallyOwnedInvestmentAccountReadModel) => {
                                            return <>
                                                <TableCell text={shortenName(account.name)}
                                                           subtext={showOwnershipType(account.ownershipCategory)}
                                                           popoverContent={generateAssetPopOverContent(account)}
                                                           popoverWidth={"288px"}
                                                           className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                />
                                                {investorGroup.partnerMember && <>
                                                    <TableCell
                                                        text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, account.memberOwnerships, account.ownershipCategory, account.marketEstateValue.totalValue))}
                                                        className="textalign-right"/>
                                                    <TableCell
                                                        text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, account.memberOwnerships, account.ownershipCategory, account.marketEstateValue.totalValue))}
                                                        className="textalign-right"/>
                                                    <TableCell
                                                        text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(account.ownershipCategory, account.marketEstateValue.totalValue))}
                                                        className="textalign-right"/>
                                                </>
                                                }
                                            </>
                                        }}/>;
                                })}
                            </>
                        }
                    </div>

                </AccordionItemWithActionMenu>
            }
        </>
    );
}

export default Accounts;
