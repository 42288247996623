import React from "react";
import {formatCurrency} from "../../../utils/format";
import {GoalType} from "../../../Goals/models/GoalType";
import {Accordion, AccordionItem, Icon, UnderlinedHeader} from "../../../components";
import {LifestyleSpendingGoal} from "../../../Goals/models/LifestyleSpendingGoal";
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import {FooterText, ReserveFooterText} from "../../../Goals/Summary/SummaryLifestyleGoalList";
import LifestyleSpendingTable from "../../../Goals/Summary/LifestyleSpendingTable";
import goalUtils from "../../../Goals/Summary/GoalUtils";

const LifestyleGoalListHeader = (props: { lifestyleGoal: LifestyleSpendingGoal }) => {
    const {presentValue} = props.lifestyleGoal.calculatedFields;

    return (
        <div className="section-header" data-testid={`${GoalType.LIFESTYLE_SPENDING}-goal-table`}>
            <h3>
                You plan to fund <b>{formatCurrency(presentValue)}</b> in lifestyle spending:
            </h3>
        </div>
    );
}

interface SummaryLifestyleGoalListReportProps {
    goalModel: GoalModelType,
    allGoalsModel: GoalModelType
}

const SectionHeader = () => {
    return <UnderlinedHeader
        leftAlignedContent={
            <span className="display-flex align-items-center">
                <Icon name="chevron_double_right"/>
                <span className="condensed-subtitle paddingleft-md">Goal Type</span>
            </span>
        }
        rightAlignedContent={
            (
                <>
                    <span className="condensed-subtitle value-label marginright-sm">
                        Present Value
                    </span>
                </>
            )
        }
    />
}

const SummaryLifestyleGoalListReport = ({ goalModel, allGoalsModel }: SummaryLifestyleGoalListReportProps) => {

    const {proposal, lifestyleSpendingGoal, investorGroup} = goalModel;
    const reserveTargetIsSet = (proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null)
    const remainingPortfolioReserveYears = proposal.isActivePortfolioReserve ? goalUtils.getRemainingPortfolioReserveLength(proposal.portfolioReserveTargetLength, proposal.portfolioReserveActivationDate) : proposal.portfolioReserveTargetLength
    const reserveTargetText = proposal.isActivePortfolioReserve ? 'Expected Reserve Length' : 'Reserve Target'
    return (
        <>
            <section>
                <div className="goal-list">
                    <div className="accordion-tables" data-testid={`${GoalType.LIFESTYLE_SPENDING}-goal-list`}>
                        <Accordion
                            expanded={["LifestyleSpendingReport"]}
                            accordionId="SummaryLifestyleGoalListAccordion"
                            allowMultipleExpanded
                            allowZeroExpanded
                        >
                            <div className="assets">
                                <div className="sticky-header">
                                    <LifestyleGoalListHeader lifestyleGoal={lifestyleSpendingGoal}/>
                                    <SectionHeader/>
                                </div>
                                <AccordionItem
                                    uuid="LifestyleSpendingReport"
                                    accentColor="#52897F"
                                    primaryText="Lifestyle Spending"
                                    rightAlignedContent={
                                        <div className="display-flex align-items-center">
                                            <span className="font-lg paddingright-xl">
                                                {formatCurrency(allGoalsModel.lifestyleSpendingGoal.calculatedFields.presentValue)}
                                            </span>
                                        </div>
                                    }
                                >
                                    <LifestyleSpendingTable
                                        lifestyleSpendingPeriods={lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods}
                                        planningPeriod={investorGroup.planningPeriod}/>
                                    <FooterText planningPeriod={investorGroup.planningPeriod}/>
                                    {reserveTargetIsSet &&
                                        <ReserveFooterText reserveTarget={remainingPortfolioReserveYears} reserveTargetText={reserveTargetText}/>}
                                </AccordionItem>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SummaryLifestyleGoalListReport;
