import {App_Conf} from "./app_conf";
import {acquireToken} from "../MsalUtils";

export interface HttpOptions {
    headers?: HeadersInit;
    body?: any;
    queryParams?: Record<string, string | boolean>;
    signal?: AbortSignal;
}

type HttpMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

const defaultHeaders = {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*',
};

const {API_URL} = App_Conf;

const call = async (method: HttpMethod, path: string, options?: HttpOptions) => {
    let {headers = {}, queryParams = {}} = options || {};

    if (!window.location.href.includes('/ReportPreview')) {
        const accessToken = await acquireToken();
        headers = {
            'Authorization': `Bearer ${accessToken}`,
            ...defaultHeaders,
            ...headers
        };
    } else {
        headers = {
            ...defaultHeaders,
            ...headers
        };
    }

    const opts: RequestInit = {
        ...options,
        credentials: 'include',
        headers,
        method,
        mode: 'cors'
    };
    const url = new URL(`${API_URL}${path}`);
    Object.entries(queryParams).forEach(([key, value]) => {
        url.searchParams.append(key, String(value));
    })

    const response = await fetch(url.toString(), opts);
    if (response.ok) {
        return response;
    }
    throw response;

};

export const get = (path: string, options?: HttpOptions): Promise<Response> => call('get', path, options);
export const post = (path: string, body: any, options?: HttpOptions): Promise<Response> => call('post', path, {
    ...options,
    body: JSON.stringify(body)
});
export const remove = (path: string, options?: HttpOptions): Promise<Response> => call('delete', path, options);
export const put = (path: string, body: any, options?: HttpOptions): Promise<Response> => call('put', path, {
    ...options,
    body: JSON.stringify(body)
});