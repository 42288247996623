import {DropdownItem, Icon, TableActionMenu} from "../../components";
import {SplitLifestyleInfoPopover} from "../Prioritization/SplitLifestyleInfoPopover";
import React, {useMemo, useState} from "react";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {Table} from "xps-react";
import DeleteGoalModal from "./DeleteGoalModal";
import {formatCurrency} from "../../utils/format";
import GoalUtils from "../Summary/GoalUtils";
import GoalsTypePopover from "../Family/GoalsTypePopover";

export interface NonLifestyleGoalRow {
    name: string,
    annualFlow: number,
    yearsOfFlow: number,
    yearsUntilFlow: number,
    presentValue: number,
    isFundedByNonInvestableAssets: boolean,
    savedGoal: NonLifestyleGoalType
}

export function makeNonLifestyleGoalListTableRowData(
    goalsForDisplay: NonLifestyleGoalType[],
    investorGroup: InvestorGroupType
): NonLifestyleGoalRow[] {
    const rowData: NonLifestyleGoalRow[] = goalsForDisplay.map<NonLifestyleGoalRow>(
        (goal: NonLifestyleGoalType) => {
            const {yearsOfFlow, yearsUntilFlow} = GoalUtils.getYearsOfFlowAndYearsUntilFlow(goal, investorGroup)

            return {
                name: goal.userInputs.description,
                annualFlow: goal.userInputs.annualFlow,
                presentValue: goal.calculatedFields.presentValue,
                isFundedByNonInvestableAssets: goal.userInputs.isFundedByNonInvestableAssets,
                yearsOfFlow,
                yearsUntilFlow,
                savedGoal: goal
            }
        }
    );
    return rowData;
}

export function getNameCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div className="family-goal-table">
                <div role="cell">
                    <div className="cell-with-popover">
                        <span className={"cell-text-content goals-table-goal-name"}>{original.name}</span>
                        {original.isFundedByNonInvestableAssets &&
                            <GoalsTypePopover
                                isFundedByNonInvestableAssets={original.isFundedByNonInvestableAssets}
                            />
                        }
                        <SplitLifestyleInfoPopover
                            show={original.name === 'Lifestyle Spending'}
                            direction="top"
                        />
                    </div>
                </div>
            </div>
        );
    };
}

export function getAnnualFlowCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{formatCurrency(original.annualFlow)}</span>
            </div>
        );
    };
}

export function getYearsUntilFlowCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{original.yearsUntilFlow}</span>
            </div>
        );
    };
}

export function getYearsOfFlowCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{original.yearsOfFlow}</span>
            </div>
        );
    };
}

export function gePresentValueCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div
                className="display-flex flex-column justify-content-center align-items-end height-100p">
                <span className="table-label-15px">{formatCurrency(original.presentValue)}</span>
            </div>
        );
    };
}

type NonLifestyleGoalsTableProps = {
    type: GoalType,
    goalsForDisplay: NonLifestyleGoalType[],
    investorGroup: InvestorGroupType,
    proposal: ProfileResponse,
    onDeleteGoal: (goal: NonLifestyleGoalType) => void,
    onEditGoal: (goal: NonLifestyleGoalType, type: GoalType) => void,
    isProfileWithProposalsOrArchived?: boolean
};

export function NonLifestyleGoalsTable({
                                           type,
                                           goalsForDisplay,
                                           investorGroup,
                                           proposal,
                                           onDeleteGoal,
                                           onEditGoal,
                                           isProfileWithProposalsOrArchived
                                       }: NonLifestyleGoalsTableProps) {
    const memoizedTable = useMemo(() => <Table
        resizable={false}
        showPagination={false}
        sortable={false}
        defaultPageSize={9999}
        data={
            makeNonLifestyleGoalListTableRowData(goalsForDisplay, investorGroup)
        }
        columns={
            [
                {
                    minWidth: 30,
                    maxWidth: 30,
                },
                {
                    accessor: 'name',
                    Cell: getNameCell(),
                    Header: 'Name',
                },
                {
                    accessor: 'annual-flow',
                    Cell: getAnnualFlowCell(),
                    headerClassName: 'display-flex justify-content-end',
                    Header: 'Annual Flow',
                    maxWidth: 128
                },
                {
                    accessor: 'years-until-flow',
                    Cell: getYearsUntilFlowCell(),
                    headerClassName: 'display-flex justify-content-end',
                    Header: 'Years Until Flow',
                    maxWidth: 128
                },
                {
                    accessor: 'years-of-flow',
                    Cell: getYearsOfFlowCell(),
                    headerClassName: 'display-flex justify-content-end',
                    Header: 'Years of Flow',
                    maxWidth: 128
                },
                {
                    accessor: 'present-value',
                    Cell: gePresentValueCell(),
                    headerClassName: 'display-flex justify-content-end',
                    Header: 'Present Value',
                    maxWidth: 128
                },
                {
                    accessor: 'rowActionMenu',
                    className: "overflow-visible",
                    minWidth: 32,
                    maxWidth: 32,
                    Cell: ({original}: { original: NonLifestyleGoalRow }) => {
                        const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);
                        const handleDeleteGoal = (goal: NonLifestyleGoalType) => {
                            onDeleteGoal(goal);
                            setShowDeleteGoalModal(false);
                        }

                        return (
                            <span className="table-action-menu">
                                <TableActionMenu className="paddingright-0" panelWidth={240}>
                                    <DropdownItem
                                        itemText="Edit Goal"
                                        value=""
                                        key="edit-goal"
                                        onClick={() => onEditGoal(original.savedGoal, type)}
                                    />
                                    <DropdownItem
                                        id={`delete${type}Goal`}
                                        onClick={() => setShowDeleteGoalModal(true)}
                                        itemText={`Delete Goal`}
                                        value={''}
                                        key="delete-goal"
                                        disabled={isProfileWithProposalsOrArchived}
                                    >
                                        <Icon name="delete" className="goals-table-delete-icon"/>
                                    </DropdownItem>
                                </TableActionMenu>
                                 <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                                                  onCancel={() => setShowDeleteGoalModal(false)}
                                                  onDelete={() => handleDeleteGoal(original.savedGoal)}
                                                  goalType={type}
                                                  description={original.name}
                                                  cancelButtonText={"CANCEL"}/>
                            </span>
                        )
                    }
                }
            ]
        }
    />, [goalsForDisplay, investorGroup]);
    return <div data-testid={`${type}-goal-table`}>{memoizedTable}</div>;
}