import {OwnershipDetailsFormData} from "../models/Ownership";
import {Beneficiary, LifeInsuranceFormData} from "../models/LifeInsurance";

export function isLifeInsuranceFormDataMissing(asset: LifeInsuranceFormData, ownershipDetails: OwnershipDetailsFormData) {
    const isDescriptionEmpty = !asset.description.trim();

    const isOwnershipDataMissing = ownershipDetails.legalEntityOwnerships.some((ownership) => {
        return !ownership.name.trim() || !ownership.type;
    });

    return isDescriptionEmpty || isOwnershipDataMissing;
}

export function calculateTotalAllocation(beneficiaries: Array<Beneficiary>) {
    return (beneficiaries.reduce((total, beneficiary) =>
        total + parseFloat(beneficiary.percentage || "0.00"), 0.00))
}

