import {BeneficiaryGoals, GoalModelType} from "../../../Goals/models/GoalModelType";

const TOTAL_PAGE_HEIGHT = 1055;
const PAGE_PADDING_HEIGHT = 96;
const SECTION_HEADER_HEIGHT = 100;
const ACCORDION_HEADER_HEIGHT = 72;
const TABLE_HEADER_HEIGHT = 36;
const TABLE_ROW_HEIGHT = 48;
const ASSET_LIABILITY_BOTTOM_MARGIN = 71;

const LIFESTYLE_SPENDING_FOOTER = 92;
const UNDERLINE_HEADER = 40;
const PAGE_DISCLAIMER_SECTION_TITLE = 54;
const SECTION_BOTTOM_PADDING = 24;

const setPageData = async (dataType: keyof GoalModelType,
                           pageData: any,
                           pageScore: number,
                           data: any,
                           calculatedData: any,
                           isNestedData: boolean,
                           defaultData: any) => {
    const nestedDataHeaderLength = isNestedData ? 1 : 0;
    let updatedPageScore = pageScore + (data.length + nestedDataHeaderLength) * TABLE_ROW_HEIGHT;

    if (updatedPageScore < TOTAL_PAGE_HEIGHT) {
        pageData = {
            ...pageData,
            [dataType]: data
        }
    } else {
        const remainingPageSpace = TOTAL_PAGE_HEIGHT - pageScore;
        let noOfDataPageCanFit = 0;
        if (remainingPageSpace > 0) {
            noOfDataPageCanFit = Math.floor(remainingPageSpace / TABLE_ROW_HEIGHT);
            if (noOfDataPageCanFit > 0) {
                const dataThatCanFitInCurrentPage = data.slice(0, noOfDataPageCanFit);
                pageData = {
                    ...pageData,
                    [dataType]: dataThatCanFitInCurrentPage
                };
            }
        }

        // Push Current Page
        calculatedData.push(pageData);

        // Creating New Page
        updatedPageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + ASSET_LIABILITY_BOTTOM_MARGIN + PAGE_DISCLAIMER_SECTION_TITLE;
        pageData = {...defaultData};

        // For rest of the data, split the remaining data recursively until all are fit into 1 or more pages
        const remainingAssets = data.slice(noOfDataPageCanFit);

        if (remainingAssets.length > 0) {
            const result = await setPageData(dataType, pageData, updatedPageScore, remainingAssets, calculatedData, isNestedData, defaultData);
            updatedPageScore = result.pageScore;
            pageData = result.pageData;
        }

    }

    return {pageScore: updatedPageScore, pageData};
}

const setFamilyGoals = async (pageScore: number, pageData: any, key: string, calculatedData: any, defaultData: any, data: any) => {

    let updatedPageScore = (pageScore + TABLE_HEADER_HEIGHT + (Object.keys(data.goals).length * TABLE_ROW_HEIGHT));
    if (updatedPageScore < TOTAL_PAGE_HEIGHT) {
        pageData = {
            ...pageData,
            familyGoals: {
                ...pageData.familyGoals,
                [key]: data
            }
        }
    } else {
        const remainingPageSpace = TOTAL_PAGE_HEIGHT - pageScore;
        let noOfDataPageCanFit = 0;
        if (remainingPageSpace > 0) {
            noOfDataPageCanFit = Math.floor(remainingPageSpace / TABLE_ROW_HEIGHT);
            if (noOfDataPageCanFit > 0) {
                const dataThatCanFitInCurrentPage = Object.fromEntries(
                    Object.entries(data.goals).slice(0, noOfDataPageCanFit)
                )
                pageData = {
                    ...pageData,
                    ['familyGoals']: {
                        ...pageData.familyGoals,
                        [key]: {
                            ...data,
                            ...pageData.familyGoals[key],
                            goals: {
                                ...dataThatCanFitInCurrentPage
                            }
                        }
                    }
                };
            }
        }

        // Push Current Page
        calculatedData.push(pageData);

        // Creating New Page
        updatedPageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + UNDERLINE_HEADER + PAGE_DISCLAIMER_SECTION_TITLE;
        pageData = {...defaultData};

        // For rest of the data, split the remaining data recursively until all are fit into 1 or more pages
        const remainingFamilyGoals = Object.fromEntries(
            Object.entries(data.goals).slice(noOfDataPageCanFit)
        );

        let familyGoals = {};
        if (Object.keys(remainingFamilyGoals).length) {
            familyGoals = {
                ...data,
                goals: {
                    ...remainingFamilyGoals
                }
            }
        }

        if (Object.values(familyGoals).length > 0) {
            updatedPageScore = updatedPageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT;
            const result = await setFamilyGoals(updatedPageScore, pageData, key, calculatedData, defaultData, familyGoals);
            updatedPageScore = result.pageScore;
            pageData = result.pageData;
        }
    }

    return {pageScore: updatedPageScore, pageData};
}

export const splitGoalsSummaryData = async (mainData: GoalModelType, defaultData: any): Promise<Array<GoalModelType>> => {

    const calculatedData: Array<any> = [];
    let pageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + PAGE_DISCLAIMER_SECTION_TITLE;
    let pageData: any = {...defaultData};

    if (mainData['lifestyleSpendingGoal'] && mainData['lifestyleSpendingGoal'].id) {
        const heightOfLifestyleGoals = TABLE_ROW_HEIGHT * mainData.lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.length;
        pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + LIFESTYLE_SPENDING_FOOTER + UNDERLINE_HEADER + heightOfLifestyleGoals + SECTION_BOTTOM_PADDING;
        pageData = {
            ...pageData,
            ['lifestyleSpendingGoal']: mainData['lifestyleSpendingGoal']
        }
    }

    if (mainData['discretionaryGoals'].length || mainData['philanthropicGoals'].length || Object.values(mainData.familyGoals).length) {

        pageScore = pageScore + PAGE_DISCLAIMER_SECTION_TITLE + UNDERLINE_HEADER;
        if (mainData['discretionaryGoals'] && mainData['discretionaryGoals'].length) {
            pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT;
            const result = await setPageData('discretionaryGoals', pageData, pageScore, mainData['discretionaryGoals'], calculatedData, false, defaultData);
            pageScore = result.pageScore;
            pageData = result.pageData;
        }

        if (mainData['philanthropicGoals'] && mainData['philanthropicGoals'].length) {
            pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT;
            const result = await setPageData('philanthropicGoals', pageData, pageScore, mainData['philanthropicGoals'], calculatedData, false, defaultData);
            pageScore = result.pageScore;
            pageData = result.pageData;
        }

        if (mainData.familyGoals && Object.values(mainData.familyGoals).length > 0) {
            pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT;

            const sortedBeneficiariesGoals: Array<BeneficiaryGoals> = [...Object.values(mainData.familyGoals)]
                .sort((bg1: any, bg2: any) =>
                    Date.parse(bg1.birthdate!).valueOf() - Date.parse(bg2.birthdate!).valueOf());

            for (const beneficiaryGoals of sortedBeneficiariesGoals) {
                pageScore = pageScore + TABLE_ROW_HEIGHT;
                const result = await setFamilyGoals(pageScore, pageData, beneficiaryGoals['beneficiaryId'], calculatedData, defaultData, beneficiaryGoals);
                pageScore = result.pageScore;
                pageData = result.pageData;
            }

        }
    }

    if (mainData['taxLiabilities']) {
        pageScore = pageScore + SECTION_HEADER_HEIGHT + UNDERLINE_HEADER + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT + (2 * TABLE_ROW_HEIGHT);

        if (pageScore < TOTAL_PAGE_HEIGHT) {
            pageData = {
                ...pageData,
                ['taxLiabilities']: mainData['taxLiabilities']
            }
        } else {
            calculatedData.push(pageData);
            pageData = {...defaultData};
            pageData = {
                ...pageData,
                ['taxLiabilities']: mainData['taxLiabilities']
            }
        }
    }
    if (pageData !== defaultData) {
        calculatedData.push(pageData);
    }
    return calculatedData;

}
