import React from "react";
import {formatCurrency} from "../../../utils/format";
import {Icon} from "../../../components";
import Popover from "@nt/dds-react/core/Popover";
import {LiabilityDetails} from "../../../Assets/models/PersonalLiability";
import {AssetRelianceAssetType} from "../../models/AssetRelianceResponse";

type props = {
    description: string;
    stackAssetId: string | null;
    stackAssetType: AssetRelianceAssetType;
    linkedTo: LiabilityDetails[];
}

const generatePopoverText = (stackAssetType: AssetRelianceAssetType) => {
    if (stackAssetType === AssetRelianceAssetType.PERSONAL_ASSET) {
        return (<i>This asset is serving as collateral for <br/> the following liabilities:</i>)
    }
    else {
        return (<i>The liability is collateralized by the <br/> following asset:</i>)
    }
}

export const AssetRelianceTablePopover = ({description, stackAssetId, stackAssetType, linkedTo}: props) => {
    return <span>
        <span className="asset-reliance-label-for-popover">{description}</span>
        {
            linkedTo && linkedTo.length > 0 ?
                <Popover
                    id={`liabilities-popover-${stackAssetId}`}
                    className="asset-reliance-popover"
                    delay={0}
                    content={
                        <div>
                            <div className="asset-reliance-popover-text">
                                {generatePopoverText(stackAssetType)}
                            </div>
                            <hr/>
                            {linkedTo.map((link) => (
                                <div key={`liability-summary-popover-${link.description}`} className="asset-reliance-popover-content">
                                    <span>{link.description}</span>
                                    <span>{formatCurrency(link.loanBalance)}</span>
                                </div>
                            ))}
                        </div>
                    }
                    direction="top"
                    aria-label="liabilities">
                    <Icon className="popover-trigger" name="link" ariaHidden={false} ariaLabel="associated liabilities"/>
                </Popover> :
                <></>
        }
    </span>;
}