import React from "react";
import {
    COLOR_PORTFOLIO_RESERVE_LEGEND,
    COLOR_RISK_ASSETS,
    COLOR_RISK_CONTROL_ASSETS,
    COLOR_WHITE,
    COLOR_EXCESS_ASSETS_ACCENT
} from "../../constants/colors";

export enum riskLegendColor {
    RISK_CONTROL_ASSETS = "RISK CONTROL ASSETS",
    RISK_ASSETS = "RISK ASSET",
    PORTFOLIO_RESERVE = "PORTFOLIO RESERVE",
    INVESTABLE_EXCESS = "INVESTABLE EXCESS"
}

interface LegendProps {
    legendColor: riskLegendColor;
    label?: string;
    children?: React.ReactChild
}

const RiskLegend: React.FC<LegendProps> = ({legendColor, label, children}) => {
    let color;
    switch (legendColor) {
        case riskLegendColor.RISK_CONTROL_ASSETS:
            color = COLOR_RISK_CONTROL_ASSETS
            break;
        case riskLegendColor.RISK_ASSETS:
            color = COLOR_RISK_ASSETS
            break;
        case riskLegendColor.PORTFOLIO_RESERVE:
            color = COLOR_PORTFOLIO_RESERVE_LEGEND
            break;
        case riskLegendColor.INVESTABLE_EXCESS:
            color = COLOR_EXCESS_ASSETS_ACCENT
            break;
        default:
            color = COLOR_WHITE
            break;
    }

    return (
        <>
            <span style={{backgroundColor: color}} className="risk-legend-container"/>
            {label &&
                <span className={"risk-label"}>
                    {label}
                </span>
            }
            {children}
        </>
    )
}

export default RiskLegend;