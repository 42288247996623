import React from 'react'
import {Donut} from "xps-react";
import { GoalFundingResponse } from './models/Funding';
import RiskLegend, { riskLegendColor } from 'src/components/Legend/RiskLegend';
import { COLOR_INVESTABLE_EXCESS, COLOR_NT_GREY_200, COLOR_RISK_ASSETS, COLOR_RISK_CONTROL_ASSETS, COLOR_TRANSPARENT } from 'src/constants/colors';
import { formatFundingCurrency, formatFundingPercentWithTwoDecimals } from './fundingUtils';

type FundingSummaryTotalsProps = {
    ageIndex: number;
    fundingInfo: GoalFundingResponse;
}

const FundingSummaryTotals: React.FC<FundingSummaryTotalsProps> = ({
    ageIndex,
    fundingInfo
}) => {
    const percentageOfRiskAssets = fundingInfo.fundedByRiskAssetsPercentageAtYear?.[ageIndex];
    const percentageOfRiskControl = fundingInfo.fundedByRiskControlPercentageAtYear?.[ageIndex];

    const donutProps = (percentageOfRiskAssets || percentageOfRiskControl)
        ? {
            data: [
                {
                    value: percentageOfRiskAssets || 0
                },
                {
                    value: percentageOfRiskControl || 0
                }
            ],
            colors: [COLOR_RISK_ASSETS, COLOR_RISK_CONTROL_ASSETS]
        }
        : {data: [{value: 100}], colors: [COLOR_NT_GREY_200]};
    
    const investableExcessPercent = 0
    const donutOverlayProps = {
        data: [
            { value: investableExcessPercent },
            { value: 100 - investableExcessPercent }
        ],
        colors: [COLOR_INVESTABLE_EXCESS, COLOR_TRANSPARENT]
    }

    const goalFundingCategoriesData = [
        {
            name: "Funded by Investable Portfolio",
            value: fundingInfo.fundedByPortfolioAtYear?.[ageIndex]
        },
        {
            name: "Funded by Inflows",
            value: fundingInfo.fundedByInflowsAtYear?.[ageIndex]
        },
        {
            name: "Funded by Non-Investable",
            value: fundingInfo.fundedByNonInvestableAtYear?.[ageIndex]
        }
    ];

    return (
        <div className="funding-summary__totals">
            <div className="funding-donut-chart" data-testid="funding-donut-chart">
                <div className='funding-donut-container'>
                    <div className="funding-donut-container__solid">
                        <Donut
                            role="figure"
                            className="funding-donut"
                            data={donutProps.data}
                            colors={donutProps.colors}
                            outerRadius={48}
                        />
                    </div>
                    <div className="funding-donut-container__shaded">
                        <Donut
                            role="figure"
                            className="funding-donut-shaded"
                            data={donutOverlayProps.data}
                            colors={donutOverlayProps.colors}
                            outerRadius={64}
                        />
                    </div>
                </div>
                <div className="funding-donut-legend-table" role="table" aria-label="Funding Donut Legend">
                    <div role="row" className="funding-donut-legend-item">
                        <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'Risk Assets'} />
                        <span className="textalign-right" aria-label="Percentage">
                            {formatFundingPercentWithTwoDecimals(fundingInfo.fundedByRiskAssetsPercentageAtYear?.[ageIndex] || 0)}
                        </span>
                        <span className="textalign-right" aria-label="Amount">
                            {formatFundingCurrency(fundingInfo.fundedByRiskAssetsAtYear?.[ageIndex])}
                        </span>
                    </div>
                    <div role="row" className="funding-donut-legend-item funding-donut-legend-item__nested">
                        <span className='display-flex grid-span-3 paddingleft-xl'>
                            <RiskLegend legendColor={riskLegendColor.INVESTABLE_EXCESS} label={'Investable Excess'} />
                        </span>
                        <span className="textalign-right" aria-label="Amount">
                            {formatFundingCurrency(0)}
                        </span>
                    </div>
                    <div role="row" className="funding-donut-legend-item">
                        <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS} label={'Risk Control'} />
                        <span className="textalign-right" aria-label="Percentage">
                            {formatFundingPercentWithTwoDecimals(fundingInfo.fundedByRiskControlPercentageAtYear?.[ageIndex] || 0)}
                        </span>
                        <span className="textalign-right" aria-label="Amount">
                            {formatFundingCurrency(fundingInfo.fundedByRiskControlAtYear?.[ageIndex])}
                        </span>
                    </div>
                    <span className='funding-legend-nested-connector' />
                </div>
            </div>
            <div role="table" className="goals-funded-by-category-table" aria-label="Goals Funded By Category Table">
                {goalFundingCategoriesData
                    .map((fundingSource, index) =>
                        (<>
                            <div role="row" key={`fundingSource-${index}`}
                                className="goals-funded-by-category-table__row">
                                <span role="cell">{fundingSource.name}</span>
                                <span role="cell" className='goals-funded-by-category-table__row__currency' aria-label={fundingSource.name}>{formatFundingCurrency(fundingSource.value)}</span>
                            </div>
                            {index !== goalFundingCategoriesData.length - 1 && <hr/>}
                        </>)
                    )
                }
            </div>
        </div>
    );
}

export default FundingSummaryTotals;