import {GeneralInflowDetails, InflowTimeFrame as InflowTimeFrameType} from "../../models/GeneralInflow";
import React from "react";
import {AlertBanner, AssetsYearsInput, Button, InfoPopover, Input, UnderlinedHeader} from "../../../components";
import ModalWrapper from "../../../components/Modal/ModalWrapper/ModalWrapper";
import {toDisplayDateFormat} from "../../../utils/dateUtils";
import {ClampingError} from "./types";

export function InflowTimeFrame(props: {
    inflowFieldsReadOnly: undefined | boolean,
    isProfileWithProposalsOrArchived: boolean | undefined,
    onShowResetInflowTimeFrameModal: () => void,
    showResetTimeFrameModal: boolean,
    onCancelResetInflowTimeFrame: () => void,
    onResetInflowTimeFrame: () => void,
    editedInflowTimeFrame: InflowTimeFrameType,
    onChangeYearsUntilFlow: (_event: React.ChangeEvent<HTMLInputElement>, value: number) => void,
    onBlurYearsUntilFlow: (_event: React.ChangeEvent<HTMLInputElement>, value: number) => void,
    generalInflow: GeneralInflowDetails,
    showErrorOnYearsUntilFlow: boolean,
    inflowTimeframeErrorDiv: JSX.Element,
    onChangeYearsOfFlow: (_event: React.ChangeEvent<HTMLInputElement>, value: number) => void,
    onBlurYearsOfFlow: (_event: React.ChangeEvent<HTMLInputElement>, value: number) => void,
    showErrorOnYearsOfFlow: boolean,
    onChangeInflowYearsFrom: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlurInflowYearsFrom: () => void,
    showErrorOnInflowYearsFrom: boolean,
    onChangeInflowYearsTo: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlurInflowYearsTo: () => void,
    showErrorOnInflowYearsTo: boolean,
    onChangeOwnersAgeRangeFrom: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlurOwnersAgeRangeFrom: () => void,
    showErrorOwnersAgeRangeFrom: boolean,
    onChangeOwnersAgeRangeTo: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlurOwnersAgeRangeTo: () => void,
    showErrorOwnersAgeRangeTo: boolean,
    inflowTimeFrameWarningShown: boolean,
    ownersPlanningPeriod: string,
    clampedInlineError: ClampingError,
    showProposalPlanningPeriod: boolean,
    proposalPlanningPeriod: string,
    onDismissAlertBanner: () => void,
    showOwnersPlanningPeriod: boolean
}) {

    const renderOwnersPlanningPeriod = () => {
        return (
            <>
                <label className="nameLabel"><b>Owner's Planning Period</b></label>
                {props.inflowTimeFrameWarningShown &&
                    <span className="owner-planning-period warning-color" aria-label="Owner's Planning Period">
                                {props.ownersPlanningPeriod}
                            </span>
                }
                {!props.inflowTimeFrameWarningShown && props.clampedInlineError.showMessage && !props.showProposalPlanningPeriod &&
                    <span className="owner-planning-period color-text--error"
                          aria-label="Owner's Planning Period">
                                {props.ownersPlanningPeriod}
                            </span>
                }
                {!props.inflowTimeFrameWarningShown && !(props.clampedInlineError.showMessage && !props.showProposalPlanningPeriod) &&
                    <span className="owner-planning-period" aria-label="Owner's Planning Period">
                                {props.ownersPlanningPeriod}
                            </span>
                }
            </>
        );
    };

    return <section className="inflow-section" data-testid="inflowTimeFrameSection">
        <UnderlinedHeader
            className="inflow-section-header"
            primaryText="Inflow Time Frame"
            secondaryContent={
                <InfoPopover
                    content={<div
                        className={"tax-info-card__content hover-text"}>
                        Inflow roll down dates are calculated based on the date of birth of the primary
                        or secondary client contact with the longest planning horizon.</div>}
                    direction="top"
                    width="248px"
                />
            }
            rightAlignedContent={props.inflowFieldsReadOnly ?
                <Button
                    icon="left"
                    iconName="restore"
                    kind="borderless"
                    onClick={props.onShowResetInflowTimeFrameModal}
                    disabled={props.isProfileWithProposalsOrArchived}>
                    reset time frame
                </Button>
                : undefined}
        />
        <ModalWrapper
            id="discard-changes-modal"
            headerText="Reset inflow time frame?"
            isOpen={props.showResetTimeFrameModal}
            buttons={[
                {
                    text: "cancel",
                    onClick: props.onCancelResetInflowTimeFrame
                },
                {
                    text: "reset time frame",
                    onClick: props.onResetInflowTimeFrame,
                    primary: true,
                    destructive: true
                }
            ]}>
                        <span className="font-md">This will override the current inflow's time frame,
                            erase historical data for the inflow, and restart the inflow.</span>
        </ModalWrapper>
        <div className="inflow-section-grid-container">
            <AssetsYearsInput
                label="Years until Flow"
                fieldName="yearsUntilFlow"
                value={props.editedInflowTimeFrame.yearsUntilFlow}
                maxLength={3}
                minValue={0}
                maxValue={999}
                readOnly={props.inflowFieldsReadOnly || props.isProfileWithProposalsOrArchived}
                onChangeValue={props.onChangeYearsUntilFlow}
                onBlur={props.onBlurYearsUntilFlow}
            />
            <label className="marginleft-lg"><b>Start Date</b></label>
            <span aria-label="Start Date">{toDisplayDateFormat(props.generalInflow.startDate)}</span>
            {props.showErrorOnYearsUntilFlow && props.inflowTimeframeErrorDiv}

            <AssetsYearsInput
                label="Years of Flow"
                fieldName="yearsOfFlow"
                value={props.editedInflowTimeFrame.yearsOfFlow}
                maxLength={3}
                minValue={1}
                maxValue={999}
                readOnly={props.generalInflow.isInflowExpired || props.isProfileWithProposalsOrArchived}
                onChangeValue={props.onChangeYearsOfFlow}
                onBlur={props.onBlurYearsOfFlow}
            />
            <label className="marginleft-lg"><b>End Date</b></label>
            <span aria-label="End Date">{toDisplayDateFormat(props.generalInflow.endDate)}</span>

            {props.showErrorOnYearsOfFlow && props.inflowTimeframeErrorDiv}

            <label><b>Inflow Years</b></label>
            <span className="range-inputs">
                            <Input
                                aria-label="Inflow Years From"
                                name="inflowYearsFrom"
                                value={props.editedInflowTimeFrame.inflowYearsFrom}
                                readOnly={props.inflowFieldsReadOnly || props.isProfileWithProposalsOrArchived}
                                onChange={props.onChangeInflowYearsFrom}
                                onBlur={props.onBlurInflowYearsFrom}
                                error={props.showErrorOnInflowYearsFrom ? " " : undefined}
                            />
                            <span className="between">to</span>
                            <Input
                                aria-label="Inflow Years To"
                                name="inflowYearsTo"
                                value={props.editedInflowTimeFrame.inflowYearsTo}
                                readOnly={props.generalInflow.isInflowExpired || props.isProfileWithProposalsOrArchived}
                                onChange={props.onChangeInflowYearsTo}
                                onBlur={props.onBlurInflowYearsTo}
                                error={props.showErrorOnInflowYearsTo ? " " : undefined}
                            />
                        </span>
            <span className="empty-grid-2-columns"/>

            {(props.showErrorOnInflowYearsFrom
                    || props.showErrorOnInflowYearsTo)
                && props.inflowTimeframeErrorDiv
            }

            <label><b>Owner's Age Range</b></label>
            <span className="range-inputs">
                            <Input
                                aria-label="Owner's Age Range From"
                                name="ownersAgeRangeFrom"
                                readOnly={props.inflowFieldsReadOnly || props.isProfileWithProposalsOrArchived}
                                value={props.editedInflowTimeFrame.ownersAgeRangeFrom}
                                onChange={props.onChangeOwnersAgeRangeFrom}
                                onBlur={props.onBlurOwnersAgeRangeFrom}
                                error={props.showErrorOwnersAgeRangeFrom ? " " : undefined}
                            />
                            <span className="between">to</span>
                            <Input
                                aria-label="Owner's Age Range To"
                                name="ownersAgeRangeTo"
                                value={props.editedInflowTimeFrame.ownersAgeRangeTo}
                                readOnly={props.generalInflow.isInflowExpired || props.isProfileWithProposalsOrArchived}
                                onChange={props.onChangeOwnersAgeRangeTo}
                                onBlur={props.onBlurOwnersAgeRangeTo}
                                error={props.showErrorOwnersAgeRangeTo ? " " : undefined}
                            />
                        </span>
            <span className="empty-grid-2-columns"/>

            {(props.showErrorOwnersAgeRangeFrom
                    || props.showErrorOwnersAgeRangeTo)
                && props.inflowTimeframeErrorDiv
            }

            {props.showOwnersPlanningPeriod &&
                renderOwnersPlanningPeriod()
            }

            {props.showProposalPlanningPeriod &&
                <>
                    <label className="nameLabel"><b>Proposal Planning Period</b></label>

                    {props.clampedInlineError.showMessage ?
                        <span className="owner-planning-period color-text--error"
                              aria-label="Proposal Planning Period">
                                    {props.proposalPlanningPeriod}
                                </span>
                        :
                        <span className="owner-planning-period" aria-label="Proposal Planning Period">
                                    {props.proposalPlanningPeriod}
                                </span>
                    }
                    {
                        props.inflowTimeFrameWarningShown &&
                        <div className="warning-message">
                            <AlertBanner
                                actions={[
                                    {
                                        name: "Dismiss",
                                        onClick: props.onDismissAlertBanner
                                    }
                                ]}
                                fullWidth={false}
                                showAlert={props.inflowTimeFrameWarningShown}
                                icon="info"
                                type="warning"
                                showCloseBtn={false}
                            >
                                Inflow time frame exceeds owner's planning period
                            </AlertBanner>
                        </div>
                    }
                </>}
        </div>

    </section>;
}