import React, {useMemo} from 'react'
import {FamilyGoalType} from "../models/FamilyGoalType";
import {Table} from "xps-react";
import {formatCurrency} from "../../utils/format";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import GoalUtils from "../Summary/GoalUtils";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";
import BeneficiaryGoalActionMenu from "./BeneficiaryGoalActionMenu";
import {BeneficiaryTableRow} from "./types";
import GoalsTypePopover from "../Family/GoalsTypePopover";
import {GoalSubType} from "../models/GoalType";

type BeneficiaryGoalsTableProps = {
    beneficiaryGoals: FamilyGoalType[],
    investorGroup: InvestorGroupType,
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onApplyFamilyAssets?: (goal: FamilyGoalType, assetsAmountToApply: number, selectedGoalType?: GoalSubType) => void,
    showApplyFamilyAssets?: boolean,
    familyAssets?: FamilyAssets,
    availableAssets?: number
}

const BeneficiaryGoalsTable = ({
                                   beneficiaryGoals,
                                   investorGroup,
                                   onEditGoal,
                                   onApplyFamilyAssets,
                                   showApplyFamilyAssets,
                                   familyAssets,
                                   availableAssets
                               }: BeneficiaryGoalsTableProps) => {

    const memoizedTable = useMemo(() => {
        const transformBeneficiaryTableRow = (goal: FamilyGoalType): BeneficiaryTableRow => {
            return {
                description: <div role="cell">
                    <div className="cell-with-popover">
                        <span className='marginright-sm cell-text-content'>{goal.userInputs.description}</span>
                        {(goal.userInputs.isFundedByNonInvestableAssets || !!goal.familyAssetsAmount) && !showApplyFamilyAssets &&
                            <GoalsTypePopover
                                beneficiaryId={goal.beneficiaryId}
                                totalPresentValue={goal.calculatedFields.presentValue > 0
                                    ? goal.calculatedFields.presentValue + (goal.familyAssetsAmount || 0)
                                    : goal.calculatedFields.goalsPresentValue || 0}
                                appliedFamilyAssets={goal.familyAssetsAmount || 0}
                                myResponsibility={goal.calculatedFields.presentValue}
                                isFundedByNonInvestableAssets={goal.userInputs.isFundedByNonInvestableAssets}
                            />
                        }
                    </div>
                </div>,
                annualFlow: formatCurrency(goal.userInputs.annualFlow),
                selectedGoalSubType: goal.userInputs.selectedGoalSubType,
                presentValue: goal.calculatedFields.presentValue > 0
                    ? formatCurrency(goal.calculatedFields.presentValue + (goal.familyAssetsAmount || 0))
                    : formatCurrency(goal.calculatedFields.goalsPresentValue),
                ...GoalUtils.getYearsOfFlowAndYearsUntilFlow(goal, investorGroup),
                savedGoal: goal,
                myResponsibility: goal.calculatedFields?.presentValue > 0 ? formatCurrency(goal.calculatedFields?.presentValue) : formatCurrency(0),
            }
        };

        const rows = [...beneficiaryGoals]
            .sort(((fg1, fg2) => Date.parse(fg1.createdTimestamp!).valueOf() - Date.parse(fg2.createdTimestamp!).valueOf()))
            .map(transformBeneficiaryTableRow);

        const columns: any[] = [
            {
                minWidth: 30,
                maxWidth: 30,
            },
            {
                Header: 'NAME',
                accessor: 'description',
                className: 'justify-content-center height-100p',
            },
            {
                Header: 'ANNUAL FLOW',
                headerClassName: 'display-flex justify-content-end',
                className: "beneficiary-goal-table-numeric-columns",
                maxWidth: 128,
                accessor: 'annualFlow'
            },
            {
                Header: 'YEARS UNTIL FLOW',
                headerClassName: 'display-flex justify-content-end',
                className: "beneficiary-goal-table-numeric-columns",
                accessor: 'yearsUntilFlow',
                maxWidth: 128
            },
            {
                Header: 'YEARS OF FLOW',
                headerClassName: 'display-flex justify-content-end',
                className: "beneficiary-goal-table-numeric-columns",
                accessor: 'yearsOfFlow',
                maxWidth: 128,
            }
        ];

        if (showApplyFamilyAssets) {
            columns.push(
                {
                    Header: 'PRESENT VALUE',
                    accessor: 'presentValue',
                    headerClassName: 'display-flex justify-content-end',
                    className: "beneficiary-goal-table-numeric-columns",
                    maxWidth: 128,
                }
            );
        }

        columns.push.apply(columns, [
            {
                Header: 'MY RESPONSIBILITY',
                headerClassName: 'display-flex justify-content-end',
                className: "beneficiary-goal-table-numeric-columns",
                accessor: 'myResponsibility',
                maxWidth: 128,
            },
            {
                accessor: 'id',
                className: "overflow-visible",
                minWidth: 32,
                maxWidth: 32,
                Cell: ({original}: { original: BeneficiaryTableRow }) => {
                    return (
                        <BeneficiaryGoalActionMenu
                            beneficiaryGoalRow={original}
                            onEditGoal={onEditGoal}
                            showApplyFamilyAssets={showApplyFamilyAssets}
                            availableAssets={availableAssets}
                            onApplyFamilyAssets={onApplyFamilyAssets}
                            familyAssets={familyAssets}
                        />
                    )
                }
            }
        ]);

        return <Table
            className='family-goal-table'
            caption="Beneficiary Goals Table"
            columns={columns}
            showPagination={false}
            defaultPageSize={20}
            data={rows}
            resizable={false}
        />;
    }, [beneficiaryGoals, investorGroup, availableAssets]);
    return <div data-testid="beneficiary-goals-table">{memoizedTable}</div>;
};
export default BeneficiaryGoalsTable;