import {AccountDetailsType, StandaloneAccountFormData} from "../models/StandaloneAccount";
import {TaxDetailsType} from "../models/TaxDetails";

export function mapStandaloneAccountToAccountDetails(standaloneAccount: StandaloneAccountFormData): AccountDetailsType {
    const {
        name,
        asOfDate,
        accountNumber,
        entityType,
        institution,
        taxStatus,
        doesPermitBeneficiary,
    } = standaloneAccount;
    return {name, asOfDate, accountNumber, entityType, institution, taxStatus, doesPermitBeneficiary};
}

export function mapStandaloneAccountToTaxDetails(standaloneAccount: StandaloneAccountFormData): TaxDetailsType {
    return {
        isEntityCapitalGains: standaloneAccount.isEntityCapitalGains,
        isLiabilityPaidByPortfolio: standaloneAccount.isLiabilityPaidByPortfolio,
    }
}

