import {formatNumberRoundedToTwoDecimals} from "../../../utils/format";
import {capitalizeFirstLetter} from "../../../utils/stringUtils";
import {InvestorGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {Account} from "./HoldingsInfo";

export const presentAccountSubtitle = (account: Account) => {
    const institution = account.institution?.trim();
    const accountNumber = account.accountNumber?.trim();

    let accountSubtitle = `${institution ?? ''} ${accountNumber ? presentAccountNumber(accountNumber) : ''}`.trim();
    if (accountSubtitle.length > 0) accountSubtitle += "  |  "
    accountSubtitle += account.taxStatus;
    return accountSubtitle;
};

const presentAccountNumber = (accountNumber?: string) => {
    return accountNumber?.length === 10 ?
        `(${accountNumber.slice(0, 3)}-${accountNumber.slice(3, 7)}-${accountNumber.slice(7, 10)})` :
        `(${accountNumber})`;
}

export const presentOwnershipSummary = (primaryMember: InvestorGroupMember, partnerMember: InvestorGroupMember | undefined, account: Account) => {
    const {
        isOwnedByMember: isAssetOwnedByPrimaryMember,
        ownershipText: primaryOwnershipText
    } = extractOwnershipInfo(account, primaryMember);

    const {
        isOwnedByMember: isAssetOwnedByPartnerMember,
        ownershipText: partnerOwnershipText
    } = extractOwnershipInfo(account, partnerMember);

    const legalEntityOwnershipText = account.legalEntityOwnerships
        .reduce((previousValue, ownership) => {
            return previousValue + `, ${ownership.legalEntity.name} ${formatNumberRoundedToTwoDecimals(ownership.percentage)}%`;
        }, "");

    const category = `${capitalizeFirstLetter(account.ownershipCategory)}`;

    if (account.ownershipCategory === "Sole") {
        const assetHasOneEntityOwnership = account.legalEntityOwnerships.length === 1;
        if(assetHasOneEntityOwnership)
            return `Ownership: ${category}${legalEntityOwnershipText}`;

        if(isAssetOwnedByPrimaryMember)
            return `Ownership: ${category}${primaryOwnershipText}`;

        if(isAssetOwnedByPartnerMember)
            return `Ownership: ${category}${partnerOwnershipText}`;
    }

    return `Ownership: ${category}${primaryOwnershipText}${partnerOwnershipText}${legalEntityOwnershipText}`;
}

function extractOwnershipInfo(account: Account, member?: InvestorGroupMember) {
    const memberOwnership = account.memberOwnerships.find(ownership => ownership.member.id === member?.id);
    let ownershipText = "";
    if (member)
        ownershipText = `, ${member.name} 0.00%`;
    if (member && memberOwnership)
        ownershipText = `, ${member.name} ${formatNumberRoundedToTwoDecimals(memberOwnership.percentage)}%`
    return {
        ownershipText,
        isOwnedByMember: !!memberOwnership,
    };
}
