import {ReportColumn, ReportConfig} from "./models/Reporting";
import {ReleaseTogglesType} from "../ReleaseToggles/ReleaseToggles";
import {FveDiscountRateType} from "../ClientManagement/AssetReliance/AssetRelianceButtonState";


const captureTheFactsPages = [
    "agenda",
    "familyTree",
    "assetsSummary",
    "currentNetWorth",
    "currentNetWorthAtDeath",
    "currentNetWorthDetailed",
    "currentNetWorthAtDeathDetailed",
    "assetGoalAlignment",
    "goalsSummary",
    "assetsSummaryDetailed",
    "goalsSummaryDetailed",
    "goalsPrioritization",
];

const portfolioReservePages = [
    "reviewPortfolioReserveDefinition",
    "reviewPortfolioReserve",
    "marketStress",
    "monitorPortfolioReserve"
];

const modelingPages = [
    "planSummary",
    "assetReliance",
    "currentPlan",
    "retireEarly",
    "retireToday",
    "compareScenarios",
    "assetRelianceDetailed",
    "assetRelianceDetailedWithFutureValue"
]

const investmentPlanPages = [
    "currentAssetAllocation",
    "proposedAssetAllocation",
    "currentVsProposed",
    "fulfillmentProposal",
    "currentVsProposedDetailed"
]

const holisticAdvicePages = [
    "wealthTransferInEstate",
    "wealthTransferInEstateFutureValue",
    "wealthTransferTotalWealth",
    "wealthPlanOptimizer"
]

const wealthTransferPages = [
    "assetTitlingAndDistribution",
    "wealthTransferCurrentValueDetailed",
    "wealthTransferFutureValueDetailed"
]

export const getReportSections = (releaseToggles: ReleaseTogglesType, reportType: string, monitorPr?: boolean): ReportColumn[] => ([

    {
        columnNumber: 1,
        sections: [
            {
                sectionTitle: 'Capture the Facts',
                pagesList: captureTheFactsPages,
                pages: [
                    {
                        label: 'Agenda',
                        value: 'agenda',
                    },
                    {
                        label: 'Family Tree',
                        value: 'familyTree',
                        isEditable: true,
                        isGreyedOut: reportType === 'Annual Report'
                    },
                    {
                        label: 'Asset Summary',
                        value: 'assetsSummary'
                    },
                    {
                        label: 'Asset Summary (Detailed)',
                        value: 'assetsSummaryDetailed',
                        isDisabled: !releaseToggles?.enableDetailedAssetSummaryReport,
                        isGreyedOut: reportType === 'Annual Report'
                    },
                    {
                        label: 'Current Net Worth',
                        value: 'currentNetWorth',
                        isDisabled: !releaseToggles?.enableCurrentNetWorth
                    },
                    {
                        label: 'Current Net Worth (At Death)',
                        value: 'currentNetWorthAtDeath',
                        isDisabled: !releaseToggles?.enableCurrentNetWorth
                    },
                    {
                        label: 'Current Net Worth (Detailed)',
                        value: 'currentNetWorthDetailed',
                        isDisabled: !releaseToggles?.enableDetailedCurrentNetWorthReport
                    },
                    {
                        label: 'Current Net Worth (At Death Detailed)',
                        value: 'currentNetWorthAtDeathDetailed',
                        isDisabled: !releaseToggles?.enableDetailedCurrentNetWorthReport
                    },
                    {
                        label: 'Asset-Goal Alignment',
                        value: 'assetGoalAlignment',
                        isDisabled: true
                    },
                    {
                        label: 'Goals Summary',
                        value: 'goalsSummary'
                    },
                    {
                        label: 'Goals Summary (Detailed)',
                        value: 'goalsSummaryDetailed',
                        isDisabled: !releaseToggles?.enableDetailedGoalsSummaryReport,
                        isGreyedOut: reportType === 'Annual Report'
                    },
                    {
                        label: 'Goals Prioritization',
                        value: 'goalsPrioritization',
                        isDisabled:  !releaseToggles?.enableGoalsPrioritizationReport
                    }
                ]
            },
        ]
    },
    {
        columnNumber: 2,
        sections: [
            {
                sectionTitle: 'Portfolio Reserve',
                pagesList: portfolioReservePages,
                pages: [
                    {
                        label: 'Review Portfolio Reserve (Definition-Only)',
                        value: 'reviewPortfolioReserveDefinition'
                    },
                    {
                        label: 'Review Portfolio Reserve',
                        value: 'reviewPortfolioReserve',
                        isGreyedOut: reportType === 'Annual Report'
                    },
                    {
                        label: 'Market Stress',
                        value: 'marketStress',
                        isEditable: true,
                        isDisabled: true
                    },
                  {
                        label: `${ !monitorPr ? 'Monitor Portfolio Reserve (not activated)': 'Monitor Portfolio Reserve'}`,
                        value: 'monitorPortfolioReserve',
                        isGreyedOut: !monitorPr,
                        isDisabled: !releaseToggles?.enableMonitorPortfolioReserveReport
                  },
                ]
            },
            {
                sectionTitle: 'Investment Plan',
                pagesList: investmentPlanPages,
                pages: [
                    {
                        label: 'Current Asset Allocation',
                        value: 'currentAssetAllocation',
                        isDisabled: true
                    },
                    {
                        label: 'Proposed Asset Allocation',
                        value: 'proposedAssetAllocation',
                        isDisabled: true
                    },
                    {
                        label: 'Current vs. Proposed',
                        value: 'currentVsProposed',
                        isGreyedOut: reportType === 'Annual Report'
                    },
                    {
                        label: 'Current vs. Proposed (Detailed)',
                        value: 'currentVsProposedDetailed',
                        isDisabled: !releaseToggles?.enableDetailedCurrentVsProposedReport
                    },
                    {
                        label: 'Fulfillment Proposal',
                        value: 'fulfillmentProposal',
                        isDisabled: true
                    },
                ]
            },
            {
                sectionTitle: 'Holistic Advice',
                pagesList: holisticAdvicePages,
                isDisabled: true,
                pages: [
                    {
                        label: 'Wealth Transfer In-Estate',
                        value: 'wealthTransferInEstate',
                        isDisabled: true
                    },
                    {
                        label: 'Wealth Transfer In-Estate Future Value (Triple Net)',
                        value: 'wealthTransferInEstateFutureValue',
                        isDisabled: true
                    },
                    {
                        label: 'Wealth Transfer Total Wealth',
                        value: 'wealthTransferTotalWealth',
                        isDisabled: true
                    },
                    {
                        label: 'Wealth Plan Optimizer',
                        value: 'wealthPlanOptimizer',
                        isDisabled: true
                    }
                ]
            },
        ]
    },
    {
        columnNumber: 3,
        sections: [
            {
                sectionTitle: 'Modeling',
                pagesList: modelingPages,
                pages: [
                    {
                        label: 'Plan Summary',
                        value: 'planSummary'
                    },
                    {
                        label: 'Asset Reliance',
                        value: 'assetReliance',
                        isGreyedOut: reportType === 'Annual Report'
                    },
                    {
                        label: 'Current Plan',
                        value: 'currentPlan',
                        isDisabled: true
                    },
                    {
                        label: 'Retire Early',
                        value: 'retireEarly',
                        isDisabled: true
                    },
                    {
                        label: 'Retire Today',
                        value: 'retireToday',
                        isDisabled: true
                    },
                    {
                        label: 'Compare Scenarios',
                        value: 'compareScenarios',
                        isDisabled: true
                    },
                    {
                        label: 'Asset Reliance (Detailed)',
                        value: 'assetRelianceDetailed',
                        isDisabled: !releaseToggles?.enableDetailedAssetRelianceReport
                    },
                    {
                        label: 'Asset Reliance - Future Value of Excess (Summary)',
                        value: 'assetRelianceWithFutureValue',
                        isDisabled: false
                    },
                    {
                        label: 'Asset Reliance - Future Value of Excess (Detailed)',
                        value: 'assetRelianceDetailedWithFutureValue',
                        isDisabled: !releaseToggles?.enableDetailedAssetRelianceReport
                    },
                    {
                        label: 'Net Worth Over Time',
                        value: 'netWorthOverTime',
                        isEditable: true,
                        isDisabled: false
                    }
                ]
            },
            {
            sectionTitle: 'Wealth Transfer',
            pagesList: wealthTransferPages,
            isDisabled: false,
            pages: [
                {
                    label: 'Asset Titling',
                    value: 'assetTitlingAndDistribution',
                    isDisabled: false
                },
                {
                    label: 'Asset Titling (Detailed)',
                    value: 'assetTitlingAndDistributionDetailed',
                    isDisabled: false
                },
                {
                    label: 'Wealth Transfer - Present Value (Detailed)',
                    value: 'wealthTransferCurrentValueDetailed',
                    isDisabled: !releaseToggles?.enableDetailedWealthTransferCurrentValueReport
                },
                {
                    label: 'Wealth Transfer - Future Value (Detailed)',
                    value: 'wealthTransferFutureValueDetailed',
                    isDisabled: !releaseToggles?.enableDetailedWealthTransferFutureValueReport
                }
            ]
        }]
    }
]);

export const initialSelectedPagesForAnnualReport: ReportConfig = {
    coverPage: {
        isEnabled: false
    },
    familyTree: {
        isEnabled: true
    },
    agenda: {
        isEnabled: false
    },
    goalsSummary: {
        isEnabled: false
    },
    goalsSummaryDetailed: {
        isEnabled: true
    },
    assetsSummary: {
        isEnabled: false
    },
    assetsSummaryDetailed: {
        isEnabled: true
    },
    currentNetWorth: {
        isEnabled: false
    },
    currentNetWorthAtDeath: {
        isEnabled: false
    },
    currentNetWorthDetailed: {
        isEnabled: false
    },
    currentNetWorthAtDeathDetailed: {
        isEnabled: false
    },
    planSummary: {
        isEnabled: false
    },
    reviewPortfolioReserveDefinition: {
        isEnabled: false
    },
    reviewPortfolioReserve: {
        isEnabled: true
    },
    assetReliance: {
        isEnabled: true
    },
    assetRelianceDetailed: {
        isEnabled: false
    },
    currentVsProposed: {
        isEnabled: true
    },
    currentVsProposedDetailed: {
        isEnabled: false
    },
    wealthTransferCurrentValueDetailed: {
        isEnabled: false
    },
    wealthTransferFutureValueDetailed: {
        isEnabled: false
    },
    goalsPrioritization: {
        isEnabled: false
    },
    disclaimerPage: {
        isEnabled: true
    },
    monitorPortfolioReserve: {
        isEnabled: false
    },
    assetTitlingAndDistribution: {
        isEnabled: false
    },
    assetTitlingAndDistributionDetailed: {
        isEnabled: false
    },
    assetRelianceDetailedWithFutureValue: {
        isEnabled: false
    },
    assetRelianceWithFutureValue: {
        isEnabled: false
    },
    netWorthOverTime: {
        isEnabled: false,
        netWorthOverTimeOptions: {
            discountRate:FveDiscountRateType.TRIPLE_NET,
            insuranceIncluded:true
        }
    }
};