import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../../store/store';
import {emptyProfileResponse, ProfileResponse} from 'src/ClientManagement/models/ProfileResponse';

export type ProfileState = ProfileResponse;

const initialState: ProfileState = {...emptyProfileResponse}

// The clientProfileSlice represents the profile that the spa is currently viewing,
// whether it is the approved profile or a proposal (proposal is just a draft profile).
export const activeProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        patchProfile: (state, action: PayloadAction<Partial<ProfileResponse>>): ProfileResponse => {
            return {
                ...state,
                ...action.payload
            }
        },
        // reducers need to return the new state, given the existing state and the action. In this case, the new state is
        // just the payload of the action; in other cases, you can mutate the state object, and changes to that state will
        // be recorded to create the new state.
        setProfile: (_state, action: PayloadAction<ProfileResponse>) => action.payload,
        setResourceCode: (state, action: PayloadAction<string>) => {
            state.resourceCode = action.payload
        },
    }
});

export const {
    patchProfile,
    setProfile,
    setResourceCode
} = activeProfileSlice.actions;

export const selectProfile = (state: RootState) => state.client.profile;
export const selectPrimaryContact = (state: RootState) => state.client.profile?.primaryContact;
export const selectResourceCode = (state: RootState) => state.client.profile?.resourceCode;

export default activeProfileSlice.reducer;
