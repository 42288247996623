export const COLOR_ASSETS_ACCOUNTS = '#3B9FA5';
export const COLOR_PERSONAL_ASSETS = '#4A8B67';
export const COLOR_LIFE_INSURANCES = '#A6D8BE';
export const COLOR_EQUITY_COMPENSATIONS = '#9CC8CB';
export const COLOR_FUTURE_INFLOWS = '#66AA93';
export const COLOR_PROJECTED_EXCESS = '#FDF2E4';
export const COLOR_EMPTY_ASSETS = '#808182';
export const COLOR_LIABILITIES = '#B1B1B1';
export const COLOR_BELOW_GOALS = '';
export const COLOR_RISK_CONTROL_ASSETS = '#104866';
export const COLOR_RISK_ASSETS = '#00A0AA';
export const COLOR_EXCESS_ASSETS = '#05676E';
export const COLOR_PROJECTED_ASSETS = '#AC5300';
export const COLOR_INVESTABLE_EXCESS = '#7DE0E65D';
export const COLOR_EXCESS_ASSETS_ACCENT = '#7DE0E6';
export const COLOR_NT_PRIMARY_AQUA = '#0A7A82';
export const COLOR_NT_GREY = '#EAEAEA';
export const COLOR_NT_GREY_050 = '#F6F6F6';
export const COLOR_GOALS = "#F1B040"
export const COLOR_NT_ORANGE_800 = '#AC5300';
export const COLOR_NT_AQUA_800 = '#05676E';
export const COLOR_NT_GREY_550 = '#767676';
export const COLOR_NT_GREY_200 = '#CECECE';
export const COLOR_WHITE = '#FFF';
export const COLOR_TRANSPARENT = "#FFFFFF00";
export const COLOR_PORTFOLIO_RESERVE = "#4AB0E55D";
export const COLOR_PORTFOLIO_RESERVE_LEGEND = "#4AB0E55D";
export const COLOR_ASSET_SHORTFALL_ACCENT = '#E97C05';
export const COLOR_TAXES = '#FFDF77';
export const COLOR_ESTATE_BENEFICIARIES = '#66AA93';
export const NET_WORTH_OVER_TIME_PLOT_LINE = '#088B94';
export const ASSET_TITLING_JOINT_TENANCY = '#FFC24B';
export const ASSET_TITLING_BENEFICIARY_DESIGNATION = '#987092';
export const ASSET_TITLING_REVOCABLE_TRUST = '#3A913F';
export const ASSET_TITLING_WILL = '#A5CF68';