import React from "react";
import {patchProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {useAppDispatch} from "../../../store/hooks";
import {ProfileResponse} from "../../../ClientManagement/models/ProfileResponse";
import {SetReserveTargetModal} from "./SetReserveTargetModal";
import {ProposalMissingReserveTargetModal} from "./ProposalMissingReserveTargetModal";
import {TurnOffPortfolioReserveModal} from "./TurnOffPortfolioReserveModal";


export interface ActivatePortfolioReserveWarningsProps {
    proposalListWithoutPRTargetLength: ProfileResponse[],
    setFlipSwitch: (isOn: boolean) => void,
    showProposalModal: boolean,
    setShowProposalModal: (isShown: boolean) => void,
    toggleActivatePortfolioReserve: () => void,
    setShowOffToggleModal: (isShown: boolean) => void,
    showOffToggleModal: boolean,
    setShowSetPRModal: (isShown: boolean) => void,
    showSetPRModal: boolean,
    showModal?: boolean,
}

export const ActivatePortfolioReserveWarnings: React.FC<ActivatePortfolioReserveWarningsProps> = ({
                                                                                                      proposalListWithoutPRTargetLength,
                                                                                                      setFlipSwitch,
                                                                                                      showProposalModal,
                                                                                                      setShowProposalModal,
                                                                                                      toggleActivatePortfolioReserve,
                                                                                                      setShowOffToggleModal,
                                                                                                      showOffToggleModal,
                                                                                                      setShowSetPRModal,
                                                                                                      showSetPRModal,
                                                                                                      showModal
                                                                                                  }) => {
    const dispatch = useAppDispatch();

    const handleSetPRModalButton = () => {
        setShowSetPRModal(false);
        dispatch(patchProfile({isActivePortfolioReserve: false}));
    }

    async function handleModalConfirmButton() {
        setShowOffToggleModal(false)
        setFlipSwitch(false)
        toggleActivatePortfolioReserve()
    }

    async function handleModalCancelButton() {
        setFlipSwitch(true)
        setShowOffToggleModal(false)
    }

    const handleProposalModalConfirmButton = () => {
        setShowProposalModal(false)
        toggleActivatePortfolioReserve()
    }

    const handleProposalModalCancelButton = () => {
        setShowProposalModal(false);
        dispatch(patchProfile({isActivePortfolioReserve: false}));
    }

    return (
        <>
            <SetReserveTargetModal open={showSetPRModal} onOK={handleSetPRModalButton}/>
            <ProposalMissingReserveTargetModal open={showProposalModal} onConfirm={handleProposalModalConfirmButton}
                                               onCancel={handleProposalModalCancelButton}>
                {
                    proposalListWithoutPRTargetLength.map(proposal => <li
                        data-testid={proposal.id}
                        key={proposal.id}>{proposal.displayName}</li>)
                }
            </ProposalMissingReserveTargetModal>
            {showModal && <TurnOffPortfolioReserveModal open={showOffToggleModal} onConfirm={handleModalConfirmButton}
                                                        onCancel={handleModalCancelButton}/>}
        </>
    )
}