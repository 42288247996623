export type AssetRelianceButtonState = {
    showExpectedExcessAsset?: boolean,
    includeLifeInsuranceAtDeath?: boolean,
    selectedFveDiscountRate: FveDiscountRateType
}

export enum FveDiscountRateType {
    TRIPLE_NET = "TRIPLE_NET",
    NOMINAL_NET = "NOMINAL_NET"
}

export const EMPTY_ASSET_RELIANCE_BUTTON_STATE: AssetRelianceButtonState = {
    showExpectedExcessAsset: false,
    includeLifeInsuranceAtDeath: false,
    selectedFveDiscountRate: FveDiscountRateType.TRIPLE_NET
}