import React from "react";
import {Dropdown, DropdownItem} from "../../../components/Dropdown";
import {InvestorGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {Label} from "../../../components";
import {calculateAgeFromBirthdate} from "../../../utils/dateUtils";
import {ISO8601_DATE_FORMAT} from "../../../constants/common";

type ClientMemberOwnerDropdownProps = {
    id?: string,
    label: string,
    labelId?: string,
    value: InvestorGroupMember | undefined ,
    firstOwnerOption: InvestorGroupMember | undefined ,
    secondOwnerOption?: InvestorGroupMember,
    thirdOwnerOption?: InvestorGroupMember,
    hideOwnerAge?: boolean,
    onChange: (selectedClientOwnerMemberId: string) => void,
    disabled?: boolean
};

export function ClientMemberOwnerDropdown(props: ClientMemberOwnerDropdownProps) {
    const firstOwnerDropdownItem = getDropdownItem(props.firstOwnerOption!, props.hideOwnerAge);
    const dropdownItems = [firstOwnerDropdownItem];

    if (props.secondOwnerOption !== undefined) {
        dropdownItems.push(getDropdownItem(props.secondOwnerOption, props.hideOwnerAge));
    }
    if (props.thirdOwnerOption !== undefined) {
        dropdownItems.push(getDropdownItem(props.thirdOwnerOption, props.hideOwnerAge));
    }

    return <div className="layout-data-entry-form__field">
        <Label labelId={props.labelId || "owner"} label={props.label}/>
        <Dropdown
            aria-labelledby={props.labelId || "owner"}
            size="medium"
            id={props.id || "ownershipDropdown"}
            value={props.value!.id}
            onChange={(data: { value: string }) => props.onChange(data.value)}
            disabled={props.disabled}
        >
            {dropdownItems}
        </Dropdown>
    </div>;
}

function getDropdownItem(owner: InvestorGroupMember, hideAges: boolean | undefined) {
    const age = calculateAgeFromBirthdate(owner.birthdate, ISO8601_DATE_FORMAT);
    const itemText = (hideAges || owner.id === "a8cd85ce-5a05-433b-836e-5e25b6cb70dd")  ? owner.name : `${owner.name} (Age ${age})`;
    return <DropdownItem itemText={itemText} value={owner.id} key={owner.id}/>;
}
