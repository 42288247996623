import {OwnershipReadModel, OwnershipWriteModel} from "./Ownership";
import {Asset, EstateValue} from "./Asset";
import {DomesticTrustAccount} from "./InvestmentProgram";
import {TaxDetailsType, TaxStatusOptions} from "./TaxDetails";

/* data types */
export type PartiallyOwnedInvestmentAccountWriteModel = Asset & TaxDetailsType & {
    name:string
    legalAgreementName:string;
    legalAgreementNumber:string;
    legalAgreementTypeCode:string;
    taxStatus: string,
    institution: string,
    entityType: string | null,
    financialAccountPersistentId: string
    accountNumber: string,
    accountStatus: string,
    financialAccountManagementCode?: string
};

export type PartiallyOwnedInvestmentAccountReadModel = Asset & TaxDetailsType & {
    name:string;
    legalAgreementName: string;
    number:string;
    typeCode:string;
    taxStatus:TaxStatusOptions;
    institution:string;
    entityType:string | null;
    holdings: null,
    domesticTrustAccounts: DomesticTrustAccount[],
    marketEstateValue: EstateValue,
    asOfDate: string
}

export type UpdatePartiallyOwnedInvestmentAccountRequest =
    Omit<PartiallyOwnedInvestmentAccountWriteModel, 'profileId' | 'ordinal' | keyof OwnershipReadModel>
    & OwnershipWriteModel;

export type CreatePartiallyOwnedInvestmentAccountRequest = Omit<UpdatePartiallyOwnedInvestmentAccountRequest, 'id'>

export const initialPartiallyOwnedAccount : PartiallyOwnedInvestmentAccountWriteModel = {
    id: '',
    profileId: '',
    ordinal: -1,
    name: '',
    legalAgreementName: '',
    legalAgreementNumber: '',
    legalAgreementTypeCode: '',
    taxStatus: 'Taxable',
    institution: '',
    entityType: '',
    financialAccountPersistentId: '',
    accountNumber: '',
    accountStatus: '',
    isEntityCapitalGains: true,
    isLiabilityPaidByPortfolio: false,
    ownershipCategory: 'Sole',
    memberOwnerships: [],
    legalEntityOwnerships: []
}