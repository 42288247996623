import React from "react";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {ActivatePortfolioReserveSwitch, LabelPosition} from "./ActivatePortfolioReserveSwitch";

export interface ProfileAwareActivatePortfolioReserveSwitchProps {
    className?: string;
    labelPosition: LabelPosition;
    onTogglePortfolioReserve?: (isActivePortfolioReserve: boolean, profileId: string, dispatch: any) => void
}

export const ProfileAwareActivatePortfolioReserveSwitch = (props: ProfileAwareActivatePortfolioReserveSwitchProps) => {
    const profileOrProposal = useAppSelector(selectProfile);
    const navDrawer = props.className === "nav-drawer__separator paddingleft-5" ? false : true

    return (
        <ActivatePortfolioReserveSwitch
            isActivePortfolioReserve={profileOrProposal.isActivePortfolioReserve}
            onTogglePortfolioReserve={props.onTogglePortfolioReserve}
            showModal={navDrawer}
            {...props}
        />
    )
}