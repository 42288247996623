import { Icon } from "../../components";
import classNames from "classnames";
import { ReactElement } from "react";
import { FundingDisplayOptions } from "./models/Funding";
import { isShowFundingForInflows, isShowFundingForNonInvestable, isShowOnlyPortfolio } from "./fundingUtils";

type FundingAccordionItemHeaderProps = {
    expanded: boolean;
    toggle: () => void;
    title: string | ReactElement;
    fundedByRiskAssets: string;
    fundedByRiskControl: string;
    fundedByPortfolio: string;
    fundedByNonInvestable: string;
    fundedByInflows: string;
    presentValue: string;
    gridClassName: string;
    showChevron?: boolean;
    displayOptions: FundingDisplayOptions;
};

export function FundingAccordionItemHeader({
    expanded,
    toggle,
    title,
    gridClassName,
    showChevron = true,
    fundedByRiskAssets,
    fundedByRiskControl,
    fundedByPortfolio,
    fundedByNonInvestable,
    fundedByInflows,
    presentValue,
    displayOptions
}: FundingAccordionItemHeaderProps) {
    return (
        <div
            className={classNames("accordion-header-content", gridClassName)}
            data-testid={`accordion-header-${title}`}
        >
            <span 
                className={`paddingleft-md display-flex align-items-center cursor-pointer`}
                onClick={toggle}
                role="button"
                aria-pressed={expanded ? 'true' : 'false'}
            >
                {showChevron && (
                    <Icon
                        name={expanded ? "chevron_down" : "chevron_right"}
                        size="large"
                    />
                )}
                <span className="paddingleft-md">
                    <div className="font-lg margin-none" aria-label="Goal Type">{title}</div>
                </span>
            </span>

            <span className="font-lg textalign-right" aria-label="Total Funded by Risk Assets">
                {fundedByRiskAssets}
            </span>
            <span className="font-lg textalign-right" aria-label="Total Funded by Risk Control">
                {fundedByRiskControl}
            </span>
            {!isShowOnlyPortfolio(displayOptions) && <span className="font-lg textalign-right" aria-label="Total Funded by Portfolio">
                {fundedByPortfolio}
            </span>}
            {isShowFundingForInflows(displayOptions) &&
                <span className="font-lg textalign-right" aria-label="Total Funded by Inflows">
                    {fundedByInflows}
                </span>
            }
            {isShowFundingForNonInvestable(displayOptions) &&
                <span className="font-lg textalign-right" aria-label="Total Funded by Non Investable">
                    {fundedByNonInvestable}
                </span>
            }
            <span className="font-lg textalign-right" aria-label="Total Present Value">
                {presentValue}
            </span>
            <span />
        </div>
    );
}
