import {AgendaItem, Presenter, PresenterType, ServiceTeamMemberAgenda} from "./models/AgendaDetails";

export function updateNtPresenterDetails(presenterId: string | undefined, presenterTitle: string | undefined, updatedPresenters: Presenter[], presenterType: PresenterType, presenterName: string | undefined, partners: ServiceTeamMemberAgenda[]) {
    if (presenterId && presenterTitle) {

            updatedPresenters.push({
                presenterType: "NT_PRESENTER",
                presenterName: presenterName,
                presenterId: presenterId,
                presenterTitle: presenterTitle
            })

    } else {
        updatedPresenters.push({
            presenterType,
            presenterName: presenterName
        })
    }
}

export function splitAgendaEntries(agendaItems: Array<AgendaItem>, calculatedData: Array<any>) {

    let maxLines = 25;
    for (let i = 0; i < agendaItems.length; i++) {
        if (agendaItems[i].title) {
            --maxLines;
        }
        if (agendaItems[i].notes) {
            let notesRow = Math.ceil(agendaItems[i].notes.length / 90);
            maxLines = maxLines - notesRow;
        }
        --maxLines;
        if (maxLines <= 0) {
            const dataThatCanFitInCurrentPage = agendaItems.slice(0, i);
            calculatedData.push({"agendaItems": dataThatCanFitInCurrentPage});
            const remainingAgendaItems = agendaItems.slice(i);
            if (remainingAgendaItems.length > 0) {
                splitAgendaEntries(remainingAgendaItems, calculatedData);
                break;
            }
        } else if (i === (agendaItems.length - 1)) {
            calculatedData.push({"agendaItems": agendaItems});
        }
    }
    return calculatedData;
}

export function splitPresenters(presenters: Presenter[], calculatedPresenters: Array<Presenter[]> = []) {
    let maxLines = 22;
    for (let i = 0; i < presenters.length; i++) {
        if (presenters[i].presenterName) {
            --maxLines;
        }
        if (presenters[i].presenterTitle) {
            --maxLines
        }

        if (maxLines <= 0) {
            const dataThatCanFitInCurrentPage = presenters.slice(0, i);
            calculatedPresenters.push(dataThatCanFitInCurrentPage);
            const remainingPresenters = presenters.slice(i);

            if (remainingPresenters.length > 0) {
                splitPresenters(remainingPresenters, calculatedPresenters);
                break;
            }

        } else if (i === (presenters.length - 1)) {
            calculatedPresenters.push(presenters);
        }

    }

    return calculatedPresenters;
}
