import React from 'react'
import {DataDisplayView, FundingDisplayOptions, GoalFundingResponse} from './models/Funding';
import FundingTableSummary from './FundingTableSummary';
import AccordionWithHeader from 'src/components/Accordion/AccordionWithHeader';
import { FundingAccordionHeader } from './FundingAccordionHeader';
import { GoalBarChartKeyColors } from '../BarChartSidebar/GoalBarChartKeyColors';
import classNames from 'classnames';
import FundingSummaryTotals from './FundingSummaryTotals';
import { formatFundingCurrency } from './fundingUtils';
import FamilyFundingSummary from "./FamilyFundingSummary";

type FundingSummaryProps = {
    ageIndex: number;
    fundingInfo: GoalFundingResponse;
    displayOptions: FundingDisplayOptions;
    displayView: DataDisplayView;
}

const FundingSummary: React.FC<FundingSummaryProps> = ({
    ageIndex,
    fundingInfo,
    displayOptions,
    displayView
}) => {
    return (
        <div
            className={classNames("funding-summary", {
                "funding-view-default":
                    displayOptions === FundingDisplayOptions.ONLY_PORTFOLIO,
                "funding-view-with-inflows-or-non-investable":
                    displayOptions === FundingDisplayOptions.PORTFOLIO_AND_INFLOWS ||
                    displayOptions === FundingDisplayOptions.PORTFOLIO_AND_NON_INVESTABLE,
                "funding-view-with-inflows-and-non-investable":
                    displayOptions === FundingDisplayOptions.PORTFOLIO_AND_INFLOWS_AND_NON_INVESTABLE,
            })}
        >
            <FundingSummaryTotals
                ageIndex={ageIndex}
                fundingInfo={fundingInfo}
            />
            <div className='funding-summary__tables'>
                <AccordionWithHeader
                    accordionId={"GoalFundingAccordion"}
                    allItems={["Lifestyle Spending", "Discretionary Goals", "Philanthropic Goals", "Family Goals"]}
                headerProps={{ className: "funding-grid", gridSpanClass: "" }}
                columnTitles={[]}
                HeaderComponent={({ expanded, toggle }) => (
                    <FundingAccordionHeader expanded={expanded} toggle={toggle} displayOptions={displayOptions} />
                )}
                renderHeader={true}
            >
                <FundingTableSummary
                    title="Lifestyle Spending"
                    ageIndex={ageIndex}
                    goalsSummary={fundingInfo.lifestyle}
                    color={GoalBarChartKeyColors.LIFESTYLE}
                    displayOptions={displayOptions}
                    displayView={displayView}
                />
                <FundingTableSummary
                    title="Discretionary Goals"
                    ageIndex={ageIndex}
                    goalsSummary={fundingInfo.discretionary}
                    color={GoalBarChartKeyColors.DISCRETIONARY}
                    displayOptions={displayOptions}
                    displayView={displayView}
                />
                <FundingTableSummary
                    title="Philanthropic Goals"
                    ageIndex={ageIndex}
                    goalsSummary={fundingInfo.philanthropic}
                    color={GoalBarChartKeyColors.PHILANTHROPIC}
                    displayOptions={displayOptions}
                    displayView={displayView}
                />
                <FamilyFundingSummary
                    title="Family Goals"
                    ageIndex={ageIndex}
                    goalsSummaryTotal={fundingInfo.family}
                    color={GoalBarChartKeyColors.FAMILY}
                    displayOptions={displayOptions}
                    displayView={displayView}
                />

                    <div className='funding-summary__tables__total funding-grid'>
                        <span className="font-lg total-label"><b>Totals</b></span>
                        <span className="font-lg textalign-right" aria-label="Goals Total Present Value">
                            <b>{formatFundingCurrency(fundingInfo.presentValueAtYear?.[ageIndex])}</b>
                        </span>
                    </div>
                </AccordionWithHeader>
            </div>
        </div>
    );
}

export default FundingSummary