import {Popover} from "../Popover";
import React from "react";
import {Button} from "../Button";

export interface InfoPopoverProps {
    content: JSX.Element,
    direction: 'top' | 'bottom' | 'left' | 'right',
    label?: string,
    width?: string,
    arrow?: boolean,
    className?: string,
}

const InfoPopover = ({
                         content,
                         direction,
                         label = "info-popover",
                         width,
                         arrow=false,
                         className
                     }: InfoPopoverProps) => {
    return (
        <Popover
            arrow={arrow}
            aria-label={label}
            className={className? className : "popover-text"}
            content={content}
            direction={direction}
            width={width}
        >
            <Button className="popover-button"
                    icon="only"
                    iconName="info_outline"
                    kind="borderless"
                    size="small"
            />
        </Popover>
    )
}

export default InfoPopover;