import React, {ReactNode, useCallback, useMemo, useReducer, useState} from 'react';
import RelayContext, {MeetingContainerSchema} from "./types/RelayContext";
import {AttachState, IFluidContainer, SharedMap} from "fluid-framework";
import {AzureRemoteConnectionConfig} from "@fluidframework/azure-client/src/interfaces";
import {
    AzureClient,
    AzureContainerServices,
    AzureLocalConnectionConfig,
    AzureUser,
    IAzureAudience
} from "@fluidframework/azure-client";
import {RelayEventManager} from "./RelayEventManager";
import {RegisterSharedObjectMutatorAction, ResetSharedObjectMutatorAction} from "./types/RelayEventHook";
import {App_Conf, isLocalGateway} from "../../../core/app_conf";
import relayMeetingStatus from "./eventHooks/relayMeetingStatus";
import {defaultSharedObjectMutators, SharedObjectMutators} from "./types/SharedObjectMutators";
import {AzureFunctionTokenProviderSecure} from "./tokenProviders/AzureFunctionTokenProviderSecure";
import relayPresenterState from "./eventHooks/relayPresenterState";
import {InsecureTokenProvider} from "@fluidframework/test-client-utils";
import relayMeetingControlRequest from "./eventHooks/relayMeetingControlRequest";
import {RelayAudienceManager} from "./RelayAudienceManager";
import {MeetingPortalTokenProvider} from "./tokenProviders/MeetingPortalTokenProvider";
import {useLocation} from "react-router-dom";
import storageHelper from 'src/core/storageHelper';

type RelayProviderProps = {
    children: ReactNode
}

export default function RelayProvider({children}: RelayProviderProps) {
    const [relayContainer, setRelayContainer] = useState<IFluidContainer>();
    const [relayContainerId, setRelayContainerId] = useState<string>('');
    const [sharedObjects, setSharedObjects] = useState<MeetingContainerSchema | null>(null);
    const [sharedObjectMutators, dispatchSharedObjectMutators] = useReducer(
        sharedObjectMutatorsReducer,
        defaultSharedObjectMutators
    );
    const [audience, setAudience] = useState<IAzureAudience>();

    const updateStateAfterConnecting = ({containerId, container, services}: {
        containerId: string,
        container: IFluidContainer,
        services: AzureContainerServices,
    }) => {
        setAudience(services.audience);
        setRelayContainerId(containerId);
        setRelayContainer(container);
        setSharedObjects(container.initialObjects as MeetingContainerSchema);
    };

    const createMeetingContainer = async (userId: string, userName: string): Promise<{ containerId: string, documentId: string }> => {
        let storedDocumentId = '';
        const setDocumentId = (documentId: string) => {
            storedDocumentId = documentId;
        }
        const {client, containerSchema} = await setupClient(userId, userName, setDocumentId);
        const {container, services} = await client.createContainer(containerSchema);
        const containerId = await container.attach();
        updateStateAfterConnecting({containerId, container, services});
        return {containerId, documentId: storedDocumentId};
    };

    const connectToMeetingContainer = async (userId: string, userName: string, containerId: string): Promise<void> => {
        const {client, containerSchema} = await setupClient(userId, userName);
        const {container, services} = await client.getContainer(containerId, containerSchema);
        updateStateAfterConnecting({containerId, container, services});
    };

    const connectToMeetingContainerFromMeetingPortal = async (
        containerId: string,
        fluidToken: string,
        joinMeetingId: string,
        joinMeetingPasscode: string,
        user: { id: string; name: string; },
        communicationsIdentifier: string | null,
    ) => {
        const {
            client,
            containerSchema
        } = await setupClientForMeetingPortal(fluidToken, joinMeetingId, joinMeetingPasscode, user, communicationsIdentifier);
        const {container, services} = await client.getContainer(containerId, containerSchema);
        updateStateAfterConnecting({containerId, container, services});
    }

    const unloadMeetingContainer = useCallback(() => {
        if (relayContainer) {
            const dispose = () => {
                relayContainer.dispose();
                setSharedObjects(null);
                dispatchSharedObjectMutators({type: 'reset'});
            };
            if (relayContainer.isDirty) {
                relayContainer.on("saved", () => {
                    dispose();
                });
            } else {
                dispose();
            }
        }
    }, [relayContainer]);

    const disconnectFromMeetingContainer = useCallback(() => {
        if (relayContainer) {
            const disconnect = () => {
                relayContainer.disconnect();
                setSharedObjects(null);
                dispatchSharedObjectMutators({type: 'reset'});
            };
            if (relayContainer.isDirty) {
                relayContainer.on("saved", () => {
                    disconnect();
                });
            } else {
                disconnect();
            }
        }
    }, [relayContainer]);

    const isContainerAttached = useMemo(() => relayContainer?.attachState === AttachState.Attached, [relayContainer]);

    const location = useLocation();
    const isInsideMeetingPortal = useMemo(() => {
        return location.pathname.startsWith('/meetings');
    }, [location]);

    return (
        <RelayContext.Provider value={{
            containerId: relayContainerId,
            createMeetingContainer,
            connectToMeetingContainer,
            connectToMeetingContainerFromMeetingPortal,
            unloadMeetingContainer,
            disconnectFromMeetingContainer,
            sharedObjectMutators,
            sharedObjects,
        }}>
            {isContainerAttached && <RelayEventManager
                sharedObjects={sharedObjects}
                registerSharedObjectMutator={dispatchSharedObjectMutators}
                relayEventHooks={[
                    relayMeetingStatus,
                    relayPresenterState,
                    relayMeetingControlRequest,
                ]}
            />

            }
            {isContainerAttached && !isInsideMeetingPortal && <RelayAudienceManager
                audience={audience}
            />
            }
            {children}
        </RelayContext.Provider>
    );
}

const sharedObjectMutatorsReducer = (
    state: SharedObjectMutators,
    action: RegisterSharedObjectMutatorAction | ResetSharedObjectMutatorAction
) => {
    if (action.type === 'reset') {
        return defaultSharedObjectMutators;
    }
    return ({
        ...state,
        [action.key]: action.callback,
    });
};

const {RELAY_ENDPOINT_URL, RELAY_FUNCTION_URL} = App_Conf;

const setupClient = async (userId: string, userName: string, setDocumentId?: (documentId: string) => void) => {
    const spaConfig = storageHelper.getSpaConfig();

    const connectionConfig: AzureLocalConnectionConfig | AzureRemoteConnectionConfig = isLocalGateway()
        ? {
            type: 'local',
            endpoint: 'http://localhost:7070',
            tokenProvider: new InsecureTokenProvider(spaConfig.relayTenantId, {
                id: 'localUserId',
                name: 'localUser'
            } as AzureUser)
        }
        : {
            type: 'remote',
            endpoint: RELAY_ENDPOINT_URL,
            tenantId: spaConfig.relayTenantId,
            tokenProvider: new AzureFunctionTokenProviderSecure(
                RELAY_FUNCTION_URL,
                setDocumentId,
                {userId, userName}
            ),
        };
    const client = new AzureClient({connection: connectionConfig});
    const containerSchema: {
        initialObjects: Record<keyof MeetingContainerSchema, typeof SharedMap>
    } = {
        initialObjects: {
            meetingControlDDS: SharedMap,
            domContentDDS: SharedMap,
            canvasContentDDS: SharedMap,
            meetingPortalParticipantJoinDDS: SharedMap,
            removeParticipantDDS: SharedMap,
            meetingParticipantDDS: SharedMap,
        }
    };
    return {
        client,
        containerSchema
    }
};

const setupClientForMeetingPortal = async (
    fluidToken: string,
    joinMeetingId: string,
    joinMeetingPasscode: string,
    user: { id: string; name: string; },
    communicationsIdentifier: string | null,
) => {
    const spaConfig = storageHelper.getSpaConfig();

    const connectionConfig: AzureLocalConnectionConfig | AzureRemoteConnectionConfig = isLocalGateway()
        ? {
            type: 'local',
            endpoint: 'http://localhost:7070',
            tokenProvider: new InsecureTokenProvider(spaConfig.relayTenantId, {
                id: 'portalUserId',
                name: 'portalUser'
            } as AzureUser)
        }
        : {
            type: 'remote',
            endpoint: RELAY_ENDPOINT_URL,
            tenantId: spaConfig.relayTenantId,
            tokenProvider: new MeetingPortalTokenProvider(
                fluidToken,
                joinMeetingId,
                joinMeetingPasscode,
                user,
                communicationsIdentifier,
            ),
        };
    const client = new AzureClient({connection: connectionConfig});
    const containerSchema: {
        initialObjects: Record<keyof MeetingContainerSchema, typeof SharedMap>
    } = {
        initialObjects: {
            meetingControlDDS: SharedMap,
            domContentDDS: SharedMap,
            canvasContentDDS: SharedMap,
            meetingPortalParticipantJoinDDS: SharedMap,
            removeParticipantDDS: SharedMap,
            meetingParticipantDDS: SharedMap,
        }
    };
    return {
        client,
        containerSchema
    }
};
