import React, {ReactNode} from 'react';
import {Popover as XpsPopover} from 'xps-react';

export type PopoverProps = {
    children: ReactNode,
    content: ReactNode | string,
    direction: 'top' | 'bottom' | 'left' | 'right',
    width?: string,
    arrow?: boolean,
    "aria-label": string,
    className?: string,
}

const Popover = ({ ...props }: PopoverProps) => <XpsPopover id={props['aria-label']} {...props} />;

export default Popover;