import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";
import {Label} from "xps-react";
import React, {useState} from "react";
import {Switch} from "../../../components/DisablableSwitch/Switch";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {selectProposals} from "../../../ClientManagement/Proposals/proposalsSlice";
import {ActivatePortfolioReserveWarnings} from "./ActivatePortfolioReserveWarnings";
import {Button} from "../../../components";

export enum LabelPosition {
    Left = "Left",
    None = "None",
    Dark = "DarkMode"
}

export type ActivatePortfolioReserveSwitchProps = {
    isActivePortfolioReserve: boolean,
    className?: string;
    labelPosition: LabelPosition;
    onTogglePortfolioReserve?: (isActivePortfolioReserve: boolean, profileId: string, dispatch: Function) => void,
    showModal?: boolean
};


export const ActivatePortfolioReserveSwitch = ({
                                                   className,
                                                   labelPosition,
                                                   isActivePortfolioReserve,
                                                   onTogglePortfolioReserve,
                                                   showModal
                                               }: ActivatePortfolioReserveSwitchProps) => {
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const profileOrProposal = useAppSelector(selectProfile);
    const proposalsForProfile = useAppSelector(selectProposals);
    const portfolioReserveIsSet = profileOrProposal.portfolioReserveTargetLength !== undefined && profileOrProposal.portfolioReserveTargetLength !== null;
    const proposalListWithoutPRTargetLength = (proposalsForProfile.filter(proposal => (
        proposal.portfolioReserveTargetLength === undefined || proposal.portfolioReserveTargetLength === null)))
    const [showSetPRModal, setShowSetPRModal] = useState<boolean>(false);
    const [flipSwitch, setFlipSwitch] = useState<boolean>(true);
    const [showOffToggleModal, setShowOffToggleModal] = useState<boolean>(false);
    const [showProposalModal, setShowProposalModal] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    if (releaseToggles?.enableActivePortfolioReserve !== true) {
        return null;
    }

    const toggleActivatePortfolioReserve = async () => {
        if (!isActivePortfolioReserve && !portfolioReserveIsSet) {
            setFlipSwitch(false)
            setShowSetPRModal(true)
        } else {
            const newIsActivePortfolioReserve = !isActivePortfolioReserve;

            if (onTogglePortfolioReserve) {
                onTogglePortfolioReserve(newIsActivePortfolioReserve, profileOrProposal.id, dispatch);
            }
        }

    }

    const hasNullPRLengthForProposals = (): boolean => {
        return (proposalListWithoutPRTargetLength.length > 0)
    }

    async function handleToggleAndModalLogic() {
        let prNotOnAndNotSet = !isActivePortfolioReserve && !portfolioReserveIsSet;
            if (isActivePortfolioReserve) {
                setShowOffToggleModal(true);
            } else {
                if (prNotOnAndNotSet) {
                    setFlipSwitch(false)
                    setShowSetPRModal(true)
                } else if (hasNullPRLengthForProposals()) {
                    setShowProposalModal(true);
                } else {
                    await toggleActivatePortfolioReserve();
                }
            }
    }

    const labelStyling = (labelPosition === LabelPosition.Dark) ? "toggle-label__white" : "toggle-label"

    return <div className={className}>
        {labelPosition !== LabelPosition.None &&
            <div style={{display: "flex", alignItems: 'baseline'}}>
                <Label
                    className={labelStyling}
                    id="switch_01_Label"
                    name="Label_01"
                    text="Portfolio Reserve"
                />
            </div>
        }
        <span
            data-testid="portfolio-reserve-toggle-slider"
        >
                <div
                    className={switchDivClass(labelPosition)}
                    style={{paddingTop: switchPaddingTop(labelPosition)}}>
                    <Switch ariaLabelledBy="switch_01_Label"
                            id="switch_01"
                            isOn={isActivePortfolioReserve}
                            offText="OFF"
                            onText="ON"
                            stateTextPosition="right"
                            isDisabled={false}
                            flipSwitch={flipSwitch}
                            toggleSwitch={handleToggleAndModalLogic}
                    />
                </div>
        </span>
        <ActivatePortfolioReserveWarnings proposalListWithoutPRTargetLength={proposalListWithoutPRTargetLength}
                                          setFlipSwitch={setFlipSwitch}
                                          showProposalModal={showProposalModal}
                                          setShowProposalModal={setShowProposalModal}
                                          toggleActivatePortfolioReserve={toggleActivatePortfolioReserve}
                                          setShowOffToggleModal={setShowOffToggleModal}
                                          showOffToggleModal={showOffToggleModal}
                                          setShowSetPRModal={setShowSetPRModal}
                                          showSetPRModal={showSetPRModal}
                                          showModal={showModal}
        />
    </div>
}
const switchDivClass = (labelPosition: LabelPosition) => labelPosition === LabelPosition.Left ? "float-right" : "";

const switchPaddingTop = (labelPosition: LabelPosition) => labelPosition === LabelPosition.None ? "0px" : "15px";