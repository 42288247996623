import React, {useMemo} from "react";
import {AccordionItem, AccordionItemListHeader} from "../components/Accordion";
import classNames from "classnames";
import {AccordionItemPresentValueHeader} from "../components/Accordion/AccordionItemPresentValueHeader";
import {Accordion} from "../components";
import {GroupedTable} from "../components/Table/GroupedTable";
import {AssetTitlingAccordionItem} from "./mappers";

export type AssetTitlingAccordionProps = {
    accordionItems: AssetTitlingAccordionItem[],
    isFromReport?: boolean
};

export function AssetTitlingAccordion({accordionItems, isFromReport}: AssetTitlingAccordionProps) {
    const expandedAccordionItems = useMemo(() => {
        return isFromReport ? accordionItems.map(value => `asset-titling-${value.id}`) : [];
    }, [isFromReport, accordionItems]);

    return (
        <>
            <AccordionItemListHeader
                testId={"asset-titling-accordion-header"}
                firstColumnTitle="Distributed By"
                lastColumnTitle="Present Value"
                className={classNames("asset-titling-summary-grid", "asset-titling-grid-table")}
            />
            <Accordion expanded={expandedAccordionItems}>
                {
                    accordionItems.map(item => (
                        <AccordionItem
                            key={item.id}
                            uuid={`asset-titling-${item.id}`}
                            primaryText={item.primaryText}
                            accentColor={item.accentColor}
                            rightAlignedContent={
                                <AccordionItemPresentValueHeader value={item.totalValue}/>
                            }
                            expandable={item.hasData}>
                            {
                                item.hasData &&
                                <GroupedTable
                                    columnHeaders={["Name", "Present Value"]}
                                    tableData={item.tableData}
                                />
                            }
                        </AccordionItem>
                    ))
                }
            </Accordion>
        </>
    );
}