import {GoalModelType} from "../models/GoalModelType";
import {formatCurrency} from "../../utils/format";
import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {AccordionItem} from "../../components";
import React from "react";
import GoalUtils from "../Summary/GoalUtils";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import {NonLifestyleGoalsTable} from "./NonLifestyleGoalsTable";
import {NO_OP} from "../../constants/common";


export function getGoalsForDisplay(type: GoalType, goalModel: GoalModelType) {
    switch (type) {
        case GoalType.DISCRETIONARY:
            return goalModel.discretionaryGoals
        case GoalType.PHILANTHROPIC:
            return goalModel.philanthropicGoals
        default:
            return []
    }
}

export const SUPPORTED_GOAL_TYPES = [
    GoalType.DISCRETIONARY,
    GoalType.PHILANTHROPIC
]

export interface NonLifestyleGoalListProps {
    type: GoalType,
    label: string,
    goalsForDisplay: NonLifestyleGoalType[],
    color: string,
    onDeleteGoal?: (goal: NonLifestyleGoalType) => void,
    onEditGoal?: (goal: NonLifestyleGoalType, type: GoalType) => void,
    isProfileWithProposalsOrArchived?: boolean
}

const NonLifestyleGoalList = ({
                                  type,
                                  label,
                                  goalsForDisplay,
                                  color,
                                  onDeleteGoal = NO_OP,
                                  onEditGoal = NO_OP,
                                  isProfileWithProposalsOrArchived
                              }: NonLifestyleGoalListProps) => {
    const {investorGroup, proposal} = useAppSelector(selectGoalModel)
    if (!SUPPORTED_GOAL_TYPES.includes(type)) {
        throw new Error(`This component does not support goals of type ${type}`)
    }

    if (goalsForDisplay.length === 0) {
        return null
    }

    return (
        <section data-testid={`${type}-goal-list`}>
            <div className="accordion-tables">
                <AccordionItem
                    uuid={label}
                    accentColor={color}
                    primaryText={label}
                    rightAlignedContent={
                        <div className="display-flex align-items-center">
                            <span className="font-lg paddingright-xl">
                                {formatCurrency(GoalUtils.getNonLifestyleGoalCumulativeTotalPresentValue(goalsForDisplay))}
                            </span>
                        </div>
                    }
                >
                    <NonLifestyleGoalsTable
                        type={type}
                        goalsForDisplay={goalsForDisplay}
                        investorGroup={investorGroup}
                        proposal={proposal}
                        onDeleteGoal={onDeleteGoal}
                        onEditGoal={onEditGoal}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                </AccordionItem>
            </div>
        </section>

    )
}

export default NonLifestyleGoalList;


