import React from "react";
import {NonClientMemberOwnershipFormData, OwnershipDetailsFormData} from "../../models/Ownership";
import {MemberGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {DeleteButton, Dropdown, DropdownItem, PercentInput} from "../../../components";
import {calculateTotalOwnedValue} from "../helpers";
import {SubSectionHeader} from "../components/SubSectionHeader";
import {Placeholder} from "../../../components/DataFormTable/Placeholder";
import {isNotUndefined} from "../../../utils/isNotUndefined";

type NonClientIndividualOwnershipTableProps = {
    formData: OwnershipDetailsFormData,
    onNonClientMemberOwnershipChange: (updatedFormData: OwnershipDetailsFormData) => void,
    nonClientMembers: MemberGroupMember[],
    totalAssetValue?: number | null,
    isProfileWithProposalsOrArchived?: boolean
}

export function NonClientIndividualOwnershipTable({
                                                      formData,
                                                      nonClientMembers,
                                                      onNonClientMemberOwnershipChange,
                                                      totalAssetValue,
                                                      isProfileWithProposalsOrArchived = false
                                                  }: NonClientIndividualOwnershipTableProps
) {
    function onDeleteOwnership(indexToDelete: number) {
        onNonClientMemberOwnershipChange({
            ...(formData),
            nonClientMemberOwnerships: formData.nonClientMemberOwnerships.filter(
                (_, index) => {
                    return index !== indexToDelete;
                }
            )
        });
    }

    function findUnusedMembers(): MemberGroupMember[] {
        const usedIds = formData.nonClientMemberOwnerships
            .map(o => o.memberId);
        return nonClientMembers
            .filter(member => !usedIds.includes(member.id))
    }

    function findUnusedMemberId(): string {
        return findUnusedMembers()[0].id;
    }

    function getCurrentMember(ownership1: NonClientMemberOwnershipFormData): MemberGroupMember | undefined {
        return nonClientMembers.find(m => m.id === ownership1.memberId);
    }

    function unselectedNonClientMembers(ownership1: NonClientMemberOwnershipFormData): MemberGroupMember[] {
        return [
            ...findUnusedMembers(),
            getCurrentMember(ownership1)
        ].filter(isNotUndefined);
    }

    return <div className="non-client-individual-ownership">
        <SubSectionHeader
            buttonDisabled={nonClientMembers.length === 0 || nonClientMembers.length === formData.nonClientMemberOwnerships.length}
            buttonText="ADD FROM FAMILY TREE"
            onAddButtonClick={() => {
                const updatedFormData = {...(formData)};
                updatedFormData.nonClientMemberOwnerships = [...updatedFormData.nonClientMemberOwnerships, {
                    memberId: findUnusedMemberId(),
                    percentage: "0",
                    isRevocableTrust: false,
                }];
                onNonClientMemberOwnershipChange(updatedFormData);
            }}
            title="Non-Client Individual Ownership"
        />
        {
            formData.nonClientMemberOwnerships.length > 0 &&
            <div className="data-form-table">
                <React.Fragment>
                    <div className="data-form-table-header">OWNER</div>
                    <div className="data-form-table-header textalign-right">% OWNED</div>
                    <Placeholder/>
                </React.Fragment>
                {
                    formData.nonClientMemberOwnerships.map((ownership, index) => {
                        const totalOwnedValue = calculateTotalOwnedValue(ownership.percentage, totalAssetValue);
                        const friendlyIndex = index + 1;

                        return (
                            <React.Fragment key={index}>
                                <Dropdown
                                    value={ownership.memberId}
                                    aria-label={`Non-Client Individual Ownership Owner ${friendlyIndex}`}
                                    className="data-form-table-row no-bottom-border"
                                    onChange={(data) => {
                                        const updatedFormData = {...(formData)};
                                        updatedFormData.nonClientMemberOwnerships[index].memberId = data.value;
                                        onNonClientMemberOwnershipChange(updatedFormData);
                                    }}
                                    disabled={isProfileWithProposalsOrArchived}>
                                    {
                                        unselectedNonClientMembers(ownership).map(member => (
                                            <DropdownItem
                                                itemText={`${member.firstName} ${member.lastName}`}
                                                value={member.id}
                                                key={member.id}
                                            />
                                        ))
                                    }
                                </Dropdown>
                                <PercentInput defaultValue="0"
                                              value={ownership.percentage}
                                              label={`Non-Client Individual Ownership Percentage Owned ${friendlyIndex}`}
                                              hideLabel
                                              hideInlineLabel
                                              size="medium"
                                              className="data-form-table-row no-bottom-border"
                                              onChange={(newValue) => {
                                                  const updatedFormData = {...(formData)};
                                                  updatedFormData.nonClientMemberOwnerships[index].percentage = newValue;
                                                  onNonClientMemberOwnershipChange(updatedFormData);
                                              }}
                                              disabled={isProfileWithProposalsOrArchived}
                                />
                                <DeleteButton
                                    className="delete no-bottom-border"
                                    aria-label="Delete Non-Client Individual Ownership 1"
                                    onDelete={() => onDeleteOwnership(index)}

                                />

                                <div className="data-form-table-row with-extra-padding textalign-right grid-span-3">
                                    <span className="fontweight-medium"> Total Owned </span>
                                    <span className="marginleft-md"> {totalOwnedValue} </span>
                                </div>
                            </React.Fragment>
                        );
                    })}
            </div>
        }
    </div>;
}