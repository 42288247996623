import {AssetsSummary} from "../../../Assets/models/Assets";
import {PAGE_CONSTANTS} from "../AssetSummaryDetailReport/AssetsReportUtils";
import {assetListData, hasAssets, hasLiabilities} from "../../../Assets/AssetSummary/common/utils";
import {defaultAssetsState} from "../../../Assets/clientAssetsSlice";
import {InvestmentProgram, LegalAgreement} from "../../../Assets/models/InvestmentProgram";
import {PersonalLiabilitySummary} from "../../../Assets/models/PersonalLiability";
import {EquityCompensationFormData} from "../../../Assets/models/EquityCompensation";
import {StandaloneAccount} from "../../../Assets/models/StandaloneAccount";
import {PersonalAsset} from "../../../Assets/models/PersonalAsset";
import {GeneralInflow} from "../../../Assets/models/GeneralInflow";
import {SocialSecurity} from "../../../Assets/models/SocialSecurity";
import {LifeInsurance} from "../../../Assets/models/LifeInsurance";

type CNWAssetTypeList =
    StandaloneAccount[]
    | PersonalAsset[]
    | GeneralInflow[]
    | SocialSecurity[]
    | LegalAgreement[]
    | PersonalLiabilitySummary[]
    | LifeInsurance[]
    | EquityCompensationFormData[]

const hasAccountsForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.accounts?.data?.length > 0;
}

const hasInvestmentProgramsForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.investmentProgram && clientAssetsData.investmentProgram.legalAgreements.length > 0
}

const hasGeneralInflowsForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.generalInflows?.data?.length > 0;
}

const hasSocialSecurity = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.socialSecurities?.data?.length > 0;
}

const populateCNWAssetsList = (assetType: keyof AssetsSummary, data: CNWAssetTypeList, clientAssets: AssetsSummary): Partial<AssetsSummary> => {

    switch (assetType) {
        case 'investmentProgram':
            return {
                investmentProgram: {
                    ...clientAssets.investmentProgram,
                    legalAgreements: data as LegalAgreement[]
                } as InvestmentProgram
            }
        case 'personalLiabilities':
            return {
                personalLiabilities: data as PersonalLiabilitySummary[]
            }
        case 'equityCompensations':
            return {
                equityCompensations: {
                    ...clientAssets.equityCompensations,
                    data:data as EquityCompensationFormData[]
                }
            }
        default:
            const assetTypeData = clientAssets[assetType];
            if (typeof assetTypeData != 'number') {
                return {
                    [assetType]: {
                        ...assetTypeData,
                        data
                    }
                }
            } else {
                return {}
            }
    }
};

const getEstimatesForCNWAssets = (assetsData: AssetsSummary, pageScore: number, pageAssets: AssetsSummary, calculatedAssets: AssetsSummary[]) => {
    if (hasAssets(assetsData)) {
        const __ret = getEstimatesForCNWInvestmentsAndAccounts(assetsData, pageScore, pageAssets, calculatedAssets);
        pageScore = __ret.pageScore;
        pageAssets = __ret.pageAssets;

        if (assetsData.equityCompensations.data.length > 0) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            const result = setPageCNWAssetData('equityCompensations', pageAssets, pageScore, assetsData.equityCompensations.data, calculatedAssets, false, assetsData);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }
        if (hasGeneralInflowsForCNW(assetsData) || hasSocialSecurity(assetsData)) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;

            if (hasSocialSecurity(assetsData)) {
                const result = setPageCNWAssetData('socialSecurities', pageAssets, pageScore, assetsData.socialSecurities.data, calculatedAssets, true, assetsData);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
        }

        if (assetsData.personalAssets.data.length > 0) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            const result = setPageCNWAssetData('personalAssets', pageAssets, pageScore, assetsData.personalAssets.data, calculatedAssets, false, assetsData);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }
        if (assetsData.lifeInsurances.data.length > 0) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            const result = setPageCNWAssetData('lifeInsurances', pageAssets, pageScore, assetsData.lifeInsurances.data, calculatedAssets, false, assetsData);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }
    }
    return {pageScore, pageAssets};
}

const getEstimatesForCNWInvestmentsAndAccounts = (assetsData: AssetsSummary, pageScore: number, pageAssets: AssetsSummary, calculatedAssets: AssetsSummary[]) => {

    if (hasAccountsForCNW(assetsData) || hasInvestmentProgramsForCNW(assetsData)) {
        pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
        if (hasAccountsForCNW(assetsData)) {
            const result = setPageCNWAssetData('accounts', pageAssets, pageScore, assetsData.accounts.data, calculatedAssets, true, assetsData);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }

        if (hasInvestmentProgramsForCNW(assetsData)) {
            const result = setPageCNWAssetData('investmentProgram', pageAssets, pageScore, (assetsData.investmentProgram?.legalAgreements || []), calculatedAssets, true, assetsData);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }
    }
    return {pageScore, pageAssets};
}

const splitCNWAssets = (assetsData: AssetsSummary) => {
    const calculatedAssets: AssetsSummary[] = [];
    let pageScore = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT;
    let pageAssets: AssetsSummary = {...defaultAssetsState};

    const __ret = getEstimatesForCNWAssets(assetsData, pageScore, pageAssets, calculatedAssets);
    pageScore = __ret.pageScore;
    pageAssets = __ret.pageAssets;

    if (assetListData(assetsData).hasInEstatePersonalLiability) {
        if (hasAssets(assetsData)) {
            pageScore += PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
        }
        pageScore += PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
        pageScore += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;

        const result = setPageCNWAssetData('personalLiabilities', pageAssets, pageScore, assetListData(assetsData).inEstatePersonalLiabilities, calculatedAssets, false, assetsData);
        pageAssets = result.pageAssets;
    }

    if (pageAssets !== defaultAssetsState) {
        calculatedAssets.push(pageAssets);
    }

    let spaceLeft: number ;
    if (PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT < pageScore) spaceLeft = 2 * PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
    else spaceLeft = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
    return {assetSummariesData: calculatedAssets, remainingSpaceAfterSplittingAssets: spaceLeft};
}

const setPageCNWAssetData = (assetType: keyof AssetsSummary, pageAssets: AssetsSummary, pageScore: number, data: CNWAssetTypeList, calculatedAssetsPages: AssetsSummary[], isNestedAssetType: boolean, assetsData: AssetsSummary) => {

    const nestedAssetsHeaderLength = isNestedAssetType ? 1 : 0;
    let updatedPageScore = pageScore + (data.length + nestedAssetsHeaderLength) * PAGE_CONSTANTS.TABLE_ROW_HEIGHT;

    if (updatedPageScore < PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT) {
        pageAssets = {
            ...pageAssets,
            ...populateCNWAssetsList(assetType, data, assetsData)
        }
    } else {
        const remainingPageSpace = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
        let noOfAssetsPageCanFit = 0;
        if (remainingPageSpace > 0) {
            // If there is enough space to fit 3 assets, slice the 3 and push them to current page
            noOfAssetsPageCanFit = Math.floor(remainingPageSpace / PAGE_CONSTANTS.TABLE_ROW_HEIGHT);
            if (noOfAssetsPageCanFit > 0) {
                const assetsThatCanFitInCurrentPage = data.slice(0, noOfAssetsPageCanFit);
                pageAssets = {
                    ...pageAssets,
                    ...populateCNWAssetsList(assetType, assetsThatCanFitInCurrentPage, assetsData)
                };
            }
        }
        // Push Current Page
        calculatedAssetsPages.push(pageAssets);

        // Creating New Page
        updatedPageScore = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT + PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN;
        pageAssets = defaultAssetsState;

        // For rest of the assets, split the remaining data recursively until all assets are fit into 1 or more pages
        const remainingAssets = data.slice(noOfAssetsPageCanFit);

        if (remainingAssets.length > 0) {

            const result = setPageCNWAssetData(assetType, pageAssets, updatedPageScore, remainingAssets, calculatedAssetsPages, isNestedAssetType, assetsData);
            updatedPageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }
    }

    return {pageScore: updatedPageScore, pageAssets};
}

export {
    splitCNWAssets
}