import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import AssetRelianceFooter from "../../../ClientManagement/AssetReliance/AssetRelianceFooter";
import AssetRelianceSummaryContent from "./AssetRelianceSummaryContent";
import AssetRelianceHeader from "../../../ClientManagement/AssetReliance/AssetRelianceHeader";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {
    selectAssetReliancePageActionMenu,
    selectAssetRelianceResponse,
    setAssetReliancePageActionMenu,
    setAssetRelianceResponse
} from "../../../ClientManagement/AssetReliance/AssetRelianceSlice";
import {selectClientAssets, setClientAssets} from "../../../Assets/clientAssetsSlice";
import {resetAccordionTableState} from "../../../components/AccordionTable/AccordionTableSlice";
import {EMPTY_ASSET_RELIANCE_BUTTON_STATE} from "../../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {assetsApiClient} from "../../../Assets/AssetsApiClient";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import usePageViewTimer from "../../../hooks/usePageViewTimer";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {RouteWithId} from "../../../routes/types";
import {genericEmptyErrorModalData, GenericErrorModalData} from "../../../components/Modal/Error/GenericErrorModal";
import {emptyInvestorGroup, InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetsSummary} from "../../../Assets/models/Assets";
import PrintViewWrapper from "../PrintViewWrapper";
import {selectAssetRelianceReportResponse} from "./AssetRelianceReportSlice";

type assetRelianceSummaryReportProps = {
    isFutureValueOfExcessRequired: boolean,
    displayName: string,
    profileId: string
}

const AssetRelianceSummaryReport = ({isFutureValueOfExcessRequired,displayName,profileId}: assetRelianceSummaryReportProps) => {
    const assetResponse = useAppSelector(selectAssetRelianceReportResponse);
    const assetRelianceButtonState = useAppSelector(selectAssetReliancePageActionMenu);

    const accountHasAssets = !!assetResponse.allAssetsStack.netAssets || !!assetResponse.goals.totalPresentValue;


    return (
        <PrintViewWrapper displayName={displayName}>
        <div className="asset-reliance">
            <AssetRelianceHeader
                id={profileId}
                title="Review Asset Reliance"
                headerOptions={assetRelianceButtonState}
                investorGroup={assetResponse.investorGroupResponse}
                showPageActionMenu={false}
            />
            <>
                <AssetRelianceSummaryContent
                    goalsSummaryDTO={assetResponse.goals}
                    allAssetsStack={assetResponse.allAssetsStack}
                    assetStacks={assetResponse.assetStacks}
                    excludedAssetStacks={assetResponse.excludedAssetStacks}
                    id={profileId}
                    accountHasAssets={accountHasAssets}
                    headerOptions={assetRelianceButtonState}
                    investorGroup={assetResponse.investorGroupResponse}
                    isFutureValueOfExcessRequired={isFutureValueOfExcessRequired}
                />
            </>
            <AssetRelianceFooter/>
        </div>
        </PrintViewWrapper>
    )
}

export default AssetRelianceSummaryReport;