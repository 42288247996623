import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import {AssetsSummary} from "../models/Assets";
import InEstateCurrentNetWorth from "./InEstate";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {LegalEntityReadModel} from "../models/Ownership";
import {NO_OP} from "../../constants/common";
import AddAssetButtonDropdown from "../AssetSummary/common/AddAssetButtonDropdown";
import {EmptyStateContainer} from "../../components";
import {hasAssets, hasLiabilities} from "../AssetSummary/common/utils";
import {AssetAddEditSideDrawer} from "../common/AssetAddEditSideDrawer";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {useOutOfEstateCurrentNetWorth} from "../common/OutOfEstate/useOutOfEstateSummary";
import OutOfEstateContainer from "../common/OutOfEstate/OutOfEstateContainer";
import {DeleteModalProps} from "../common/AssetsView";

export type CurrentNetWorthProps = {
    profileId: string,
    clientAssets: AssetsSummary,
    investorGroup: InvestorGroupType,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityReadModel[],
    isLoading: boolean,
    openDrawer: boolean,
    showDeathBenefit?: boolean,
    toggleShowDeathBenefit?: () => void
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void
    onRemoveInvestmentProgram: () => void;
    onRefreshInvestmentProgramHoldings: () => void;
    onDeleteLegalEntity: (legalEntityId: string) => void;
}

const CurrentNetWorth = ({
                             profileId,
                             clientAssets,
                             investorGroup,
                             memberGroup,
                             legalEntities,
                             isLoading,
                             openDrawer,
                             showDeathBenefit = false,
                             toggleShowDeathBenefit = NO_OP,
                             onRemoveAssetClick,
                             onRemoveInvestmentProgram,
                             onRefreshInvestmentProgramHoldings,
                             onDeleteLegalEntity
                         }: CurrentNetWorthProps) => {

    const isLoadingContent = isLoading || !clientAssets || !legalEntities;
    usePageViewTimer('Current Net Worth Page Load Timer (milliseconds)', isLoadingContent);

    return (
        <section>
            {isLoadingContent
                ? <LoadingIndicator/>
                : <CurrentNetWorthContent profileId={profileId}
                                            clientAssets={clientAssets}
                                            legalEntities={legalEntities}
                                            investorGroup={investorGroup}
                                            memberGroup={memberGroup}
                                            showDeathBenefit={showDeathBenefit}
                                            toggleShowDeathBenefit={toggleShowDeathBenefit}
                                            onRemoveAssetClick={onRemoveAssetClick}
                                            onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                                            onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                                            onDeleteLegalEntity={onDeleteLegalEntity}
                />
            }
            <AssetAddEditSideDrawer
                open={openDrawer}
                profileId={profileId}
                clientAssets={clientAssets}
                investorGroup={investorGroup}
                memberGroup={memberGroup}
            />
        </section>
    )
};

interface CurrentNetWorthContentProps {
    profileId: string,
    clientAssets: AssetsSummary;
    legalEntities: LegalEntityReadModel[];
    investorGroup: InvestorGroupType;
    memberGroup: MemberGroup;
    showDeathBenefit: boolean;
    toggleShowDeathBenefit: () => void
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void
    onRemoveInvestmentProgram: () => void;
    onRefreshInvestmentProgramHoldings: () => void;
    onDeleteLegalEntity: (legalEntityId: string) => void;
}

const CurrentNetWorthContent = ({
                                    profileId,
                                    clientAssets,
                                    legalEntities,
                                    investorGroup,
                                    memberGroup,
                                    showDeathBenefit,
                                    toggleShowDeathBenefit,
                                    onRemoveAssetClick,
                                    onRemoveInvestmentProgram,
                                    onRefreshInvestmentProgramHoldings,
                                    onDeleteLegalEntity
                                }: CurrentNetWorthContentProps) => {
    const hasAssetsOrLiabilities = (assets: AssetsSummary) => hasAssets(assets) || hasLiabilities(assets);
    const {showOutOfEstateCurrentNetWorth, setShowOutOfEstateCurrentNetWorth} = useOutOfEstateCurrentNetWorth();

    return <ScrollableContainer>
        <div data-testid="current-net-worth-content">
            {hasAssetsOrLiabilities(clientAssets) ?
                <div className="asset-summary-content">
                    <InEstateCurrentNetWorth profileId={profileId}
                                             assets={clientAssets}
                                             investorGroup={investorGroup}
                                             memberGroup={memberGroup}
                                             showDeathBenefit={showDeathBenefit}
                                             toggleShowDeathBenefit={toggleShowDeathBenefit}
                                             onRemoveAssetClick={onRemoveAssetClick}
                                             onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                                             onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                    />

                    <OutOfEstateContainer showOutOfEstate={showOutOfEstateCurrentNetWorth}
                                          onClickShowOutOfEstate={setShowOutOfEstateCurrentNetWorth}
                                          assets={clientAssets}
                                          onDeleteLegalEntity={onDeleteLegalEntity}
                                          legalEntities={legalEntities}
                                          showDeathBenefit={showDeathBenefit}
                    />
                </div> :
                <EmptyStateContainer
                    className="no-assets-placeholder"
                    title="Begin by entering your assets"
                    size="large"
                    description={
                        <AddAssetButtonDropdown
                            className="add-an-asset-button add-asset-dropdown margintop-lg"
                            assets={clientAssets}
                            investorGroup={investorGroup}
                            memberGroup={memberGroup}
                            showFutureInflowButtons={false}/>
                    }
                />
            }
        </div>
    </ScrollableContainer>;
}

export default CurrentNetWorth;
