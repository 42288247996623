import React, {ReactNode} from "react";
import {formatCurrency} from "../../utils/format";
import {TableCell} from "./TableCell";
import {TableActionDropdownMenu} from "./TableActionDropdownMenu";
import {DropdownItem} from "../Dropdown";
import classNames from "classnames";
import {shortenName} from "../../Assets/common/textUtils";
import {Icon} from "../Icon";

export type GroupedTableData = {
    groupName?: string,
    groupId: string,
    groupValue?: number,
    items: GroupedTableItem[]
}

export type GroupedTableItem = {
    itemName: string,
    itemNameSubtext?: string,
    extraDetail1?: string,
    extraDetail2?: string,
    extraDetail3?: string,
    itemValue: number,
    menuOptions?: {
        itemText: string,
        value: string,
        route: string,
    }[],
}

type GroupedTableProps = {
    columnHeaders: string[],
    tableData: GroupedTableData[],
    menuClickHandler?: (route: string) => void,
    renderGroupHeader?: (group: GroupedTableData) => ReactNode,
};

export function GroupedTable({
                                 columnHeaders,
                                 tableData,
                                 menuClickHandler,
                                 renderGroupHeader = renderDefaultGroupHeader
                             }: GroupedTableProps) {
    const classesForColumnHeaderIndex = (index: number) => {
        if (index === 0) {
            const columnSpan = getFirstColumnSpan(columnHeaders.length);
            return columnSpan + ' justify-content-start';
        }
        return 'justify-content-end';
    }

    return <div role='table' className='grouped-table-grid grouped-table'>
        {columnHeaders.map((text, index) =>
            <span key={text}
                  role='columnheader'
                  className={classesForColumnHeaderIndex(index)}>{text}</span>)}
        <span aria-hidden role='columnheader'/>
        {tableData.map((group, index) =>
            <TableGroup key={index}
                        group={group}
                        tableWidth={columnHeaders.length}
                        menuClickHandler={menuClickHandler}
                        renderGroupHeader={renderGroupHeader}/>
        )}
    </div>;
}

type TableGroupProps = {
    group: GroupedTableData,
    tableWidth: number,
    menuClickHandler?: (route: string) => void,
    renderGroupHeader: (group: GroupedTableData) => ReactNode,
}

const renderDefaultGroupHeader = (group: GroupedTableData) => <GroupHeader groupName={group.groupName}
                                                                           groupValue={group.groupValue}/>;

function TableGroup({
                        group,
                        tableWidth,
                        menuClickHandler,
                        renderGroupHeader,
                    }: TableGroupProps) {
    const groupName = group.groupName;

    return <React.Fragment>
        {renderGroupHeader(group)}
        {
            group.items.map((item, index) =>
                <div className={classNames("invisible-row-wrapper", {'within-group': !!groupName})}
                     role="row"
                     key={groupName + item.itemName + index}
                     data-testid="table-group">
                    <TableCell text={item.itemName} subtext={item.itemNameSubtext} textSize="medium"
                               className={classNames("textalign-left", getFirstColumnSpan(tableWidth))}/>
                    {tableWidth > 2 && <TableCell text={item.extraDetail1} className="textalign-right"/>}
                    {tableWidth > 3 && <TableCell text={item.extraDetail2} className="textalign-right"/>}
                    {tableWidth > 4 && <TableCell text={item.extraDetail3} className="textalign-right"/>}
                    <TableCell text={formatCurrency(item.itemValue)} className='textalign-right cell-text-content'/>
                    {item.menuOptions && item.menuOptions.length ?
                        <TableActionDropdownMenu ariaLabel={`${item.itemName} Menu`} className="grouped-table-cell">
                            {item.menuOptions.map((menuOption) =>
                                <DropdownItem key={menuOption.route}
                                              itemText={menuOption.itemText}
                                              value={menuOption.value}
                                              onClick={() => menuClickHandler && menuClickHandler(menuOption.route)}/>
                            )}
                        </TableActionDropdownMenu> : <span aria-hidden/>}
                </div>
            )
        }
    </React.Fragment>;
}

function GroupHeader(props: { groupName: string | undefined, groupValue: number | undefined }) {
    if (!props.groupName) {
        return <React.Fragment/>;
    }

    return <>
        <span role="cell"
              className="grouped-table-subheader textalign-left"><b>{shortenName(props.groupName)}</b></span>
        <GroupedTablePlaceholder/>
        <GroupedTablePlaceholder/>
        <GroupedTablePlaceholder/>
        {props.groupValue !== undefined ?
            <span role="cell"
                  className={classNames("grouped-table-subheader", "group-value")}>{formatCurrency(props.groupValue)}</span> :
            <GroupedTablePlaceholder/>}
        <GroupedTablePlaceholder/>
    </>;
}

export function LegalEntityGroupHeader(props: { groupName: string | undefined, groupId: string, groupValue: number | undefined, deleteEntity: (id: string) => void }) {
    if (!props.groupName) {
        return <React.Fragment/>;
    }

    return <>
        <span role="cell"
              className="grouped-table-subheader textalign-left"><b>{shortenName(props.groupName)}</b></span>
        <GroupedTablePlaceholder/>
        <GroupedTablePlaceholder/>
        <GroupedTablePlaceholder/>
        {props.groupValue !== undefined ?
            <span role="cell"
                  className={classNames("grouped-table-subheader", "group-value")}>{formatCurrency(props.groupValue)}</span> :
            <GroupedTablePlaceholder/>}
        <span role="cell">
            <TableActionDropdownMenu ariaLabel={`${props.groupName} Menu`}
                                     disabled={false}>
                <DropdownItem
                    itemText="Delete Entity"
                    value="Delete Entity"
                    onClick={() => props.deleteEntity(props.groupId)}>
                    <Icon name="delete"
                          className="asset-table-delete-icon"/>
                </DropdownItem>
            </TableActionDropdownMenu>
        </span>
    </>;
}

const MAXIMUM_NUMBER_OF_COLUMNS_ALLOWED = 6;

function getFirstColumnSpan(tableWidth: number) {
    return 'grid-span-' + (MAXIMUM_NUMBER_OF_COLUMNS_ALLOWED - tableWidth);
}

function GroupedTablePlaceholder() {
    return <div className="grid-span-1" aria-hidden/>;
}