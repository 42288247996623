import PlanSummaryReport from "./PlanSummaryReport";
import React, {useEffect, useState} from "react";
import {clientManagementApiClient} from "../ClientManagement/ClientManagementApiClient";
import {setAssetRelianceReportResponse} from "./DetailedEmailReports/AssetRelianceReport/AssetRelianceReportSlice";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import {assetsApiClient} from "../Assets/AssetsApiClient";
import {setClientAssets} from "../Assets/clientAssetsSlice";
import {useAppDispatch} from "../store/hooks";
import {getPlanSummary} from "../Plans/PlanSummary/planSummarySlice";
import LoadingIndicator from "../pages/LoadingIndicator";
import {wealthManagementApiClient} from "../ClientManagement/WealthManagementApiClient";
import {wealthTransferApiClient} from "../WealthTransfer/WealthTransferApiClient";
import {emptyEstateSummary, EstateSummary, EstateType} from "../WealthTransfer/models/api";

const PlanSummaryWrapper = () => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const [isLoading, setIsLoading] = useState(true);
    const [estateSummary, setEstateSummary] = useState<EstateSummary>(emptyEstateSummary);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            dispatch(getPlanSummary(id))
            Promise.all([
                wealthTransferApiClient.getEstateSummary(id, EstateType.CURRENT),
                wealthManagementApiClient.getAssetReliance(id),
                clientManagementApiClient.getInvestorGroup(id),
                assetsApiClient.getAssetsSummary(id)
            ]).then(([
                         estateSummaryResponse,
                         assetRelianceResponse,
                         investorGroupResponse,
                         assetsSummaryResponse
                     ]) => {
                dispatch(setAssetRelianceReportResponse({
                    ...assetRelianceResponse,
                    investorGroupResponse: investorGroupResponse
                }));
                dispatch(setClientAssets(assetsSummaryResponse));
                setIsLoading(false);
                setEstateSummary(estateSummaryResponse)
            }).catch((error) => {
                console.error('Could not fetch asset reliance', error.message)
                setIsLoading(false);
            });
        }
    }, [id]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return <PlanSummaryReport id={id} estateSummary={estateSummary}/>
}

export default PlanSummaryWrapper;