import React from 'react';
import classNames from 'classnames';
import {NavDrawerItemGroup} from ".";
import {ReactComponentLike} from "prop-types";
import {HistoryProps, LinkProps} from "../../models/routeData/RouteParamTypes";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import PortfolioReserveStatus from "./PortfolioReserveStatus";
import {useHistory} from "react-router-dom";
import {toDisplayDateFormat} from "../../utils/dateUtils";

type NavDrawerProps = {
    className?: string,
    history?: HistoryProps,
    isOpen: boolean,
    LinkRenderer?: ReactComponentLike | string,
    primaryLinks: LinkProps[],
    secondaryLinks?: LinkProps[],
    Toolbar?: ReactComponentLike,
    sideMenuRequired: boolean
}

export const NavDrawer = ({
                              className,
                              history,
                              isOpen = false,
                              LinkRenderer = 'a',
                              primaryLinks,
                              secondaryLinks = [],
                              Toolbar,
                              sideMenuRequired
                          }: NavDrawerProps) => {

    const profileFromState = useAppSelector(selectProfile)
    const domHistory = useHistory()

    return (
        <nav className={classNames('nav-drawer', {
            'nav-drawer--open': isOpen,
            'nav-drawer--closed': !isOpen
        }, className)}>
            <div className="nav-drawer__menu" role="menu">
                <NavDrawerItemGroup
                    history={history}
                    links={primaryLinks}
                    LinkRenderer={LinkRenderer}
                    sideMenuRequired={sideMenuRequired}
                />

                {secondaryLinks.length > 0 && (
                    <>
                        <div className="nav-drawer__separator"/>
                        <NavDrawerItemGroup
                            history={history}
                            links={secondaryLinks}
                            LinkRenderer={LinkRenderer}
                            sideMenuRequired={sideMenuRequired}
                        />
                    </>
                )}
                <div className="nav-drawer__separator paddingleft-5">
                <PortfolioReserveStatus
                    isActivePortfolioReserve={profileFromState.isActivePortfolioReserve}
                    activatePRDate={toDisplayDateFormat(profileFromState.portfolioReserveActivationDate)}
                    onClickMonitorPortfolioReserve={() => domHistory.push(`/Profile/${profileFromState.id}/ClientProfile/PortfolioReserve/MonitorPortfolioReserve`)}
                />
                </div>
            </div>
            {
                Toolbar && (
                    <div className="nav-drawer__toolbar">
                        <Toolbar/>
                    </div>
                )
            }
        </nav>
    )
}
