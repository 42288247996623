import {AssetsSummary, CurrentNetWorthAsset} from "../../../Assets/models/Assets";
import {COLOR_ASSETS_ACCOUNTS} from "../../../constants/colors";
import {AccordionItemWithActionMenu} from "../../../components";
import React from "react";
import {
    calculateAllAccountsInEstateTotalMarketValue,
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator"
import {NO_OP} from "../../../constants/common";
import {TableCell} from "../../../components/Table/TableCell";
import {InvestorGroupMember, InvestorGroupType} from "src/ClientManagement/models/InvestorGroupType";
import {LegalAgreement} from "../../../Assets/models/InvestmentProgram";
import {StandaloneAccount} from "../../../Assets/models/StandaloneAccount";
import {getOwnershipDescription} from "../../../Assets/CurrentNetWorth/InEstate/ownershipUtils";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {AssetAccordionContentHeader} from "../../../Assets/AssetSummary/common/AssetAccordionContentHeader";
import {LegalAgreementRow} from "../../../Assets/AssetSummary/InEstate/LegalAgreementRow";
import {StandaloneAccountRow} from "../../../Assets/AssetSummary/InEstate/StandaloneAccountRow";
import {showOwnershipType} from "../../../Assets/Ownership/helpers";
import {assetListData} from "../../../Assets/AssetSummary/common/utils";
import {shortenName} from "../../../Assets/common/textUtils";

export interface InEstateAccountsProps {
    assetsDataForPage: AssetsSummary,
    investorGroup: InvestorGroupType,
    caption: string,
    allAssetsCNW: AssetsSummary
}


const AccountsCNWReport = ({assetsDataForPage, investorGroup, caption, allAssetsCNW }:
                      InEstateAccountsProps) => {
    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        hasInEstateAccount,
        hasInEstateInvestmentProgram,
        inEstateStandaloneAccounts,
        inEstateLegalAgreements,
        inEstatePartiallyOwnedInvestmentAccounts,
    } = assetListData(assetsDataForPage);

    const currentNetWorthStandaloneAccounts: CurrentNetWorthAsset[] = inEstateStandaloneAccounts.map(account => ({
        id: account.id,
        name: account.name,
        presentValue: account.marketValue.totalValue,
        assetType: "standaloneAccount",
        ownershipCategory: account.ownershipCategory,
        memberOwnerships: account.memberOwnerships
    }));

    const currentNetWorthLegalAgreements: CurrentNetWorthAsset[] = inEstateLegalAgreements.map(legalAgreement => ({
        id: legalAgreement.id,
        name: legalAgreement.name,
        presentValue: legalAgreement.marketValue,
        assetType: "investmentProgram",
        ownershipCategory: legalAgreement.ownershipCategory,
        memberOwnerships: legalAgreement.memberOwnerships
    }));

    const currentNetWorthPartiallyOwnedInvestmentAccounts: CurrentNetWorthAsset[] = inEstatePartiallyOwnedInvestmentAccounts.map(legalAgreement => ({
        id: legalAgreement.id,
        name: legalAgreement.name,
        presentValue: legalAgreement.marketEstateValue.totalValue,
        assetType: "partiallyOwnedInvestmentAccount",
        ownershipCategory: legalAgreement.ownershipCategory,
        memberOwnerships: legalAgreement.memberOwnerships
    }));

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;


    function generateAssetPopOverContent(account: StandaloneAccount) {
        const ownershipDescriptionCNW = getOwnershipDescription(account, primaryMember, partnerMember)
        return ownershipDescriptionCNW ? <>{ownershipDescriptionCNW}</> : undefined;
    }

    function calculateMemberOwnedAccountAndIPValue(member: InvestorGroupMember) {
        return calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthLegalAgreements)
            + calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthPartiallyOwnedInvestmentAccounts);
    }
    function calculateJointlyOwnedAccountAndIPValue() {
        return calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthStandaloneAccounts)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthLegalAgreements)
            + calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthPartiallyOwnedInvestmentAccounts);
    }

    return (
        <>
            {(hasInEstateAccount || hasInEstateInvestmentProgram) && <AccordionItemWithActionMenu
                uuid="AccountsCNWReport"
                accentColor={COLOR_ASSETS_ACCOUNTS}
                HeaderComponent={({expanded}) => {
                    const totalInEstateMarketValue = calculateAllAccountsInEstateTotalMarketValue(
                        allAssetsCNW.accounts.data,
                        assetsDataForPage.investmentProgram,
                        allAssetsCNW.partiallyOwnedInvestmentAccounts);

                    return <AssetAccordionHeader
                        caption={caption}
                        expanded={expanded}
                        title="Accounts"
                        formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.primaryMember)) : undefined}
                        formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateMemberOwnedAccountAndIPValue(investorGroup.partnerMember)) : undefined}
                        formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateJointlyOwnedAccountAndIPValue()) : undefined}
                        formattedTotalPresentValue={getFormattedTotalPresentValue(totalInEstateMarketValue)}
                        gridClassName={"current-net-worth-grid"}/>
                }
                }>

                <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table" aria-label="accounts-table">
                    <AssetAccordionContentHeader investorGroup={investorGroup}/>

                    {assetsDataForPage.investmentProgram &&
                        <>
                            {inEstateLegalAgreements.map((legalAgreement: LegalAgreement) => {
                                return <LegalAgreementRow legalAgreement={legalAgreement}
                                                          actionsDisabled={false}
                                                          onClickEdit={NO_OP}
                                                          key={legalAgreement.id}
                                                          gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                          renderLegalAgreementDetails={(agreement: LegalAgreement) => {
                                                              return <>
                                                                  <TableCell text={shortenName(agreement.name)}
                                                                             className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}/>
                                                                  {investorGroup.partnerMember && <>
                                                                      <TableCell
                                                                          text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, agreement.memberOwnerships, agreement.ownershipCategory, agreement.marketValue))}
                                                                          className="textalign-right"/>
                                                                      <TableCell
                                                                          text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, agreement.memberOwnerships, agreement.ownershipCategory, agreement.marketValue))}
                                                                          className="textalign-right"/>
                                                                      <TableCell
                                                                          text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(agreement.ownershipCategory, agreement.marketValue))}
                                                                          className="textalign-right"/>
                                                                  </>
                                                                  }
                                                              </>
                                                          }} onClickViewHoldings={NO_OP}/>;
                            })}


                        </>
                    }

                    {assetsDataForPage.accounts.data.length > 0 &&
                        <>
                            {inEstateStandaloneAccounts.map((standaloneAccount: StandaloneAccount) => {
                                return <StandaloneAccountRow standaloneAccount={standaloneAccount}
                                                             actionsDisabled={false}
                                                             onClickEdit={NO_OP}
                                                             onClickDelete={NO_OP}
                                                             onClickViewHoldings={NO_OP}
                                                             key={standaloneAccount.id}
                                                             gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                             renderStandaloneAccountDetails={(account: StandaloneAccount) => {
                                                                 return <>
                                                                     <TableCell text={account.name}
                                                                                subtext={showOwnershipType(account.ownershipCategory)}
                                                                                className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                                popoverContent={generateAssetPopOverContent(account)}
                                                                                popoverWidth={"288px"}
                                                                     />
                                                                     {investorGroup.partnerMember && <>
                                                                         <TableCell
                                                                             text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, account.memberOwnerships, account.ownershipCategory, account.marketValue.totalValue))}
                                                                             className="textalign-right"/>
                                                                         <TableCell
                                                                             text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, account.memberOwnerships, account.ownershipCategory, account.marketValue.totalValue))}
                                                                             className="textalign-right"/>
                                                                         <TableCell
                                                                             text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(account.ownershipCategory, account.marketValue.totalValue))}
                                                                             className="textalign-right"/>
                                                                     </>
                                                                     }
                                                                 </>
                                                             }}/>;
                            })}
                        </>
                    }
                </div>

            </AccordionItemWithActionMenu>}
        </>
    );
}

export default AccountsCNWReport;
