import React from "react";
import {LegalEntityFormData, LegalEntityOwnershipFormData} from "../../models/Ownership";
import {RedAsterisk} from "../../../components";
import LegalEntityOwnershipRow from "./LegalEntityOwnershipRow";
import {SubSectionHeader} from "../components/SubSectionHeader";

export type LegalEntityOwnershipTableProps = {
    legalEntityOwnerships: LegalEntityOwnershipFormData[],
    totalAssetValue?: number | null,
    onLegalEntityOwnershipChange: (legalEntityOwnerships: LegalEntityOwnershipFormData[]) => void
    legalEntities: LegalEntityFormData[]
    updateLegalEntity: (legalEntity: LegalEntityFormData) => void,
    isProfileWithProposalsOrArchived?: boolean
}

export function LegalEntityOwnershipTable({
                                              legalEntityOwnerships,
                                              onLegalEntityOwnershipChange,
                                              totalAssetValue,
                                              legalEntities,
                                              updateLegalEntity,
                                              isProfileWithProposalsOrArchived = false
                                          }: LegalEntityOwnershipTableProps) {

    const onAddOwnerClick = () => {
        const newOwnership = {
            type: undefined,
            name: "",
            percentage: "0",
            showWithinOutOfEstateReport: false
        };
        onLegalEntityOwnershipChange([...legalEntityOwnerships, newOwnership])
    };

    const selectedLegalEntityNames = legalEntityOwnerships.map(ownership => {
        return ownership.name
    });

    return <>
        <div className="legal-entity-ownership">
            <SubSectionHeader
                onAddButtonClick={onAddOwnerClick}
                title="Non-Client Ownership"
                buttonText="ADD OWNER"
            />
            <div className="legal-entity-ownership-table">
                {legalEntityOwnerships.length > 0 &&
                    <div role="row">
                        <div>TYPE<RedAsterisk/></div>
                        <div>OWNER<RedAsterisk/></div>
                        <div className="legal-entity-ownership-owned-col">% OWNED</div>
                    </div>
                }
                {legalEntityOwnerships.map((legalEntityOwnership, index) => {
                        return (
                            <LegalEntityOwnershipRow
                                key={index}
                                legalEntityOwnership={legalEntityOwnership}
                                index={index}
                                onChange={(changedIndex, updatedLegalEntityOwnership) => {
                                    const updatedLegalEntityOwnerships = [...legalEntityOwnerships];
                                    updatedLegalEntityOwnerships[changedIndex] = updatedLegalEntityOwnership;
                                    onLegalEntityOwnershipChange(updatedLegalEntityOwnerships);
                                }}
                                onDelete={(deleteIndex) => {
                                    const updatedLegalEntityOwnerships = legalEntityOwnerships.filter((_nco, i) => deleteIndex !== i);
                                    onLegalEntityOwnershipChange(updatedLegalEntityOwnerships);
                                }}
                                legalEntities={legalEntities.filter((entity) => {
                                    const isEntityUnSelected = !selectedLegalEntityNames.includes(entity.name);
                                    const isEntityAlreadySelected = legalEntityOwnership.name === entity.name;
                                    return isEntityUnSelected || isEntityAlreadySelected;
                                })}
                                onAddLegalEntity={(name) => updateLegalEntity({name})}
                                totalAssetValue={totalAssetValue}
                                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                            />
                        )
                    }
                )}
            </div>
        </div>
    </>;
}

