import React, {ChangeEvent, useState} from 'react';
import moment, {Moment} from "moment";
import {AccountDetailsType,} from 'src/Assets/models/StandaloneAccount';
import {DISPLAY_DATE_FORMAT, ISO8601_DATE_FORMAT} from "../../constants/common";
import {booleanToRadioButton, radioButtonToBoolean} from '../formHelpers';
import {RadioYesNoOptional} from '../models/Common';
import {
    DatePicker,
    Dropdown,
    DropdownItem,
    Input,
    RadioGroup,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import {TaxStatusOptions} from "../models/TaxDetails";

const taxStatusOptions = Object.freeze(["Taxable", "Deferred", "Exempt"]);
const taxStatusDropdownItems = taxStatusOptions.map(taxOption => (<DropdownItem key={taxOption}
                                                                                itemText={taxOption}
                                                                                value={taxOption}/>));

export const isFutureDate = (date: Moment) => date.isAfter(moment().endOf('day'));

type AccountDetailsProps = {
    accountDetails: AccountDetailsType,
    handleUpdateAccountDetails: (e: ChangeEvent<HTMLInputElement>) => void,
    updateAccountDetails: (accountDetails: AccountDetailsType) => void,
    handleTaxStatusChange: (value: TaxStatusOptions) => void,
    isRequiredFieldsBannerShown: boolean,
    isProfileWithProposalsOrArchived?: boolean
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
                                                           accountDetails,
                                                           handleUpdateAccountDetails,
                                                           updateAccountDetails,
                                                           handleTaxStatusChange,
                                                           isRequiredFieldsBannerShown,
                                                           isProfileWithProposalsOrArchived = false
                                                       }) => {
    const [isNameInlineErrorShown, setIsNameInlineErrorShown] = useState(false);
    const [isAsOfDateEmptyOnBlur, setIsAsOfDateEmptyOnBlur] = useState(false);

    const isAsOfDateInlineErrorShown = () => {
        const isAsOfDateEmptyOnSave = isRequiredFieldsBannerShown && !accountDetails.asOfDate?.trim();
        return isAsOfDateEmptyOnBlur || isAsOfDateEmptyOnSave;
    };

    return (
        <section className="asset-details-section">
            <UnderlinedHeader
                className="asset-details-section-header"
                primaryText="Account Details"
                rightAlignedContent={<RequiredFieldsSubheader/>}
            />
            <div className="layout-data-entry-form__field">
                <label className="nameLabel" id="nameFieldInput-label">
                    <b>Name<RedAsterisk/></b>
                </label>
                <Input
                    className="nameFieldInput"
                    name="name"
                    error={isNameInlineErrorShown ? "Name is required." : undefined}
                    aria-labelledby="nameFieldInput-label"
                    aria-label="name"
                    id="nameFieldInput"
                    onChange={handleUpdateAccountDetails}
                    onBlur={() => setIsNameInlineErrorShown(!accountDetails.name.trim())}
                    removeMarginTop
                    size="medium"
                    type="text"
                    maxLength={100}
                    value={accountDetails.name}
                    readOnly={isProfileWithProposalsOrArchived}
                />
            </div>
            <div className="layout-data-entry-form__field">
                <label className="asOfDateLabel">
                    <b>As of Date<RedAsterisk/></b>
                </label>
                <div>
                    <DatePicker
                        className="asOfDateInput"
                        id="asOfDateInput"
                        displayFormat={DISPLAY_DATE_FORMAT}
                        error={isAsOfDateInlineErrorShown() ? "As of Date is required." : ''}
                        hideKeyboardShortcutsPanel
                        isOutsideRange={isFutureDate}
                        date={accountDetails.asOfDate ? moment(accountDetails.asOfDate) : undefined}
                        onDateChange={
                            (date: Moment) => {
                                const asOfDate = date?.utc().format(ISO8601_DATE_FORMAT);
                                updateAccountDetails({
                                    ...accountDetails,
                                    asOfDate,
                                });
                                setIsAsOfDateEmptyOnBlur(!asOfDate?.trim());
                            }
                        }
                        disabled={isProfileWithProposalsOrArchived}
                    />
                </div>
            </div>
            <div className="layout-data-entry-form__field">
                <label className="accountNumberLabel" id="accountNumberInput-label">
                    <b>Account Number</b>
                </label>
                <Input
                    className="accountNumberInput"
                    name="accountNumber"
                    aria-labelledby="accountNumberInput-label"
                    id="accountNumberInput"
                    onChange={handleUpdateAccountDetails}
                    removeMarginTop
                    size="medium"
                    type="text"
                    maxLength={100}
                    value={accountDetails.accountNumber}
                    readOnly={isProfileWithProposalsOrArchived}
                />
            </div>
            <div className="layout-data-entry-form__field">
                <label className="entityTypeLabel" id="entityTypeInput-label">
                    <b>Entity Type</b>
                </label>
                <Input
                    className="entityTypeInput"
                    name="entityType"
                    aria-labelledby="entityTypeInput-label"
                    id="entityTypeInput"
                    onChange={handleUpdateAccountDetails}
                    removeMarginTop
                    size="medium"
                    type="text"
                    maxLength={100}
                    value={accountDetails.entityType}
                    readOnly={isProfileWithProposalsOrArchived}
                />
            </div>
            <div className="layout-data-entry-form__field">
                <label className="institutionLabel" id="institutionInput-label">
                    <b>Institution</b>
                </label>
                <Input
                    className="institutionInput"
                    name="institution"
                    aria-labelledby="institutionInput-label"
                    id="institutionInput"
                    onChange={handleUpdateAccountDetails}
                    removeMarginTop
                    size="medium"
                    type="text"
                    maxLength={100}
                    value={accountDetails.institution}
                    readOnly={isProfileWithProposalsOrArchived}
                />
            </div>
            <div className="layout-data-entry-form__field">
                <label id="taxStatus">
                    <b>Tax Status</b>
                </label>
                <Dropdown
                    className="taxStatusField"
                    name="taxStatus"
                    id="taxStatusInput"
                    aria-labelledby="taxStatus"
                    size="medium"
                    onChange={(data: any) => handleTaxStatusChange(data?.value as TaxStatusOptions)}
                    value={accountDetails.taxStatus}
                    disabled={isProfileWithProposalsOrArchived}
                >
                    {taxStatusDropdownItems}
                </Dropdown>
            </div>
            {(accountDetails.taxStatus === "Deferred" || accountDetails.taxStatus === "Exempt") &&
                <RadioGroup
                    id="doesPermitBeneficiary"
                    name="doesPermitBeneficiary"
                    label="Does this account permit a beneficiary?"
                    layout="horizontal"
                    values={["Yes", "No"]}
                    disabled={isProfileWithProposalsOrArchived}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const {value} = e.target;
                        updateAccountDetails({
                            ...accountDetails,
                            doesPermitBeneficiary: radioButtonToBoolean(value as RadioYesNoOptional),
                        })
                    }}
                    selected={booleanToRadioButton(accountDetails.doesPermitBeneficiary)}
                />
            }
        </section>
    )
};

export default AccountDetails;
