import {InvestorGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {Asset} from "../../models/Asset";

const getRevTrustMemberOwnership = (asset: Asset, memberGroupMember?: InvestorGroupMember) =>
    asset.memberOwnerships.find(ownership => !!memberGroupMember &&
        ownership.member.id === memberGroupMember.id &&
        ownership.isRevocableTrust);


export const getOwnershipDescription = (asset: Asset,
                                        primaryMember: InvestorGroupMember,
                                        partnerMember?: InvestorGroupMember) => {
    const primaryRevTrustMemberOwnership = getRevTrustMemberOwnership(asset, primaryMember);
    const partnerRevTrustMemberOwnership = getRevTrustMemberOwnership(asset, partnerMember);

    let messagePrefix = "";
    if (asset.ownershipCategory === "TIC (Tenants in Common)") {
        messagePrefix = "Tenants in Common titled in ";
    } else if (asset.ownershipCategory === "Community Property") {
        messagePrefix = "Community Property titled in ";
    }

    if (primaryRevTrustMemberOwnership && partnerRevTrustMemberOwnership) {
        return `${messagePrefix}${primaryMember.firstName}'s Revocable Trust and ${partnerMember!.firstName}'s Revocable Trust`;
    } else if (primaryRevTrustMemberOwnership) {
        return `${messagePrefix}${primaryMember.firstName}'s Revocable Trust`;
    } else if (partnerRevTrustMemberOwnership) {
        return `${messagePrefix}${partnerMember!.firstName}'s Revocable Trust`;
    }
}