import React from "react";
import {Icon} from "../Icon";
import classNames from "classnames";

type AccordionItemListHeaderProps = {
    firstColumnTitle: string,
    secondColumnTitle?: string,
    lastColumnTitle: string,
    className?: string,
    testId?: string
}

export default function AccordionItemListHeader({
                                    firstColumnTitle,
                                    secondColumnTitle,
                                    lastColumnTitle,
                                    className,
                                    testId
                                }: AccordionItemListHeaderProps) {
    return <div className={classNames(className, 'paddingleft-12', 'margintop-xl')} data-testid={testId}>
        <span className="display-flex align-items-center paddingleft-md marginbottom-md grid-span-3">
            <Icon name="chevron_double_right"/>
            <span className="condensed-subtitle paddingleft-md">{firstColumnTitle}</span>
        </span>
        <span role="cell" className="condensed-subtitle textalign-right">{secondColumnTitle}</span>
        <span role="cell" className="condensed-subtitle textalign-right">{lastColumnTitle}</span>
    </div>;
}
