import React, {FC} from "react";
import {clamp} from "../../utils/Clamp";
import classNames from "classnames";
import diagonalImage from '../images/diagonal-pattern-reverse.png';

export type StackedBarChartItemType = {
    color: string,
    total: number,
    className?: string,
    key?: string,
    label?: string,
    highlight?: number,
    inactive?: boolean,
    hideLabel?: boolean,
    showTotal?: boolean,
}

type StackedBarChartItemProps = {
    item: StackedBarChartItemType,
    onSelectItem?: (key?: string) => void,
    overallTotal?: number,
};

const StackedBarChartItem: FC<StackedBarChartItemProps> = ({
                                                               item,
                                                               onSelectItem,
                                                               overallTotal = 0,
                                                           }) => {

    return <div
        className={classNames(
            'stacked-bar-chart__item',
            {'stacked-bar-chart__item--inactive': item.inactive},
            item.className
        )}
        style={{height: `${clamp(Math.abs(item.total) / overallTotal, 0, 1) * 100}%`}}
    >
        <div
            aria-label={item.label}
            className="stacked-bar-chart__fill"
            onClick={() => onSelectItem && onSelectItem(item.key)}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    onSelectItem && onSelectItem(item.key);
                }
            }}
            role={onSelectItem ? 'button' : undefined}
            style={{backgroundColor: item.color}}
            tabIndex={onSelectItem ? -1 : undefined}
        >
            <div
                className={classNames('stacked-bar-chart__highlight', {
                    'stacked-bar-chart__highlight--hidden': !item.highlight,
                })}
                style={{
                    height: `${clamp(Math.abs(item.highlight || 0) / Math.abs(item.total), 0, 1) * 100}%`,
                    background: `url(${diagonalImage}) repeat`,
                }}
            />
        </div>
    </div>;
}

export default StackedBarChartItem;
