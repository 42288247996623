import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from "react";
import {Logo, SideDrawer} from 'xps-react';
import {Button, Name} from "../components";
import {useRelayContext} from "../ClientManagement/Meeting/Relay/types/RelayContext";
import {Meeting, MeetingParticipant, MeetingStatus} from "../ClientManagement/Meeting/Meeting";
import LoadingIndicator from "../pages/LoadingIndicator";
import {meetingPortalAuthenticatorClient} from "../ClientManagement/Meeting/MeetingPortalAuthenticatorClient";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {resetMeeting, selectActiveMeeting, setActiveMeeting} from "../ClientManagement/Meeting/meetingSlice";
import {
    MeetingBackgroundImage,
    MeetingIsStoppedImage,
    ThankYouImage,
    WelcomeImage
} from "../ClientManagement/Meeting/MeetingImages";
import ModalWrapper from "../components/Modal/ModalWrapper/ModalWrapper";
import {useLocation} from "react-router-dom";
import {v4 as generate_uuid} from "uuid";
import {SynchronizedMeetingContent} from "./SynchronizedMeetingContent";
import AudioVideoPreview from "./AudioVideoPreview";
import {base64ToString} from "../utils/base64Utils";
import {useCommunicationsContext} from "../ClientManagement/Meeting/CommunicationsContext";
import MeetingPortalAudioVideo from "./MeetingPortalAudioVideo";
import {IValueChanged} from "fluid-framework";
import {validateInputRemovingSpaces} from "../utils/stringUtils";
import {darkTheme, FluentThemeProvider, ParticipantList, ParticipantListParticipant} from '@azure/communication-react';

enum MeetingPortalView {
    LOGIN,
    AUDIO_VIDEO_PREVIEW,
    MEETING_CONTENT,
    LOADING,
}

type MeetingPortalParams = {
    id: string | null;
    code: string | null;
    firstName: string | null;
    lastName: string | null;
};

export const MeetingPortal: React.FC = () => {
    const location = useLocation();
    const {
        connectToMeetingContainerFromMeetingPortal,
        disconnectFromMeetingContainer,
        sharedObjects
    } = useRelayContext();

    const params: MeetingPortalParams = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        return {
            id: searchParams.get('id'),
            code: searchParams.get('code'),
            firstName: searchParams.get('firstName'),
            lastName: searchParams.get('lastName')
        };
    }, []);

    const communicationsContext = useCommunicationsContext();
    const [meetingId, setMeetingId] = useState<string>(params.id || '');
    const [passcode, setPasscode] = useState<string>(params.code || '');
    const [firstName, setFirstName] = useState<string>(params.firstName || '');
    const [lastName, setLastName] = useState<string>(params.lastName || '');
    const [showNameInput, setShowNameInput] = useState<boolean>(false);
    const activeMeeting = useAppSelector(selectActiveMeeting);
    const dispatch = useAppDispatch();

    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [showMeetingNotFoundModal, setShowMeetingNotFoundModal] = useState<boolean>(false);
    const [view, setView] = useState<MeetingPortalView>(MeetingPortalView.LOGIN);

    const userId = useMemo(() => generate_uuid(), []);
    const [acsToken, setAcsToken] = useState<string>('');
    const [communicationsIdentifier, setCommunicationsIdentifier] = useState<string | null>(null);
    const [showParticipantControl, setShowParticipantControl] = useState<boolean>(false);
    const [listParticipants, setListParticipants] = useState<ParticipantListParticipant[]>([]);


    const getDisplayName = () => {
        let displayName = firstName;
        if (lastName) {
            displayName = displayName + ' ' + lastName;
        }
        return displayName;
    }

    const connectToMeeting = useCallback(() => {
        if (meetingId && passcode && firstName) {
            joinMeeting(meetingId, passcode, getDisplayName()).then();
        }
    }, [meetingId, passcode, firstName, lastName]);

    useEffect(() => {
        connectToMeeting();
    }, []);

    useEffect(() => {
        const onValueChanged = (valueChanged: IValueChanged) => {
            if (!sharedObjects) {
                return;
            }

            const fluidUserToRemove = sharedObjects.removeParticipantDDS.get(valueChanged.key);
            if (userId === fluidUserToRemove) {
                leaveMeeting();
                sharedObjects.removeParticipantDDS.delete(valueChanged.key);
            }
        };

        sharedObjects?.removeParticipantDDS.on('valueChanged', onValueChanged);

        return () => {
            sharedObjects?.removeParticipantDDS.off('valueChanged', onValueChanged);
        }
    }, [sharedObjects?.removeParticipantDDS])

    useEffect(() => {
        const onValueChanged = (valueChanged: IValueChanged) => {
            if (!sharedObjects) {
                return;
            }
            setListParticipants((sharedObjects?.meetingParticipantDDS.get(valueChanged.key) || [])
                .map((participant: MeetingParticipant) => {
                        return {
                            userId: participant.userId,
                            displayName: participant.userName,
                            isRemovable: false,
                        }
                    }
                ))
        };

        sharedObjects?.meetingParticipantDDS.on('valueChanged', onValueChanged);

        return () => {
            sharedObjects?.meetingParticipantDDS.off('valueChanged', onValueChanged);
        }
    }, [sharedObjects?.meetingParticipantDDS])

    const showAudioVideoControls: boolean = useMemo(() =>
        !!acsToken && activeMeeting?.status !== MeetingStatus.ENDED,
        [acsToken, activeMeeting?.status]
    );

    const joinMeeting = async (joinMeetingId: string, joinMeetingPasscode: string, displayName: string) => {
        setView(MeetingPortalView.LOADING);
        const user = {
            id: userId,
            name: displayName,
        };
        return meetingPortalAuthenticatorClient.getMeetingDetails(joinMeetingId, joinMeetingPasscode, user)
            .then((meetingDetails) => {
                if (meetingDetails.meetingName
                    && meetingDetails.fluidToken
                    && meetingDetails.fluidContainerId) {
                    dispatch(setActiveMeeting({
                        ...activeMeeting,
                        id: 'meetingId',
                        status: meetingDetails.meetingStatus,
                        onlineMeetingJoinUrl: base64ToString(meetingDetails.onlineMeetingJoinUrl)
                    } as Meeting));

                    return connectToMeetingContainerFromMeetingPortal(
                        meetingDetails.fluidContainerId,
                        meetingDetails.fluidToken,
                        joinMeetingId,
                        joinMeetingPasscode,
                        user,
                        meetingDetails.communicationsIdentifier,
                    ).then(() => {
                        console.debug('Connected to meeting container');
                        if (meetingDetails.acsToken) {
                            setAcsToken(meetingDetails.acsToken);
                            setCommunicationsIdentifier(meetingDetails.communicationsIdentifier);
                            setView(MeetingPortalView.AUDIO_VIDEO_PREVIEW);
                        } else {
                            setView(MeetingPortalView.MEETING_CONTENT);
                        }
                    }).catch((error) => {
                        console.error('Could not connect to meeting container', error.message);
                        setShowErrorModal(true);
                        setView(MeetingPortalView.LOGIN);
                    });
                } else {
                    setShowMeetingNotFoundModal(true);
                    console.error('Could not find meeting for id', joinMeetingId);
                    setView(MeetingPortalView.LOGIN);
                }
            }).catch((error) => {
                setShowErrorModal(true);
                console.error('Could not fetch meeting details', error.message)
                setView(MeetingPortalView.LOGIN);
            });
    };

    const onClickJoinNow = async (isAudioEnabled: boolean, isCameraEnabled: boolean) => {
        if (!activeMeeting?.onlineMeetingJoinUrl) {
            console.error('Invalid onlineMeetingJoinUrl', activeMeeting?.onlineMeetingJoinUrl);
            setShowErrorModal(true);
            return;
        }

        if (!communicationsIdentifier) {
            console.error('No communicationsIdentifier available');
            setShowErrorModal(true);
            return;
        }

        const tokenRefresher = () => {
            return meetingPortalAuthenticatorClient.getMeetingDetails(
                activeMeeting.onlineMeetingId,
                activeMeeting.onlineMeetingCode,
                {
                    id: userId,
                    name: 'Guest',
                },
                communicationsIdentifier).then((response) => {
                return response.acsToken;
            });
        };

        communicationsContext.disposeVideoPreview();    // To dispose created video preview before joining call
        communicationsContext.connect({
            acsTokenDetails: {
                accessToken: acsToken,
                tokenRefresher
            },
            userId: userId,
            displayName: getDisplayName(),
            joinUrl: activeMeeting.onlineMeetingJoinUrl,
            isAudioOn: isAudioEnabled,
            isVideoOn: isCameraEnabled
        })
            .then(() => {
                setView(MeetingPortalView.MEETING_CONTENT)
            })
            .catch((error) => {
                console.error('Could not connect to meeting communications', error.message);
                setShowErrorModal(true);
                throw error;
            });
    };

    const leaveMeeting = () => {
        setView(MeetingPortalView.LOADING);
        disconnectFromMeetingContainer();
        communicationsContext.disconnect().then(() => {
            setMeetingId('');
            setPasscode('');
            setFirstName('');
            setLastName('');
            setShowNameInput(false);
            dispatch(resetMeeting());
            setView(MeetingPortalView.LOGIN);
        });
    }

    return <div className="meeting-portal">
        {
            view === MeetingPortalView.LOADING && <LoadingIndicator/>
        }
        {
            view === MeetingPortalView.LOGIN && <>
                <MeetingBackgroundImage/>
                {!showNameInput &&
                    <div className="login-container center-content">
                        <div className="nt-logo">
                            <Logo
                                alt="Single Line Anchor Logo"
                                ariaLabel="Northern Trust Logo"
                                color="black"
                                logoType="single"
                                width="300px"
                            />
                        </div>
                        <div className="login-form">
                            <h2 className="login-instructions">Please enter the Meeting ID and Passcode to join your
                                meeting.</h2>
                            <Name
                                name={meetingId}
                                className="meeting-id-input"
                                label={'Meeting ID'}
                                aria-label={'meetingId'}
                                aria-labelledby={'meetingId'}
                                required={true}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const re = /^[0-9\b]+$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        setMeetingId(e.target.value);
                                    }
                                }}
                            />
                            <Name
                                name={passcode}
                                className="meeting-passcode-input"
                                label={'Meeting Passcode'}
                                aria-label={'meetingPasscode'}
                                aria-labelledby={'meetingPasscode'}
                                required={true}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    const re = /^[a-zA-Z0-9]*$/;
                                    if (e.target.value === '' || re.test(e.target.value)) {
                                        setPasscode(e.target.value);
                                    }
                                }}
                            />
                            <Button className="join-meeting-button"
                                    icon="none"
                                    includeRef={false}
                                    kind="primary"
                                    size="medium"
                                    tabIndex={0}
                                    type="button"
                                    onClick={() => {
                                        if (meetingId && passcode) {
                                            setShowNameInput(true);
                                        }
                                    }}>Next</Button>
                        </div>
                    </div>
                }
                {showNameInput &&
                    <div className="login-container center-content">
                        <div className="nt-logo">
                            <Logo
                                alt="Single Line Anchor Logo"
                                ariaLabel="Northern Trust Logo"
                                color="black"
                                logoType="single"
                                width="300px"
                            />
                        </div>
                        <div className="login-form">
                            <h2 className="login-instructions">Please enter your first and last name.</h2>
                            <Name
                                name={firstName}
                                className="first-name-input"
                                label={'First Name'}
                                aria-label={'firstName'}
                                aria-labelledby={'firstName'}
                                required={true}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFirstName(validateInputRemovingSpaces(e.target.value) || '');
                                }}
                            />
                            <Name
                                name={lastName}
                                className="last-name-input"
                                label={'Last Name'}
                                aria-label={'lastName'}
                                aria-labelledby={'lastName'}
                                required={true}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setLastName(validateInputRemovingSpaces(e.target.value) || '');
                                }}
                            />
                            <Button className="join-meeting-button"
                                    icon="none"
                                    includeRef={false}
                                    kind="primary"
                                    size="medium"
                                    tabIndex={0}
                                    type="button"
                                    onClick={() => {
                                        if (firstName && lastName) {
                                            connectToMeeting();
                                        }
                                    }}>Join Your Meeting</Button>
                        </div>
                    </div>
                }
            </>
        }
        {
            view === MeetingPortalView.AUDIO_VIDEO_PREVIEW &&
            <AudioVideoPreview
                showNTLogo={true}
                onJoinNow={onClickJoinNow}
                setMeetingPortalView={() => {
                    setView(MeetingPortalView.LOGIN);
                    setShowNameInput(false);
                }}
            />
        }
        {
            view === MeetingPortalView.MEETING_CONTENT &&
            <div className='meeting-portal-meeting-content'>
                <div className='meeting-portal-visual-content'>
                    {showAudioVideoControls && <MeetingPortalAudioVideo displayName={firstName + " " + lastName}/>}
                    {
                        activeMeeting?.status === MeetingStatus.CREATED &&
                        <div className="meeting-portal-image-container"><WelcomeImage/></div>
                    }
                    {
                        activeMeeting?.status === MeetingStatus.ENDED &&
                        <div className="meeting-portal-image-container"><ThankYouImage/></div>
                    }
                    {
                        activeMeeting?.status === MeetingStatus.STOPPED &&
                        <div className="meeting-portal-image-container"><MeetingIsStoppedImage/></div>
                    }

                    {
                        activeMeeting?.status !== MeetingStatus.CREATED &&
                        activeMeeting?.status !== MeetingStatus.ENDED &&
                        activeMeeting?.status !== MeetingStatus.STOPPED &&
                        <SynchronizedMeetingContent/>
                    }
                </div>
            </div>
        }
        {/* Error Modals */}
        {
            showErrorModal && <ModalWrapper
                id="join-meeting-error"
                isOpen={showErrorModal}
                headerText={"Error"}
                alertIconType={'error'}
                alertIcon={'warning'}
                buttons={[
                    {
                        text: "Close",
                        onClick: () => {
                            setShowErrorModal(false);
                            setShowNameInput(false);
                        }
                    }
                ]}>
                <div className="font-md">
                    {"Error occurred while joining the meeting"}
                </div>
            </ModalWrapper>
        }
        {
            showMeetingNotFoundModal && <ModalWrapper
                id="meeting-not-found"
                isOpen={showMeetingNotFoundModal}
                headerText={"Meeting Not Found"}
                alertIconType={'warning'}
                alertIcon={'warning'}
                buttons={[
                    {
                        text: "Close",
                        onClick: () => {
                            setShowMeetingNotFoundModal(false);
                            setShowNameInput(false);
                        }
                    }
                ]}>
                <div className="font-md">
                    {"No matching meeting found for given Meeting Id and Passcode combination"}
                </div>
            </ModalWrapper>
        }
        <SideDrawer
            id="participants-side-drawer"
            aria-label="View Participants"
            className="meeting-participants"
            direction="right"
            size="small"
            noPadding={false}
            isOpen={showParticipantControl}>
            <div className="meeting-participant-list">
                <h3>Participants</h3>
                <Button
                    icon="only"
                    iconName="close"
                    kind="borderless"
                    size="large"
                    onClick={() => setShowParticipantControl(false)}
                ></Button>
            </div>
            <FluentThemeProvider fluentTheme={{...darkTheme, semanticColors: {bodyBackground: '#000000'}}}>
                <ParticipantList participants={listParticipants}/>
            </FluentThemeProvider>
        </SideDrawer>
    </div>;
}