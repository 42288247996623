import React from "react";
import {StackedBarChart} from "../StackedBarChart";

export type StackedBarChartData = {
    className: string;
    label: string;
    color: string;
    total: number;
    key?: string;
}

interface BarChartSidebarProps {
    displayName: string;
    title: string;
    noDataText: string;
    children: React.ReactNode;
    data: StackedBarChartData[];
    subtitle?: string;
}

const BarChartSidebar = (props: BarChartSidebarProps) => {
    return (
        <section data-theme="aqua" data-testid="data-summary-sidebar">
            <div className="sidebar">
                <header className="marginbottom-xxxl">
                    <b className="eyebrow-label">{props.displayName}</b>
                    <h1 className="margintop-sm">{props.title}</h1>
                    {props.subtitle && <span className="bar-chart-subtitle">{props.subtitle}</span>}
                </header>
                <StackedBarChart
                    noDataText={props.noDataText}
                    data={props.data}
                    enforceMinimumSize={true}
                />
                <footer>
                    {props.children}
                </footer>
            </div>
        </section>
    )
}

export default BarChartSidebar;

