import React from "react";
import {Dropdown, DropdownItem} from "./index";
import useProfileEditableState from "../../hooks/useProfileEditableState";

export type EditableDropdownProps = {
    defaultText ?:string
    label: string,
    value: string,
    error?: string,
    items: string[],
    onAdd: (newValue: string) => void
    onChange: (newValue: string) => void,
    onBlur?: () => void,
    isProfileWithProposalsOrArchived?: boolean
};

export function EditableDropdown({
                                     defaultText,
                                     label,
                                     value,
                                     error,
                                     items,
                                     onAdd,
                                     onChange,
                                     onBlur,
                                     isProfileWithProposalsOrArchived = false
                                 }: EditableDropdownProps) {

    return <Dropdown
        defaultText={defaultText}
        aria-label={label}
        searchable
        id={label}
        value={value}
        error={error}
        onChange={(data) => data.value && onChange(data.value)}
        onBlur={onBlur}
        onKeyPress={(data: any) => {
            const trimmedValue = data.target.value.trim();
            if (data.key === 'Enter' && trimmedValue) {
                onAdd(trimmedValue);
                onChange(trimmedValue);
            }
        }}
        disabled={isProfileWithProposalsOrArchived}
    >
        {items.map(v => getDropdownItem(v))}
    </Dropdown>

}

function getDropdownItem(value: string) {
    return <DropdownItem itemText={value} value={value} key={value}/>
}
