import React from "react";
import SetPortfolioReserveHeader from "./SetPortfolioReserveHeader";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";

type SetPortfolioReserveProps = {
    errorModelData?: GenericErrorModalData
}


const SetPortfolioReserve = ({errorModelData}: SetPortfolioReserveProps) => {
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const handleErrorCloseButton = () => {
        errorModelData!.isOpen = false;
        setGenericError({...genericError, isOpen: false});
    }

    return (
        <div className="set-portfolio-reserve-page">
            <div>
                {errorModelData?.isOpen ?
                    <GenericErrorModal
                        errorModalData={errorModelData}
                        onClickButton={() => handleErrorCloseButton()}
                        buttonText={'Close'}/> : <React.Fragment/>
                }
            </div>
            <ScrollableContainer className="display-flex flex-column">
                <SetPortfolioReserveHeader displaySetReserveTargetButton={true} isSetReserve={true}/>
                <div className="set-portfolio-reserve-page__body">
                    <div className="set-portfolio-reserve-page__body__chart">
                        <img className="set-portfolio-reserve-page__body__chart__image"
                             src="/DefaultAssetAllocationChart.svg" style={{paddingLeft: "60px"}}/>
                    </div>
                    <div className="set-portfolio-reserve-page__body__customized-allocation">
                        <img className="set-portfolio-reserve-page__body__customized-allocation__image"
                             src="/CustomizedAllocationBreakdown.svg"/>
                    </div>
                </div>
            </ScrollableContainer>
        </div>
    );
};

export default SetPortfolioReserve;

