import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import {AlertBanner} from "../../../components";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {wealthManagementApiClient} from "../../WealthManagementApiClient";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "src/routes/types";
import ReviseAssetClassTable from "./ReviseAssetClassTable";
import {COLOR_RISK_ASSETS, COLOR_RISK_CONTROL_ASSETS} from "src/constants/colors";
import {
    AlertMessageForRevisedAllocation,
    AllocateRemainingAssetsProRataRequest,
    AssetClassification,
    DataDisplayView,
    EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE,
    ExcludeSubclassRequest,
    ProposedAllocationEntry,
    ReviseAssetAllocationResponse,
    RevisedProposedAllocation
} from "../ReviseAssetAllocation";
import DiscardModal from "../../../components/DiscardModal/DiscardModal";
import {
    formatAllocationPercentage,
    isDollarView,
    resetAssetClassification, sortAssetClassHierarchy,
    updateAssetClassificationForProposedEntry
} from "./reviseAssetAllocationUtils";
import deepEquals from "fast-deep-equal";
import ReviseAllocationPageMenu from "./ReviseAllocationPageMenu";
import {HistoryBlockModal} from "../../../components/HistoryBlockModal/HistoryBlockModal";
import {
    formatCurrencyRoundedToTwoDecimals,
    formatNumberRoundedToTwoDecimals,
    formatNumberRoundedToWholeNumber
} from "../../../utils/format";
import usePageViewTimer from "../../../hooks/usePageViewTimer";
import {toDisplayDateFormat} from "../../../utils/dateUtils";
import CustomModal from "../../../components/Modal/Custom/CustomModal";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../ClientProfile/activeProfileSlice";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

const ReviseAssetAllocation: React.FC = () => {
    const {id} = useParams<RouteWithId>();
    const history = useHistory();

    const [reviseAssetAllocation, setReviseAssetAllocation] =
        useState<ReviseAssetAllocationResponse>(EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE);
    const [initialReviseAssetAllocation, setInitialReviseAssetAllocation] =
        useState<ReviseAssetAllocationResponse>(EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE);
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [alertMessages, setAlertMessages] = useState<AlertMessageForRevisedAllocation[]>([]);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [showAutoAdjustModal, setShowAutoAdjustModal] = useState<boolean>(false);
    const [showIPCRange, setShowIPCRange] = useState<boolean>(true);
    const [showRiskAssetsNotAllocatedWarning, setShowRiskAssetsNotAllocatedWarning] = useState<boolean>(false);
    const [showRiskControlNotAllocatedWarning, setShowRiskControlNotAllocatedWarning] = useState<boolean>(false);
    const [displayView, setDisplayView] = useState<DataDisplayView>(DataDisplayView.PERCENTAGE_VIEW);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [isProposedAllocationValuesLoadedFirstTime, setIsProposedAllocationValuesLoadedFirstTime] = useState<boolean>(true);
    const profileState = useAppSelector(selectProfile);
    const [averageTargetMaturity, setAverageTargetMaturity] = useState<number|null>(null);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    usePageViewTimer('Revise Asset Allocation Page Load Timer (milliseconds)', !pageLoaded);

    const totalRemainingAllocationForRiskAssets = alertMessages.find(alertMessage => alertMessage.assetClassification === "Risk Asset");
    const totalRemainingAllocationForRiskControl = alertMessages.find(alertMessage => alertMessage.assetClassification === "Risk Control Asset");

    const areRiskAssetsDisabled = (initialReviseAssetAllocation.totalProposedRiskAssetsAmount === 0);
    const areRiskControlAssetsDisabled = (initialReviseAssetAllocation.totalProposedRiskControlAssetsAmount === 0);

    useEffect(() => {
        (async () => {
            if (id) {
                setPageLoaded(false);
                const reviseAssetAllocationResponse = await wealthManagementApiClient.getReviseAssetAllocation(id);
                setPageLoaded(true);
                setReviseAssetAllocation(reviseAssetAllocationResponse);
                setInitialReviseAssetAllocation(reviseAssetAllocationResponse);
            }
        })();
    }, [id]);

    useEffect(() => {
        if (profileState.id) {
            wealthManagementApiClient.getAverageTargetLength(profileState.id, profileState.portfolioReserveTargetLength)
                .then((res)=> setAverageTargetMaturity(res));
        }
    }, [profileState.id]);

    useEffect(() => {
        validateEntries(reviseAssetAllocation);
        if (showRiskAssetsNotAllocatedWarning) {
            if (areRiskAssetsCompletelyFilled(reviseAssetAllocation).riskAssetsCompletelyFilled) {
                setShowRiskAssetsNotAllocatedWarning(false);
            }
        }
        if (showRiskControlNotAllocatedWarning) {
            if (areRiskControlCompletelyFilled(reviseAssetAllocation).riskControlCompletelyFilled) {
                setShowRiskControlNotAllocatedWarning(false);
            }
        }
        if (areRiskAssetsCompletelyFilled(reviseAssetAllocation).riskAssetsCompletelyFilled && areRiskControlCompletelyFilled(reviseAssetAllocation).riskControlCompletelyFilled) {
            setShowWarning(false);
        }
    }, [reviseAssetAllocation]);

    function generateAlertMessage(assetClassification: 'Risk Asset' | 'Risk Control Asset', initialTotalProposedPercent: number, totalProposedPercent: number, initialTotalProposedAmount: number, totalProposedAmount: number): AlertMessageForRevisedAllocation {
        return {
            assetClassification,
            totalProposedAllocationPercent: totalProposedPercent,
            differenceInTotalProposedAllocationPercent: initialTotalProposedPercent - totalProposedPercent,
            totalProposedAllocationAmount: totalProposedAmount,
            differenceInTotalProposedAllocationAmount: initialTotalProposedAmount - totalProposedAmount
        }
    }

    const areRiskAssetsCompletelyFilled = useCallback((updatedReviseAssetAllocation: ReviseAssetAllocationResponse) => {
        const riskAssetsPercentagesAreCompletelyFilled = parseFloat(formatNumberRoundedToTwoDecimals(updatedReviseAssetAllocation.totalProposedRiskAssetsPercent)) === 100;
        const riskAssetsAmountsAreCompletelyFilled =   formatNumberRoundedToWholeNumber(updatedReviseAssetAllocation.totalProposedRiskAssetsAmount) === formatNumberRoundedToWholeNumber(initialReviseAssetAllocation.totalProposedRiskAssetsAmount)
        return {
            riskAssetsCompletelyFilled: riskAssetsPercentagesAreCompletelyFilled && riskAssetsAmountsAreCompletelyFilled,
            autoAdjustForRiskAssets: riskAssetsPercentagesAreCompletelyFilled && !riskAssetsAmountsAreCompletelyFilled
        };
    }, [initialReviseAssetAllocation]);

    const areRiskControlCompletelyFilled = useCallback((updatedReviseAssetAllocation: ReviseAssetAllocationResponse) => {
        const riskControlAssetsPercentagesAreCompletelyFilled =  parseFloat(formatNumberRoundedToTwoDecimals(updatedReviseAssetAllocation.totalProposedRiskControlAssetsPercent)) === 100;
        const riskControlAssetsAmountsAreCompletelyFilled = formatNumberRoundedToWholeNumber(updatedReviseAssetAllocation.totalProposedRiskControlAssetsAmount) === formatNumberRoundedToWholeNumber(initialReviseAssetAllocation.totalProposedRiskControlAssetsAmount)
        return {
            riskControlCompletelyFilled: riskControlAssetsPercentagesAreCompletelyFilled && riskControlAssetsAmountsAreCompletelyFilled,
            autoAdjustRiskControl: riskControlAssetsPercentagesAreCompletelyFilled && !riskControlAssetsAmountsAreCompletelyFilled
        };
    }, [initialReviseAssetAllocation]);

    const validateEntries = (updatedReviseAssetAllocation: ReviseAssetAllocationResponse) => {
        const updatedAlertMessages: AlertMessageForRevisedAllocation[] = [];
        const {
            riskAssetsCompletelyFilled,
            autoAdjustForRiskAssets
        } = areRiskAssetsCompletelyFilled(updatedReviseAssetAllocation);

        const {
            riskControlCompletelyFilled,
            autoAdjustRiskControl
        } = areRiskControlCompletelyFilled(updatedReviseAssetAllocation);

        const isRiskAssetsTotalValid = riskAssetsCompletelyFilled || areRiskAssetsDisabled;
        const isRiskControlAssetsTotalValid = riskControlCompletelyFilled || areRiskControlAssetsDisabled;

        if (!isRiskAssetsTotalValid) {
            updatedAlertMessages.push(generateAlertMessage(
                'Risk Asset',
                initialReviseAssetAllocation.totalProposedRiskAssetsPercent,
                reviseAssetAllocation.totalProposedRiskAssetsPercent,
                initialReviseAssetAllocation.totalProposedRiskAssetsAmount,
                reviseAssetAllocation.totalProposedRiskAssetsAmount
            ));
        }
        if (!isRiskControlAssetsTotalValid) {
            updatedAlertMessages.push(generateAlertMessage(
                'Risk Control Asset',
                initialReviseAssetAllocation.totalProposedRiskControlAssetsPercent,
                reviseAssetAllocation.totalProposedRiskControlAssetsPercent,
                initialReviseAssetAllocation.totalProposedRiskControlAssetsAmount,
                reviseAssetAllocation.totalProposedRiskControlAssetsAmount
            ));
        }

        setAlertMessages(updatedAlertMessages);

        return {
            isValid: isRiskAssetsTotalValid && isRiskControlAssetsTotalValid,
            openAutoAdjustModal: autoAdjustForRiskAssets || autoAdjustRiskControl
        };
    }

    const updateRiskAssetsProposedAllocationEntry = (proposedAllocationEntry: ProposedAllocationEntry) => {
        const {
            updatedAssetClassificationList,
            updatedTotalProposedPercent,
            updatedTotalProposedAmount,
            updatedTotalDifferenceAmount,
            updatedTotalProposedPercentOfTotalPortfolio
        } = updateAssetClassificationForProposedEntry({
            assetClassificationList: reviseAssetAllocation.riskAssets,
            proposedAllocationEntry,
            previousTotalProposedPercent: reviseAssetAllocation.totalProposedRiskAssetsPercent,
            previousTotalProposedPercentOfTotalPortfolio: reviseAssetAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio,
            initialTotalProposedAmount: initialReviseAssetAllocation.totalProposedRiskAssetsAmount,
            initialTotalProposedPercentOfTotalPortfolio: initialReviseAssetAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio,
            previousTotalProposedAmount: reviseAssetAllocation.totalProposedRiskAssetsAmount,
            previousTotalDifferenceAmount: reviseAssetAllocation.totalDifferenceAmountRiskAssets,
            displayView,
            excludeSubclass: proposedAllocationEntry.excludeSubclass
        });

        setReviseAssetAllocation({
            ...reviseAssetAllocation,
            riskAssets: updatedAssetClassificationList,
            totalProposedRiskAssetsPercentOfTotalPortfolio: updatedTotalProposedPercentOfTotalPortfolio,
            totalProposedRiskAssetsPercent: updatedTotalProposedPercent,
            totalProposedRiskAssetsAmount: updatedTotalProposedAmount,
            totalDifferenceAmountRiskAssets: updatedTotalDifferenceAmount
        });
    }

    const updateRiskControlAssetsProposedAllocationEntry = (proposedAllocationEntry: ProposedAllocationEntry) => {
        const {
            updatedAssetClassificationList,
            updatedTotalProposedPercent,
            updatedTotalProposedAmount,
            updatedTotalDifferenceAmount,
            updatedTotalProposedPercentOfTotalPortfolio
        } = updateAssetClassificationForProposedEntry({
            assetClassificationList: reviseAssetAllocation.riskControlAssets,
            proposedAllocationEntry,
            previousTotalProposedPercent: reviseAssetAllocation.totalProposedRiskControlAssetsPercent,
            previousTotalProposedPercentOfTotalPortfolio: reviseAssetAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio,
            initialTotalProposedAmount: initialReviseAssetAllocation.totalProposedRiskControlAssetsAmount,
            initialTotalProposedPercentOfTotalPortfolio: initialReviseAssetAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio,
            previousTotalProposedAmount: reviseAssetAllocation.totalProposedRiskControlAssetsAmount,
            previousTotalDifferenceAmount: reviseAssetAllocation.totalDifferenceAmountRiskControlAssets,
            displayView,
            excludeSubclass: proposedAllocationEntry.excludeSubclass
        });

        setReviseAssetAllocation({
            ...reviseAssetAllocation,
            riskControlAssets: updatedAssetClassificationList,
            totalProposedRiskControlAssetsPercentOfTotalPortfolio: updatedTotalProposedPercentOfTotalPortfolio,
            totalProposedRiskControlAssetsPercent: updatedTotalProposedPercent,
            totalProposedRiskControlAssetsAmount: updatedTotalProposedAmount,
            totalDifferenceAmountRiskControlAssets: updatedTotalDifferenceAmount
        });
    }

    const resetRiskAssets = () => {
        if (areRiskAssetsDisabled) {
            return;
        }

        const {
            updatedAssetClassificationList,
            updatedTotalProposedPercentOfTotalPortfolio,
            updatedTotalDifferenceAmount,
            updatedTotalProposedAmount,
            updatedTotalProposedPercent
        } = resetAssetClassification(
            reviseAssetAllocation.riskAssets,
            initialReviseAssetAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio,
            reviseAssetAllocation.totalProposedRiskAssetsPercent,
            reviseAssetAllocation.totalRecommendedProposedRiskAssetsPercent
        );

        const updatedReviseAssetAllocation = {
            ...reviseAssetAllocation,
            riskAssets: updatedAssetClassificationList,
            totalProposedRiskAssetsPercent: updatedTotalProposedPercent,
            totalProposedRiskAssetsAmount: updatedTotalProposedAmount,
            totalProposedRiskAssetsPercentOfTotalPortfolio: updatedTotalProposedPercentOfTotalPortfolio,
            totalDifferenceAmountRiskAssets: updatedTotalDifferenceAmount,
        };

        if (updatedReviseAssetAllocation.riskAssets.some(assetSubclassSummary => assetSubclassSummary.lockedAmount)) {
            const allocateRemainingAssetsProRataRequest: AllocateRemainingAssetsProRataRequest = {
                revisedAssetAllocation: updatedReviseAssetAllocation,
                assetClassification: AssetClassification.RISK_ASSET
            }

            wealthManagementApiClient.allocateRemainingAssetsProRata(id, allocateRemainingAssetsProRataRequest)
                .then(setReviseAssetAllocation);
        } else {
            setReviseAssetAllocation(updatedReviseAssetAllocation);
        }
        setIsProposedAllocationValuesLoadedFirstTime(true);
    }

    const resetRiskControlAssets = () => {
        if (areRiskControlAssetsDisabled) {
            return;
        }

        const {
            updatedAssetClassificationList,
            updatedTotalProposedPercentOfTotalPortfolio,
            updatedTotalDifferenceAmount,
            updatedTotalProposedAmount,
            updatedTotalProposedPercent
        } = resetAssetClassification(
            reviseAssetAllocation.riskControlAssets,
            initialReviseAssetAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio,
            reviseAssetAllocation.totalProposedRiskControlAssetsPercent,
            reviseAssetAllocation.totalRecommendedProposedRiskControlAssetsPercent
        );

        const updatedReviseAssetAllocation = {
            ...reviseAssetAllocation,
            riskControlAssets: updatedAssetClassificationList,
            totalProposedRiskControlAssetsPercent: updatedTotalProposedPercent,
            totalProposedRiskControlAssetsAmount: updatedTotalProposedAmount,
            totalProposedRiskControlAssetsPercentOfTotalPortfolio: updatedTotalProposedPercentOfTotalPortfolio,
            totalDifferenceAmountRiskControlAssets: updatedTotalDifferenceAmount
        };

        if (updatedReviseAssetAllocation.riskControlAssets.some(assetSubclassSummary => assetSubclassSummary.lockedAmount)) {
            const allocateRemainingAssetsProRataRequest: AllocateRemainingAssetsProRataRequest = {
                revisedAssetAllocation: updatedReviseAssetAllocation,
                assetClassification: AssetClassification.RISK_CONTROL
            }

            wealthManagementApiClient.allocateRemainingAssetsProRata(id, allocateRemainingAssetsProRataRequest)
                .then(setReviseAssetAllocation);
        } else {
            setReviseAssetAllocation(updatedReviseAssetAllocation);
        }
        setIsProposedAllocationValuesLoadedFirstTime(true);
    }

    const resetAll = () => {
        let updatedReviseAssetAllocation = {
            ...reviseAssetAllocation
        }

        if (!areRiskAssetsDisabled) {
            const updatedRiskAssetsValues = resetAssetClassification(
                reviseAssetAllocation.riskAssets,
                initialReviseAssetAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio,
                reviseAssetAllocation.totalProposedRiskAssetsPercent,
                reviseAssetAllocation.totalRecommendedProposedRiskAssetsPercent
            );
            updatedReviseAssetAllocation = {
                ...updatedReviseAssetAllocation,
                riskAssets: updatedRiskAssetsValues.updatedAssetClassificationList,
                totalProposedRiskAssetsPercent: updatedRiskAssetsValues.updatedTotalProposedPercent,
                totalProposedRiskAssetsAmount: updatedRiskAssetsValues.updatedTotalProposedAmount,
                totalProposedRiskAssetsPercentOfTotalPortfolio: updatedRiskAssetsValues.updatedTotalProposedPercentOfTotalPortfolio,
                totalDifferenceAmountRiskAssets: updatedRiskAssetsValues.updatedTotalDifferenceAmount
            }
        }

        if (!areRiskControlAssetsDisabled) {
            const updatedRiskControlAssetsValues = resetAssetClassification(
                reviseAssetAllocation.riskControlAssets,
                initialReviseAssetAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio,
                reviseAssetAllocation.totalProposedRiskControlAssetsPercent,
                reviseAssetAllocation.totalRecommendedProposedRiskControlAssetsPercent
            );
            updatedReviseAssetAllocation = {
                ...updatedReviseAssetAllocation,
                riskControlAssets: updatedRiskControlAssetsValues.updatedAssetClassificationList,
                totalProposedRiskControlAssetsPercent: updatedRiskControlAssetsValues.updatedTotalProposedPercent,
                totalProposedRiskControlAssetsAmount: updatedRiskControlAssetsValues.updatedTotalProposedAmount,
                totalProposedRiskControlAssetsPercentOfTotalPortfolio: updatedRiskControlAssetsValues.updatedTotalProposedPercentOfTotalPortfolio,
                totalDifferenceAmountRiskControlAssets: updatedRiskControlAssetsValues.updatedTotalDifferenceAmount,
            }
        }

        // Resetting allocations when any of subclasses has locked holdings may results in
        // total allocations not exactly 100%.
        // In order to save user an extra click on "Allocate Remaining Assets Pro Rata",
        // we are pre-emptively making the call

        let riskPromise;
        let riskControlPromise;

        if(updatedReviseAssetAllocation.riskAssets.some(assetSubclassSummary => assetSubclassSummary.lockedAmount)) {
            const allocateRemainingAssetsProRataRequest: AllocateRemainingAssetsProRataRequest = {
                revisedAssetAllocation: updatedReviseAssetAllocation,
                assetClassification: AssetClassification.RISK_ASSET
            }

            riskPromise = wealthManagementApiClient.allocateRemainingAssetsProRata(id, allocateRemainingAssetsProRataRequest);
        }

        if(updatedReviseAssetAllocation.riskControlAssets.some(assetSubclassSummary => assetSubclassSummary.lockedAmount)) {
            const allocateRemainingAssetsProRataRequest: AllocateRemainingAssetsProRataRequest = {
                revisedAssetAllocation: updatedReviseAssetAllocation,
                assetClassification: AssetClassification.RISK_CONTROL
            }

            riskControlPromise = wealthManagementApiClient.allocateRemainingAssetsProRata(id, allocateRemainingAssetsProRataRequest);
        }

        if (riskPromise && riskControlPromise) {
            Promise.all([riskPromise, riskControlPromise])
                .then(([{riskAssets}, {riskControlAssets}]) => {
                    setReviseAssetAllocation({
                        ...updatedReviseAssetAllocation,
                        riskAssets,
                        riskControlAssets
                    })
                });
        } else if (riskPromise) {
            riskPromise.then(setReviseAssetAllocation)
        } else if (riskControlPromise) {
            riskControlPromise.then(setReviseAssetAllocation)
        } else {
            setReviseAssetAllocation(updatedReviseAssetAllocation);
        }
        setIsProposedAllocationValuesLoadedFirstTime(true);
    }

    const handleUpdateDataDisplay = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setDisplayView(value as DataDisplayView);
        setIsProposedAllocationValuesLoadedFirstTime(true);
    }

    async function toggleExcludeSubclass(subclassName: string, excludeSubclass: boolean, assetClassification: AssetClassification) {
        const excludeSubclassRequest: ExcludeSubclassRequest = {
            subclassName,
            excludeSubclass,
            revisedAssetAllocation: reviseAssetAllocation,
            assetClassification
        }
        const proRataReviseAssetAllocation = await wealthManagementApiClient.excludeSubclass(id, excludeSubclassRequest);
        setReviseAssetAllocation(proRataReviseAssetAllocation);
    }

    async function allocateRemainingAssetsProRata(assetClassification: AssetClassification): Promise<ReviseAssetAllocationResponse> {
        const allocateRemainingAssetsProRataRequest: AllocateRemainingAssetsProRataRequest = {
            revisedAssetAllocation: reviseAssetAllocation,
            assetClassification
        }
        const proRataReviseAssetAllocation = await wealthManagementApiClient.allocateRemainingAssetsProRata(id, allocateRemainingAssetsProRataRequest);
        setReviseAssetAllocation(proRataReviseAssetAllocation);
        if (assetClassification === AssetClassification.RISK_ASSET) {
            if (!areRiskAssetsCompletelyFilled(proRataReviseAssetAllocation).riskAssetsCompletelyFilled) {
                setShowRiskAssetsNotAllocatedWarning(true);
            }
        } else {
            if (!areRiskControlCompletelyFilled(proRataReviseAssetAllocation).riskControlCompletelyFilled) {
                setShowRiskControlNotAllocatedWarning(true);
            }
        }
        return proRataReviseAssetAllocation;
    }

    const saveRevisedAssetAllocation = async (updatedReviseAssetAllocation: ReviseAssetAllocationResponse) => {
        const {isValid, openAutoAdjustModal} = validateEntries(updatedReviseAssetAllocation);

        if (openAutoAdjustModal) {
            setShowAutoAdjustModal(true);
            return false;
        }

        if (isValid) {
            const revisedProposedAllocation = mapResponseToRevisedProposedAllocation(updatedReviseAssetAllocation);
            const savedReviseAssetAllocation = await wealthManagementApiClient.saveRevisedAssetAllocation(id, revisedProposedAllocation);
            setInitialReviseAssetAllocation(savedReviseAssetAllocation)
            setReviseAssetAllocation(savedReviseAssetAllocation);
            return true;
        }
        return false;
    }

    const applyProposedAssetAllocation = async (updatedReviseAssetAllocation: ReviseAssetAllocationResponse) => {
        setShowWarning(true);
        const shouldNavigate = await saveRevisedAssetAllocation(updatedReviseAssetAllocation)
        shouldNavigate && navigateToCurrentVsProposed();
    }

    const handleAutoAdjust = async () => {
        setShowAutoAdjustModal(false);

        let updatedReviseAssetAllocation: ReviseAssetAllocationResponse = reviseAssetAllocation;
        if (areRiskAssetsCompletelyFilled(reviseAssetAllocation).autoAdjustForRiskAssets) {
            updatedReviseAssetAllocation = await allocateRemainingAssetsProRata(AssetClassification.RISK_ASSET);
        }
        if (areRiskControlCompletelyFilled(reviseAssetAllocation).autoAdjustRiskControl) {
            updatedReviseAssetAllocation = await allocateRemainingAssetsProRata(AssetClassification.RISK_CONTROL);
        }

        if (validateEntries(updatedReviseAssetAllocation).isValid) {
            await applyProposedAssetAllocation(updatedReviseAssetAllocation);
        }
    }

    const hasRevisedAssetAllocation = () => {
        return reviseAssetAllocation.totalRecommendedProposedRiskAssetsAmount > 0 || reviseAssetAllocation.totalRecommendedProposedRiskControlAssetsAmount > 0
    }

    function hasUnsavedChanges() {
        return !deepEquals(reviseAssetAllocation, initialReviseAssetAllocation);
    }

    const handleCancel = () => {
        if (hasUnsavedChanges()) {
            setShowCancelModal(true);
        } else {
            navigateToCurrentVsProposed();
        }
    };

    function handleDiscardChanges() {
        setReviseAssetAllocation(initialReviseAssetAllocation);
        setShowCancelModal(false);
        navigateToCurrentVsProposed();
    }

    function navigateToCurrentVsProposed() {
        history.push(`/Profile/${id}/ClientProfile/AssetAllocation/CurrentVsProposed`, {forceNavigate: true});
    }

    const totalRiskAssetsCurrentAllocation = isDollarView(displayView) ? reviseAssetAllocation.totalCurrentRiskAssetsAmount : reviseAssetAllocation.totalCurrentRiskAssetsPercent;
    const totalRiskAssetsProposedAllocation = isDollarView(displayView) ? reviseAssetAllocation.totalProposedRiskAssetsAmount : reviseAssetAllocation.totalProposedRiskAssetsPercent;
    const totalRiskAssetsRecommendedAllocation = isDollarView(displayView) ? reviseAssetAllocation.totalRecommendedProposedRiskAssetsAmount : reviseAssetAllocation.totalRecommendedProposedRiskAssetsPercent;
    const totalRiskControlCurrentAllocation = isDollarView(displayView) ? reviseAssetAllocation.totalCurrentRiskControlAssetsAmount : reviseAssetAllocation.totalCurrentRiskControlAssetsPercent;
    const totalRiskControlProposedAllocation = isDollarView(displayView) ? reviseAssetAllocation.totalProposedRiskControlAssetsAmount : reviseAssetAllocation.totalProposedRiskControlAssetsPercent;
    const totalRiskControlRecommendedAllocation = isDollarView(displayView) ? reviseAssetAllocation.totalRecommendedProposedRiskControlAssetsAmount : reviseAssetAllocation.totalRecommendedProposedRiskControlAssetsPercent;

    const subtitle = () => {
        return <span
            className='revise-asset-allocation__header-subtitle'>{`As of ${toDisplayDateFormat(reviseAssetAllocation.asOfDate)}`}</span>;
    }

    if (!pageLoaded) {
        return <LoadingIndicator displayMessage={"Page loading"}/>
    }

    return (
        <div className="revise-asset-allocation">
            <DataEntryHeader
                title="Revise Asset Allocation"
                SubtitleComponent={reviseAssetAllocation.asOfDate ? subtitle : undefined}
                primaryButtonText="Apply Proposed Asset Allocation"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={() => applyProposedAssetAllocation(reviseAssetAllocation)}
                onSecondaryButtonClick={handleCancel}
                disablePrimaryButton={!hasUnsavedChanges() || isProfileWithProposalsOrArchived}
                isExtraHTMLElementRequired={true}
                extraHTMLElementComponent={
                    <ReviseAllocationPageMenu
                        resetAll={resetAll}
                        resetRiskAssets={resetRiskAssets}
                        resetRiskControlAssets={resetRiskControlAssets}
                        handleChangeDisplayView={handleUpdateDataDisplay}
                        displayView={displayView}
                        showIPCRange={showIPCRange}
                        toggleIPCRange={() => setShowIPCRange(!showIPCRange)}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                }
            />


            {showWarning ?
                alertMessages.map((alertMessage, index) => {
                    return getInvalidAllocationAlertMessage(displayView, alertMessage, index);
                }) : <div/>}


            {!hasRevisedAssetAllocation() ?
                <div className="empty-asset-allocation">
                    <span>To revise asset allocation, please begin by entering assets.</span>
                </div>
                :
                <>
                    <ReviseAssetClassTable
                        assetClassificationName="Risk Assets"
                        assetClassHierarchy={sortAssetClassHierarchy(reviseAssetAllocation.riskAssets)}
                        accentColor={COLOR_RISK_ASSETS}
                        totalCurrentAllocation={totalRiskAssetsCurrentAllocation}
                        totalProposedAllocation={totalRiskAssetsProposedAllocation}
                        totalRecommendedAllocation={totalRiskAssetsRecommendedAllocation}
                        totalDifferenceAmount={reviseAssetAllocation.totalDifferenceAmountRiskAssets}
                        totalProposedAllocationOfTotalPortfolio={reviseAssetAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio}
                        updateProposedAllocationEntry={updateRiskAssetsProposedAllocationEntry}
                        totalRemainingAllocation={totalRemainingAllocationForRiskAssets}
                        displayView={displayView}
                        showIPCRange={showIPCRange}
                        areInputsDisabled={areRiskAssetsDisabled}
                        isCompletelyAllocated={areRiskAssetsCompletelyFilled(reviseAssetAllocation).riskAssetsCompletelyFilled}
                        toggleExcludeSubclass={(subclassName, excludeSubclass) =>
                            toggleExcludeSubclass(subclassName, excludeSubclass, AssetClassification.RISK_ASSET)}
                        allocateRemainingAssetsProRata={() =>
                            allocateRemainingAssetsProRata(AssetClassification.RISK_ASSET)}
                        showAssetsNotAllocatedWarning={showRiskAssetsNotAllocatedWarning}
                        setShowAssetsNotAllocatedWarning={setShowRiskAssetsNotAllocatedWarning}
                        totalProposedAmount={initialReviseAssetAllocation.totalProposedRiskAssetsAmount}
                        showWarning={showWarning}
                        goalsSummaryForOneYear={reviseAssetAllocation.goalsSummaryForOneYear}
                        isProposedAllocationValuesLoadedFirstTime={isProposedAllocationValuesLoadedFirstTime}
                        averageTargetMaturity={null}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                    <ReviseAssetClassTable
                        assetClassificationName="Risk Control"
                        assetClassHierarchy={sortAssetClassHierarchy(reviseAssetAllocation.riskControlAssets)}
                        accentColor={COLOR_RISK_CONTROL_ASSETS}
                        totalCurrentAllocation={totalRiskControlCurrentAllocation}
                        totalProposedAllocation={totalRiskControlProposedAllocation}
                        totalRecommendedAllocation={totalRiskControlRecommendedAllocation}
                        totalDifferenceAmount={reviseAssetAllocation.totalDifferenceAmountRiskControlAssets}
                        totalProposedAllocationOfTotalPortfolio={reviseAssetAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio}
                        updateProposedAllocationEntry={updateRiskControlAssetsProposedAllocationEntry}
                        totalRemainingAllocation={totalRemainingAllocationForRiskControl}
                        displayView={displayView}
                        showIPCRange={showIPCRange}
                        areInputsDisabled={areRiskControlAssetsDisabled}
                        isCompletelyAllocated={areRiskControlCompletelyFilled(reviseAssetAllocation).riskControlCompletelyFilled}
                        toggleExcludeSubclass={(subclassName, excludeSubclass) =>
                            toggleExcludeSubclass(subclassName, excludeSubclass, AssetClassification.RISK_CONTROL)}
                        allocateRemainingAssetsProRata={() =>
                            allocateRemainingAssetsProRata(AssetClassification.RISK_CONTROL)}
                        showAssetsNotAllocatedWarning={showRiskControlNotAllocatedWarning}
                        setShowAssetsNotAllocatedWarning={setShowRiskControlNotAllocatedWarning}
                        totalProposedAmount={initialReviseAssetAllocation.totalProposedRiskControlAssetsAmount}
                        showWarning={showWarning}
                        goalsSummaryForOneYear={reviseAssetAllocation.goalsSummaryForOneYear}
                        isProposedAllocationValuesLoadedFirstTime={isProposedAllocationValuesLoadedFirstTime}
                        averageTargetMaturity={averageTargetMaturity}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                </>
            }

            <DiscardModal
                itemType={"Asset Allocation"}
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={handleDiscardChanges}
                isEditing={true}
            />

            <HistoryBlockModal
                when={hasUnsavedChanges()}
                itemType="Asset Allocation"
                onSave={() => saveRevisedAssetAllocation(reviseAssetAllocation)}
            />

            <CustomModal
                isOpen={showAutoAdjustModal}
                title={"Auto-Adjust Proposed Allocation?"}
                content={"The difference between Proposed Allocation and Recommended Target is less than 0.01%. Do you want to auto-adjust the allocation?"}
                onClickCancel={() => setShowAutoAdjustModal(false)}
                onClickConfirm={handleAutoAdjust}
                cancelText={"Keep Editing"}
                confirmText={"Confirm"}
                confirmButtonDestructive={false}
            />
        </div>
    );
};

function mapResponseToRevisedProposedAllocation(reviseAssetAllocationResponse: ReviseAssetAllocationResponse): RevisedProposedAllocation {
    return {
        riskAssets: reviseAssetAllocationResponse.riskAssets.map(reviseAssetSubclass => ({
            assetSubClass: reviseAssetSubclass.subclassName,
            proposedAllocationPercent: reviseAssetSubclass.proposedInvestablePercent,
            excludeSubclass: reviseAssetSubclass.excludeSubclass,
            editedManually: reviseAssetSubclass.editedManually,
            excludedProposedAllocation: reviseAssetSubclass.excludedProposedAllocation
        })),
        riskControlAssets: reviseAssetAllocationResponse.riskControlAssets.map(reviseAssetSubclass => ({
            proposedAllocationPercent: reviseAssetSubclass.proposedInvestablePercent,
            assetSubClass: reviseAssetSubclass.subclassName,
            excludeSubclass: reviseAssetSubclass.excludeSubclass,
            editedManually: reviseAssetSubclass.editedManually,
            excludedProposedAllocation: reviseAssetSubclass.excludedProposedAllocation
        }))
    }
}

function getInvalidAllocationAlertMessage(displayView: DataDisplayView, alertMessage: AlertMessageForRevisedAllocation, index: number) {
    const formattedTotalValue = isDollarView(displayView) ?
        formatCurrencyRoundedToTwoDecimals(alertMessage.totalProposedAllocationAmount) :
        formatAllocationPercentage(alertMessage.totalProposedAllocationPercent);

    const differenceValue = isDollarView(displayView) ? alertMessage.differenceInTotalProposedAllocationAmount : alertMessage.differenceInTotalProposedAllocationPercent;
    const reduceOrIncrease = differenceValue < 0 ? 'reduce' : 'increase';
    const formattedDifferenceInTotalAllocation = `${reduceOrIncrease} ${isDollarView(displayView) ? formatCurrencyRoundedToTwoDecimals(Math.abs(differenceValue)) : formatAllocationPercentage(Math.abs(differenceValue))}`;
    return (<div
        key={index}
        className="alert-banner-container marginbottom-md">
        <AlertBanner
            fullWidth={false}
            icon="warning"
            type="error"
            showAlert={true}
            showCloseBtn={false}
        >
            <span className="banner-text">
                {`Invalid Proposed Allocation: Total ${alertMessage.assetClassification} Proposed equals ${formattedTotalValue}. Please ${formattedDifferenceInTotalAllocation} across ${alertMessage.assetClassification}s.`}
            </span>
        </AlertBanner>
    </div>);
}

export default ReviseAssetAllocation;