import React, {useEffect, useState} from "react";
import {Mapping as ClassNamesMapping} from "classnames";
import {AccordionTableReport} from "./AccordionTableReport";
import {AssetRelianceGoalSummaryDTO, AssetRelianceStack} from "../../../ClientManagement/models/AssetRelianceResponse";
import {
    AssetRelianceButtonState,
    FveDiscountRateType
} from "../../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";
import {
    COLOR_ASSET_SHORTFALL_ACCENT,
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS_ACCENT,
    COLOR_GOALS
} from "../../../constants/colors";
import {hasPositiveExcessAssets} from "../../../ClientManagement/AssetReliance/AssetRelianceUtil";
import AssetRelianceBarchartContainer from "../../../ClientManagement/AssetReliance/AssetRelianceBarchartContainer";
import AssetRelianceTitleGrid from "../../../ClientManagement/AssetReliance/AssetRelianceTitleGrid";
import NoAssetsComponent from "../../../ClientManagement/AssetReliance/NoAssetsComponent";
import {ExcessAssetsTableDisplay} from "../../../ClientManagement/AssetReliance/TableDisplay/ExcessAssetsTableDisplay";
import {GoalTableDisplay} from "../../../ClientManagement/AssetReliance/TableDisplay/GoalTableDisplay";
import {ReviewAssetTableDisplay} from "../../../ClientManagement/AssetReliance/TableDisplay/ReviewAssetTableDisplay";
import {FixedColumnCounter} from "../../../components";
import {CellClassParamsReport} from "./TableRowComponentReport";
import {AssetRelianceDetails, createTableDisplayObjects} from "./reportUtil";

const TITLE_CELL_WIDTH = 310;


export interface AssetRelianceContentProps {
    goalsSummaryDTO: AssetRelianceGoalSummaryDTO,
    allAssetsStack: AssetRelianceStack,
    assetStacks: AssetRelianceStack[],
    excludedAssetStacks: AssetRelianceStack[],
    id: string,
    accountHasAssets: boolean,
    headerOptions: AssetRelianceButtonState,
    investorGroup: InvestorGroupType,
    isFutureValueOfExcessRequired: boolean
}

const AssetRelianceSummaryContent: React.FC<AssetRelianceContentProps> = ({
                                                                              goalsSummaryDTO,
                                                                              allAssetsStack,
                                                                              assetStacks,
                                                                              excludedAssetStacks = [],
                                                                              id,
                                                                              accountHasAssets,
                                                                              headerOptions,
                                                                              investorGroup,
                                                                              isFutureValueOfExcessRequired
                                                                          }) => {

    const totalGoals = goalsSummaryDTO.totalPresentValue;
    const {enableOtherExcludeAssetsOnAssetReliance} = useAppSelector(selectReleaseToggles)!;

    const headerOptionsForReport: AssetRelianceButtonState = {
        showExpectedExcessAsset: true,
        includeLifeInsuranceAtDeath: false,
        selectedFveDiscountRate: FveDiscountRateType.TRIPLE_NET
    }

    const {
        excessAssets,
        assets,
        goals
    } = createTableDisplayObjects({
        goalsDTO: goalsSummaryDTO,
        allAssetsStack,
        assetStacks,
        excludedAssetStacks,
        showExpectedExcessAssets: headerOptionsForReport.showExpectedExcessAsset!,
        includeLifeInsuranceAtDeath:  headerOptionsForReport.includeLifeInsuranceAtDeath!,
        futureValueOfExcessAssetsDiscountType: headerOptionsForReport.selectedFveDiscountRate,
        investorGroup,
        enableOtherExcludeAssetsOnAssetReliance: enableOtherExcludeAssetsOnAssetReliance!
    });

    const assetStacksContainerWidth = useAssetStackContainerWidth(excludedAssetStacks.length);

    const createTableCellValueClassNames = () => ({columnIndex_}: CellClassParamsReport): ClassNamesMapping[] => {
        return [{"table-cell-values": columnIndex_ >= 0},];
    };

    const createTableCellValueClassNamesForExcessAssets = () => ({columnIndex_}: CellClassParamsReport): ClassNamesMapping[] => {
        return [{"table-cell-values": columnIndex_ >= 0}, {"excess_assets": true}];
    };

    const excessAssetsAccentColor = hasPositiveExcessAssets(allAssetsStack.excessAssets) ? COLOR_EXCESS_ASSETS_ACCENT : COLOR_ASSET_SHORTFALL_ACCENT;

    return <>
        {accountHasAssets ?
            <main className="asset-reliance-content">
                <AssetRelianceBarchartContainer allAssetsStack={allAssetsStack}
                                                additionalAssetStacks={excludedAssetStacks}
                                                totalGoals={totalGoals}
                                                totalNetAssets={allAssetsStack.presentValue}
                                                stackContainerWidth={assetStacksContainerWidth}
                                                titleCellWidth={TITLE_CELL_WIDTH}
                />
                <AssetRelianceTitleGrid
                    additionalStacksTitles={excludedAssetStacks.map(stack => stack.name)}
                    stackContainerWidth={assetStacksContainerWidth}
                    titleCellWidth={TITLE_CELL_WIDTH}
                    id={id}
                />
                <AccordionTableReport accordionTableId_={`${id}-review-asset-reliance-assets`}
                                collapsedAccentColor_={COLOR_ASSETS_ACCOUNTS}
                                ariaLabel_='assets-row'
                                tableDisplayData_={assets}
                                titleCellWidth_={TITLE_CELL_WIDTH}
                                valueColumnSize_={assetStacksContainerWidth}
                                additionalCellClasses_={createTableCellValueClassNames()}
                />
                <AccordionTableReport accordionTableId_={`${id}-review-asset-reliance-goals`}
                                collapsedAccentColor_={COLOR_GOALS}
                                ariaLabel_='goals-row'
                                tableDisplayData_={goals}
                                titleCellWidth_={TITLE_CELL_WIDTH}
                                valueColumnSize_={assetStacksContainerWidth}
                                additionalCellClasses_={createTableCellValueClassNames()}
                />
                <AccordionTableReport accordionTableId_={`${id}-review-asset-reliance-excess-assets`}
                                      collapsedAccentColor_={excessAssetsAccentColor}
                                      ariaLabel_='excess-assets-row'
                                      tableDisplayData_={excessAssets}
                                      titleCellWidth_={TITLE_CELL_WIDTH}
                                      valueColumnSize_={assetStacksContainerWidth}
                                      additionalCellClasses_={createTableCellValueClassNamesForExcessAssets()}
                                      isReportExpanded_={isFutureValueOfExcessRequired}
                />
            </main>
            : <NoAssetsComponent/>
        }
    </>
}

export default AssetRelianceSummaryContent;

const ONE_EXCLUDED_ASSETS_WIDTH = 4.55
const TWO_EXCLUDED_ASSETS_WIDTH = 3.03
const THREE_EXCLUDED_ASSETS_WIDTH = 2.275
const FOUR_EXCLUDED_ASSETS_WIDTH = 1.825
const DEFAULT_EXCLUDED_ASSETS_WIDTH = 1.51

export const useAssetStackContainerWidth = (excludedAssetStacksAmount: number) => {
    const [assetStacksContainerWidth, setAssetStacksContainerWidth] = useState<number>(1.5);
    useEffect(() => {
        switch (excludedAssetStacksAmount) {
            case 1:
                setAssetStacksContainerWidth(ONE_EXCLUDED_ASSETS_WIDTH)
                break;
            case 2:
                setAssetStacksContainerWidth(TWO_EXCLUDED_ASSETS_WIDTH)
                break;
            case 3:
                setAssetStacksContainerWidth(THREE_EXCLUDED_ASSETS_WIDTH)
                break;
            case 4:
                setAssetStacksContainerWidth(FOUR_EXCLUDED_ASSETS_WIDTH)
                break;
            default:
                setAssetStacksContainerWidth(DEFAULT_EXCLUDED_ASSETS_WIDTH)
                break;
        }
    }, [excludedAssetStacksAmount]);
    return assetStacksContainerWidth;
}