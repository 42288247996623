import {
    AlertBanner,
    Button,
    CurrencyInput,
    DeleteButton,
    Dropdown,
    DropdownItem,
    Input,
    PercentInput,
    RedAsterisk
} from "../../components";
import {MemberDropdown} from "../components/MemberDropdown";
import React, {ChangeEvent, useState} from "react";
import {MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {
    BeneficiariesFormData,
    MemberBequestFormData,
    PhilanthropicBequestFormData,
    ResiduaryMemberBequestFormData
} from "../models/ui";
import UnderlinedSubHeader from "../../components/UnderlinedHeader/UnderlinedSubHeader";
import {AddBequestWithMemberButton} from "./AddResiduaryBequestsButton";
import {deduplicateArray} from "../../utils/deduplicateArray";
import {Placeholder} from "../../components/DataFormTable/Placeholder";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import {formatCurrency} from "../../utils/format";
import {isGeneralBequestsValid, isRemainingEstateValid, validateForm} from "./validation";
import {EstateSummary, PhilanthropicBequestType} from "../models/api";
import {safeParseFloat} from "../../utils/stringUtils";
import classNames from "classnames";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import deepEquals from "fast-deep-equal";

export interface EditBeneficiariesFormProps {
    estateSummary: EstateSummary,
    initialFormData: BeneficiariesFormData,
    members: MemberGroupMember[];
    onSave: (formData: BeneficiariesFormData) => void;
    onCancel: () => void;
    onEvaluate: (formData: BeneficiariesFormData) => void;
}

const getUnselectedMembers = (selectedMembers: {
    memberId: string | null
}[], allMembers: MemberGroupMember[]): MemberGroupMember[] => {
    const selectedMemberIds = selectedMembers
        .map((member) => member.memberId);
    return allMembers.filter(member => !selectedMemberIds.includes(member.id));
}

const emptyEstimatedNetValue = {
    label: '',
    value: '––',
    className: 'text-gray-900',
    testId: 'estimated-net-value-empty'
};

export function EditBeneficiariesForm({
                                          estateSummary,
                                          initialFormData,
                                          members,
                                          onSave,
                                          onCancel,
                                          onEvaluate,
                                      }: EditBeneficiariesFormProps) {
    const [formData, setFormData] = useState<BeneficiariesFormData>(initialFormData);
    const [eligibleResiduaryMembers, setEligibleResiduaryMembers] = useState(getUnselectedMembers(initialFormData.residuaryMemberBequests, members));
    const [eligibleIndividualMembers, setEligibleIndividualMembers] = useState(getUnselectedMembers(initialFormData.memberBequests, members));
    const [showRemainingEstateAlertBanner, setShowRemainingEstateAlertBanner] = useState<boolean>(false);
    const [showGeneralBequestsAlertBanner, setShowGeneralBequestsAlertBanner] = useState<boolean>(false);
    const [showMemberBequestNameIsRequiredError, setShowMemberBequestNameIsRequiredError] = useState<boolean>(false);
    const [showRemainingBequestNameRequiredError, setShowRemainingBequestNameRequiredError] = useState<boolean>(false);
    const [philanthropicBequestsValidityStatus, setPhilanthropicBequestsValidityStatus] = useState<boolean[]>(initialFormData.philanthropicBequests.map(() => true));
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);

    const onSaveClick = () => {
        const {
            isMemberBequestsInvalid,
            isRemainingMemberBequestInvalid,
            isRemainingEstateInvalid,
            isGeneralBequestsInvalid,
            philanthropicBequestsValidity
        } = validateForm(formData, estateSummary);
        setShowMemberBequestNameIsRequiredError(isMemberBequestsInvalid);
        setShowRemainingBequestNameRequiredError(isRemainingMemberBequestInvalid);
        setShowRemainingEstateAlertBanner(isRemainingEstateInvalid);
        setShowGeneralBequestsAlertBanner(isGeneralBequestsInvalid);
        setPhilanthropicBequestsValidityStatus(philanthropicBequestsValidity);
        isMemberBequestsInvalid ||
        isRemainingMemberBequestInvalid ||
        isRemainingEstateInvalid ||
        isGeneralBequestsInvalid ||
        !philanthropicBequestsValidity.every(value => value) ||
        onSave(formData);
    };

    const onAddFamilyClick = () => {
        const defaultMemberBequest: MemberBequestFormData = {
            memberId: null,
            presentValue: "0",
        };
        const {isMemberBequestsInvalid} = validateForm(formData, estateSummary);
        setShowMemberBequestNameIsRequiredError(isMemberBequestsInvalid);
        if (isMemberBequestsInvalid) {
            return;
        }
        setFormData({...formData, memberBequests: [...formData.memberBequests, defaultMemberBequest]});
    };

    const onPhilanthropicClick = () => {
        const philanthropicBequestType = formData.philanthropicBequests.length > 0 ? formData.philanthropicBequests[0].type : PhilanthropicBequestType.AMOUNT;
        const defaultPhilanthropicBequest: PhilanthropicBequestFormData = {
            philanthropyName: '',
            value: '0',
            type: philanthropicBequestType,
        };
        setFormData({
            ...formData,
            philanthropicBequests: [
                ...formData.philanthropicBequests,
                defaultPhilanthropicBequest
            ]
        });
        setPhilanthropicBequestsValidityStatus([...philanthropicBequestsValidityStatus, true]);
    };

    const onAddRemainingEstateClick = () => {
        const defaultRemainingBequest = {
            memberId: null,
            percentage: "0",
        };
        const {isRemainingMemberBequestInvalid} = validateForm(formData, estateSummary);
        setShowRemainingBequestNameRequiredError(isRemainingMemberBequestInvalid);
        if (isRemainingMemberBequestInvalid) {
            return;
        }
        const newFormData = {
            ...formData,
            residuaryMemberBequests: [
                ...formData.residuaryMemberBequests,
                defaultRemainingBequest
            ]
        };
        setFormData(newFormData);
    };

    function updateArrayElementField(beneficiariesFormData: BeneficiariesFormData, arrayFieldName: keyof BeneficiariesFormData, index: number, elementFieldName: string, value: string): BeneficiariesFormData {
        return {
            ...beneficiariesFormData,
            [arrayFieldName]: [
                ...beneficiariesFormData[arrayFieldName].slice(0, index),
                {
                    ...beneficiariesFormData[arrayFieldName][index],
                    [elementFieldName]: value,
                },
                ...beneficiariesFormData[arrayFieldName].slice(index + 1, beneficiariesFormData[arrayFieldName].length),
            ]
        };
    }

    function deleteArrayElementField(beneficiariesFormData: BeneficiariesFormData, arrayFieldName: keyof BeneficiariesFormData, index: number): BeneficiariesFormData {
        return {
            ...beneficiariesFormData,
            [arrayFieldName]: [
                ...beneficiariesFormData[arrayFieldName].slice(0, index),
                ...beneficiariesFormData[arrayFieldName].slice(index + 1, beneficiariesFormData[arrayFieldName].length)
            ]
        };
    }

    const onIndividualBequestMemberChange = (newMemberId: string, index: number) => {
        const newFormData = updateArrayElementField(
            formData,
            'memberBequests',
            index,
            'memberId',
            newMemberId);
        setShowMemberBequestNameIsRequiredError(false);
        setEligibleIndividualMembers(getUnselectedMembers(newFormData.memberBequests, members));
        setFormData(newFormData);
    }

    const onResiduaryBequestMemberChange = (newMemberId: string, index: number) => {
        const newFormData = updateArrayElementField(
            formData,
            'residuaryMemberBequests',
            index,
            'memberId',
            newMemberId);
        setShowRemainingBequestNameRequiredError(false);
        setEligibleResiduaryMembers(getUnselectedMembers(newFormData.residuaryMemberBequests, members));
        setFormData(newFormData);
    }

    const onPhilanthropyNameChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const newFormData = updateArrayElementField(
            formData,
            'philanthropicBequests',
            index,
            'philanthropyName',
            event.target.value);
        setFormData(newFormData);

        if (!philanthropicBequestsValidityStatus[index]) {
            const {philanthropicBequestsValidity} = validateForm(newFormData, estateSummary);
            philanthropicBequestsValidityStatus[index] = philanthropicBequestsValidity[index];
            setPhilanthropicBequestsValidityStatus(philanthropicBequestsValidityStatus);
        }
    };

    const onValueChange = (value: string, index: number, arrayFieldName: keyof BeneficiariesFormData, elementFieldName: string) => {
        const newFormData = updateArrayElementField(
            formData,
            arrayFieldName,
            index,
            elementFieldName,
            value);
        setFormData(newFormData);
        if (isGeneralBequestsValid(newFormData, estateSummary)) {
            setShowGeneralBequestsAlertBanner(false);
        }
    }

    const onPercentChange = (percentage: string, index: number) => {
        const newFormData = updateArrayElementField(
            formData,
            'residuaryMemberBequests',
            index,
            'percentage',
            percentage);
        setFormData(newFormData);
        if (isRemainingEstateValid(newFormData)) {
            setShowRemainingEstateAlertBanner(false);
        }
    };

    const eligibleMembersPlusSelected = (selected: {
        memberId: string | null
    }, eligibleMembers: MemberGroupMember[]): MemberGroupMember[] => {
        return deduplicateArray([
            ...eligibleMembers,
            ...members.filter(m => m.id === selected.memberId)
        ]);
    }

    const eligibleResiduaryMembersPlusSelected = (selected: ResiduaryMemberBequestFormData): MemberGroupMember[] => {
        return eligibleMembersPlusSelected(selected, eligibleResiduaryMembers);
    }

    const eligibleIndividualMembersPlusSelected = (selected: MemberBequestFormData): MemberGroupMember[] => {
        return eligibleMembersPlusSelected(selected, eligibleIndividualMembers);
    }

    const onRemoveIndividualBequest = (index: number, bequest: MemberBequestFormData) => {
        const newFormData = deleteArrayElementField(formData, "memberBequests", index);
        setEligibleIndividualMembers(eligibleIndividualMembersPlusSelected(bequest));
        const {isMemberBequestsInvalid} = validateForm(newFormData, estateSummary);
        setShowMemberBequestNameIsRequiredError(isMemberBequestsInvalid);
        setFormData(newFormData);
        onEvaluate(newFormData);
    }

    const onRemovePhilanthropicBequest = (index: number) => {
        const newFormData = deleteArrayElementField(formData, "philanthropicBequests", index);
        setFormData(newFormData);
        const currentPhilanthropicBequestValidityStatus = [...philanthropicBequestsValidityStatus]
        currentPhilanthropicBequestValidityStatus.splice(index, 1);
        setPhilanthropicBequestsValidityStatus(currentPhilanthropicBequestValidityStatus);
        onEvaluate(newFormData);
    };

    const onRemoveRemainingBequest = (index: number, bequest: ResiduaryMemberBequestFormData) => {
        const newFormData = deleteArrayElementField(formData, "residuaryMemberBequests", index);
        setEligibleResiduaryMembers(eligibleResiduaryMembersPlusSelected(bequest))
        const {isRemainingMemberBequestInvalid} = validateForm(newFormData, estateSummary);
        setShowRemainingBequestNameRequiredError(isRemainingMemberBequestInvalid);
        setFormData(newFormData);
        onEvaluate(newFormData);
    };

    const onEditFinished = () => {
        onEvaluate(formData);
    }

    const calculateBequestTotal = (): number => {
        const memberBequestPresentValues = formData.memberBequests.map((bequest) => safeParseFloat(bequest.presentValue));
        const philanthropicBequestPresentValues = formData.philanthropicBequests.map((bequest) => {
            if (bequest.type == PhilanthropicBequestType.AMOUNT)
                return safeParseFloat(bequest.value)
            else
                return (safeParseFloat(bequest.value) * estateSummary.estateValue) / 100
        });

        return [...memberBequestPresentValues, ...philanthropicBequestPresentValues]
            .reduce((previousValue: number, currentValue) => {
                return previousValue + currentValue;
            }, 0);
    }

    const philanthropicEstimatedNetValues = estateSummary.philanthropicBeneficiaries
        .map(beneficiary => ({
            label: beneficiary.philanthropyName,
            value: formatCurrency(beneficiary.value),
        }));

    const mapMemberBeneficiaryEstimatedNetValue: (memberId: string | null) => {
        label: string,
        value: string
    }[] = (memberId) => {
        const memberBeneficiary = estateSummary.memberBeneficiaries
            .find(beneficiary => beneficiary.id === memberId);
        if (!memberBeneficiary) {
            return []
        }
        return [{
            label: memberBeneficiary.name,
            value: formatCurrency(memberBeneficiary.presentValue),
        }];
    };

    const individualBequestMemberIds = formData.memberBequests.map(bequest => bequest.memberId);
    const individualEstimatedNetValues = individualBequestMemberIds
        .flatMap(mapMemberBeneficiaryEstimatedNetValue);

    const remainingIndividualEstimatedNetValues = formData.residuaryMemberBequests
        .map(bequest => bequest.memberId)
        .filter(memberId => !individualBequestMemberIds.includes(memberId))
        .flatMap(mapMemberBeneficiaryEstimatedNetValue);

    const estimatedNetValues = philanthropicEstimatedNetValues
        .concat(individualEstimatedNetValues)
        .concat(remainingIndividualEstimatedNetValues)

    const onPhilanthropicBequestTypeChange = (philanthropicBequestType: PhilanthropicBequestType) => {
        const newFormData = {
            ...formData,
            philanthropicBequests: formData.philanthropicBequests
                .map(bequest => ({...bequest, value: '0', type: philanthropicBequestType}))
        };
        setFormData(newFormData);
        onEvaluate(newFormData);
    };

    const remainingEstateTotalPercentage = (): number => {
        return formData.residuaryMemberBequests.reduce((total, bequest) => total + safeParseFloat(bequest.percentage), 0);
    }

    return <>
        <div data-testid="edit-beneficiaries-form" className="side-drawer-form">
            <DataEntryHeader
                className='dataEntryHeader'
                title="Edit Beneficiaries"
                primaryButtonText='Save'
                onPrimaryButtonClick={onSaveClick}
                secondaryButtonText='Cancel'
                onSecondaryButtonClick={() => {
                    const isFormChanged = !deepEquals(formData, initialFormData)
                    isFormChanged ? setShowDiscardChangesModal(true) : onCancel()
                }
                }
            />

            <AlertBanner showAlert={showGeneralBequestsAlertBanner} icon='warning'>
                Your bequests cannot exceed your Amount to Beneficiaries value.
            </AlertBanner>

            <div className="side-drawer-form-content">
                <article>
                    <section aria-label='Philanthropic Bequests'>
                        <UnderlinedSubHeader primaryText="Philanthropic Bequests" rightAlignedContent={
                            formData.philanthropicBequests.length > 0 ? <Button
                                onClick={onPhilanthropicClick}
                                kind="borderless"
                                icon="left"
                                iconName="add"
                                size="medium"
                                className="centered"
                            > Add Bequest </Button> : null
                        }/>

                        {formData.philanthropicBequests.length === 0 ?
                            <>
                                <div className="subtitle">No philanthropic bequests have been added</div>
                                <Button
                                    onClick={onPhilanthropicClick}
                                    kind="secondary"
                                    icon="left"
                                    iconName="add"
                                    size="medium"
                                    className="centered"
                                > Add Bequest </Button>
                            </> : null}

                        {formData.philanthropicBequests.length > 0 &&
                            <div className="bequest-type-grid">
                                <label className="bequest-type-label">
                                    Bequest Type
                                </label>
                                <Dropdown value={formData.philanthropicBequests[0].type}
                                          aria-label={`Bequest Type`}
                                          onChange={e => {
                                              onPhilanthropicBequestTypeChange(e.value);
                                          }}>
                                    <DropdownItem itemText={`Amount`} key={`Amount`}
                                                  value={PhilanthropicBequestType.AMOUNT}
                                                  aria-label={`Bequest Type Amount`}/>
                                    <DropdownItem itemText={`Percentage`} key={`Percentage`}
                                                  value={PhilanthropicBequestType.PERCENTAGE}
                                                  aria-label={`Bequest Type Percentage`}/>
                                </Dropdown>
                            </div>
                        }

                        <div className="data-form-table">
                            {formData.philanthropicBequests.length > 0 &&
                                <React.Fragment>
                                    <div className="data-form-table-header">Name<RedAsterisk/></div>
                                    {formData.philanthropicBequests[0].type === PhilanthropicBequestType.AMOUNT &&
                                        <div className="data-form-table-header textalign-right">Present Value</div>}
                                    {formData.philanthropicBequests[0].type === PhilanthropicBequestType.PERCENTAGE &&
                                        <div className="data-form-table-header textalign-right">Allocated %</div>}
                                    <Placeholder/>
                                </React.Fragment>}
                            {formData.philanthropicBequests.map((bequest, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Input
                                            aria-label={`Philanthropy Name ${index}`}
                                            value={bequest.philanthropyName}
                                            onChange={e => onPhilanthropyNameChange(e, index)}
                                            onBlur={onEditFinished}
                                            className="data-form-table-row"
                                            size="medium"
                                            error={!philanthropicBequestsValidityStatus[index] ? 'This field is required.' : undefined}
                                        />
                                        {bequest.type === PhilanthropicBequestType.AMOUNT &&
                                            <CurrencyInput
                                                aria-label={`Philanthropy Bequest Present Value ${index}`}
                                                value={bequest.value}
                                                onChangeValue={(e) => onValueChange(e.target.value, index, 'philanthropicBequests', 'value')}
                                                onBlur={onEditFinished}
                                                className="data-form-table-row"
                                                size="medium"
                                            />
                                        }
                                        {bequest.type === PhilanthropicBequestType.PERCENTAGE &&
                                            <PercentInput
                                                defaultValue="0"
                                                hideInlineLabel
                                                hideLabel
                                                className="data-form-table-row"
                                                size="medium"
                                                textAlign="right"
                                                value={bequest.value}
                                                label={`Philanthropy Bequest Percentage ${index}`}
                                                onBlur={onEditFinished}
                                                onChange={(value) => onValueChange(value, index, 'philanthropicBequests', 'value')}/>
                                        }
                                        <DeleteButton
                                            className={classNames("button-delete", !philanthropicBequestsValidityStatus[index] ? "bequest-name-missing-error-spacing" : '')}
                                            aria-label={`Remove Philanthropic Bequest ${index}`}
                                            onDelete={() => onRemovePhilanthropicBequest(index)}
                                        />

                                    </React.Fragment>);
                            })}
                        </div>
                    </section>

                    <section aria-label='Individual Bequests'>
                        <UnderlinedSubHeader primaryText="Individual Bequests" rightAlignedContent={
                            formData.memberBequests.length > 0
                                ? <AddBequestWithMemberButton
                                    buttonText="Add Bequest"
                                    members={members}
                                    bequests={formData.memberBequests}
                                    kind="borderless"
                                    onClick={onAddFamilyClick}
                                /> : null
                        }/>
                        {formData.memberBequests.length === 0
                            ?
                            <>
                                <div className="subtitle">No individual bequests have been added</div>
                                <AddBequestWithMemberButton
                                    buttonText="Add Bequest"
                                    members={members}
                                    bequests={formData.memberBequests}
                                    onClick={onAddFamilyClick}
                                    kind="secondary"
                                    size="medium"
                                    className="centered"
                                />
                            </> : null}
                        <div className="data-form-table">
                            {formData.memberBequests.length > 0
                                ? <React.Fragment>
                                    <div className="data-form-table-header">Name<RedAsterisk/></div>
                                    <div className="data-form-table-header textalign-right">Present Value</div>
                                    <Placeholder/>
                                </React.Fragment>
                                : null}

                            {formData.memberBequests.map((bequest, index) => {
                                const isBequestNameMissing = showMemberBequestNameIsRequiredError && formData.memberBequests.length - 1 === index;
                                return <React.Fragment key={index}>
                                    <MemberDropdown
                                        aria-label={`Bequest Individual Member ${index}`}
                                        value={bequest.memberId}
                                        members={eligibleIndividualMembersPlusSelected(bequest)}
                                        onChange={(e) => onIndividualBequestMemberChange(e, index)}
                                        onBlur={onEditFinished}
                                        className={classNames("data-form-table-row", isBequestNameMissing ? "bequest-name-missing" : '')}
                                        error={isBequestNameMissing ? "This field is required." : undefined}
                                    />
                                    <CurrencyInput
                                        aria-label={`Individual Member Bequest Present Value ${index}`}
                                        value={bequest.presentValue}
                                        onChangeValue={(e) => onValueChange(e.target.value, index, 'memberBequests', 'presentValue')}
                                        onBlur={onEditFinished}
                                        size="medium"
                                        className="data-form-table-row"
                                    />
                                    <DeleteButton
                                        className={classNames("button-delete", isBequestNameMissing ? "bequest-name-missing-error-spacing" : '')}
                                        aria-label={`Remove Individual Member Bequest ${index}`}
                                        onDelete={() => onRemoveIndividualBequest(index, bequest)}
                                    />
                                </React.Fragment>
                            })}
                        </div>
                        {(formData.memberBequests.length > 0 || formData.philanthropicBequests.length > 0) &&
                            <div className="side-drawer-form-content-subtotals">
                                <div className="side-drawer-form-content-subtotals-row">
                                    <div className="fontweight-500">Bequest Total</div>
                                    <div className="fontweight-500"
                                         aria-label="Bequest Total">{formatCurrency(calculateBequestTotal())}</div>
                                </div>
                                <div className="side-drawer-form-content-subtotals-row">
                                    <div>Remaining Amount to Beneficiaries</div>
                                    <div aria-label="Remaining Amount to Beneficiaries">
                                        {formatCurrency(estateSummary.beneficiaryTotalValue - calculateBequestTotal())}
                                    </div>
                                </div>
                            </div>}
                    </section>

                    <AlertBanner showAlert={showRemainingEstateAlertBanner} icon='warning'>
                        Remaining Estate allocation must not exceed 100%
                    </AlertBanner>
                    <section aria-label='Remaining Estate' className="remaining-estate-section">
                        <UnderlinedSubHeader
                            primaryText="Remaining Estate"
                            rightAlignedContent={
                                (formData.residuaryMemberBequests.length > 0) &&
                                <AddBequestWithMemberButton
                                    buttonText="Add"
                                    members={members}
                                    bequests={formData.residuaryMemberBequests}
                                    kind="borderless"
                                    onClick={onAddRemainingEstateClick}
                                />
                            }/>

                        {(formData.residuaryMemberBequests.length === 0) &&
                            <>
                                <div className="subtitle">The remaining estate’s distribution has not been specified.
                                </div>
                                <AddBequestWithMemberButton
                                    buttonText="Add"
                                    members={members}
                                    bequests={formData.residuaryMemberBequests}
                                    onClick={onAddRemainingEstateClick}
                                    kind="secondary"
                                    size="medium"
                                    className="centered"
                                />
                            </>}
                        <div className="data-form-table">
                            {formData.residuaryMemberBequests.length > 0 ?
                                <>
                                    <div className="data-form-table-header">Name<RedAsterisk/></div>
                                    <div className="data-form-table-header textalign-right">Allocated %</div>
                                    <Placeholder/>
                                </> : null}
                            {formData.residuaryMemberBequests.map((bequest, index) => {
                                const isBequestNameMissing = showRemainingBequestNameRequiredError && formData.residuaryMemberBequests.length - 1 === index;
                                return <React.Fragment key={index}>
                                    <MemberDropdown
                                        aria-label={`Remaining Estate Individual Member ${index}`}
                                        value={bequest.memberId}
                                        members={eligibleResiduaryMembersPlusSelected(bequest)}
                                        onChange={(e) => onResiduaryBequestMemberChange(e, index)}
                                        onBlur={onEditFinished}
                                        className={classNames("data-form-table-row", isBequestNameMissing ? "bequest-name-missing" : '')}
                                        error={isBequestNameMissing ? "This field is required." : undefined}
                                    />
                                    <PercentInput
                                        label={`Remaining Estate Individual Percentage ${index}`}
                                        value={bequest.percentage}
                                        defaultValue={"0"}
                                        hideInlineLabel
                                        hideLabel
                                        className={classNames("data-form-table-row", isBequestNameMissing ? "bequest-name-missing-percent-input" : '')}
                                        size="medium"
                                        textAlign="right"
                                        onChange={(e) => onPercentChange(e, index)}
                                        onBlur={onEditFinished}
                                    />
                                    <DeleteButton
                                        className={classNames("button-delete", isBequestNameMissing ? "bequest-name-missing-error-spacing" : '')}
                                        aria-label={`Remove Remaining Estate Individual Bequest ${index}`}
                                        onDelete={() => onRemoveRemainingBequest(index, bequest)}
                                    />
                                </React.Fragment>
                            })}
                        </div>
                        {formData.residuaryMemberBequests.length > 0 &&
                            <div className="side-drawer-form-content-subtotals">
                                <div className="side-drawer-form-content-subtotals-row">
                                    <div>% of Remaining Estate Allocated</div>
                                    <div aria-label="Percentage of Remaining Estate Allocated">
                                        {remainingEstateTotalPercentage() + "%"}
                                    </div>
                                </div>
                            </div>}
                    </section>
                </article>
                <aside>
                    <DataEntrySummary
                        title="Estimated Estate Summary"
                        items={[
                            {
                                label: "Current Estate Value",
                                testId: "current-estate-value",
                                value: formatCurrency(estateSummary.estateValue)
                            },
                            {
                                label: "Estimated Estate Tax",
                                testId: "estimated-estate-tax",
                                value: formatCurrency(estateSummary.estimatedEstateTax)
                            },
                            {
                                label: "Amount to Beneficiaries",
                                testId: "amount-to-beneficiaries",
                                value: formatCurrency(estateSummary.beneficiaryTotalValue)
                            },
                        ]}
                    />
                    <DataEntrySummary
                        title="Estimated Net Value"
                        items={estimatedNetValues.length === 0 ?
                            [emptyEstimatedNetValue] :
                            estimatedNetValues
                                .map(({label, value}, index) => ({
                                    testId: `estimated-net-value-${index}`,
                                    label,
                                    value,
                                }))}
                    />
                </aside>
            </div>
        </div>
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={showDiscardChangesModal}
            headerText={'Discard changes?'}
            size="small"
            buttons={
                [
                    {
                        text: 'Keep Editing',
                        onClick: (() => setShowDiscardChangesModal(false)),
                    },
                    {
                        text: 'Discard Changes',
                        onClick: (() => onCancel()),
                        destructive: true,
                        primary: true,
                    }
                ]
            }
        >
            <div className="font-md">{`Any entered data on the Edit Beneficiaries page will not be saved.`}</div>
        </ModalWrapper>

    </>;
}
