import React, {useEffect, useState} from 'react';
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import GoalsService from "./GoalsService";
import FamilyGoal from "../Family/FamilyGoalContainer";
import {deleteNonLifestyleGoal, selectGoalModel, setGoalModel} from "./GoalsModelSlice";
import Summary from "../Summary/Summary";
import LoadingIndicator from "../../pages/LoadingIndicator";
import LifestyleSpendingForm from "../LifestyleSpendingForm/LifestyleSpendingForm";
import AddFamilyGoal from "../Family/form/AddFamilyGoal";
import {GoalModelType} from "../models/GoalModelType";
import AddDiscretionaryGoal from "../Discretionary/AddDiscretionaryGoal";
import AddPhilanthropicGoal from "../Philanthropic/AddPhilanthropicGoal";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import Funding from "../Funding/Funding";
import Prioritization from "../Prioritization/Prioritization";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../components/Modal/Error/GenericErrorModal";
import {EmptyStateContainer} from "../../components";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {goalsApiClient} from "../GoalsApiClient";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";


const Goals = () => {
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const previousGoalModel: GoalModelType = useAppSelector<GoalModelType>(selectGoalModel);
    const {nonLifestyleGoalChanged, lifestyleSpendingGoal} = previousGoalModel;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false)
    const totalLifestyleAnnualSpend = lifestyleSpendingGoal?.userInputs.lifestyleSpendingPeriods.reduce(
        (accumulator, currentValue) => accumulator + currentValue.annualSpend, 0);

    const handleError = ((error: Error | any) => {
        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: error.name ? error.name : `Failed to load goals`,
            operationId: error.operationId ? error.operationId : error?.correlationId
        });
    })
    usePageViewTimer('Goal Summary Page Load Timer (milliseconds)', isLoading);

    function loadGoals() {
        GoalsService.getGoalsModel(id, previousGoalModel, handleError)
            .then(goalResponse => {
                dispatch(setGoalModel(goalResponse))
                setIsLoading(false)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        loadGoals();
    }, [id])

    useEffect(() => {
        if (nonLifestyleGoalChanged && totalLifestyleAnnualSpend > 0) {
            loadGoals()
        }
    }, [nonLifestyleGoalChanged])

    const closeErrorModal = () => {
        setGenericError({...genericError, isOpen: false});
    };

    const handleOnClickButtonGenericErrorModal = () => {
        closeErrorModal();
        loadGoals()
    };

    const handleOnRequestCloseGenericErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true); //TODO remove this once we determine what needs to be shown if the error modal is closed
    }

    if (genericError.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={genericError}
                onClickButton={handleOnClickButtonGenericErrorModal}
                onRequestClose={handleOnRequestCloseGenericErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        );
    }

    /*
    TODO remove this and the showTempErrorMessage state variable once a future story defines what should be displayed
    if the error modal is closed by clicking the X button.
    */
    if(showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-assets-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        );
    }

    const handleEditFamilyGoal = (selectedGoalType: string, savedGoal: FamilyGoalType) => {
        history.push(
            `/Profile/${id}/ClientProfile/Goals/EditFamilyGoal`,
            {
                ...history.location.state,
                sourcePage: "FamilyGoal",
                selectedGoalSubType: selectedGoalType,
                savedGoal
            }
        );
    }

    const handleViewFamilyGoal = (memberSelected: MemberType) => {
        history.push(
            `/Profile/${id}/ClientProfile/Goals/FamilyGoal`,
            {memberSelected}
        )
    }

    const handleDeleteNonLifestyleGoal = (goal: NonLifestyleGoalType) => {
        goalsApiClient.deleteNonLifestyleGoal(goal.id!)
            .then(response => {
                if (response.status === 204) {
                    dispatch(deleteNonLifestyleGoal({goal}))
                }
            })
    }


    const handleEditNonLifestyleGoal = (savedGoal: NonLifestyleGoalType, type: GoalType) => {
        const editURL = type === GoalType.DISCRETIONARY ? "EditDiscretionaryGoal" : "EditPhilanthropicGoal";
        history.push(
            `/Profile/${id}/ClientProfile/Goals/${editURL}`,
            {
                ...history.location.state,
                sourcePage: "Summary",
                savedGoal
            }
        )
    };

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <Switch>
            <Route path="/Profile/:id/ClientProfile/Goals/AddLifestyleSpending"
                   render={() => <LifestyleSpendingForm/>
            }/>
            <Route path="/Profile/:id/ClientProfile/Goals/EditLifestyleSpending"
                   render={() => <LifestyleSpendingForm isEditing={true}/>}/>
            <Route path="/Profile/:id/ClientProfile/Goals/FamilyGoal" render={() => <FamilyGoal/>}/>
            <Route path="/Profile/:id/ClientProfile/Goals/AddFamilyGoal"
                   component={AddFamilyGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/EditFamilyGoal"
                   component={AddFamilyGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/AddDiscretionaryGoal"
                   component={AddDiscretionaryGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/EditDiscretionaryGoal"
                   component={AddDiscretionaryGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/AddPhilanthropicGoal"
                   component={AddPhilanthropicGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/EditPhilanthropicGoal"
                   component={AddPhilanthropicGoal}/>
            <Route path="/Profile/:id/ClientProfile/Goals/Prioritization"
                   component={Prioritization}/>
            <Route path="/Profile/:id/ClientProfile/Goals/Summary">
                <Summary
                    onEditFamilyGoal={handleEditFamilyGoal}
                    onViewFamilyGoal={handleViewFamilyGoal}
                    onDeleteNonLifestyleGoal={handleDeleteNonLifestyleGoal}
                    onEditNonLifestyleGoal={handleEditNonLifestyleGoal}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/Goals/Funding"
                   component={Funding}/>
        </Switch>)
}

export default Goals
