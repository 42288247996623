import React, {ChangeEvent} from "react";
import PercentInput from "../../../components/Input/PercentInput";
import {Checkbox} from "xps-react";
import {MemberGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {MemberOwnershipFormData} from "../../models/Ownership";
import {calculateTotalOwnedValue} from "../helpers";

type MemberOwnershipRowProps = {
    showStaticPercentage: boolean,
    totalAssetValue?: number | null,
    inputLabel: string,
    memberOwnerships: MemberOwnershipFormData[],
    member: MemberGroupMember
    onChange: (memberOwnership: MemberOwnershipFormData, memberId: string) => void,
    showIrrevocableTrust: boolean,
    isProfileWithProposalsOrArchived?: boolean
}

export function MemberOwnershipRow({
   showStaticPercentage,
   totalAssetValue,
   inputLabel,
   memberOwnerships,
   member,
   onChange,
   showIrrevocableTrust,
   isProfileWithProposalsOrArchived = false
}: MemberOwnershipRowProps) {
    const existingOwnership = memberOwnerships.find(ownership => ownership.memberId === member.id)
    const defaultOwnership = {
        percentage: "0",
        memberId: member.id,
        isRevocableTrust: false
    }
    const memberOwnership = existingOwnership || defaultOwnership;
    const totalOwnedValue = calculateTotalOwnedValue(memberOwnership.percentage, totalAssetValue)

    return (
        <div role={showIrrevocableTrust ? "5-column-row" : "4-column-row"} className="ownership-table-row">
            <div>{member.firstName} {member.lastName}</div>
            <div className="center-align">{member.age}</div>
            <div className="center-align">
                {showStaticPercentage
                    ? memberOwnership.percentage.toString()
                    : <PercentInput defaultValue={"0"}
                                    value={memberOwnership.percentage.toString()}
                                    label={inputLabel}
                                    hideInlineLabel
                                    hideLabel
                                    minValue={0}
                                    maxValue={100}
                                    onChange={(value) => {
                                        onChange({
                                            ...memberOwnership,
                                            percentage: value
                                        }, member.id)
                                    }}
                                    disabled={isProfileWithProposalsOrArchived}
                    />}
            </div>
            { showIrrevocableTrust &&
            <div className="ownership-table-checkbox-field">
                <Checkbox
                    aria-label={`REV TRUST for ${member.firstName} ${member.lastName} Age ${member.age}`}
                    value={memberOwnership.isRevocableTrust}
                    checked={memberOwnership.isRevocableTrust}
                    name={`REV TRUST for ${member.firstName} ${member.lastName} Age ${member.age}`}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onChange({
                            ...memberOwnership,
                            isRevocableTrust: event.target.checked
                        }, member.id)
                    }}
                    disabled={isProfileWithProposalsOrArchived}
                />
            </div> }
            <div className="right-align"> {totalOwnedValue} </div>
        </div>
    );
}

