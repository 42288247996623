import React, {useMemo} from "react";
import {Accordion, AccordionItem} from "../../../components";
import {GoalType} from "../../../Goals/models/GoalType";
import NonLifestyleGoalListHeader from "../../../Goals/Summary/NonLifestyleGoalListHeader";
import {formatCurrency} from "../../../utils/format";
import FamilyGoalUtils from "../../../Goals/Summary/FamilyGoalUtils";
import {FamilySummaryColumnHeader} from "../../../Goals/Summary/SummaryFamilyGoalList";
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import FamilyGoalsSummaryItems from "../../../Goals/Summary/FamilyGoalsSummaryItems";
import {NO_OP} from "../../../constants/common";

interface SummaryFamilyGoalListReportProps {
    goalModel: GoalModelType,
    label: string,
    allGoalsModel: GoalModelType
}

const SummaryFamilyGoalListReport = ({goalModel, label, allGoalsModel}: SummaryFamilyGoalListReportProps) => {
    const {
        familyGoals,
        discretionaryGoals,
        philanthropicGoals,
        investorGroup
    } = goalModel;

    const familyGoalArrayIndex: Array<string> = useMemo(() => {
            return Object.values(allGoalsModel.familyGoals).map((value, index) => {
                return "FamilyGoalAccordionItem" + index;
            })
        }, [familyGoals]
    )

    return (
        <>
            <section>
                <div className="accordion-tables" data-testid={`${GoalType.FAMILY}-goal-list`}>
                    <Accordion
                        expanded={["FamilyGoalReport", ...familyGoalArrayIndex]}
                        accordionId="SummaryFamilyGoalListAccordionReport"
                        allowMultipleExpanded
                        allowZeroExpanded
                    >
                        {(discretionaryGoals.length === 0 && philanthropicGoals.length === 0) &&
                            <NonLifestyleGoalListHeader/>}
                        <AccordionItem
                            uuid="FamilyGoalReport"
                            accentColor="#FFC24B"
                            primaryText={label}
                            rightAlignedContent={
                                <div className="display-flex align-items-center"
                                     data-testid="family-goal-accordion-item">
                                            <span className="font-lg paddingright-xl">
                                                {formatCurrency(FamilyGoalUtils.getCumulativeTotalMyResponsibility(allGoalsModel.familyGoals))}
                                            </span>
                                </div>
                            }
                        >
                            <FamilySummaryColumnHeader/>
                            <FamilyGoalsSummaryItems familyGoals={familyGoals} investorGroup={investorGroup}
                                                     onEditGoal={NO_OP} onViewGoal={NO_OP} allFamilyGoalsForReport={allGoalsModel.familyGoals}/>
                        </AccordionItem>
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default SummaryFamilyGoalListReport;
