import {Input, RedAsterisk, RequiredFieldsSubheader, UnderlinedHeader} from "../../../components";
import React, {ChangeEvent} from "react";
import {PartiallyOwnedInvestmentAccountWriteModel} from "../../models/PartiallyOwnedInvestmentAccount";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type PartiallyOwnedInvestmentAccountDetailsProps = {
    account: PartiallyOwnedInvestmentAccountWriteModel,
    onChangeAccount: (updatedAccount: PartiallyOwnedInvestmentAccountWriteModel) => void
}

export function PartiallyOwnedInvestmentAccountDetails({
                                                           account,
                                                           onChangeAccount
                                                       }: PartiallyOwnedInvestmentAccountDetailsProps) {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    return (
        <article>
            <section className="account-details">
                <UnderlinedHeader primaryText="Account Details"
                                  rightAlignedContent={<RequiredFieldsSubheader/>}/>

                <div className="layout-data-entry-form__field">
                    <label id="nameInput-label"><b>Name<RedAsterisk/></b></label>
                    <Input
                        aria-labelledby="nameInput-label"
                        value={account.name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onChangeAccount({
                                ...account,
                                name: e.target.value
                            })
                        }}
                        readOnly={isProfileWithProposalsOrArchived}
                    />
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="accountNumber-label">
                        <b>Account Number</b>
                    </label>
                    <span role="note" aria-labelledby="accountNumber-label" className="paddingleft-md">
                                {account.accountNumber}
                            </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="legalAgreementName-label">
                        <b>Legal Agreement Name</b>
                    </label>
                    <span role="note" aria-labelledby="legalAgreementName-label" className="paddingleft-md">
                                {account.legalAgreementName}
                            </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="legalAgreement-label">
                        <b>Legal Agreement</b>
                    </label>
                    <span role="note" aria-labelledby="legalAgreement-label" className="paddingleft-md">
                                {account.legalAgreementNumber}
                            </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="entityType-label">
                        <b>Entity Type</b>
                    </label>
                    <span role="note" aria-labelledby="entityType-label" className="paddingleft-md">
                                {account.entityType}
                            </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="institution-label">
                        <b>Institution</b>
                    </label>
                    <span role="note" aria-labelledby="institution-label" className="paddingleft-md">
                                {account.institution}
                            </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="taxStatus-label">
                        <b>Tax Status</b>
                    </label>
                    <span role="note" aria-labelledby="taxStatus-label" className="paddingleft-md">
                                {account.taxStatus}
                            </span>
                </div>
            </section>
        </article>
    )
}