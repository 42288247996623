import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../store/hooks";
import {selectGoalModel} from "../../../Goals/controller/GoalsModelSlice";
import {GoalModelType} from "../../../Goals/models/GoalModelType";
import SummaryBarChartSideBar from "../../../Goals/BarChartSidebar/SummaryBarChartSideBar";
import NoGoalsLandingPage from "../../../Goals/Summary/NoGoalsLandingPage";
import GoalListReport from "./GoalListReport";
import {splitGoalsSummaryData} from "./reportUtil";
import PrintViewWrapper from "../PrintViewWrapper";

interface GoalsSummaryWrapperProps {
    goalsSummary: GoalModelType,
    displayName: string
}

const GoalsSummaryWrapper = ({goalsSummary, displayName}: GoalsSummaryWrapperProps) => {
    const {
        lifestyleSpendingGoal,
        familyGoals,
        discretionaryGoals,
        philanthropicGoals
    } = goalsSummary

    const clientHasFamilyGoals = !!familyGoals && Object.values(familyGoals).length > 0;
    const clientHasLifestyleGoals = !!lifestyleSpendingGoal.id;
    const clientHasNonLifestyleGoals = [...discretionaryGoals, ...philanthropicGoals].some(nonLifestyleGoal => !!nonLifestyleGoal.id);

    const clientHasSavedGoals = clientHasLifestyleGoals || clientHasFamilyGoals || clientHasNonLifestyleGoals;

    const [splittedGoalsSummary, setSplittedGoalsSummary] = useState<Array<GoalModelType>>([]);

    useEffect(() => {
        const defaultData = {
            ...goalsSummary,
            discretionaryGoals: [],
            philanthropicGoals: [],
            lifestyleSpendingGoal: {},
            familyGoals: {},
            taxLiabilities: {}
        }
        splitGoalsSummaryData(goalsSummary, defaultData).then((res: Array<GoalModelType>) => {
            setSplittedGoalsSummary(res);
        });
    }, []);

    return (<>
            {splittedGoalsSummary.map((goalsData, index) => {
                return (
                    <PrintViewWrapper displayName={displayName} key={index}>
                        <div className="goals-summary-container layout-split-sidebar">
                            <SummaryBarChartSideBar pageIndex={index}/>
                            {clientHasSavedGoals ? <GoalListReport goalModel={goalsData} pageNo={index} allGoalsModel={goalsSummary}
                                                                   goalsSummaryList={splittedGoalsSummary}/> :
                                <NoGoalsLandingPage/>}
                        </div>
                    </PrintViewWrapper>
                )
            })}
        </>
    )
}

type goalsSummaryContainerProps = {
    displayName: string
}

const GoalsSummaryContainer = ({displayName}: goalsSummaryContainerProps) => {
    const goalModel = useAppSelector<GoalModelType>(selectGoalModel);

    return <GoalsSummaryWrapper goalsSummary={goalModel} displayName={displayName}/>
}

export default GoalsSummaryContainer;
