import {TableCell} from "../../../components/Table/TableCell";
import React from "react";
import {Icon} from "../../../components";
import {PartiallyOwnedInvestmentAccountReadModel} from "../../models/PartiallyOwnedInvestmentAccount";
import moment from "moment/moment";
import {DISPLAY_DATE_FORMAT} from "../../../constants/common";

export const AssetSummaryPartiallyOwnedInvestmentAccountDetails = ({legalAgreement}: { legalAgreement: PartiallyOwnedInvestmentAccountReadModel }) => {
    moment.suppressDeprecationWarnings = true;
    const presentableAsOfDate = legalAgreement.asOfDate ? `As of ${moment(legalAgreement.asOfDate).format(DISPLAY_DATE_FORMAT)}` : '--';
    const shouldShowAccountNumber = !!legalAgreement.institution && !!legalAgreement.number && legalAgreement.number.length > 3;
    const presentableAccountNumber = `(...${legalAgreement.number?.substring(legalAgreement.number.length - 4)})`;

    const legalAgreementText = (
        <div role="cell" style={{paddingLeft: "0px"}}>
            <div className="cell-with-popover">
                <div className="label-15px cell-text-content paddingright-sm">
                    <span>{legalAgreement.name}</span>
                </div>
            </div>
        </div>)

    const legalAgreementSubtext = (
        <>
            {presentableAsOfDate}
            <Icon name="status_processing" className='paddingleft-md' ariaHidden={false}
                  ariaLabel="Legal Agreements are Updated"/>
        </>
    );

    return <>
        <TableCell text={legalAgreementText} subtext={legalAgreementSubtext}/>
        <TableCell text={legalAgreement.institution} subtext={shouldShowAccountNumber ? presentableAccountNumber : undefined} textSize="small"/>
        <TableCell text={legalAgreement.entityType} subtext={legalAgreement.taxStatus} textSize="small"/>
        <TableCell text={'$0'} className="textalign-right"/>
    </>;
};
