import {CurrencyInput, Dropdown, Input, RedAsterisk, RequiredFieldsSubheader, UnderlinedHeader} from "../../components";
import React, {ChangeEvent, useState} from "react";
import {NonLifestyleGoalType} from "../models/GoalType";

export interface GoalDetailsFormProps {
    nonLifestyleGoal: NonLifestyleGoalType
    typeDropdownItems: JSX.Element[],
    beneficiary?: string,
    handleGoalTypeChange: (e: any) => void,
    updateNonLifestyleGoal: (updatedNonLifestyleGoal: Partial<NonLifestyleGoalType['userInputs']>) => void,
    updatePresentValue: () => void,
    isProfileWithProposalsOrArchived?: boolean
}

export default function GoalDetailsForm({
                                            nonLifestyleGoal,
                                            typeDropdownItems,
                                            beneficiary,
                                            handleGoalTypeChange,
                                            updateNonLifestyleGoal,
                                            updatePresentValue,
                                            isProfileWithProposalsOrArchived = false
                                        }: GoalDetailsFormProps) {

    const [isDescriptionInlineErrorShown, setIsDescriptionInlineErrorShown] = useState(false);

    return <section aria-label="Goal Details">
        <UnderlinedHeader
            primaryText="Goal Details"
            secondaryContent={<RequiredFieldsSubheader/>}
        />
        <div className="family-goal-details-table">
            {beneficiary &&
                <>
                    <label className="beneficiary-label">Beneficiary </label>
                    <div style={{marginTop: "12px"}}>
                                    <span className="paddingleft-md" role="note" aria-label="beneficiary">
                                        {beneficiary}
                                    </span>
                    </div>
                </>
            }
            <label>Type</label>
            <Dropdown
                className="drop-wrapper"
                name="goalType"
                id="goalTypeInput"
                aria-labelledby="goalType"
                size="medium"
                value={nonLifestyleGoal.userInputs.selectedGoalSubType}
                onChange={(e) => {
                    handleGoalTypeChange(e)
                    setIsDescriptionInlineErrorShown(false)
                }}
                disabled={isProfileWithProposalsOrArchived}
            >
                {typeDropdownItems}
            </Dropdown>
            <label>Description<RedAsterisk/></label>
            <Input
                className="descriptionFieldInput"
                name="descriptionField"
                aria-label="description"
                aria-labelledby="descriptionFieldInput-label"
                id="descriptionFieldInput"
                removeMarginTop
                size="medium"
                type="text"
                maxLength={100}
                value={nonLifestyleGoal.userInputs.description}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setIsDescriptionInlineErrorShown(false)
                    const value = e.target.value;
                    updateNonLifestyleGoal({description: value})
                }
                }
                onBlur={() => {
                    setIsDescriptionInlineErrorShown(false)
                    if (!nonLifestyleGoal.userInputs.description ||
                        nonLifestyleGoal.userInputs.description === "") {
                        setIsDescriptionInlineErrorShown(true)
                    }
                }}
                error={isDescriptionInlineErrorShown ? "Description is required." : undefined}
                readOnly={isProfileWithProposalsOrArchived}
            />
            <label>Annual Flow</label>
            <CurrencyInput
                aria-label="annualFlow"
                name="annualFlow"
                value={nonLifestyleGoal.userInputs.annualFlow}
                onChangeValue={(e) => updateNonLifestyleGoal({annualFlow: parseInt(e.target.value) ? parseInt(e.target.value) : 0})}
                onBlur={() => {
                    updatePresentValue();
                }}
                readOnly={isProfileWithProposalsOrArchived}
            />
        </div>
    </section>;
}
